import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab, MenuItem,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import {withRouter} from "react-router-dom";
import LabelEditor from "../LabelEditor";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class CrackNumberNumbers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            numbers: [],
            displayedNumbers: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
            languageId: 1,
            filterCategoryId: -1,
            filterType: -1,
            filterName: "",
            sortType: "category"
        };
    }

    componentDidMount() {
        this.updateData(this.state.languageId);
    }

    updateData(languageId) {
        Promise.all([
            api.getAppLanguages(this.props.app.app_id),
            api.getCrackNumberCategoriesByLanguage(languageId),
            api.getCrackNumberNumbers(languageId),
        ]).then(res => {
            const languages = res[0];
            const categories = res[1];
            const numbers = res[2];
            this.setState({
                languages: languages,
                categories: categories,
                numbers: numbers,
                displayedNumbers: this.sortNumbers(numbers.filter(l => this.numberMatchesFilters(l, this.state.filterCategoryId, this.state.filterName))),
            });
        });
    }

    sortNumbers(numbers, sortType) {
        let sort = !!sortType ? sortType : this.state.sortType
        switch (sort) {
            case "voteUp": return numbers.sort((n1, n2) => n2.vote_up - n1.vote_up)
            case "voteDown": return numbers.sort((n1, n2) => n2.vote_down - n1.vote_down)
            case "playCount": return numbers.sort((n1, n2) => n2.play_count - n1.play_count)
            default: return numbers.sort((n1, n2) => n1.category_id - n2.category_id)
        }
    }

    editNumber = (nb) => (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/number/${nb.number_id}`);
    };

    createNumber = (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/create-number/${this.state.languageId}`);
    };

    deleteNumber = (nb) => (e) => {
        if (!window.confirm('Supprimer le number ?')) {
            return;
        }
        api.deleteCrackNumberNumber(nb.number_id).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la suppression du number");
                return;
            }
            this.updateData(this.state.languageId);
        });
    }

    handleFilterCategoryChange = (e) => {
        this.setState({
            filterCategoryId: e.target.value,
            displayedNumbers: this.sortNumbers(this.state.numbers.filter(l => this.numberMatchesFilters(l, parseInt(e.target.value, 10), this.state.filterName)))
        });
    }

    handleFilterNameChange = (e) => {
        this.setState({
            filterName: e.target.value,
            displayedNumbers: this.sortNumbers(this.state.numbers.filter(l => this.numberMatchesFilters(l, this.state.filterCategoryId, e.target.value)))
        });
    }

    numberMatchesFilters(nb, categoryId, name) {
        return (name.length === 0 || (!!nb.question && nb.question.toLowerCase().indexOf(name.toLowerCase()) !== -1))
            && (categoryId === -1 || nb.category_id === categoryId);
    }

    categoryName(categoryId) {
        return LabelEditor.getLabelValue(this.state.categories.find(c => c.category_id === categoryId).label)
    }

    handleLanguageChange = (e) => {
        this.setState({languageId: e.target.value});
        this.updateData(e.target.value);
    };

    handleSortChange = (e) => {
        this.setState({
            sortType: e.target.value,
            displayedNumbers: this.sortNumbers(this.state.numbers.filter(l => this.numberMatchesFilters(l, this.state.filterCategoryId, this.state.filterName)), e.target.value)
        })
    }

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (!this.state.languages) {
            return null;
        }

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Numbers</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '0 24px'}}>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.state.languageId}
                                onChange={this.handleLanguageChange}
                                label="Langue"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                {
                                    this.state.languages.map((l, i) => <MenuItem key={l.language_id} value={l.language_id}>{l.name}</MenuItem>)
                                }
                            </TextField>

                        </div>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.state.filterCategoryId}
                                onChange={this.handleFilterCategoryChange}
                                label="Catégorie"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                <MenuItem value={-1}>Toutes les catégories ({this.state.numbers.length})</MenuItem>
                                {
                                    this.state.categories.map((c, i) => <MenuItem key={i} value={c.category_id}>{LabelEditor.getLabelValue(c.label)} ({c['number_count']})</MenuItem>)
                                }
                            </TextField>

                            <TextField
                                style={{marginRight: 24, width: 300}}
                                value={this.state.filterName}
                                label="Intitulé"
                                placeholder="Filtrer sur l'intitulé"
                                onChange={this.handleFilterNameChange}
                            />

                        </div>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.state.sortType}
                                onChange={this.handleSortChange}
                                label="Trier les numbers…"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                <MenuItem value="category">Par catégorie</MenuItem>
                                <MenuItem value="voteUp">Vote 👍</MenuItem>
                                <MenuItem value="voteDown">Vote 👎</MenuItem>
                                <MenuItem value="playCount">Les plus joués 🕹</MenuItem>
                            </TextField>

                        </div>

                    </div>

                    <div style={{padding: '24px'}}>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: 80}}>ID</TableCell>
                                    <TableCell style={{width: 200}}>Catégorie</TableCell>
                                    <TableCell style={{width: 200}}>Stats</TableCell>
                                    <TableCell style={{width: 500}}>Intitulé</TableCell>
                                    <TableCell style={{width: 200}}>Réponse</TableCell>
                                    <TableCell style={{width: 260}}>Source</TableCell>
                                    <TableCell style={{width: 180}}/>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.displayedNumbers.map((nb, i) => (
                                    <TableRow key={nb.number_id}>
                                        <TableCell>{nb.number_id}</TableCell>
                                        <TableCell>{this.categoryName(nb.category_id)}</TableCell>
                                        <TableCell>{nb.vote_up} 👍 - {nb.vote_down} 👎 - {nb.play_count} 🕹</TableCell>
                                        <TableCell>{nb.question}</TableCell>
                                        <TableCell><strong>{nb.answer}</strong> <em>{!!nb.answer_unit ? nb.answer_unit : ""}</em></TableCell>
                                        <TableCell>{nb.answer_source}</TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={this.editNumber(nb)}><EditIcon/></Button>
                                            <Button color="secondary" onClick={this.deleteNumber(nb)}><DeleteIcon/></Button>
                                        </TableCell>
                                        <TableCell/>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.createNumber}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

CrackNumberNumbers.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackNumberNumbers.defaultProps = {};

export default withRouter(CrackNumberNumbers);