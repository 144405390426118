import React from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import AppImageCard from "./AppImageCard";
import Pagination from "../tools/Pagination";
import SearchBox from "../tools/SearchBox";

class ImagePicker extends React.Component {

    constructor(props) {
        super(props);
        const images = this.getImagesData(this.props.images);
        this.state = {
            images: images,
            filteredImages: images,
            filterText: '',
            imagePerPage: 20,
            currentPage: 0,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContent) {
        this.resetImages(nextProps.images);
    }

    resetImages(fromImages) {
        const images = this.getImagesData(fromImages);
        this.setState({
            images: images,
        });
        this.filterImages(images, this.state.filterText);
    }

    getImagesData(images) {
        return images.map(image => {
            return {
                data: image,
                selected: false,
            };
        });
    }

    onImageClick = (image, idx) => () => {
        if (this.props.selectable) {
            this.onImageSelected(image, idx);
        } else {
            this.props.onSelect(image.data);
        }
    };

    onImageSelected = (image, idx) => {
        let images = this.state.images.map(i => {
            if (i.data.image_id === image.data.image_id) {
                return {
                    ...i,
                    selected: !i.selected
                };
            }
            return i;
        });
        this.setState({
            images: images,
        });
        this.filterImages(images, this.state.filterText);
    };

    onCancelClick = () => {
        this.resetImages(this.props.images);
        this.props.onCancel();
    };

    onSaveClick = () => {
        this.props.onSave(this.state.images.filter(image => image.selected).map(image => image.data), (images) => {
            this.resetImages(images);
        });
    };

    onFilterChange = (filter) => {
        this.setState({
            filterText: filter
        });
        this.filterImages(this.state.images, filter);
    };

    filterImages(images, filter) {
        if (this.props.searchable) {
            let filterText = filter.trim().toLowerCase();
            if (filterText.length < 3) {
                this.setState({
                    filteredImages: images
                });
                return;
            }
            const filteredImages = images.filter((image) => {
                return image.data.name.toLowerCase().includes(filterText);
            });
            this.setState({
                filteredImages: filteredImages,
                currentPage: this.props.pagination ? Pagination.clampedCurrentPage(this.state.currentPage, filteredImages.length, this.state.imagePerPage) : 0,
            });
        } else {
            this.setState({
                filteredImages: images,
                currentPage: this.props.pagination ? Pagination.clampedCurrentPage(this.state.currentPage, images.length, this.state.imagePerPage) : 0,
            });
        }
    }

    getFilterHelperText() {
        if (this.state.filteredImages.length === 0) {
            return 'Aucune image ne correspond à cette recherche.';
        }
        return "Affichage des images "
            +  (Pagination.getCurrentMinimumImageIndex(this.state.currentPage, this.state.imagePerPage) + 1)
            + " à "
            + (Pagination.getCurrentMaximumImageIndex(this.state.filteredImages.length, this.state.currentPage, this.state.imagePerPage + 1))
            + " sur "
            + this.state.filteredImages.length
            + " correspondant à cette recherche";
    }

    static getSelectedImages(images) {
        return images.filter(i => i.selected);
    }

    static getSelectedHelperText(numberOfSelectedImages) {
        switch (numberOfSelectedImages) {
            case 0: return 'Aucune image sélectionnée';
            case 1: return <React.Fragment><strong>1</strong> image sélectionnée</React.Fragment>;
            default: return <React.Fragment><strong>{numberOfSelectedImages}</strong> images sélectionnées</React.Fragment>;
        }
    }

    render() {

        const numberOfPages = Pagination.getNumberOfPages(this.state.filteredImages.length, this.state.imagePerPage);
        const numberOfSelectedImages = ImagePicker.getSelectedImages(this.state.images).length;

        let pagination = this.props.pagination ? (
            <Pagination
                numberOfPages={numberOfPages}
                currentPage={this.state.currentPage}
                onPageChange={(i) => this.setState({currentPage: i})}
            />
        ) : null;

        const displayedImages = this.props.pagination ? Pagination.currentPageObjects(this.state.filteredImages, this.state.currentPage, this.state.imagePerPage) : this.state.filteredImages;

        return (
            <div style={{width: '100%'}}>

                {
                    this.props.selectable ? (
                        <Paper
                            style={{
                                padding: '12px 24px',
                                display: 'flex',
                                maxWidth: 500,
                                margin: 'auto',
                                alignItems: 'center'
                            }}
                            elevation={1}
                        >
                            <Typography style={{flex: 1}}>
                                {ImagePicker.getSelectedHelperText(numberOfSelectedImages)}
                            </Typography>
                            <Button onClick={this.onCancelClick}>
                                Annuler
                            </Button>
                            <Button
                                onClick={this.onSaveClick}
                                color="primary"
                                disabled={numberOfSelectedImages === 0}
                            >
                                {this.props.saveButtonText}
                            </Button>
                        </Paper>
                    ) : null
                }

                {
                    this.props.searchable ? (
                        <SearchBox
                            placeholder={this.props.filterPlaceholder}
                            onFilterChange={this.onFilterChange}
                            helperText={this.getFilterHelperText()}
                        />
                    ) : null
                }

                {pagination}
                {
                    displayedImages.map((image, idx) => {
                        return (
                            <AppImageCard
                                key={image.data.image_id}
                                image={image.data}
                                selectable={this.props.selectable}
                                selected={image.selected}
                                onSelect={this.onImageClick(image, idx)}
                                onDelete={this.props.onDelete(image.data)}
                                deletable={!this.props.draggable && this.props.deletable}
                                editable={this.props.editable}
                                onEdit={this.props.onEdit(image.data)}
                                showActions={this.props.showActions}
                                draggable={this.props.draggable}
                                onMove={this.props.onMove}
                                onDragBegin={this.props.onDragBegin}
                                onDragEnd={this.props.onDragEnd}
                                index={idx}
                            />
                        )
                    })
                }
                {pagination}


            </div>
        )
    }
}

function requireIfDraggable(error) {
    return (props, propName, componentName) => {
        if ((!!props.draggable && (props[propName] === undefined || typeof(props[propName]) !== 'function'))) {
            return new Error(error);
        }
    };
}

ImagePicker.propTypes = {
    selectable: PropTypes.bool,
    deletable: PropTypes.bool,
    editable: PropTypes.bool,
    filterText: PropTypes.string,
    images: PropTypes.array.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onSelect: PropTypes.func,
    saveButtonText: PropTypes.string,
    filterPlaceholder: PropTypes.string,
    onDelete: PropTypes.func,
    searchable: PropTypes.bool,
    pagination: PropTypes.bool,
    showActions: PropTypes.bool,
    draggable: PropTypes.bool,
    onMove: requireIfDraggable('Please provide a onMove(sourceIndex, targetIndex) function as prop.'),
    onDragBegin: requireIfDraggable('Please provide a onDragBegin(index) function as prop.'),
    onDragEnd: requireIfDraggable('Please provide a onDragEnd() function as prop.'),
};

ImagePicker.defaultProps = {
    selectable: false,
    saveButtonText: 'Enregistrer',
    deletable: false,
    editable: false,
    filterPlaceholder: 'Filtrer la liste des images',
    onDelete: (image) => () => {},
    onEdit: (image) => () => {},
    searchable: true,
    pagination: true,
    showActions: false,
    draggable: false,
};

export default ImagePicker;