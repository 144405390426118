import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab, FormControlLabel,
    IconButton, MenuItem,
    Paper,
    Snackbar, Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import {DateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import KVC from "../../services/KVC";
import ColorPicker from "material-ui-color-picker";
import LabelEditor from "../LabelEditor";
import classNames from "classnames";
import WordsOutImagePicker from "./WordsOutImagePicker";

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, "d MMM yyyy", { locale: this.locale });
    }
}

class WordsOutTournaments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tournaments: [],
            filteredTournaments: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
            createTournamentModal: false,
            createTournament: this.getCreateTournament(),
            editedLabel: null,
            editedTournamentIndex: null,
            pickImageTournamentId: -1,
            filter: 1,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    getCreateTournament() {
        return {
            start_date: (+ new Date()) / 1000,
            end_date: (+ new Date() + 7 * 86400000) / 1000,
            card_count: 30
        };
    }

    updateData() {
        Promise.all([
            api.getLanguages(),
            api.getWordsOutTournaments(),
        ]).then(res => {
            const languages = res[0];
            const tournaments = res[1];
            this.setState({
                languages: languages,
                tournaments: tournaments,
                filteredTournaments: this.filterTournaments(this.state.filter, tournaments),
            });
        });
    }

    addNewTournament = () => {
        this.setState({
            createTournamentModal: true
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    pickImage = (tournament) => e => {
        this.setState({
            pickImageTournamentId: tournament.uuid
        })
        console.log(tournament)
    };

    didSelectImage = (image) => {
        console.log(image)
        let tournamentIndex = this.state.filteredTournaments.findIndex(t => t.uuid === this.state.pickImageTournamentId)
        let tournament = Object.assign({}, this.state.filteredTournaments[tournamentIndex])
        tournament.image_id = image.image_id
        tournament.image_name = image.name

        let tournaments = Array.from(this.state.tournaments);
        let index = this.state.tournaments.findIndex(t => t.tournament_id === tournament.tournament_id);
        tournaments[index] = tournament;

        this.setState({
            tournaments: tournaments,
            filteredTournaments: this.filterTournaments(this.state.filter, tournaments),
            pickImageTournamentId: -1
        });
    }

    startEditingTournament = (i) => () => {
        let tournamentBackup = Object.assign({}, this.state.filteredTournaments[i]);
        let tournament = Object.assign({}, this.state.filteredTournaments[i]);

        let tournaments = Array.from(this.state.tournaments);
        let index = this.state.tournaments.findIndex(t => t.tournament_id === tournament.tournament_id);
        tournaments[index] = tournament;

        tournament.editing = true;
        tournament.$tournament = tournamentBackup
        this.setState({
            filteredTournaments: this.filterTournaments(this.state.filter, tournaments),
        });
    };

    cancelEditingTournament = (i) => () => {
        let tournaments = Array.from(this.state.tournaments);
        let tournament = this.state.filteredTournaments[i];
        tournament.editing = false;

        let index = this.state.tournaments.findIndex(t => t.tournament_id === tournament.tournament_id);
        tournaments[index] = tournament;

        delete tournament.$tournament;

        this.setState({
            tournaments: tournaments,
            filteredTournaments: this.filterTournaments(this.state.filter, tournaments),
        });
    };

    commitEditingTournament = (i) => (e) => {
        let tournament = Object.assign({}, this.state.filteredTournaments[i]);

        console.log(tournament)

        api.patchWordsOutTournament(tournament.tournament_id, tournament).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde du tournoi.");
                return;
            }

            delete tournament.$tournament;
            tournament.editing = false;

            let tournaments = Array.from(this.state.tournaments);
            let index = this.state.tournaments.findIndex(t => t.tournament_id === tournament.tournament_id);
            tournaments[index] = tournament;

            this.setState({
                tournaments: tournaments,
                filteredTournaments: this.filterTournaments(this.state.filter, tournaments),
            });
            this.displaySnackbar("Tournoi sauvegardé !");
        });

        e.preventDefault();
    };

    handleTournamentSwitchChange = (i, propNamePath) => (e, checked) => {
        this.handleTournamentChange(i, propNamePath, checked ? 1 : 0);
    };

    handleTournamentValueChange = (i, propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleTournamentChange(i, propNamePath, parseInt(e.target.value, 10));
        } else if (type === 'date') {
            this.handleTournamentChange(i, propNamePath, Math.round(e/1000));
        } else {
            this.handleTournamentChange(i, propNamePath, e.target.value);
        }
    };

    handleTournamentChange = (i, propNamePath, value) => {
        let tournament = Object.assign({}, this.state.filteredTournaments[i]);
        KVC(tournament).setValueForKeyPath(propNamePath, value);

        let tournaments = Array.from(this.state.tournaments);
        let index = this.state.tournaments.findIndex(t => t.tournament_id === tournament.tournament_id);
        tournaments[index] = tournament;

        this.setState({
            tournaments: tournaments,
            filteredTournaments: this.filterTournaments(this.state.filter, tournaments)
        });
    };

    onCancelCreateTournament = () => {
        this.setState({
            createTournamentModal: false,
            createTournament: this.getCreateTournament(),
        });
    }

    onCommitCreateTournament = () => {
        api.postWordsOutTournament(this.state.createTournament).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la création du tournoi.");
                return;
            }
            this.displaySnackbar('Tournoi créé');
            this.updateData();
            this.setState({
                createTournament: this.getCreateTournament(),
                createTournamentModal: false,
            });
        })
    }

    onCreateTournamentClose = () => {

    }

    onCreateTournamentStartDateChange = (d) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.start_date = d/1000;
        this.setState({createTournament: tournament});
    }

    onCreateTournamentEndDateChange = (d) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.end_date = d/1000;
        this.setState({createTournament: tournament});
    }

    onCreateTournamentCardCountChange = (e) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.card_count = parseInt(e.target.value, 10)
        this.setState({createTournament: tournament});
    }

    onLabelEditingFinished = (label) => {
        let tournamentIndex = this.state.editedTournamentIndex;
        let tournament = Object.assign({}, this.state.tournaments[tournamentIndex]);
        tournament.label = label;

        let tournaments = Array.from(this.state.tournaments);
        tournaments[tournamentIndex] = tournament;

        this.setState({
            editedLabel: null,
            editedTournamentIndex: null,
            tournaments: tournaments,
            filteredTournaments: this.filterTournaments(this.state.filter, tournaments),
        });
    };

    handleFilterChange = (e) => {
        this.setState({
            filter: e.target.value,
            filteredTournaments: this.filterTournaments(e.target.value, this.state.tournaments),
        });
    };

    filterTournaments(filter, tournaments) {
        const now = Math.floor((new Date()).getTime() / 1000);
        switch (filter) {
            case 0: return tournaments;
            case 1:
                return tournaments.filter(t => t.start_date <= now && (t.closed !== 1 || t.end_date + 2* 86400 >= now));
            case 2:
                return tournaments.filter(t => t.start_date <= now && t.end_date >= now);
            case 3:
                return tournaments.filter(t => t.start_date > now);

        }
    }

    render() {

        return (
            <React.Fragment>

                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
                <Paper style={{paddingBottom: 190}}>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Niveaux</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                onChange={this.handleFilterChange}
                                value={this.state.filter}
                                label="Afficher"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                <MenuItem value={0}>Tous</MenuItem>
                                <MenuItem value={1}>Visibles dans le jeu</MenuItem>
                                <MenuItem value={2}>En cours</MenuItem>
                                <MenuItem value={3}>À venir</MenuItem>
                            </TextField>

                        </div>
                    </div>
                    <div style={{padding: '24px 0'}}>
                        <Table size={"medium"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{padding: 5}}>Début</TableCell>
                                    <TableCell style={{padding: 5}}>Fin</TableCell>
                                    <TableCell style={{padding: 5}}>Nom</TableCell>
                                    <TableCell style={{padding: 5}}>Mise</TableCell>
                                    <TableCell style={{padding: 5}}>Gains</TableCell>
                                    <TableCell style={{padding: 5}}>Free</TableCell>
                                    <TableCell style={{padding: 5}}>Cartes</TableCell>
                                    <TableCell style={{padding: 5}}>Lettres</TableCell>
                                    <TableCell style={{padding: 5}}>Lignes</TableCell>
                                    <TableCell style={{padding: 5}}>?</TableCell>
                                    <TableCell style={{padding: 5}}>&times;2</TableCell>
                                    <TableCell style={{padding: 5}}>&times;3</TableCell>
                                    <TableCell style={{padding: 5}}>Bomb.</TableCell>
                                    <TableCell style={{padding: 5}}>Let. +</TableCell>
                                    <TableCell style={{padding: 5}}>Poub.</TableCell>
                                    <TableCell style={{padding: 5}}>Dico ?</TableCell>
                                    <TableCell style={{padding: 5}}>#Dico</TableCell>
                                    <TableCell style={{padding: 5}}>Cartes</TableCell>
                                    <TableCell style={{padding: 5}}>Image</TableCell>
                                    <TableCell style={{padding: 5}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.filteredTournaments.map((tournament, i) => {
                                    const labelValue = LabelEditor.getLabelValue(tournament.label);
                                    return <TableRow key={tournament.tournament_id}>
                                        {!tournament.editing ?
                                            <React.Fragment>
                                                <TableCell style={{
                                                    width: "140px",
                                                    padding: 5
                                                }}>{api.getDateFromTimestamp(tournament.start_date)}</TableCell>
                                                <TableCell style={{
                                                    width: "140px",
                                                    padding: 5
                                                }}>{api.getDateFromTimestamp(tournament.end_date)}</TableCell>
                                                <TableCell style={{width: 100, padding: 5}}>{!labelValue ? <em>{tournament.card_count + tournament.special_mystery_count + tournament.special_double_count + tournament.special_triple_count + tournament.special_bomb_count + tournament.special_more_card_count + tournament.special_trash_count} cartes</em> : labelValue}</TableCell>
                                                <TableCell
                                                    style={{width: "50px", padding: 5}}>{tournament.coin_cost}<span
                                                    style={{color: "#aaa", fontSize: '10px'}}> pcs</span></TableCell>
                                                <TableCell
                                                    style={{width: "70px", padding: 5}}>{!!tournament.reward_coin_count ? `${tournament.reward_coin_count}` : ''}<span
                                                    style={{color: "#aaa", fontSize: '10px'}}> {!!tournament.reward_coin_count ? ` pcs` : 'auto'}</span></TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.free_try_count}</TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.card_count}</TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.letter_count}</TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.line_count}</TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.special_mystery_count}<span
                                                    style={{color: "#aaa", fontSize: '10px'}}> ?</span></TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.special_double_count}<span style={{
                                                    color: "#aaa",
                                                    fontSize: '10px'
                                                }}> &times;2</span></TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.special_triple_count}<span style={{
                                                    color: "#aaa",
                                                    fontSize: '10px'
                                                }}> &times;3</span></TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.special_bomb_count}<span
                                                    style={{color: "#aaa", fontSize: '10px'}}> b.</span></TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.special_more_card_count}<span
                                                    style={{color: "#aaa", fontSize: '10px'}}> +</span></TableCell>
                                                <TableCell
                                                    style={{width: "50px", padding: 5}}>{tournament.special_trash_count}<span
                                                    style={{color: "#aaa", fontSize: '10px'}}> <DeleteIcon
                                                    fontSize={"small"}/></span></TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{!tournament.allow_hint ? 'Non' : 'Oui'}</TableCell>
                                                <TableCell style={{
                                                    width: "50px",
                                                    padding: 5
                                                }}>{tournament.hint_count}</TableCell>
                                                <TableCell style={{width: "60px", padding: 5}}><span style={{
                                                    display: 'inline-block',
                                                    width: 30,
                                                    height: 40,
                                                    backgroundColor: tournament.game_color
                                                }}>&nbsp;</span></TableCell>
                                                <TableCell><img src={`/words-out/v3/thumb_${tournament.image_name}`} height={80}/></TableCell>
                                                <TableCell style={{padding: 0}}>
                                                    <Button color="primary"
                                                            onClick={this.startEditingTournament(i)}><EditIcon/></Button> (ID {tournament.tournament_id})

                                                </TableCell>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <TableCell style={{width: "200px"}} colSpan={2}>
                                                    <DateTimePicker
                                                        value={new Date(tournament.start_date * 1000)}
                                                        format="d/MM/yyyy à H:mm"
                                                        disablePast={true}
                                                        ampm={false}
                                                        minutesStep={10}
                                                        helperText={"Date de début"}
                                                        onChange={this.handleTournamentValueChange(i, 'start_date', 'date')}/>
                                                    <DateTimePicker
                                                        value={new Date(tournament.end_date * 1000)}
                                                        format="d/MM/yyyy à H:mm"
                                                        disablePast={true}
                                                        ampm={false}
                                                        minutesStep={10}
                                                        helperText={"Date de fin"}
                                                        onChange={this.handleTournamentValueChange(i, 'end_date', 'date')}/>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1">
                                                        <ButtonBase
                                                            focusRipple
                                                            onClick={(e) => this.setState({
                                                                editedLabel: tournament.label,
                                                                editedTournamentIndex: i
                                                            })}
                                                            style={{
                                                                fontSize: '1rem',
                                                                padding: '4px 4px 4px 0',
                                                                color: '#2196f3',
                                                                marginTop: 12
                                                            }}
                                                        >
                                                            {LabelEditor.getLabelValue(tournament.label)} <EditIcon
                                                            style={{marginLeft: 4}}/>
                                                        </ButtonBase>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={13}>
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'coin_cost', 'number')}
                                                        value={tournament.coin_cost}
                                                        color="primary"
                                                        label="Mise"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'reward_coin_count', 'number')}
                                                        value={tournament.reward_coin_count}
                                                        color="primary"
                                                        label="Gains"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "70px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'free_try_count', 'number')}
                                                        value={tournament.free_try_count}
                                                        color="primary"
                                                        label="Nb."
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'card_count', 'number')}
                                                        value={tournament.card_count}
                                                        color="primary"
                                                        label="Cartes"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'letter_count', 'number')}
                                                        value={tournament.letter_count}
                                                        color="primary"
                                                        label="lettres"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'line_count', 'number')}
                                                        value={tournament.line_count}
                                                        color="primary"
                                                        label="lignes"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'special_mystery_count', 'number')}
                                                        value={tournament.special_mystery_count}
                                                        color="primary"
                                                        label="?"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'special_double_count', 'number')}
                                                        value={tournament.special_double_count}
                                                        color="primary"
                                                        label="x2"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'special_triple_count', 'number')}
                                                        value={tournament.special_triple_count}
                                                        color="primary"
                                                        label="x3"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'special_bomb_count', 'number')}
                                                        value={tournament.special_bomb_count}
                                                        color="primary"
                                                        label="bombes"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'special_more_card_count', 'number')}
                                                        value={tournament.special_more_card_count}
                                                        color="primary"
                                                        label="+"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'special_trash_count', 'number')}
                                                        value={tournament.special_trash_count}
                                                        color="primary"
                                                        label="Poub."
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                    <TextField
                                                        onChange={this.handleTournamentValueChange(i, 'hint_count', 'number')}
                                                        value={tournament.hint_count}
                                                        color="primary"
                                                        label="#Dico"
                                                        type="number"
                                                        inputProps={{
                                                            form: `tournament-${tournament.tournament_id}`,
                                                            style: {width: "50px"}
                                                        }}
                                                        style={{margin: 5}}
                                                    />
                                                </TableCell>
                                                <TableCell style={{padding: 0}}>
                                                    <Switch
                                                        checked={tournament.allow_hint === 1}
                                                        onChange={this.handleTournamentSwitchChange(i, 'allow_hint')}
                                                        value="1"
                                                    />
                                                </TableCell>
                                                <TableCell style={{padding: 5, width: 100}}>
                                                    <ColorPicker
                                                        label="Cartes"
                                                        name='game_color'
                                                        defaultValue={tournament.game_color}
                                                        value={tournament.game_color}
                                                        onChange={color => this.handleTournamentChange(i, 'game_color', color)}
                                                        style={{margin: 5}}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        <div onClick={this.pickImage(tournament)} className={classNames({
                                                            'group-image': true,
                                                        })} style={{marginBottom: -6}}>
                                                            <img height={200} src={`/words-out/v3/thumb_${tournament.image_name}`}/>
                                                            <div className={"group-image-overlay"}/>
                                                        </div>
                                                        <Button style={{marginLeft: -6}} color="primary" onClick={this.pickImage(tournament)}>Image</Button>
                                                    </div>
                                                    <WordsOutImagePicker
                                                        open={this.state.pickImageTournamentId && this.state.pickImageTournamentId === tournament.uuid}
                                                        onClose={() => { this.setState({pickImageTournamentId: null})}}
                                                        onPick={this.didSelectImage}
                                                        selectedImageId={!!this.state.pickImageTournamentId ? this.state.pickImageTournamentId : -1}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <form onSubmit={this.commitEditingTournament(i)}
                                                          id={`tournament-${tournament.tournament_id}`}>
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={this.cancelEditingTournament(i)}
                                                        >
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <IconButton
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            <CheckCircleIcon/>
                                                        </IconButton>
                                                    </form>
                                                </TableCell>
                                            </React.Fragment>
                                        }
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.addNewTournament}
                >
                    <AddIcon />
                </Fab>

                {!this.state.createTournamentModal ? null :
                    <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.createTournamentModal}
                            onClose={this.onCreateTournamentClose}>
                        <DialogTitle>Créer un tournoi</DialogTitle>
                        <DialogContent>
                            <form>
                                <div style={{marginBottom: 24}}>
                                        <DateTimePicker
                                            value={new Date(this.state.createTournament.start_date * 1000)}
                                            format="d/MM/yyyy à H:mm"
                                            disablePast={true}
                                            ampm={false}
                                            minutesStep={10}
                                            helperText={"Date de début"}
                                            onChange={this.onCreateTournamentStartDateChange} />
                                    </div>
                                    <div style={{marginBottom: 24}}>
                                        <DateTimePicker
                                            value={new Date(this.state.createTournament.end_date * 1000)}
                                            format="d/MM/yyyy à H:mm"
                                            disablePast={true}
                                            ampm={false}
                                            minutesStep={10}
                                            helperText={"Date de fin"}
                                            onChange={this.onCreateTournamentEndDateChange} />
                                    </div>
                                    <div style={{marginBottom: 24}}>
                                        <TextField
                                            value={this.state.createTournament.card_count}
                                            onChange={this.onCreateTournamentCardCountChange}
                                            color="primary"
                                            label="Cartes"
                                            type="number"
                                        />
                                    </div>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onCancelCreateTournament} color="secondary">
                                Annuler
                            </Button>
                            <Button onClick={this.onCommitCreateTournament}
                                    color="primary">
                                Créer
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                    {
                        !this.state.editedLabel ? null :
                            <LabelEditor
                                labelId={this.state.editedLabel.label_id}
                                label={this.state.editedLabel}
                                open={!!this.state.editedLabel}
                                languages={this.state.languages.map(l => l.language_id)}
                                onClose={this.onLabelEditingFinished}
                                title="Nom du tournoi"
                            />
                    }

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />
                </MuiPickersUtilsProvider>
            </React.Fragment>
        );
    }
}

WordsOutTournaments.propTypes = {
    app: PropTypes.object.isRequired,
};

WordsOutTournaments.defaultProps = {};

export default WordsOutTournaments;