import React from 'react';
import PropTypes from 'prop-types';

class FormatteDateTime extends React.Component {

    formattedDate(timestamp) {
        if (!timestamp) {
            return this.props.emptyDescription;
        }
        const options = {
            year: this.props.year,
            month: this.props.month,
            day: this.props.day,
            hour: this.props.hour,
            minute: this.props.minute,
            second: this.props.second,
        };
        const date = new Date(timestamp);
        return new Intl.DateTimeFormat('fr-FR', options).format(date * 1000);
    }

    render() {
        return (
            <React.Fragment>{this.formattedDate(this.props.timestamp)}</React.Fragment>
        );
    }
}

FormatteDateTime.propTypes = {
    timestamp: PropTypes.number,
    emptyDescription: PropTypes.string,
    year: PropTypes.string,
    month: PropTypes.string,
    day: PropTypes.string,
    hour: PropTypes.string,
    minute: PropTypes.string,
    second: PropTypes.string,
};

FormatteDateTime.defaultProps = {
    emptyDescription: '',
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
};

export default FormatteDateTime;