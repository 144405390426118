import React from 'react';
import PropTypes from 'prop-types';
import {Button, Fab, Paper, Snackbar, Toolbar, Typography} from "@material-ui/core";
import api from '../services/api'
import PackCard from "./PackCard";
import NavLink from "react-router-dom/NavLink";
import AddIcon from "@material-ui/icons/Add"
import './PackDetails.css'

class AppPacks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            publishedPacks: [],
            nonPublishedPacks: [],
            orderedPublishedPacks: [],
            isDragging: false,
            snackbarDisplayed: false,
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        this.updateData();
    }

    getAppId() {
        return this.props.app.app_id;
    }

    updateData() {
        Promise.all([
            api.getLanguages(),
            api.getAppPacks(this.getAppId()),
        ]).then(res => {
            const languages = res[0];
            const packs = res[1];
            packs.forEach(pack => {
                pack.languages = pack.languages.map(languageId => languages.find(l => l.language_id === languageId));
            });
            this.setState({
                publishedPacks: packs.filter(p => !!p.published),
                orderedPublishedPacks: packs.filter(p => !!p.published),
                nonPublishedPacks: packs.filter(p => !p.published),
            });
        });
    }

    isPackOrderPristine() {
        return this.state.publishedPacks.every((p, i) => p.pack_id === this.state.orderedPublishedPacks[i].pack_id);
    }

    onPackMove = (sourceIndex, targetIndex) => {
        let packs = Array.from(this.state.orderedPublishedPacks);
        let pack = packs[sourceIndex];
        packs.splice(sourceIndex, 1);
        packs.splice(targetIndex, 0, pack);
        this.setState({
            orderedPublishedPacks: packs
        });
    };

    onPackDragBegin = () => {
        this.setState({
            isDragging: true,
        });
    };

    onPackDragEnd = () => {
        this.setState({
            isDragging: false,
        });
    };

    cancelPackOrder = () => {
        this.setState({
            orderedPublishedPacks: Array.from(this.state.publishedPacks),
        });
    };

    commitPackOrder = () => {
        const appId = this.getAppId();
        api.postAppPacks(appId, this.state.orderedPublishedPacks.map((pack, i) => ({
            app_id: appId,
            pack_id: pack.pack_id,
            index: i,
        }))).then(packs => {
            if (packs === undefined) {
                this.displaySnackbar('Erreur lors de l\'enregistrement de l\'ordre des packs.');
                return;
            }
            this.displaySnackbar('Ordre des packs enregistré avec succès.');
            this.setState({
                publishedPacks: Array.from(this.state.orderedPublishedPacks),
                orderedPublishedPacks: Array.from(this.state.orderedPublishedPacks),
            });
        });
    };

    addNewAppPack = () => {
        const appId = this.getAppId();
        api.postAppPack(appId).then(pack => {
            this.updateData();
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        const packCard = function (pack, i, draggable) {
            return (
                <NavLink
                    key={pack.pack_id}
                    to={`/${this.props.app.url_slug}/pack/${pack.pack_id}`}
                >
                    <PackCard
                        pack={pack}
                        app={this.props.app}
                        draggable={draggable}
                        index={i}
                        onMove={this.onPackMove}
                        onDragBegin={this.onPackDragBegin}
                        onDragEnd={this.onPackDragEnd}
                    />
                </NavLink>
            )
        }.bind(this);

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Packs d'images du jeu publiés</Typography>
                        </div>
                        {
                            (this.state.isDragging || this.isPackOrderPristine()) ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelPackOrder}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitPackOrder}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                <div style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    margin: '12px -12px',
                }}>
                    {
                        this.state.orderedPublishedPacks.map((pack, i) => packCard(pack, i, true))
                    }
                </div>

                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Packs non publiés</Typography>
                        </div>
                    </Toolbar>
                </Paper>

                <div style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    margin: '12px -12px',
                }}>
                    {
                        this.state.nonPublishedPacks.map((pack, i) => packCard(pack, i, false))
                    }
                </div>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.addNewAppPack}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

AppPacks.propTypes = {
    app: PropTypes.object.isRequired,
};

AppPacks.defaultProps = {};

export default AppPacks;