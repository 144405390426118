import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab, MenuItem,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import {withRouter} from "react-router-dom";
import LabelEditor from "../LabelEditor";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class CrackListPartyPenalties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            penalties: [],
            displayedPenalties: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
            languageId: 1,
            filterCategoryId: -1,
            filterType: -1,
            filterName: "",
        };
    }

    componentDidMount() {
        this.updateData(this.state.languageId);
    }

    updateData(languageId) {
        Promise.all([
            api.getAppLanguages(this.props.app.app_id),
            api.getCrackListPartyPenaltyCategories(languageId),
            api.getCrackListPartyPenalties(languageId),
        ]).then(res => {
            const languages = res[0];
            const categories = res[1];
            const penalties = res[2];
            this.setState({
                languages: languages,
                categories: categories,
                penalties: penalties,
                displayedPenalties: penalties.filter(l => this.penaltyMatchesFilters(l, this.state.filterCategoryId, this.state.filterName)),
            });
        });
    }

    editPenalty = (penalty) => (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/penalty/${penalty.penalty_id}`);
    };

    createPenalty = (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/create-penalty/${this.state.languageId}`);
    };

    deletePenalty = (penalty) => (e) => {
        if (!window.confirm('Supprimer le gage ?')) {
            return;
        }
        api.deleteCrackListPartyPenalty(penalty.penalty_id).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la suppression du gage");
                return;
            }
            this.updateData(this.state.languageId);
        });
    }

    handleFilterCategoryChange = (e) => {
        this.setState({
            filterCategoryId: e.target.value,
            displayedPenalties: this.state.penalties.filter(l => this.penaltyMatchesFilters(l, parseInt(e.target.value, 10), this.state.filterName))
        });
    }

    handleFilterNameChange = (e) => {
        this.setState({
            filterName: e.target.value,
            displayedPenalties: this.state.penalties.filter(l => this.penaltyMatchesFilters(l, this.state.filterCategoryId, e.target.value))
        });
    }

    penaltyMatchesFilters(penalty, categoryId, name) {
        return (name.length === 0 || (!!penalty.name && penalty.name.toLowerCase().indexOf(name.toLowerCase()) !== -1))
            && (categoryId === -1 || penalty.category_id === categoryId);
    }

    handleLanguageChange = (e) => {
        this.setState({languageId: e.target.value});
        this.updateData(e.target.value);
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (!this.state.languages) {
            return null;
        }

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Gages</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '0 24px'}}>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.state.languageId}
                                onChange={this.handleLanguageChange}
                                label="Langue"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                {
                                    this.state.languages.map((l, i) => <MenuItem key={l.language_id} value={l.language_id}>{l.name}</MenuItem>)
                                }
                            </TextField>

                        </div>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.state.filterCategoryId}
                                onChange={this.handleFilterCategoryChange}
                                label="Catégorie"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                <MenuItem value={-1}>Toutes les catégories ({this.state.penalties.length})</MenuItem>
                                {
                                    this.state.categories.map((c, i) => <MenuItem key={i} value={c.category_id}>{c.name} ({c.penalty_count})</MenuItem>)
                                }
                            </TextField>

                            <TextField
                                style={{marginRight: 24, width: 300}}
                                value={this.state.filterName}
                                label="Intitulé"
                                placeholder="Filtrer sur l'intitulé"
                                onChange={this.handleFilterNameChange}
                            />

                        </div>

                    </div>

                    <div style={{padding: '24px'}}>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nom</TableCell>
                                    <TableCell style={{width: 100}}>Catégorie</TableCell>
                                    <TableCell style={{width: 160}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.displayedPenalties.map((p, i) => (
                                    <TableRow key={p.penalty_id}>
                                        <TableCell>{p.name}</TableCell>
                                        <TableCell>{p.category_name}</TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={this.editPenalty(p)}><EditIcon/></Button>
                                            <Button color="secondary" onClick={this.deletePenalty(p)}><DeleteIcon/></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.createPenalty}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

CrackListPartyPenalties.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListPartyPenalties.defaultProps = {};

export default withRouter(CrackListPartyPenalties);