import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab, FormControlLabel, MenuItem,
    Paper,
    Snackbar,
    TextField,
    Toolbar,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputLabel,
    Select,
    Slider
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy"
import withRouter from "react-router-dom/withRouter";
import {DataGrid} from "@material-ui/data-grid";

const sortModel = [
    {
        field: 'level_id',
        sort: 'asc',
    },
];

class CrackListLists extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            lists: [],
            pageSize: parseInt(props.match.params.pageSize),
            page: parseInt(props.match.params.page),
            nameFilter: '',
            filteredLists: [],
            tileRange: [1, 50],
            difficultyRange: [0, 5],
            answerRange: [1, 15],
            levelSelectionList: null,
            listDuplicationList: null,
            listDuplicationLanguageId: -1,
        };

        this.columns = [
            {
                field: 'level_id',
                headerName: 'Niv.',
                renderCell: (params) => {
                    const displayedLevel = !!params.value ? `${params.data.level_index + 1}` : '-';
                    return <span onClick={this.onLevelSelectionOpen(params.data)} style={{cursor: 'pointer', minWidth: 50}}>{displayedLevel}</span>;
                },
                sortComparator: (v1, v2, params1, params2) => {
                    if (!!v1 && !!v2) {
                        return v1 - v2;
                    } else if (!!v1) {
                        return -1;
                    } else if (!!v2) {
                        return 1;
                    } else {
                        return params1.data.name.localeCompare(params2.data.name);
                    }
                },
                sortable: true,
                width: 85
            },
            {
                field: 'name',
                headerName: 'Intitulé',
                width: 320,
            },
            {
                field: 'name_short',
                headerName: 'Intitulé court',
                width: 130,
            },
            {
                field: 'items',
                headerName: 'Réponses',
                renderCell: (params) => {
                    return <span><strong>{params.data.items.length}</strong> - {params.data.items.map(i => i.name).join(" - ")}</span>;
                },
                sortComparator: (v1, v2, params1, params2) => {
                    return params1.data.items.length - params2.data.items.length;
                },
                width: 550
            },
            {
                field: 'list_id',
                headerName: 'ID / Éditer',
                renderCell: (params) => {
                    return <span>{params.value}
                        <Button color="primary" onClick={this.editList(params.data)}><EditIcon/></Button>
                    </span>
                },
                sortable: false
            },
            {
                field: 'min_tile_count',
                headerName: 'Tuiles',
                renderCell: (params) => {
                    if (params.data.min_fixed_tile_count === 0 && params.data.max_fixed_tile_count === 0) {
                        return <span style={{color: '#007AFF', fontWeight: 'bold'}}>{params.data.fixed_tile_count}</span>
                    } else {
                        return <span>{params.data.min_tile_count}-{params.data.max_tile_count}</span>
                    }
                },
                sortComparator: (v1, v2, params1, params2) => {
                    let tileCount1 = v1;
                    let secondaryValue1 = params1.data.max_tile_count;
                    if (params1.data.min_fixed_tile_count === 0 && params1.data.max_fixed_tile_count === 0) {
                        tileCount1 = params1.data.fixed_tile_count;
                        secondaryValue1 = params1.data.fixed_tile_count;
                    }
                    let tileCount2 = v2;
                    let secondaryValue2 = params2.data.max_tile_count;
                    if (params2.data.min_fixed_tile_count === 0 && params2.data.max_fixed_tile_count === 0) {
                        tileCount2 = params2.data.fixed_tile_count;
                        secondaryValue2 = params2.data.fixed_tile_count;
                    }
                    if (tileCount1 < tileCount2) {
                        return -1;
                    } else if (tileCount2 < tileCount1) {
                        return 1;
                    } else {
                        return secondaryValue1 - secondaryValue2;
                    }
                },
                width: 80,
            },
            {
                field: 'target_reward',
                headerName: 'Rwd',
                type: 'number',
                width: 60,
                renderCell: (params) => {
                    if (params.data.auto_reward) {
                        return <span style={{fontStyle: 'italic', color: '#999'}}>{params.data.auto_reward}</span>
                    } else {
                        return <span/>
                    }
                },
            },
            {
                field: 'id2',
                headerName: 'Dup.',
                renderCell: (params) => {
                    return <span>
                        <Button color="primary" onClick={this.onListDuplicationOpen(params.data)}><FileCopyIcon/></Button>
                    </span>
                },
                sortable: false,
                width: 70
            },
            {
                field: 'id3',
                headerName: 'Suppr.',
                renderCell: (params) => {
                    return <span>
                        <Button color="secondary" onClick={this.deleteList(params.data)}><DeleteIcon/></Button>
                    </span>
                },
                sortable: false,
                width: 80
            },
        ]
    }

    getRouteLanguageId() {
        return parseInt(this.props.match.params.languageId, 10);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.languageId === this.props.match.params.languageId) {
            if (prevProps.match.params.page !== this.props.match.params.page) {
                this.setState({
                    page: parseInt(this.props.match.params.page),
                    pageSize: parseInt(this.props.match.params.pageSize)
                })
            }
            return;
        }
        this.updateData();
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let requests = [
            api.getAppLanguages(this.props.app.app_id),
            api.getCrackListLevels(),
        ];
        if (this.hasDefinedLanguage()) {
            requests.push(api.getCrackListLists(this.getRouteLanguageId()))
        }

        Promise.all(requests).then(res => {
            const languages = res[0];
            const levels = res[1];
            const lists = (res.length > 2 ? res[2] : []).map(l => {
                return {
                    ...l,
                    id: l.list_id,
                    id2: l.list_id,
                    id3: l.list_id,
                }
            });

            const maxTileCount = lists.map(l => l.max_tile_count).reduce((val, cur) => Math.max(val, cur), 0);
            const minTileCount = lists.map(l => l.min_tile_count).reduce((val, cur) => Math.min(val, cur), 1000);
            const minTileRange = minTileCount - (minTileCount % 5);
            const maxTileRange = Math.max(minTileRange + 10, Math.ceil(maxTileCount / 5) * 5);
            const minAnswerCount = lists.map(l => l.items.length).reduce((val, cur) => Math.min(val, cur), 1000);
            const maxAnswerCount = Math.max(minAnswerCount, lists.map(l => l.items.length).reduce((val, cur) => Math.max(val, cur), 0));

            this.setState({
                languages: languages,
                levels: levels,
                lists: lists,
                filteredLists: Array.from(lists),
                nameFilter: '',
                tileRange: [minTileRange, maxTileRange],
                minTileRange: minTileRange,
                maxTileRange: maxTileRange,
                answerRange: [minAnswerCount, maxAnswerCount],
                minAnswerRange: minAnswerCount,
                maxAnswerRange: maxAnswerCount,
                difficultyRange: [0, 5],
            });
        });
    }

    hasDefinedLanguage() {
        return this.getRouteLanguageId() !== -1
    }

    editList = (list) => (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/list/${list.list_id}`);
    };

    createList = (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/create-list/${this.getRouteLanguageId()}`);
    };

    deleteList = (list) => (e) => {
        if (!window.confirm('Supprimer la liste ?')) {
            return;
        }
        api.deleteCrackListList(list.list_id).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la suppression de la liste");
                return;
            }
            this.updateData();
        });
    }

    handleLanguageChange = (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/lists/lang-${e.target.value}/${this.state.pageSize}-1`);
    };

    handleNameFilterChange = (e) => {
        this.setState({
            nameFilter: e.target.value,
            filteredLists: this.state.lists.filter(l => this.listMatchesFilters(l, e.target.value, this.state.tileRange, this.state.difficultyRange, this.state.answerRange)),
        })
    }

    handleTileRangeFilterChange = (e, range) => {
        this.setState({
            tileRange: range,
            filteredLists: this.state.lists.filter(l => this.listMatchesFilters(l, this.state.nameFilter, range, this.state.difficultyRange, this.state.answerRange)),
        });
    }

    handleDifficultyRangeFilterChange = (e, range) => {
        this.setState({
            difficultyRange: range,
            filteredLists: this.state.lists.filter(l => this.listMatchesFilters(l, this.state.nameFilter, this.state.tileRange, range, this.state.answerRange)),
        });
    }

    handleAnswerRangeFilterChange = (e, range) => {
        this.setState({
            answerRange: range,
            filteredLists: this.state.lists.filter(l => this.listMatchesFilters(l, this.state.nameFilter, this.state.tileRange, this.state.difficultyRange, range)),
        });
    }

    listMatchesFilters(list, nameFilter, tileRange, difficultyRange, answerRange) {
        return (!nameFilter || (!!list.name && list.name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1))
            && (tileRange[0] <= list.max_tile_count && tileRange[1] >= list.min_tile_count)
            && (difficultyRange[0] <= list.difficult && difficultyRange[1] >= list.difficult)
            && (answerRange[0] <= list.items.length && answerRange[1] >= list.items.length);
    }

    onLevelSelectionOpen = (list) => (e) => {
        this.setState({levelSelectionList: Object.assign({}, list)})
    }

    onListDuplicationOpen = (list) => (e) => {
        this.setState({listDuplicationList: Object.assign({}, list)});
    }

    onLevelSelectionClose() {

    }

    onListDuplicationClose() {

    }

    onPageChange = (p) => {
        if (p.page === this.state.page) {
            return;
        }
        this.props.history.push(`/${this.props.app.url_slug}/lists/lang-${this.getRouteLanguageId()}/${p.pageSize}-${p.page}`, {page: p.page});
    }

    onPageSizeChange = (p) => {
        if (p.pageSize === this.state.pageSize) {
            return;
        }
        this.props.history.push(`/${this.props.app.url_slug}/lists/lang-${this.getRouteLanguageId()}/${p.pageSize}-1`, {pageSize: p.pageSize, page: 1});
    }

    onCancelLevelSelection = () => {
        this.setState({levelSelectionList: null});
    }

    onCancelListDuplication = () => {
        this.setState({
            listDuplicationList: null,
            listDuplicationLanguageId: -1,
        });
    }

    onCommitLevelSelection = () => {
        const specifics = {
            'list_id': this.state.levelSelectionList.list_id,
            'language_id': this.state.levelSelectionList.language_id,
            'level_id': this.state.levelSelectionList.level_id,
        };
        if (specifics.level_id === -1) {
            api.deleteCrackListLevelSpecifics(specifics).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de la suppresssion de l'association au niveau.");
                    return;
                }
                this.setState({levelSelectionList: null});
                this.updateData();
            });
            return;
        }
        let alreadyAssociatedList = null
        this.state.lists.forEach(list => {
            if (list.level_id === specifics.level_id && list.list_id !== specifics.list_id) {
                alreadyAssociatedList = list;
            }
        });
        if (!!alreadyAssociatedList && !window.confirm(`Ce niveau est déjà associé à la liste "${alreadyAssociatedList.name}", confirmer ?`)) {
            return;
        }
        api.patchCrackListLevelSpecifics(specifics).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'association au niveau.");
                return;
            }
            this.setState({levelSelectionList: null});
            this.updateData();
        });
    }

    onCommitListDuplication = (editAfter) => () => {
        const languageId = this.state.listDuplicationLanguageId;
        if (!languageId) {
            return;
        }
        api.duplicateCrackListList(this.state.listDuplicationList.list_id, languageId).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la duplication de la liste.");
                return;
            }
            this.setState({
                listDuplicationList: null,
                listDuplicationLanguageId: -1,
            });
            if (editAfter) {
                this.props.history.push(`/${this.props.app.url_slug}/list/${res.list_id}`);
            } else {
                this.updateData();
            }
        });
    }

    onLevelSelectionChange = (e) => {
        let list = this.state.levelSelectionList;
        if (e.target.value === -1) {
            list.level_id = -1;
        } else {
            list.level_id = parseInt(e.target.value, 10);
        }
        this.setState({levelSelectionList: list});
    }

    onListDuplicationLanguageChange = (e) => {
        this.setState({listDuplicationLanguageId: e.target.value});
    }

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (!this.state.languages) {
            return null;
        }

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Listes</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.getRouteLanguageId()}
                                onChange={this.handleLanguageChange}
                                label="Langue"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                { !this.hasDefinedLanguage() ?
                                    <MenuItem disabled value={-1}>Sélectionner une langue</MenuItem> : null }
                                {
                                    this.state.languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                }
                            </TextField>

                        </div>

                        <div style={{marginBottom: 24, marginLeft: 10, display: this.hasDefinedLanguage() ? 'block' : 'none'}}>

                            { this.state.lists.length === 0 ? null :
                                <React.Fragment>
                                    <FormControlLabel
                                        control={
                                            <Slider
                                                style={{width: 200}}
                                                value={this.state.tileRange}
                                                onChange={this.handleTileRangeFilterChange}
                                                valueLabelDisplay="auto"
                                                marks={this.state.maxTileRange >= this.state.minTileRange + 5}
                                                step={5}
                                                min={this.state.minTileRange}
                                                max={this.state.maxTileRange}
                                                disabled={!this.hasDefinedLanguage()}
                                            />
                                        }
                                        label={<div style={{marginLeft: 24}}>{this.state.tileRange[0]}-{this.state.tileRange[1]} tuiles</div>}
                                        style={{
                                            background: '#f8f8f8',
                                            padding: 12,
                                            borderRadius: 8,
                                        }}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Slider
                                                style={{width: 100}}
                                                value={this.state.answerRange}
                                                onChange={this.handleAnswerRangeFilterChange}
                                                valueLabelDisplay="auto"
                                                marks={this.state.maxAnswerRange > this.state.minAnswerRange}
                                                step={1}
                                                min={this.state.minAnswerRange}
                                                max={this.state.maxAnswerRange}
                                                disabled={!this.hasDefinedLanguage()}
                                            />
                                        }
                                        label={<div style={{marginLeft: 24}}>{this.state.answerRange[0]}-{this.state.answerRange[1]} réponses</div>}
                                        style={{
                                            background: '#f8f8f8',
                                            padding: 12,
                                            borderRadius: 8,
                                        }}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Slider
                                                style={{width: 100}}
                                                value={this.state.difficultyRange}
                                                onChange={this.handleDifficultyRangeFilterChange}
                                                valueLabelDisplay="auto"
                                                marks
                                                min={0}
                                                max={5}
                                                step={1}
                                                disabled={!this.hasDefinedLanguage()}
                                            />
                                        }
                                        label={<div style={{marginLeft: 24}}>Difficulté {this.state.difficultyRange[0]} à {this.state.difficultyRange[1]}</div>}
                                        style={{
                                            background: '#f8f8f8',
                                            padding: 12,
                                            borderRadius: 8,
                                        }}
                                    />

                                    <TextField
                                        style={{marginRight: 24, width: 300}}
                                        value={this.state.nameFilter}
                                        label="Intitulé"
                                        placeholder="Filtrer sur l'intitulé"
                                        onChange={this.handleNameFilterChange}
                                        disabled={!this.hasDefinedLanguage()}
                                    />
                                </React.Fragment>
                            }

                        </div>

                        <div style={{ display: 'flex', height: 5000 }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGrid
                                    rowHeight={44}
                                    autoHeight
                                    sortingOrder={['asc', 'desc']}
                                    sortModel={sortModel}
                                    hideFooterSelectedRowCount={false}
                                    columns={this.columns}
                                    rows={this.state.filteredLists}
                                    pageSize={this.state.pageSize}
                                    page={this.state.page}
                                    onPageChange={this.onPageChange}
                                    onPageSizeChange={this.onPageChange}
                                    rowsPerPageOptions={[50, 100]}
                                />
                            </div>
                        </div>

                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.createList}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

                {!this.state.levelSelectionList ? null :
                    <Dialog disableBackdropClick disableEscapeKeyDown open={!!this.state.levelSelectionList}
                            onClose={this.onLevelSelectionClose}>
                        <DialogTitle>Choisir un niveau pour la
                            liste<br/><em>{this.state.levelSelectionList.name}</em></DialogTitle>
                        <DialogContent>
                            <form>
                                <TextField
                                    style={{marginRight: 12, width: 250, marginBottom: 24}}
                                    select
                                    value={this.state.levelSelectionList.level_id}
                                    onChange={this.onLevelSelectionChange}
                                    label="Niveau"
                                    SelectProps={{
                                        displayEmpty: true
                                    }}
                                >
                                    <MenuItem value={-1}>Aucun</MenuItem>
                                    {
                                        this.state.levels.map((lvl, i) => <MenuItem
                                            key={lvl.level_id}
                                            value={lvl.level_id}>Niveau {lvl.level_index + 1}</MenuItem>)
                                    }
                                </TextField>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onCancelLevelSelection} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={this.onCommitLevelSelection} color="primary">
                                Enregistrer
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                {!this.state.listDuplicationList ? null :
                    <Dialog disableBackdropClick disableEscapeKeyDown open={!!this.state.listDuplicationList}
                            onClose={this.onListDuplicationClose}>
                        <DialogTitle>Dupliquer la liste<br/><em>{this.state.listDuplicationList.name}</em></DialogTitle>
                        <DialogContent>
                            <form>
                                <TextField
                                    style={{marginRight: 12, width: 250, marginBottom: 24}}
                                    select
                                    value={this.state.listDuplicationLanguageId}
                                    onChange={this.onListDuplicationLanguageChange}
                                    label="Langue"
                                    SelectProps={{
                                        displayEmpty: true
                                    }}
                                >
                                    <MenuItem value={-1}>Aucun</MenuItem>
                                    {
                                        this.state.languages.filter(l => l.language_id !== this.state.listDuplicationList.language_id).map((l, i) => <MenuItem
                                            key={l.language_id}
                                            value={l.language_id}>{l.name}</MenuItem>)
                                    }
                                </TextField>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onCancelListDuplication} color="primary">
                                Annuler
                            </Button>
                            <Button disabled={this.state.listDuplicationLanguageId === -1}
                                    onClick={this.onCommitListDuplication(false)}
                                    color="secondary">
                                Dupliquer
                            </Button>
                            <Button disabled={this.state.listDuplicationLanguageId === -1}
                                    onClick={this.onCommitListDuplication(true)}
                                    color="secondary">
                                Dupliquer et modifier
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

            </React.Fragment>
        );
    }
}

CrackListLists.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListLists.defaultProps = {};

export default withRouter(CrackListLists);