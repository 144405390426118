import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {
    Button,
    IconButton,
    MenuItem,
    Paper,
    Snackbar,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ListIcon from '@material-ui/icons/List'
import KVC from "../../services/KVC";
import LabelEditor from "../LabelEditor";
import CrackListListPicker from "./CrackListListPicker";

class CrackListLevelDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
            pickListLanguageId: -1,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getAppLanguages(9),
            api.getCrackListLevel(this.getLevelId())
        ]).then(results => {
            const languages = results[0];
            const level = results[1];
            this.setState({
                languages: languages,
                level: level,
                $level: JSON.parse(JSON.stringify(level)),
            });
        });
    }

    getLevelId() {
        return parseInt(this.props.match.params.levelId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/levels/`);
            return;
        }
        this.props.history.goBack();
    };

    levelConfigIsPristine = () => {
        return JSON.stringify(this.state.level) === JSON.stringify(this.state.$level);
    };

    handleConfigValueChange = (propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleConfigChange(propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleConfigChange(propNamePath, e.target.value);
        }
    };

    handleConfigChange = (propNamePath, value) => {
        let level = Object.assign({}, this.state.level);
        KVC(level).setValueForKeyPath(propNamePath, value);
        this.setState({
            level: level,
        });
    };

    cancelEditingLevel = () => {
        this.setState({
            level: JSON.parse(JSON.stringify(this.state.$level)),
        });
    };

    commitEditingLevel = () => {
        const levelId = this.getLevelId();
        let level = this.state.level;
        api.patchCrackListLevel(levelId, level).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement du niveau.");
                return;
            }
            this.displaySnackbar("Niveau sauvegardé.");
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    pickList = (languageId) => (e) => {
        this.setState({
            pickListLanguageId: languageId,
        });
    }

    onListPicked = (languageId) => (list) => {
        api.patchCrackListLevelSpecifics({
            'list_id': list.list_id,
            'language_id': languageId,
            'level_id': this.getLevelId(),
        }).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement du choix de liste.");
                return;
            }
            this.setState({
                pickListLanguageId: -1,
            });
            this.updateData();
        });
    };

    openListDetails = (listId) => (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/list/${listId}`);
    };

    render() {

        if (this.state.level === undefined) {
            return <div/>;
        }

        const { languages, level } = this.state;

        let specificsMap = {};
        level.specifics.forEach(s => {
            specificsMap[s.language_id] = s
        });

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails du niveau {level.level_index + 1}</Typography>
                        </div>
                        {
                            this.levelConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingLevel}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingLevel}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>

                </Paper>

                {
                    level && languages ? (

                        <React.Fragment>

                            <Paper style={{padding: 24, marginBottom: 12}}>
                                <TextField
                                    onChange={this.handleConfigValueChange('fake_tile_count', 'number')}
                                    value={level.fake_tile_count}
                                    color="primary"
                                    label="leurres"
                                    type="number"
                                    inputProps={{
                                        style: {width: "100px"}
                                    }}
                                    style={{marginRight: 24}}
                                />
                                <TextField
                                    onChange={this.handleConfigValueChange('coin_reward', 'number')}
                                    value={level.coin_reward}
                                    color="primary"
                                    label="pièces"
                                    type="number"
                                    inputProps={{
                                        style: {width: "100px"}
                                    }}
                                    style={{marginRight: 24}}
                                />
                                <TextField
                                    onChange={this.handleConfigValueChange('live_count', 'number')}
                                    value={level.rotation}
                                    color="primary"
                                    label="vies"
                                    type="number"
                                    inputProps={{
                                        style: {width: "100px"},
                                        min: -1,
                                        max: 10
                                    }}
                                    style={{marginRight: 24}}
                                />(-1 = illimité)
                            </Paper>

                            {
                                languages.map(l =>
                                    <Paper key={l.language_id} style={{padding: 24, marginBottom: 12, display: 'none'}}>
                                        <div style={{display: 'flex'}}>
                                            <Typography variant="h6">{l.name}</Typography>
                                            <Button color="primary" onClick={this.pickList(l.language_id)}><ListIcon/>&nbsp;Choisir une liste</Button>
                                            <CrackListListPicker
                                                languageId={l.language_id}
                                                open={this.state.pickListLanguageId === l.language_id}
                                                onClose={() => { this.setState({pickListLanguageId: -1})}}
                                                onPick={this.onListPicked(l.language_id)}
                                                level={level}
                                            />
                                        </div>
                                        {
                                            !!specificsMap[l.language_id] ?
                                                <div>
                                                    <ul>
                                                        <li><strong>{specificsMap[l.language_id].list.name}</strong></li>
                                                        <li>{specificsMap[l.language_id].list.items.map(i => i.name).join(", ")}</li>
                                                    </ul>
                                                    <Button color="primary" onClick={this.openListDetails(specificsMap[l.language_id].list.list_id)}>Choisir la découpe</Button>
                                                </div>
                                                : null
                                        }
                                    </Paper>
                                )
                            }

                        </React.Fragment>
                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

CrackListLevelDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListLevelDetails.defaultProps = {

};

export default withRouter(CrackListLevelDetails);