import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withRouter from "react-router-dom/withRouter";
import '../8wordssearch/ImageDetails.css'
import api from '../../services/api';
import LanguagePicker from "../LanguagePicker";
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import KVC from "../../services/KVC";

class CodeWordsImageDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            $imageSpecificsConfig: undefined,
            languages: [],
            wordsEditorExpanded: true,
            languagePickerExpanded: false,
            snackbarDisplayed: false,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        const appId = this.props.app.app_id;
        Promise.all([
            api.getAppLanguages(appId),
            api.getAppImage(appId, this.getImageId())
        ]).then(results => {
            const image = results[1];
            const languages = results[0];//.filter(l => image.languages.indexOf(l.language_id) !== -1);
            this.setState({
                languages: languages,
                image: image,
                $imageSpecificsConfig: JSON.parse(JSON.stringify(image.specifics.config)),
            });
        });
    }

    getImageId() {
        return parseInt(this.props.match.params.imageId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/images/`);
            return;
        }
        this.props.history.goBack();
    };

    imageConfigIsPristine = () => {
        return JSON.stringify(this.state.image.specifics.config) === JSON.stringify(this.state.$imageSpecificsConfig);
    };

    onLanguagePickerToggle = () => {
        this.setState({
            languagePickerExpanded: !this.state.languagePickerExpanded,
        });
    };

    onWordsEditorToggle = () => {
        this.setState({
            wordsEditorExpanded: !this.state.wordsEditorExpanded,
        });
    };

    handleConfigSwitchChange = (propNamePath) => (e, checked) => {
        this.handleConfigChange(propNamePath, checked);
    };

    handleConfigValueChange = (propNamePath) => (e) => {
        this.handleConfigChange(propNamePath, e.target.value);
    };

    handleConfigChange = (propNamePath, value) => {
        let image = Object.assign({}, this.state.image);
        KVC(image.specifics.config).setValueForKeyPath(propNamePath, value);
        this.setState({
            image: image,
        });
    };

    saveImageLanguages = (languages, i) => {
        const appId = this.props.app.app_id;
        const imageId = this.getImageId();
        api.postAppImageLanguages(appId, imageId, languages.map(l => {
            return {
                app_id: appId,
                image_id: imageId,
                language_id: l.language_id,
            };
        })).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde des langues.");
                return;
            }
            this.updateData();
            this.displaySnackbar("Langues de l'image sauvegardées !");
        });
    };

    cancelEditingImage = () => {
        let image = Object.assign({}, this.state.image);
        image.specifics.config = JSON.parse(JSON.stringify(this.state.$imageSpecificsConfig));
        this.setState({
            image: image,
        });
    };

    commitEditingImage = () => {
        const appId = this.props.app.app_id;
        const imageId = this.getImageId();
        api.patchAppImage(appId, imageId, {
            app_id: appId,
            image_id: imageId,
            specifics: this.state.image.specifics.config,
            added_at: this.state.image.added_at,
        }).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement des propriétés de l'image.");
                return;
            }
            this.setState({
                $imageSpecificsConfig: JSON.parse(JSON.stringify(this.state.image.specifics.config)),
            });
            this.displaySnackbar("Propriétés de l'image enregistrées.");
        });
    };

    startEditingWords = (language, i) => () => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[i];
        languageSpecifics.editing = true;
        languageSpecifics.$words = Array.from(languageSpecifics.words);
        this.setState({
            image: image,
        });
    };

    handleEditingWord = (language, languageIndex, wordIndex) => (e) => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[languageIndex];
        languageSpecifics.words[wordIndex] = e.target.value.toUpperCase();
        this.setState({
            image: image,
        });
    };

    cancelEditingWords = (language, i) => () => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[i];
        languageSpecifics.editing = false;
        languageSpecifics.words = Array.from(languageSpecifics.$words);
        delete languageSpecifics.$words;
        this.setState({
            image: image,
        });
    };

    submitWords = (language, i) => (e) => {
        e.preventDefault();
        this.commitEditingWords(language, i);
    };

    commitEditingWords = (language, i) => () => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[i];
        delete languageSpecifics.$words;
        languageSpecifics.editing = false;

        let specifics = Object.assign({}, languageSpecifics);
        delete specifics.language_id;
        delete specifics.editing;
        const appId = this.props.app.app_id;
        const imageId = this.getImageId();
        api.patchAppImageLanguages(appId, imageId, [{
            app_id: appId,
            image_id: imageId,
            language_id: language.language_id,
            specifics: specifics,
        }]).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde des mots.");
                return;
            }
            this.setState({
                image: image,
            });
            this.displaySnackbar("Mots sauvegardés !");
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (this.state.image === undefined) {
            return <div/>;
        }

        const { image } = this.state;
        const { config, languages } = image.specifics;

        const positionValues = [
            {
                label: 'Ancré en haut',
                value: 'top'
            }, {
                label: 'Centré verticalement',
                value: 'center'
            }, {
                label: 'Ancré en bas',
                value: 'bottom'
            },
        ];

        //const appLanguages = languages.map(l => this.state.languages.find(e => e.language_id === l.language_id));
        const wordRange = [...Array(8).keys()];

        const classNamesForWordCell = (language, wordIndex) => {
            if (language.words === undefined || wordIndex >= language.words.length) {
                return {};
            }
            const bitLongThreshold = 10;
            const tooLongThreshold = 12;
            let wordLength = language.words[wordIndex].length;
            return {
                bitLong: wordLength >= bitLongThreshold && wordLength < tooLongThreshold,
                tooLong: wordLength >= tooLongThreshold,
            }
        };

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails de l'image</Typography>
                            <Typography variant="subtitle1" color="primary">{image.name}</Typography>
                        </div>
                        {
                            this.imageConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingImage}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingImage}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    this.state.image && this.state.languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 32}}>
                                        <div className="image">
                                            <img src={image.image_url} alt="" />
                                            <div className={classNames('crop', {
                                                cropTop: image.specifics.config.crop === 'top',
                                                cropCenter: image.specifics.config.crop === 'center',
                                                cropBottom: image.specifics.config.crop === 'bottom',
                                            })}/>
                                        </div>

                                        <TextField
                                            select
                                            style={{width: '100%'}}
                                            label="Cadrage"
                                            value={config.crop}
                                            onChange={this.handleConfigValueChange('crop')}

                                        >
                                            {
                                                positionValues.map((val, i) => <MenuItem key={i} value={val.value}>{val.label}</MenuItem>)
                                            }
                                        </TextField>
                                    </div>

                                    <div style={{flex: 1}}>


                                        <div style={{marginBottom: 24}}>

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={config.first_word_letter}
                                                        onChange={this.handleConfigSwitchChange('first_word_letter')}
                                                        value="1"
                                                    />}
                                                label="Offre une lettre du mot n°1"
                                            />

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={config.given_letter_count || 0}
                                                onChange={this.handleConfigValueChange('given_letter_count')}
                                                label="Lettres offertes au départ"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    [0, 1, 2, 3, 4, 5, 6].map((val, i) => <MenuItem key={i} value={val}>{""+val}</MenuItem>)
                                                }
                                            </TextField>

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={config.magic_letter_count || 0}
                                                onChange={this.handleConfigValueChange('magic_letter_count')}
                                                label="✨ Lettres magiques ✨"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    [0, 1, 2, 3, 4, 5, 6].map((val, i) => <MenuItem key={i} value={val}>{""+val}</MenuItem>)
                                                }
                                            </TextField>

                                        </div>

                                    </div>
                                </div>


                            </Paper>


                            <ExpansionPanel expanded={this.state.wordsEditorExpanded} onChange={this.onWordsEditorToggle}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Mots</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display: 'block', padding: 0}}>

                                    <Table className="words">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: 20}}/>
                                                {
                                                    languages.map(l => this.state.languages.find(ll => ll.language_id === l.language_id)).map(l =>
                                                        <TableCell key={l.language_id}>
                                                            {l.name}
                                                        </TableCell>
                                                    )
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{whiteSpace: 'nowrap'}}>
                                            {
                                                wordRange.map(wordIndex => (
                                                    <TableRow key={wordIndex}>
                                                        <TableCell>{wordIndex + 1}</TableCell>
                                                        {
                                                            languages.map((l, languageIndex) => (
                                                                <TableCell
                                                                    key={l.language_id}
                                                                    className={classNames(classNamesForWordCell(l, wordIndex))}
                                                                >
                                                                    {
                                                                        l.editing ? (
                                                                            <TextField
                                                                                value={wordIndex < l.words.length ? l.words[wordIndex] : ''}
                                                                                style={{maxWidth: '100%'}}
                                                                                onChange={this.handleEditingWord(l, languageIndex, wordIndex)}
                                                                                inputProps={{
                                                                                    form: `words-${l.language_id}`,
                                                                                    autoFocus: wordIndex === 0,
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            wordIndex < l.words.length ? l.words[wordIndex] : ''
                                                                        )
                                                                    }
                                                                </TableCell>
                                                            ))
                                                        }
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                        <TableFooter>

                                            <TableRow>
                                                <TableCell/>
                                                {
                                                    languages.map((l, i) => (

                                                        <TableCell key={l.language_id} style={{}}>
                                                            {l.editing ? (
                                                                <form onSubmit={this.submitWords(l, i)} id={`words-${l.language_id}`}>
                                                                    <IconButton
                                                                        color="secondary"
                                                                        onClick={this.cancelEditingWords(l, i)}
                                                                    >
                                                                        <CancelIcon/>
                                                                    </IconButton>
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={this.commitEditingWords(l, i)}
                                                                        type="submit"
                                                                    >
                                                                        <CheckCircleIcon/>
                                                                    </IconButton>
                                                                </form>
                                                            ) : (
                                                                <Button color="primary" onClick={this.startEditingWords(l, i)}>
                                                                    <EditIcon/>Modifier
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableFooter>
                                    </Table>


                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={this.state.languagePickerExpanded} onChange={this.onLanguagePickerToggle}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Disponibilité selon la langue</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display: 'block', padding: 0}}>
                                    <LanguagePicker
                                        languages={this.state.languages}
                                        initialLanguages={this.state.image.languages}
                                        onSave={this.saveImageLanguages}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

CodeWordsImageDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

CodeWordsImageDetails.defaultProps = {

};

export default withRouter(CodeWordsImageDetails);