import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography} from '@material-ui/core';
import api from '../services/api'
import {withRouter} from "react-router-dom";

class InAppStoreProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            storeProducts: [],
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        api.getAppStoreProducts(this.props.app.app_id).then(products => {
            this.setState({
                storeProducts: products,
            });
        });
    }

    static getProductLabel(product, labelKey, defaultValue, languageId) {
        if (!product[labelKey] || !product[labelKey].languages || product[labelKey].languages.length === 0) {
            return defaultValue;
        }
        let labelValue = undefined;
        if (languageId !== undefined) {
            labelValue = product[labelKey].languages.find(l => l.language_id === languageId);
        }
        if (!labelValue || !labelValue.value) {
            labelValue = product[labelKey].languages.find(l => !!l.value && l.value.length !== 0);
        }
        if (!!labelValue && !!labelValue.value) {
            return labelValue.value;
        }
        return defaultValue;
    }

    goToProduct = (product) => (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/product/${product.product_id}`);
    };

    render() {
        return (
            <Paper>
                <Toolbar>
                    <div style={{flex: 1, alignItems: 'center'}}>
                        <Typography variant="h6">
                            Boutique in-app
                        </Typography>
                    </div>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: 60}}/>
                            <TableCell style={{width: 60}}/>
                            <TableCell>Nom</TableCell>
                            <TableCell>Détails</TableCell>
                            <TableCell>Nombre de pièces</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.storeProducts.map((product, i) => {
                            return (
                                <TableRow key={product.product_id} onClick={this.goToProduct(product)}>
                                    <TableCell><img src={`/${product.image_name}.png`} alt="" style={{width: '48px', height: '48px'}} /></TableCell>
                                    <TableCell>{product.remove_ads ? <img src="/IconNoAds.png" alt="" style={{width: 32, height: 32}} /> : null }</TableCell>
                                    <TableCell>{InAppStoreProducts.getProductLabel(product, 'title_label', '(Sans titre)')}</TableCell>
                                    <TableCell>{InAppStoreProducts.getProductLabel(product, 'details_label', '(Sans détails)')}</TableCell>
                                    <TableCell numeric="true">{product.coin_count}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

InAppStoreProducts.propTypes = {
    app: PropTypes.object.isRequired,
};

export default withRouter(InAppStoreProducts);