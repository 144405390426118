import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import Link from '@material-ui/core/Link'
import api from '../../services/api'
import LinkIcon from '@material-ui/icons/Link'

class WoodysWordsAppDataVersions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newDataVersion: '',
            dataVersions: [],
        };
    }

    componentDidMount() {
        api.getAppDataVersions(this.props.app.app_id).then(versions => {
            this.setState({
                dataVersions: versions,
            });
        });
    }

    newDataVersionIsValid() {
        return this.state.newDataVersion.trim().length !== 0;
    }

    createDataVersion = (e) => {
        e.preventDefault();
        if (!this.newDataVersionIsValid()) {
            return;
        }
        const appId = this.props.app.app_id;
        api.postAppDataVersion(appId, {
            app_id: appId,
            name: this.state.newDataVersion,
        }).then(version => {
            this.setState({
                newDataVersion: '',
                dataVersions: this.state.dataVersions.concat([version]),
            });
        });
    };

    getLastPublicationMoment(version) {
        if (!version.last_update) {
            return 'Jamais';
        }

        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        };
        const date = new Date(version.last_update * 1000);
        return (
            <React.Fragment>
                {new Intl.DateTimeFormat('fr-FR', options).format(date)}
                <Link target="_blank" href={version.levels_feed_url}>
                    <Button size="small" style={{marginLeft: 12}}>
                        <LinkIcon/>&nbsp;Niveaux
                    </Button>
                </Link>
                <Link target="_blank" href={version.store_feed_url}>
                    <Button size="small">
                        <LinkIcon/>&nbsp;Boutique
                    </Button>
                </Link>
            </React.Fragment>
        );

    }

    handleVersionChange = (e) => {
        this.setState({
            newDataVersion: e.target.value,
        });
    };

    publishVersion = (version, i) => (e) => {
        api.postAppDataVersionPublish(this.props.app.app_id, version.name).then(res => {
            let versions = Array.from(this.state.dataVersions);
            versions[i] = res;
            this.setState({
                dataVersions: versions,
            });
        });
    };

    render() {
        return (
            <Paper>
                <Toolbar>
                    <div style={{flex: 1, alignItems: 'center'}}>
                        <Typography variant="h6">
                            Publication des données
                        </Typography>
                    </div>
                    <div>
                        <form onSubmit={this.createDataVersion}>
                            <TextField
                                label="Nom de version"
                                placeholder="x.y.z"
                                onChange={this.handleVersionChange}
                                value={this.state.newDataVersion}
                                color="primary"
                            />
                            <Button
                                color="primary"
                                type="submit"
                                disabled={!this.newDataVersionIsValid()}
                            >Nouvelle version</Button>
                        </form>
                    </div>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: 200}}>Nom de version</TableCell>
                            <TableCell>Dernière publication</TableCell>
                            <TableCell style={{width: 280}}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.dataVersions.map((version, i) => {
                            return (
                                <TableRow key={version.name}>
                                    <TableCell>
                                        <pre style={{
                                            fontSize:'1.3rem',
                                        }}>{version.name}</pre>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{this.getLastPublicationMoment(version)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {version.active ? <Button color="primary" onClick={this.publishVersion(version, i)}>Publier les données</Button> : null}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

WoodysWordsAppDataVersions.propTypes = {
    app: PropTypes.object.isRequired,
};

WoodysWordsAppDataVersions.defaultProps = {};

export default WoodysWordsAppDataVersions;
