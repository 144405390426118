import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {
    Button, Checkbox, FormControlLabel,
    IconButton, ListItemText,
    MenuItem, OutlinedInput,
    Paper, Select,
    Snackbar, Switch,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import KVC from "../../services/KVC";
import LabelEditor from "../LabelEditor";

class CrackListPartyListDetails extends React.Component {

    constructor(props) {
        super(props);
        let state = {
            languages: [],
            categories: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
        };
        if (props.match.url.includes("/create-list")) {
            state.list = {
                name: '',
                language_id: props.match.params.languageId,
                category_id: null,
                discovery: 0,
                discovery_box: 0,
            }
            state.$list = Object.assign({}, state.list);
        }
        this.state = state;
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let requests = [
            api.getAppLanguages(10),
        ];
        const listId = this.getListId();
        if (!!listId) {
            requests.push(api.getCrackListPartyList(this.getListId()));
        }
        Promise.all(requests).then(results => {
            const languages = results[0];
            let state = {
                languages: languages,
                list: this.state.list,
            };
            if (results.length > 1) {
                const list = results[1];
                state = {
                    ...state,
                    list: list,
                    $list: Object.assign({}, list),
                };
            }

            api.getCrackListPartyCategories(state.list.language_id).then(res => {
                state.categories = res;
                this.setState(state);
            });
        });
    }

    getListId() {
        return parseInt(this.props.match.params.listId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/lists/`);
            return;
        }
        this.props.history.goBack();
    };

    listConfigIsPristine = () => {
        return JSON.stringify(this.state.list) === JSON.stringify(this.state.$list);
    };

    handleConfigValueChange = (propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleConfigChange(propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleConfigChange(propNamePath, e.target.value);
        }
    };

    handleConfigSwitchChange = (propNamePath) => (e, checked) => {
        this.handleConfigChange(propNamePath, checked ? 1 : 0);
    };

    handleConfigChange = (propNamePath, value) => {
        let list = Object.assign({}, this.state.list);
        KVC(list).setValueForKeyPath(propNamePath, value);
        this.setState({
            list: list,
        });
    };

    handleCategoryChange = (e) => {
        this.handleConfigChange('category_id', e.target.value);
    }

    cancelEditingList = () => {
        this.setState({
            list: Object.assign(this.state.$list),
        });
    };

    commitEditingList = () => {
        const listId = this.getListId();
        if (!!listId) {
            api.patchCrackListPartyList(listId, this.state.list).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement de la liste.");
                    return;
                }
                this.displaySnackbar("Liste sauvegardée.");
                this.updateData();
            });
        } else {
            api.postCrackListPartyList(this.state.list).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement de la liste.");
                    return;
                }
                this.setState({
                    list: res,
                    $list: JSON.parse(JSON.stringify(res))
                });
                this.props.history.replace(`/${this.props.app.url_slug}/list/${res.list_id}`);
            });
        }
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (this.state.list === undefined) {
            return <div/>;
        }

        const { languages, categories, list } = this.state;

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">{!this.getListId() ? "Nouvelle liste" : `Détails de la liste (ID : ${this.getListId()})`}</Typography>
                        </div>
                        {
                            this.listConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingList}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingList}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    list && languages && categories ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={list.language_id || -1}
                                                onChange={this.handleConfigValueChange('language_id')}
                                                label="Langue"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !list.language_id ? <MenuItem value={-1} disabled>Sélectionner une langue</MenuItem> : null
                                                }
                                                {
                                                    languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                                }
                                            </TextField>

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={list.category_id || -1}
                                                onChange={this.handleCategoryChange}
                                                label="Catégorie"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !list.category_id ? <MenuItem value={-1} disabled>Sélectionner une catégorie</MenuItem> : null
                                                }
                                                {
                                                    categories.map((c, i) => <MenuItem key={i} value={c.category_id}>{c.name} ({c.list_count})</MenuItem>)
                                                }
                                            </TextField>
                                        </div>

                                        <div style={{marginBottom: 32}}>

                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={list.name}
                                                onChange={this.handleConfigValueChange('name')}
                                                label="Intitulé"
                                            />

                                        </div>


                                        <div style={{marginBottom: 12}}>

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={list.discovery === 1}
                                                        onChange={this.handleConfigSwitchChange('discovery')}
                                                        value="1"
                                                    />}
                                                label="Liste Découverte"
                                            />

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={list.discovery_box === 1}
                                                        onChange={this.handleConfigSwitchChange('discovery_box')}
                                                        value="1"
                                                    />}
                                                label="Liste Découverte Boîte de jeu"
                                            />

                                        </div>


                                    </div>
                                </div>

                            </Paper>

                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

CrackListPartyListDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListPartyListDetails.defaultProps = {

};

export default withRouter(CrackListPartyListDetails);