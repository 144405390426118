import React from 'react'
import {Button} from '@material-ui/core';

class Pagination extends React.Component {


    static getNumberOfPages(totalCount, itemPerPage) {
        if (totalCount === 0) {
            return 0;
        }
        return Math.ceil((totalCount - 1) / itemPerPage);
    }

    static getCurrentMinimumImageIndex(currentPage, itemPerPage) {
        return currentPage * itemPerPage;
    }

    static getCurrentMaximumImageIndex(totalCount, currentPage, itemPerPage) {
        return Math.min(totalCount - 1, this.getCurrentMinimumImageIndex(currentPage, itemPerPage) + itemPerPage - 1);
    }

    static currentPageObjects(objects, currentPage, itemPerPage) {
        const i = this.getCurrentMinimumImageIndex(currentPage, itemPerPage);
        return objects.slice(i, Math.min(objects.length, i + itemPerPage));
    }

    static clampedCurrentPage(currentPage, totalCount, itemPerPage) {
        return Math.max(Math.min(currentPage, this.getNumberOfPages(totalCount, itemPerPage) - 1), 0)
    }

    render () {
        const styles = {
            button: {padding: '4px', minWidth: '32px'}
        };
        return this.props.numberOfPages <= 1 ? null : (
            <div style={{padding: '12px', margin: '12px', textAlign: 'center', clear: 'both'}}>

                <Button
                    color="primary"
                    size="small"
                    style={{
                        visibility: this.props.currentPage < 1 ? 'hidden' : 'visible',
                        ...styles.button
                    }}
                    onClick={() => this.props.onPageChange(this.props.currentPage - 1)}
                >&laquo;</Button>

                {
                    [...Array(this.props.numberOfPages).keys()].map((i) => {
                        return (
                            <Button
                                key={i}
                                color={i === this.props.currentPage ? "primary" : "default"}
                                size="small"
                                style={styles.button}
                                variant={i === this.props.currentPage ? "contained" : "text"}
                                onClick={() => this.props.onPageChange(i)}
                            >{i+1}</Button>
                        )
                    })
                }

                <Button
                    color="primary"
                    size="small"
                    style={{
                        visibility: this.props.currentPage >= this.props.numberOfPages - 1 ? 'hidden' : 'visible',
                        ...styles.button
                    }}
                    onClick={() => this.props.onPageChange(this.props.currentPage + 1)}
                >&raquo;</Button>

            </div>
        )
    }
}

export default Pagination;
