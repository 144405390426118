import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import LanguagePicker from "../LanguagePicker";
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    MenuItem,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import KVC from "../../services/KVC";

class WordsApartImageDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            $imageSpecificsConfig: undefined,
            languages: [],
            wordsEditorExpanded: true,
            languagePickerExpanded: false,
            snackbarDisplayed: false,
        };
    }
    componentDidMount() {
        this.updateData();
    }

    updateData() {
        const appId = this.props.app.app_id;
        Promise.all([
            api.getAppLanguages(appId),
            api.getAppImage(appId, this.getImageId())
        ]).then(results => {
            const image = results[1];
            const languages = results[0];

            var languageSpecifics = image.specifics.languages;
            languageSpecifics.forEach(sp => {
                if (!sp.words || sp.words.length === 0) {
                    sp.words = [
                        ["","","","","","","",""],
                        ["","","","","","","",""],
                        ["","","","","","","",""],
                        ["","","","","","","",""],
                        ["","","","","","","",""],
                        ["","","","","","","",""],
                        ["","","","","","","",""],
                        ["","","","","","","",""],
                    ];
                }
            });

            this.setState({
                languages: languages,
                image: image,
                $imageSpecificsConfig: JSON.parse(JSON.stringify(image.specifics.config)),
            });
        });
    }

    getImageId() {
        return parseInt(this.props.match.params.imageId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/images/`);
            return;
        }
        this.props.history.goBack();
    };

    imageConfigIsPristine = () => {
        return JSON.stringify(this.state.image.specifics.config) === JSON.stringify(this.state.$imageSpecificsConfig);
    };

    onLanguagePickerToggle = () => {
        this.setState({
            languagePickerExpanded: !this.state.languagePickerExpanded,
        });
    };

    onWordsEditorToggle = () => {
        this.setState({
            wordsEditorExpanded: !this.state.wordsEditorExpanded,
        });
    };

    handleConfigValueChange = (propNamePath) => (e) => {
        this.handleConfigChange(propNamePath, e.target.value);
    };

    handleConfigChange = (propNamePath, value) => {
        let image = Object.assign({}, this.state.image);
        KVC(image.specifics.config).setValueForKeyPath(propNamePath, value);
        this.setState({
            image: image,
        });
    };

    saveImageLanguages = (languages, i) => {
        const appId = this.props.app.app_id;
        const imageId = this.getImageId();
        api.postAppImageLanguages(appId, imageId, languages.map(l => {
            return {
                app_id: appId,
                image_id: imageId,
                language_id: l.language_id,
            };
        })).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde des langues.");
                return;
            }
            this.updateData();
            this.displaySnackbar("Langues de l'image sauvegardées !");
        });
    };

    cancelEditingImage = () => {
        let image = Object.assign({}, this.state.image);
        image.specifics.config = JSON.parse(JSON.stringify(this.state.$imageSpecificsConfig));
        this.setState({
            image: image,
        });
    };

    commitEditingImage = () => {
        const appId = this.props.app.app_id;
        const imageId = this.getImageId();
        api.patchAppImage(appId, imageId, {
            app_id: appId,
            image_id: imageId,
            specifics: this.state.image.specifics.config,
            added_at: this.state.image.added_at,
        }).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement des propriétés de l'image.");
                return;
            }
            this.setState({
                $imageSpecificsConfig: JSON.parse(JSON.stringify(this.state.image.specifics.config)),
            });
            this.displaySnackbar("Propriétés de l'image enregistrées.");
        });
    };

    startEditingWords = (language, i) => () => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[i];
        languageSpecifics.editing = true;
        languageSpecifics.$words = Array.from(languageSpecifics.words);
        this.setState({
            image: image,
        });
    };

    handleEditingPart = (language, languageIndex, wordIndex, partIndex) => (e) => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[languageIndex];
        languageSpecifics.words[wordIndex][partIndex] = e.target.value.toUpperCase();
        this.setState({
            image: image,
        });
    };

    cancelEditingWords = (language, i) => () => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[i];
        languageSpecifics.editing = false;
        languageSpecifics.words = Array.from(languageSpecifics.$words);
        delete languageSpecifics.$words;
        this.setState({
            image: image,
        });
    };

    submitWords = (language, i) => (e) => {
        e.preventDefault();
        this.commitEditingWords(language, i);
    };

    commitEditingWords = (language, i) => () => {
        let image = Object.assign({}, this.state.image);
        let languageSpecifics = image.specifics.languages[i];
        delete languageSpecifics.$words;
        languageSpecifics.editing = false;

        let specifics = Object.assign({}, languageSpecifics);
        delete specifics.language_id;
        delete specifics.editing;
        delete specifics.display_words;
        const appId = this.props.app.app_id;
        const imageId = this.getImageId();
        api.patchAppImageLanguages(appId, imageId, [{
            app_id: appId,
            image_id: imageId,
            language_id: language.language_id,
            specifics: specifics,
        }]).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde des mots.");
                return;
            }
            this.updateData();
            this.displaySnackbar("Mots sauvegardés !");
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    countParts(languageId) {
        const languageSpecific = this.state.image.specifics.languages.find((it, idx) => it.language_id === languageId);
        if (!languageSpecific) {
            return 0;
        }
        return languageSpecific.words.reduce(function(prevValue, word) {
            let c = 0;
            word.forEach(part => {
                if (!!part && part !== null && part.length !== 0) {
                    c++;
                }
            });
            return prevValue + c;
        }, 0);
    }

    render() {

        if (this.state.image === undefined) {
            return <div/>;
        }

        const { image } = this.state;
        const { config, languages } = image.specifics;
        const wordRange = [...Array(8).keys()];

        function wordBackgroundColor(l, wordIndex) {
            const word = l.words[wordIndex];
            if (word[0].length !== 0) {
                if (l.words[wordIndex][1].length === 0 && l.words[wordIndex][2].length === 0) {
                    if (word[0].length < 4) {
                        return "#fff4d8";
                    } else {
                        return "#fac6cc"
                    }
                }
            }
            return "#fff";
        }

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails de l'image</Typography>
                            <Typography variant="subtitle1" color="primary">{image.name}</Typography>
                        </div>
                        {
                            this.imageConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingImage}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingImage}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    this.state.image && this.state.languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 32}}>
                                        <div className="image">
                                            <img src={image.image_url} alt="" />
                                        </div>

                                    </div>

                                    <div style={{flex: 1}}>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 200}}
                                                select
                                                value={config.fake_parts}
                                                onChange={this.handleConfigValueChange('fake_parts')}
                                                label="Nombre de faux morceaux"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((val, i) => <MenuItem key={i} value={val}>{""+val}</MenuItem>)
                                                }
                                            </TextField>

                                        </div>

                                    </div>
                                </div>

                            </Paper>


                            <ExpansionPanel expanded={this.state.wordsEditorExpanded} onChange={this.onWordsEditorToggle}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Mots</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display: 'block', padding: 0}}>

                                    <Table className="words">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width: 20}}/>
                                                {
                                                    languages.map(l =>
                                                        <TableCell key={l.language_id} style={{
                                                            width: l.editing ? 250 : 'auto',
                                                        }}>
                                                            {this.state.languages.find(ll => ll.language_id === l.language_id).name}
                                                        </TableCell>
                                                    )
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{whiteSpace: 'nowrap'}}>
                                            {
                                                wordRange.map(wordIndex => (
                                                    <TableRow key={wordIndex}>
                                                        <TableCell>{wordIndex + 1}</TableCell>
                                                        {
                                                            languages.map((l, languageIndex) => (

                                                                l.editing ? (

                                                                    <TableCell
                                                                        key={l.language_id}
                                                                        style={{width: 250}}
                                                                    >
                                                                        <React.Fragment>
                                                                            {
                                                                                l.words[wordIndex].map((part, partIndex) => (
                                                                                    <TextField
                                                                                        key={partIndex}
                                                                                        value={part}
                                                                                        style={{
                                                                                            width: 28
                                                                                        }}
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                fontSize: "1em",
                                                                                            },
                                                                                        }}
                                                                                        placeholder="&nbsp;&nbsp;.&nbsp;&nbsp;"
                                                                                        onChange={this.handleEditingPart(l, languageIndex, wordIndex, partIndex)}
                                                                                    />
                                                                                ))
                                                                            }
                                                                        </React.Fragment>

                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell
                                                                        key={l.language_id}
                                                                        style={{
                                                                            backgroundColor: wordBackgroundColor(l, wordIndex)

                                                                        }}>
                                                                        {wordIndex < l.display_words.length ? l.display_words[wordIndex] : ''}
                                                                    </TableCell>
                                                                )
                                                            ))
                                                        }
                                                    </TableRow>
                                                ))
                                            }
                                            <TableRow>
                                                <TableCell/>
                                                {
                                                    languages.map((l, languageIndex) => (
                                                        <TableCell
                                                            key={l.language_id}
                                                            style={{
                                                                width: l.editing ? 250 : 'auto',
                                                                backgroundColor: this.countParts(l.language_id) <= 25 ? "inherit" : "#fff4d8"
                                                            }}
                                                        >
                                                            {this.countParts(l.language_id)} morc.
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableBody>
                                        <TableFooter>

                                            <TableRow>
                                                <TableCell/>
                                                {
                                                    languages.map((l, i) => (

                                                        <TableCell key={l.language_id} style={{
                                                            width: l.editing ? 250 : 'auto',
                                                        }}>
                                                            {l.editing ? (
                                                                <form onSubmit={this.submitWords(l, i)} id={`words-${l.language_id}`}>
                                                                    <IconButton
                                                                        color="secondary"
                                                                        onClick={this.cancelEditingWords(l, i)}
                                                                    >
                                                                        <CancelIcon/>
                                                                    </IconButton>
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={this.commitEditingWords(l, i)}
                                                                        type="submit"
                                                                    >
                                                                        <CheckCircleIcon/>
                                                                    </IconButton>
                                                                </form>
                                                            ) : (
                                                                <Button color="primary" onClick={this.startEditingWords(l, i)}>
                                                                    <EditIcon/>Modifier
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableFooter>
                                    </Table>


                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={this.state.languagePickerExpanded} onChange={this.onLanguagePickerToggle}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Disponibilité selon la langue</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display: 'block', padding: 0}}>
                                    <LanguagePicker
                                        languages={this.state.languages}
                                        initialLanguages={this.state.image.languages}
                                        onSave={this.saveImageLanguages}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>
        )
    }
}

WordsApartImageDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

WordsApartImageDetails.defaultProps = {

};

export default withRouter(WordsApartImageDetails);