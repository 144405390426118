import React from 'react';
import Dropzone from "react-dropzone";
import './UploadDialog.css'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    GridList,
    GridListTile,
    IconButton,
    Snackbar,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import FileUploadIcon from '@material-ui/icons/CloudUpload';
import api from '../services/api'

class UploadDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadingImages: false,
            showUploadError: false,
            uploadError: "",
            previewImages: [],
            open: false,
            showUploadErrorDialog: false,
            uploadErrorSimilarImages: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            open: props.open
        };
    }

    onUploadDialogClose = () => {
        this.resetUploadState();
        this.props.onUploadFinished([], null);
    };

    onUploadDialogAccept = (e) => {
        e.preventDefault();
        if (this.state.previewImages.length === 0) {
            this.resetUploadState();
            return;
        }
        this.uploadImages(this.state.previewImages.map((previewImage) => previewImage.file), (images, error) => {
            this.props.onUploadFinished(images, error);
        });
    };

    onUploadDialogCancel = (e) => {
        e.preventDefault();
        this.resetUploadState();
        this.props.onUploadFinished([], null);
    };

    onImagesDrop = (acceptedFiles, rejectedFiles) => {
        this.loadImagePreviews(acceptedFiles);
    };

    async uploadImages(imageFiles, callback) {
        const formData = new FormData();

        imageFiles.forEach((file, i) => {
            formData.append(file.name, file, file.name);
        });

        this.setState({
            uploadingImages: true,
            uploadError: "",
            showUploadError: false,
        });

        api.uploadImages(formData).then(res => {
            if (res === undefined) {
                this.setState({
                    uploadError: "Erreur lors de l'envoi des images",
                    showUploadError: true,
                    uploadingImages: false
                });
                return;
            }

            let similarImagesErrors = res
                .filter(wrapper => !!wrapper.error && wrapper.error.code === 'similar_image_found')
                .map(wrapper => wrapper.error.similar_image);
            if (similarImagesErrors.length > 0) {
                this.setState({
                    showUploadErrorDialog: true,
                    uploadErrorSimilarImages: similarImagesErrors,
                    uploadingImages: false,
                });
                this.resetUploadState();
                return;
            }

            this.setState({
                uploadingImages: false,
                previewImages: []
            });
            callback(res, null);
        });
    }

    resetUploadState() {
        this.state.previewImages.forEach((previewImage) => {
            window.URL.revokeObjectURL(previewImage.file.preview);
        });
        this.setState({
            previewImages: [],
            showUploadError: false,
            uploadError: ""
        });
    }

    removeImage = (image) => () => {
        const idx = this.state.previewImages.findIndex((el, idx) => {
            return el.url === image.url;
        });
        if (idx === -1) {
            return;
        }
        window.URL.revokeObjectURL(image.file.preview);
        let previewImages = this.state.previewImages;
        previewImages.splice(idx, 1);
        this.setState({
            previewImages: previewImages
        });
    };


    loadImagePreviews(imageFiles) {
        let previewImages = this.state.previewImages;
        imageFiles.forEach((file) => {
            if (!file.type.match('image.*')) {
                return;
            }
            const reader = new FileReader();
            reader.onload = (upload) => {
                previewImages.push({
                    url: upload.target.result,
                    file: file
                });
                this.setState({
                    previewImages: previewImages
                });
            };
            reader.readAsDataURL(file);
        });
    }

    dismissUploadErrorSimilarImagesDialog = () => {
        this.setState({
            showUploadErrorDialog: false,
            uploadErrorSimilarImages: []
        });
    };

    render() {

        let dialogBody = this.state.uploadingImages ? (
            <div className="uploadProgress">
                <CircularProgress size={50}/>
            </div>
        ) : (

            <React.Fragment>
                <DialogContentText>
                    Glisser-déposer une ou plusieurs images dans cette zone ou appuyer pour choisir des images.
                </DialogContentText>

                <Dropzone
                    onDrop={this.onImagesDrop}
                    className="uploadImage"
                    accept="image/*"
                    multiple>
                    <ImageIcon/><br/>
                    <Typography variant="subtitle1">Sélectionner une ou plusieurs images</Typography>
                </Dropzone>
                <GridList cellHeight={200} cols={3}>
                    {this.state.previewImages.map((previewImage, i) => (
                        <GridListTile key={i}>
                            <img src={previewImage.url} alt="" className="imagePreview"/>
                            <div className="imagePreviewLegend">
                                <span>{previewImage.file.name}</span>
                                <IconButton className="imagePreviewDelete" onClick={this.removeImage(previewImage)}>
                                    <DeleteIcon style={{color: 'white'}}/>
                                </IconButton>
                            </div>
                        </GridListTile>
                    ))}
                </GridList>
            </React.Fragment>
        );

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.onUploadDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Ajouter de nouvelles images</DialogTitle>
                    <DialogContent>
                        {dialogBody}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onUploadDialogCancel} color="default">
                            Annuler
                        </Button>
                        <Button color="primary" onClick={this.onUploadDialogAccept}
                                disabled={this.state.uploadingImages}>
                            <FileUploadIcon/>Enregistrer
                        </Button>
                    </DialogActions>
                    <Snackbar
                        open={this.state.showUploadError}
                        message={this.state.uploadError}
                        action={
                            <Button color="secondary" size="small"
                                    onClick={() => this.setState({showUploadError: false})}>
                                OK
                            </Button>
                        }
                        onClose={() => this.setState({showUploadError: false})}
                        autoHideDuration={3000}
                    />
                </Dialog>

                {
                    this.state.showUploadErrorDialog ? (
                        <Dialog
                            open={this.state.showUploadErrorDialog}
                            maxWidth="sm"
                            aria-labelledby="confirmation-dialog-title"
                        >
                            <DialogTitle id="confirmation-dialog-title">
                                Erreurs lors de l'envoi
                            </DialogTitle>
                            <DialogContent>
                                {
                                    this.state.uploadErrorSimilarImages.length === 0 ? null : (
                                        <React.Fragment>
                                            <Typography variant="body1" component="p">
                                                Les images suivantes existent déjà
                                            </Typography>
                                            {
                                                this.state.uploadErrorSimilarImages.map((image, idx) => (
                                                    <img
                                                        src={image.thumbnail_url}
                                                        alt=""
                                                        key={idx}
                                                        width={250}
                                                        style={{
                                                            display: 'block',
                                                            margin: '24px auto 0 auto'
                                                        }}
                                                        align="center"
                                                    />
                                                ))
                                            }
                                        </React.Fragment>
                                    )
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.dismissUploadErrorSimilarImagesDialog} color="default">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null
                }

            </div>
        )

    }
}

export default UploadDialog;
