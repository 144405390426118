import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {
    Button,
    ButtonBase,
    FormControlLabel,
    IconButton,
    Paper, Slider,
    Snackbar, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import KVC from "../../services/KVC";
import ColorPicker from 'material-ui-color-picker'
import EditIcon from "@material-ui/core/SvgIcon/SvgIcon";
import LabelEditor from "../LabelEditor";

class CrackListPartyPackDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            categories: [],
            penaltyCategories: [],
            snackbarDisplayed: false,
            showNameLabelEditor: false,
            showDetailsLabelEditor: false,
            editingMix: false,
            editingPenaltyMix: false
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getAppLanguages(10),
            api.getCrackListPartyCategories(1),
            api.getCrackListPartyPenaltyCategories(1),
            api.getCrackListPartyPack(this.getPackId())
        ]).then(results => {
            const languages = results[0];
            const categories = results[1];
            const penaltyCategories = results[2];
            const pack = results[3];
            this.setState({
                languages: languages,
                pack: pack,
                categories: categories,
                penaltyCategories: penaltyCategories,
                $pack: Object.assign({}, pack),
            });
        });
    }

    getPackId() {
        return parseInt(this.props.match.params.packId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/packs/`);
            return;
        }
        this.props.history.goBack();
    };

    packConfigIsPristine = () => {
        return JSON.stringify(this.state.pack) === JSON.stringify(this.state.$pack);
    };

    handleConfigValueChange = (propNamePath) => (e) => {
        this.handleConfigChange(propNamePath, e.target.value);
    };

    handleConfigSwitchChange = (propNamePath) => (e, checked) => {
        this.handleConfigChange(propNamePath, checked);
    };

    handleConfigChange = (propNamePath, value) => {
        let pack = Object.assign({}, this.state.pack);
        KVC(pack).setValueForKeyPath(propNamePath, value);
        this.setState({
            pack: pack,
        });
    };

    cancelEditingPack = () => {
        this.setState({
            pack: Object.assign(this.state.$pack),
        });
    };

    commitEditingPack = () => {
        const packId = this.getPackId();
        let pack = Object.assign({}, this.state.pack)
        delete pack.mix;
        api.patchCrackListPartyPack(packId, pack).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement du pack.");
                return;
            }
            this.displaySnackbar("Pack sauvegardé.");
        });
    };

    onNameLabelEditingFinished = (label) => {
        let pack = Object.assign({}, this.state.pack);
        pack.name = label;
        let $pack = Object.assign({}, this.state.$pack);
        $pack.name = label;
        this.setState({
            showNameLabelEditor: false,
            pack: pack,
            $pack: $pack,
        });
    };

    onDetailsLabelEditingFinished = (label) => {
        let pack = Object.assign({}, this.state.pack);
        pack.details = label;
        let $pack = Object.assign({}, this.state.$pack);
        $pack.details = label;
        this.setState({
            showDetailsLabelEditor: false,
            pack: pack,
            $pack: $pack,
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    startEditingMix = e => {
        this.setState({
            editingMix: true
        });
    }

    cancelEditingMix = e => {
        this.setState({
            editingMix: true
        });
    }

    commitEditingMix = e => {
        let pack = Object.assign({}, this.state.pack)
        let mix = [...pack.mix.list_1, ...pack.mix.list_2, ...pack.mix.list_3]
        api.patchCrackListPartyPackMix(mix).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement du mix");
                return;
            }
            this.displaySnackbar("Mix sauvegardé.");
            this.updateData();
        });
        this.setState({
            editingMix: false
        });
    }

    handleMixValueChange = (listKey, categoryId) => (e, value) => {
        let pack = Object.assign({}, this.state.pack)
        let categoryIndex = pack.mix[listKey].findIndex(m => m.category_id === categoryId)
        pack.mix[listKey][categoryIndex].mix_value = value / 100
        this.setState({
            pack: pack,
        })
    }


    startEditingPenaltyMix = e => {
        this.setState({
            editingPenaltyMix: true
        });
    }

    cancelEditingPenaltyMix = e => {
        this.setState({
            editingPenaltyMix: true
        });
    }

    commitEditingPenaltyMix = e => {
        let pack = Object.assign({}, this.state.pack)
        let mix = [...pack.penalty_mix.penalty_1, ...pack.penalty_mix.penalty_2, ...pack.penalty_mix.penalty_3]
        api.patchCrackListPartyPackPenaltyMix(mix).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement du mix gages");
                return;
            }
            this.displaySnackbar("Mix gages sauvegardé.");
            this.updateData();
        });
        this.setState({
            editingPenaltyMix: false
        });
    }

    handlePenaltyMixValueChange = (penaltyKey, categoryId) => (e, value) => {
        let pack = Object.assign({}, this.state.pack)
        let categoryIndex = pack.penalty_mix[penaltyKey].findIndex(m => m.category_id === categoryId)
        pack.penalty_mix[penaltyKey][categoryIndex].mix_value = value / 100
        this.setState({
            pack: pack,
        })
    }

    render() {

        if (this.state.pack === undefined) {
            return <div/>;
        }

        const { pack, languages, categories, penaltyCategories } = this.state;

        const name = LabelEditor.getLabelValue(pack.name, undefined, "Pack sans nom");
        const details = LabelEditor.getLabelValue(pack.details, undefined, "Pas de sous-titre");

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails du pack</Typography>
                        </div>
                        {
                            this.packConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingPack}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingPack}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    pack && languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                        <div style={{marginBottom: 24}}>
                                            <Typography variant="button">
                                                Nom du pack
                                            </Typography>
                                            <Typography variant="body1">
                                                <ButtonBase
                                                    focusRipple
                                                    onClick={(e) => this.setState({showNameLabelEditor: true})}
                                                    style={{fontSize:'1rem', padding: '4px 4px 4px 0', color: '#2196f3', marginTop: 12}}
                                                >
                                                    {name.length === 0 ? "<Pack sans nom>" : name} <EditIcon color="primary" style={{marginLeft: 4}}/>
                                                </ButtonBase>
                                            </Typography>
                                            <LabelEditor
                                                labelId={pack.name_label_id}
                                                label={pack.name}
                                                open={this.state.showNameLabelEditor}
                                                languages={this.state.languages.map(l => l.language_id)}
                                                onClose={this.onNameLabelEditingFinished}
                                                title="Nom du pack"
                                            />
                                        </div>


                                        <div style={{marginBottom: 24}}>
                                            <Typography variant="button">
                                                Sous-titre
                                            </Typography>
                                            <Typography variant="body1">
                                                <ButtonBase
                                                    focusRipple
                                                    onClick={(e) => this.setState({showDetailsLabelEditor: true})}
                                                    style={{fontSize:'1rem', padding: '4px 4px 4px 0', color: '#2196f3', marginTop: 12}}
                                                >
                                                    {details.length === 0 ? "<Pas de sous-titre>" : details} <EditIcon color="primary" style={{marginLeft: 4}}/>
                                                </ButtonBase>
                                            </Typography>
                                            <LabelEditor
                                                labelId={pack.details_label_id}
                                                label={pack.details}
                                                open={this.state.showDetailsLabelEditor}
                                                languages={this.state.languages.map(l => l.language_id)}
                                                onClose={this.onDetailsLabelEditingFinished}
                                                title="Sous-titre du pack"
                                            />
                                        </div>

                                        <div style={{marginBottom: 24}}>
                                            <ColorPicker
                                                label="Couleur"
                                                name='color'
                                                value={pack.color}
                                                onChange={color => this.handleConfigChange('color', color)}
                                            />
                                        </div>

                                        <div style={{marginBottom: 24}}>
                                            <div style={{width: 400, height: 200, padding: 12, backgroundColor: pack.color, textAlign: 'right'}}>
                                                <div style={{float: 'right', background: 'white', padding: 8}}>{pack.color.toUpperCase()}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Paper>

                            <Paper style={{padding: 24, marginTop: 24}}>
                                <Toolbar>
                                    <div style={{flex: 1}}>
                                        <Typography variant="h6">Mix catégories</Typography>
                                    </div>
                                    {
                                        this.state.editingMix ?
                                            <React.Fragment>
                                                <Button color="secondary" onClick={this.cancelEditingMix}>
                                                    Annuler
                                                </Button>
                                                <Button color="primary" onClick={this.commitEditingMix}>
                                                    Enregistrer
                                                </Button>
                                            </React.Fragment>
                                            :
                                            <Button color="primary" onClick={this.startEditingMix}>
                                                Modifier
                                            </Button>
                                    }

                                </Toolbar>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: 250}}/>
                                            <TableCell style={{width: 250}}>Liste 1</TableCell>
                                            <TableCell style={{width: 250}}>Liste 2</TableCell>
                                            <TableCell style={{width: 250}}>Liste 3</TableCell>
                                            <TableCell/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            categories.map((c, i) => (
                                                <TableRow key={c.category_id}>
                                                    <TableCell style={{width: 250}}>
                                                        <strong>{c.short}</strong> - {c.name}
                                                    </TableCell>
                                                    {
                                                        this.state.editingMix ? (
                                                            <React.Fragment>
                                                                <TableCell>
                                                                    {Math.round(pack.mix.list_1.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                    <Slider
                                                                        onChange={this.handleMixValueChange('list_1', c.category_id)}
                                                                        value={Math.round(pack.mix.list_1.find(m => m.category_id === c.category_id).mix_value * 100)}
                                                                        valueLabelDisplay="auto"
                                                                        marks={true}
                                                                        step={5}
                                                                        min={0}
                                                                        max={100}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.mix.list_2.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                    <Slider
                                                                        onChange={this.handleMixValueChange('list_2', c.category_id)}
                                                                        value={Math.round(pack.mix.list_2.find(m => m.category_id === c.category_id).mix_value * 100)}
                                                                        valueLabelDisplay="auto"
                                                                        marks={true}
                                                                        step={5}
                                                                        min={0}
                                                                        max={100}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.mix.list_3.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                    <Slider
                                                                        style={{width: 200}}
                                                                        onChange={this.handleMixValueChange('list_3', c.category_id)}
                                                                        value={Math.round(pack.mix.list_3.find(m => m.category_id === c.category_id).mix_value * 100)}
                                                                        valueLabelDisplay="auto"
                                                                        marks={true}
                                                                        step={5}
                                                                        min={0}
                                                                        max={100}
                                                                    />
                                                                </TableCell>
                                                                <TableCell/>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <TableCell>
                                                                    {Math.round(pack.mix.list_1.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.mix.list_2.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.mix.list_3.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                </TableCell>
                                                                <TableCell/>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>

                            <Paper style={{padding: 24, marginTop: 24}}>
                                <Toolbar>
                                    <div style={{flex: 1}}>
                                        <Typography variant="h6">Mix gages</Typography>
                                    </div>
                                    {
                                        this.state.editingPenaltyMix ?
                                            <React.Fragment>
                                                <Button color="secondary" onClick={this.cancelEditingPenaltyMix}>
                                                    Annuler
                                                </Button>
                                                <Button color="primary" onClick={this.commitEditingPenaltyMix}>
                                                    Enregistrer
                                                </Button>
                                            </React.Fragment>
                                            :
                                            <Button color="primary" onClick={this.startEditingPenaltyMix}>
                                                Modifier
                                            </Button>
                                    }

                                </Toolbar>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: 250}}/>
                                            <TableCell style={{width: 250}}>Gage 1</TableCell>
                                            <TableCell style={{width: 250}}>Gage 2</TableCell>
                                            <TableCell style={{width: 250}}>Gage 3</TableCell>
                                            <TableCell/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            penaltyCategories.map((c, i) => (
                                                <TableRow key={c.category_id}>
                                                    <TableCell style={{width: 250}}>
                                                        <strong>{c.code}</strong> - {c.name}
                                                    </TableCell>
                                                    {
                                                        this.state.editingPenaltyMix ? (
                                                            <React.Fragment>
                                                                <TableCell>
                                                                    {Math.round(pack.penalty_mix.penalty_1.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                    <Slider
                                                                        onChange={this.handlePenaltyMixValueChange('penalty_1', c.category_id)}
                                                                        value={Math.round(pack.penalty_mix.penalty_1.find(m => m.category_id === c.category_id).mix_value * 100)}
                                                                        valueLabelDisplay="auto"
                                                                        marks={true}
                                                                        step={5}
                                                                        min={0}
                                                                        max={100}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.penalty_mix.penalty_2.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                    <Slider
                                                                        onChange={this.handlePenaltyMixValueChange('penalty_2', c.category_id)}
                                                                        value={Math.round(pack.penalty_mix.penalty_2.find(m => m.category_id === c.category_id).mix_value * 100)}
                                                                        valueLabelDisplay="auto"
                                                                        marks={true}
                                                                        step={5}
                                                                        min={0}
                                                                        max={100}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.penalty_mix.penalty_3.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                    <Slider
                                                                        onChange={this.handlePenaltyMixValueChange('penalty_3', c.category_id)}
                                                                        value={Math.round(pack.penalty_mix.penalty_3.find(m => m.category_id === c.category_id).mix_value * 100)}
                                                                        valueLabelDisplay="auto"
                                                                        marks={true}
                                                                        step={5}
                                                                        min={0}
                                                                        max={100}
                                                                    />
                                                                </TableCell>
                                                                <TableCell/>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <TableCell>
                                                                    {Math.round(pack.penalty_mix.penalty_1.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.penalty_mix.penalty_2.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Math.round(pack.penalty_mix.penalty_3.find(m => m.category_id === c.category_id).mix_value * 100)}%
                                                                </TableCell>
                                                                <TableCell/>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>

                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

CrackListPartyPackDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListPartyPackDetails.defaultProps = {

};

export default withRouter(CrackListPartyPackDetails);