import React from 'react';
import PropTypes from 'prop-types';
import LinkIcon from '@material-ui/icons/Link'
import {CopyToClipboard} from "react-copy-to-clipboard";
import {IconButton, Tooltip} from "@material-ui/core";

class CopyToClipboardLink extends React.Component {
    render() {
        return (
            <CopyToClipboard text={this.props.value}>
                <div>{this.props.text}
                    <Tooltip
                        title="Copier le lien"
                        placement="right"
                    >
                        <IconButton style={{height: 'auto'}}>
                            <LinkIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            </CopyToClipboard>
        );
    }
}

CopyToClipboardLink.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};
CopyToClipboardLink.defaultProps = {};

export default CopyToClipboardLink;