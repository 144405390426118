

export default object => ({
    valueForKeyPath: function (keyPath) {
        if (typeof keyPath === 'string') {
            keyPath = keyPath.split('.')
        }
        return keyPath.reduce((prevValue, currentValue) => {
            return prevValue[currentValue];
        }, object);
    },
    setValueForKeyPath: function (keyPath, value) {
        if (typeof keyPath === 'string') {
            keyPath = keyPath.split('.')
        }
        const key = keyPath[keyPath.length - 1];
        const subObject = this.valueForKeyPath(keyPath.slice(0, keyPath.length - 1));
        subObject[key] = value;
    },
})
