import React from 'react';
import PropTypes from 'prop-types';
import api from "../../services/api";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import classNames from "classnames";
import "../GroupImage.css"

class WordsOutImagePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
            images: [],
            selectedImageId: props.selectedImageId || -1
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            open: props.open || false,
            selectedImageId: props.selectedImageId,
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.open || prevState.open === this.state.open) {
            return;
        }
        this.updateImages();
    }

    updateImages() {
        Promise.all([api.getWordsOutImages()]).then(res => {
            let images = res[0];

            this.setState({
                images: images,
            });
        });
    }

    pickImage = (image) => (e) => {
        this.props.onPick(image);
    };

    cancelEditing = () => {
        this.props.onClose();
    };

    render() {

        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="form-dialog-title"
                style={{minWidth: 800}}
                maxWidth="xl"
                onClose={ (e, reason) => this.cancelEditing() }
            >
                {
                    !this.state.images ? null :

                        <form noValidate autoComplete="off" onSubmit={this.commitEditing}>
                            <DialogTitle id="form-dialog-title">Choisir une image</DialogTitle>
                            <DialogContent>
                                <br/>

                                <div style={{marginBottom: 24, display: 'flex', flexWrap: 'wrap'}}>
                                    {
                                        this.state.images.map(img => {
                                            return <div onClick={this.pickImage(img)} key={img.image_id} className={classNames({
                                                'group-image': true,
                                                'group-image-selected': img.image_id === this.state.selectedImageId
                                            })}>
                                                <img height={200} src={`/words-out/v3/thumb_${img.name}`}/>
                                                <div className={"group-image-overlay"}></div>
                                            </div>
                                        })
                                    }
                                </div>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.cancelEditing} color="default">
                                    Annuler
                                </Button>
                            </DialogActions>
                        </form>
                }
            </Dialog>
        )
    }
}

WordsOutImagePicker.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onPick: PropTypes.func.isRequired,
    selectedImageId: PropTypes.number
};

export default WordsOutImagePicker;