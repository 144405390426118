import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Fab,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import LabelEditor from "../LabelEditor";
import {withRouter} from "react-router-dom";
import PackCard from "../PackCard";
import CrackListPartyOrderedPack from "./CrackListPartyOrderedPack";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

class CrackListPartyPacks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            packs: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
            deviceOrderedPacks: [],
            gameOrderedPacks: [],
            isDraggingDevicePack: false,
            isDraggingGamePack: false,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getCrackListPartyPacks(),
        ]).then(res => {
            const packs = res[0];
            this.setState({
                packs: packs,
                deviceOrderedPacks: packs.slice().sort((p1, p2) => { return (p1.order_index_device > p2.order_index_device) ? 1 : -1 }),
                gameOrderedPacks: packs.slice().sort((p1, p2) => { return (p1.order_index_game > p2.order_index_game) ? 1 : -1 })
            });
        });
    }

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    goToPack = (pack) => () => {
        this.props.history.push(`/${this.props.app.url_slug}/pack/${pack.pack_id}`);
    };

    isDevicePackOrderPristine() {
        return this.state.deviceOrderedPacks.every((p, i) => p.order_index_device === i);
    }

    onDevicePackMove = (sourceIndex, targetIndex) => {
        let packs = Array.from(this.state.deviceOrderedPacks);
        let pack = packs[sourceIndex];
        packs.splice(sourceIndex, 1);
        packs.splice(targetIndex, 0, pack);
        this.setState({
            deviceOrderedPacks: packs
        });
    };

    onDevicePackDragBegin = () => {
        this.setState({
            isDraggingDevicePack: true,
        });
    };

    onDevicePackDragEnd = () => {
        this.setState({
            isDraggingDevicePack: false,
        });
    };

    cancelDevicePackOrder = () => {
        this.setState({
            deviceOrderedPacks: this.state.packs.slice().sort((p1, p2) => { return (p1.order_index_device > p2.order_index_device) ? 1 : -1 }),
        });
    };

    commitDevicePackOrder = () => {
        api.patchCrackListPartyPacks(this.state.deviceOrderedPacks.map((pack, i) => ({
            ...pack,
            order_index_device: i,
        }))).then(packs => {
            if (packs === undefined) {
                this.displaySnackbar('Erreur lors de l\'enregistrement de l\'ordre des packs.');
                return;
            }
            this.displaySnackbar('Ordre des packs enregistré avec succès.');
            this.updateData();
        });
    };

    isGamePackOrderPristine() {
        return this.state.gameOrderedPacks.every((p, i) => p.order_index_game === i);
    }

    onGamePackMove = (sourceIndex, targetIndex) => {
        let packs = Array.from(this.state.gameOrderedPacks);
        let pack = packs[sourceIndex];
        packs.splice(sourceIndex, 1);
        packs.splice(targetIndex, 0, pack);
        this.setState({
            gameOrderedPacks: packs
        });
    };

    onGamePackDragBegin = () => {
        this.setState({
            isDraggingGamePack: true,
        });
    };

    onGamePackDragEnd = () => {
        this.setState({
            isDraggingGamePack: false,
        });
    };

    cancelGamePackOrder = () => {
        this.setState({
            gameOrderedPacks: this.state.packs.slice().sort((p1, p2) => { return (p1.order_index_game > p2.order_index_game) ? 1 : -1 }),
        });
    };

    commitGamePackOrder = () => {
        api.patchCrackListPartyPacks(this.state.gameOrderedPacks.map((pack, i) => ({
            ...pack,
            order_index_game: i,
        }))).then(packs => {
            if (packs === undefined) {
                this.displaySnackbar('Erreur lors de l\'enregistrement de l\'ordre des packs.');
                return;
            }
            this.displaySnackbar('Ordre des packs enregistré avec succès.');
            this.updateData();
        });
    };

    createPack = (e) => {
        api.postCrackListPartyPack().then(res => {
            const packId = res.pack_id;
            this.props.history.push(`/${this.props.app.url_slug}/pack/${packId}`);
        });
    };

    deletePack = (pack) => (e) => {
        if (!window.confirm('Supprimer le pack ?')) {
            return;
        }
        api.deleteCrackListPartyPack(pack.pack_id).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la suppression du pack");
                return;
            }
            this.updateData(this.state.languageId);
        });
    }


    render() {

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Packs de listes</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: 200}}>Nom</TableCell>
                                    <TableCell style={{width: 200}}>Couleur</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.packs.map((pack, i) => (
                                    <TableRow style={{cursor: 'pointer'}} key={pack.pack_id}>
                                        <TableCell style={{width: 200}}>{LabelEditor.getLabelValue(pack.name, undefined, "Pack sans nom")}</TableCell>
                                        <TableCell style={{width: 200, height: 40}}>
                                            <div style={{padding: 0, margin: -8, backgroundColor: pack.color, width: '100%', position: 'relative', height: 32, borderRadius: 4}}></div>
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={this.goToPack(pack)}><EditIcon/></Button>
                                            <Button color="secondary" onClick={this.deletePack(pack)}><DeleteIcon/></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <div style={{display: 'flex', gap: 24, marginTop: 24}}>

                <Paper>
                    <Toolbar>
                        <div>
                            <div style={{flex: 1}}>
                                <Typography variant="h6">Joue avec ton téléphone</Typography>
                                Glisser les packs dans l'ordre désiré
                            </div>
                        </div>

                    </Toolbar>

                    <div style={{padding: '0 24px'}}>
                        {this.state.deviceOrderedPacks.map((pack, i) => (
                            <CrackListPartyOrderedPack
                                pack={pack}
                                index={i}
                                draggable={true}
                                onMove={this.onDevicePackMove}
                                onDragBegin={this.onDevicePackDragBegin}
                                onDragEnd={this.onDevicePackDragEnd}
                            />
                        ))}
                    </div>

                    <div style={{padding: '24px'}}>
                        {
                            (this.state.isDraggingDevicePack || this.isDevicePackOrderPristine()) ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelDevicePackOrder}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitDevicePackOrder}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </div>
                </Paper>

                <Paper>
                    <Toolbar>
                        <div>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Joue avec ta boîte de jeu</Typography>
                            Glisser les packs dans l'ordre désiré
                        </div>
                        </div>

                    </Toolbar>

                    <div style={{padding: '0 24px'}}>
                        {this.state.gameOrderedPacks.map((pack, i) => (
                            <CrackListPartyOrderedPack
                                pack={pack}
                                index={i}
                                draggable={true}
                                onMove={this.onGamePackMove}
                                onDragBegin={this.onGamePackDragBegin}
                                onDragEnd={this.onGamePackDragEnd}
                            />
                        ))}
                    </div>

                    <div style={{padding: '24px'}}>
                        {
                        (this.state.isDraggingGamePack || this.isGamePackOrderPristine()) ? null : (
                            <React.Fragment>
                                <Button color="secondary" onClick={this.cancelGamePackOrder}>
                                    Annuler
                                </Button>
                                <Button color="primary" onClick={this.commitGamePackOrder}>
                                    Enregistrer
                                </Button>
                            </React.Fragment>
                        )
                    }
                    </div>
                </Paper>

                </div>


                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.createPack}
                >
                    <AddIcon />
                </Fab>


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

CrackListPartyPacks.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListPartyPacks.defaultProps = {};

export default withRouter(CrackListPartyPacks);