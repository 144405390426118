import React from 'react';
import DefaultApp from "./DefaultApp";
import {Tab, Tabs} from '@material-ui/core';
import {Redirect, Switch, withRouter} from "react-router-dom";
import Route from "react-router-dom/Route";
import AppLanguages from "./AppLanguages";
import AppImages from "./AppImages";
import PicsAndWordsImageDetails from "./1pic8words/PicsAndWordsImageDetails";
import AppPacks from "./AppPacks";
import PackDetails from "./PackDetails";
import AppConfig from "./AppConfig";
import AppDataVersions from "./AppDataVersions";
import AppPreview from "./AppPreview";
import CouponCodes from "./CouponCodes";
import UnlockCodes from "./UnlockCodes";
import InAppStoreProducts from "./InAppStoreProducts";
import StoreProduct from "./StoreProduct";

class PicsAndWords extends DefaultApp {

    render () {
        const { app, match } = this.props;
        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/image/:imageId`} render={(props) => (
                        <PicsAndWordsImageDetails app={app}/>
                    )} />
                    <Route path={`${match.url}/pack/:packId`} render={(props) => (
                        <PackDetails app={app}/>
                    )} />
                    <Route exact path={`${match.url}/preview/`} render={(props) => (
                        <AppPreview app={app}/>
                    )} />
                    <Route path={`${match.url}/product/:productId/`} render={(props) => (
                        <StoreProduct app={app}/>
                    )} />
                    <Route path={`${match.path}/:tab`}>
                        <PicsAndWordsTabs app={app}/>
                    </Route>
                    <Route render={() => <Redirect to={`/${app.url_slug}/${PicsAndWordsTabs.defaultTab}/`}/>} />
                </Switch>


            </div>
        );
    }
}

class PicsAndWordsTabs extends React.Component {

    static defaultTab = "images";

    constructor(props) {
        super(props);
        this.state = {
            currentTab: PicsAndWordsTabs.getCurrentTab(props),
        };
    }

    static getCurrentTab(props) {
        const tabName = props.match.params.tab;
        return tabName === undefined ? PicsAndWordsTabs.defaultTab : tabName;
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            currentTab: PicsAndWordsTabs.getCurrentTab(props),
        };
    }

    onCurrentTabChange = (e, value) => {
        this.props.history.push('/'+this.props.app.url_slug + '/' + value);
    };

    render() {
        const { app } = this.props;
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.currentTab}
                    onChange={this.onCurrentTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        margin: '-20px -22px 22px -22px',
                        borderBottom: '1px #ddd solid',
                        background: 'white',
                    }}
                    scrollable="true"
                    scrollButtons="auto"
                >
                    <Tab
                        value="images"
                        label="Images"
                    />
                    <Tab
                        value="packs"
                        label="Packs d'images"
                    />
                    <Tab
                        value="config"
                        label="Configuration"
                    />
                    <Tab
                        value="store"
                        label="Boutique"
                    />
                    <Tab
                        value="publish"
                        label="Publication"
                    />
                    <Tab
                        value="coupon-codes"
                        label="Codes promo"
                    />
                    <Tab
                        value="unlock-codes"
                        label="Codes de déblocage"
                    />
                    <Tab
                        value="languages"
                        label="Langues"
                    />
                </Tabs>

                <Switch>
                    <Route exact path={`/${app.url_slug}/languages/`} render={(props) => (
                        <AppLanguages app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/packs/`} render={(props) => (
                        <AppPacks app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/config/`} render={(props) => (
                        <AppConfig app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/store/`} render={(props) => (
                        <InAppStoreProducts app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/publish/`} render={(props) => (
                        <AppDataVersions app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/coupon-codes/`} render={(props) => (
                        <CouponCodes app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/unlock-codes/`} render={(props) => (
                        <UnlockCodes app={app}/>
                    )} />
                    <Route path={`/${app.url_slug}/images`} render={(props) => (
                        <AppImages app={this.props.app}/>
                    )} />
                    <Route/>
                </Switch>
            </React.Fragment>
        );
    }
}

PicsAndWordsTabs = withRouter(PicsAndWordsTabs);

PicsAndWords.propTypes = {};
PicsAndWords.defaultProps = {};

export default withRouter(PicsAndWords);