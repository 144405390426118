import React from 'react';
import PropTypes from 'prop-types';
import api from '../../services/api';
import {
    Button,
    IconButton,
    Paper,
    Snackbar,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import KVC from "../../services/KVC";
import {withRouter} from "react-router-dom";

class WoodysWordsChallengeDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    getDefaultCustomConfig() {
        return {
            def_1: '',
            def_2: '',
            def_3: '',
            def_4: '',
            def_5: '',
            word_1: '',
            word_2: '',
            word_3: '',
            word_4: '',
            word_5: '',
        }
    }

    updateData() {
        Promise.all([
            api.getAppLanguages(11),
            api.getWoodysWordsChallenge(this.getChallengeId())
        ]).then(results => {
            const languages = results[0];
            const challenge = results[1];

            let otherConfig = []
            let config = challenge.config || []
            languages.forEach(l => {
                if (!config.find(c => c.language_id === l.language_id)) {
                    let c = this.getDefaultCustomConfig()
                    c.language_id = l.language_id
                    c.challenge_id = challenge.challenge_id
                    otherConfig.push(c)
                }
            });
            config = config.concat(otherConfig)

            challenge.config = config

            this.setState({
                languages: languages,
                challenge: challenge,
                $challenge: JSON.parse(JSON.stringify(challenge)),
            });
        });
    }

    getChallengeId() {
        return parseInt(this.props.match.params.challengeId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/challenges/`);
            return;
        }
        this.props.history.goBack();
    };

    challengeConfigIsPristine = () => {
        return JSON.stringify(this.state.challenge) === JSON.stringify(this.state.$challenge);
    };

    handleConfigValueChange = (propNamePath) => (e) => {
        this.handleConfigChange(propNamePath, e.target.value);
    };

    handleCustomConfigValueChange = (languageId, propNamePath) => (e) => {
        this.handleCustomConfigChange(languageId, propNamePath, e.target.value);
    };

    handleConfigChange = (propNamePath, value) => {
        let challenge = Object.assign({}, this.state.challenge);
        KVC(challenge).setValueForKeyPath(propNamePath, value);
        this.setState({
            challenge: challenge,
        });
    };

    handleCustomConfigChange = (languageId, propNamePath, value) => {
        let challenge = JSON.parse(JSON.stringify(this.state.challenge));
        challenge.config.forEach(c => {
            if (c.language_id !== languageId) {
                return;
            }
            KVC(c).setValueForKeyPath(propNamePath, value);
        })
        this.setState({
            challenge: challenge,
        });
    };

    cancelEditingChallenge = () => {
        this.setState({
            challenge: JSON.parse(JSON.stringify(this.state.$challenge)),
        });
    };

    commitEditingChallenge = () => {
        const challengeId = this.getChallengeId();
        api.patchWoodysWordsChallenge(challengeId, this.state.challenge).then(res => {
            api.patchWoodysWordsChallengeConfig(this.state.challenge.config).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement du défi du jour.");
                    return;
                }
                this.displaySnackbar("Défi du jour sauvegardé.");
            });
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (this.state.challenge === undefined) {
            return <div/>;
        }

        const { challenge, languages } = this.state;
        const yearIndex = parseInt(challenge.uuid.substring(0, 4), 10);
        const monthIndex = parseInt(challenge.uuid.substring(4, 6), 10) - 1;
        const dayIndex = parseInt(challenge.uuid.substring(6));
        const challengeDate = new Date(yearIndex, monthIndex, dayIndex);

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Défi du {challengeDate.toLocaleDateString()}</Typography>
                        </div>
                        {
                            this.challengeConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingChallenge}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingChallenge}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>



                {
                    challenge && languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>

                                <div style={{marginBottom: 50}}>
                                    <div style={{marginBottom: 24, color: 'gray'}}>
                                        Vies : -1 = Illimitées / Jokers : -1 = Désactivé
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('life_count', 'number')}
                                            label={"Vies"}
                                            value={challenge.life_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('abc_joker_count')}
                                            label={"Jokers ABC"}
                                            value={challenge.abc_joker_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('later_joker_count')}
                                            label={"Jokers Plus tard"}
                                            value={challenge.later_joker_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('red_letter_count')}
                                            label={"Lettres magiques"}
                                            value={challenge.red_letter_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                </div>

                                {

                                    (
                                        languages.map(lang => {
                                            let config = challenge.config.find(c => c.language_id === lang.language_id)
                                            return (
                                                <div key={lang.language_id} style={{borderRadius: 16, backgroundColor: '#f8f8f8', padding: '12px 24px 12px 24px', marginBottom: 24}}>
                                                    <h3>{lang.name}</h3>
                                                    <div style={{marginBottom: 24}}>
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_1')}
                                                            value={config.def_1}
                                                            color="primary"
                                                            label="Définition 1"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                            style={{
                                                                marginRight: 24,
                                                            }}
                                                        />
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_1')}
                                                            value={config.word_1}
                                                            color="primary"
                                                            label="Mot 1"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{marginBottom: 24}} hidden={!config.def_1 || config.def_1.length === 0}>
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_2')}
                                                            value={config.def_2}
                                                            color="primary"
                                                            label="Définition 2"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                            style={{
                                                                marginRight: 24,
                                                            }}
                                                        />
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_2')}
                                                            value={config.word_2}
                                                            color="primary"
                                                            label="Mot 2"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{marginBottom: 24}} hidden={!config.def_2 || config.def_2.length === 0}>
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_3')}
                                                            value={config.def_3}
                                                            color="primary"
                                                            label="Définition 3"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                            style={{
                                                                marginRight: 24,
                                                            }}
                                                        />
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_3')}
                                                            value={config.word_3}
                                                            color="primary"
                                                            label="Mot 3"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{marginBottom: 24}} hidden={!config.def_3 || config.def_3.length === 0}>
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_4')}
                                                            value={config.def_4}
                                                            color="primary"
                                                            label="Définition 4"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                            style={{
                                                                marginRight: 24,
                                                            }}
                                                        />
                                                        <TextField
                                                            onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_4')}
                                                            value={config.word_4}
                                                            color="primary"
                                                            label="Mot 4"
                                                            inputProps={{
                                                                form: `challenge-custom-${lang.language_id}`,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })

                                    )
                                }

                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                    </div>
                                </div>

                            </Paper>

                        </React.Fragment>

                    ) : null
                }

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

WoodysWordsChallengeDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

WoodysWordsChallengeDetails.defaultProps = {

};

export default withRouter(WoodysWordsChallengeDetails);