import React from 'react';
import DefaultApp from "./DefaultApp";
import {Tab, Tabs} from '@material-ui/core';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import AppLanguages from "./AppLanguages";
import WoodysWordsAppDataVersions from "./woodys-words/WoodysWordsAppDataVersions";
import CouponCodes from "./CouponCodes";
import StoreProduct from "./StoreProduct";
import WoodysWordsLevels from "./woodys-words/WoodysWordsLevels";
import WoodysWordsLevelGroups from "./woodys-words/WoodysWordsLevelGroups";
import WoodysWordsGroupLevelDetails from "./woodys-words/WoodysWordsGroupLevelDetails";
import WoodysWordsTournaments from "./woodys-words/WoodysWordsTournaments";
import WoodysWordsLevelDetails from "./woodys-words/WoodysWordsLevelDetails";
import WoodysWordsDefinitions from "./woodys-words/WoodysWordsDefinitions";
import WoodysWordsDefinitionDetails from "./woodys-words/WoodysWordsDefinitionDetails";
import WoodysWordsChallenges from "./woodys-words/WoodysWordsChallenges";
import WoodysWordsChallengeDetails from "./woodys-words/WoodysWordsChallengeDetails";

class WoodysWords extends DefaultApp {

    render () {
        const { app, match } = this.props;
        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/create-definition/:languageId/`} render={(props) => (
                        <WoodysWordsDefinitionDetails app={app} />
                    )} />
                    <Route path={`${match.url}/product/:productId/`} render={(props) => (
                        <StoreProduct app={app}/>
                    )} />
                    <Route path={`${match.url}/group/:groupId/`} render={(props) => (
                        <WoodysWordsGroupLevelDetails app={app} />
                    )} />
                    <Route path={`${match.url}/definition/:definitionId/`} render={(props) => (
                        <WoodysWordsDefinitionDetails app={app} />
                    )} />
                    <Route path={`${match.url}/level/:levelId/`} render={(props) => (
                        <WoodysWordsLevelDetails app={app} />
                    )} />
                    <Route path={`${match.url}/challenge/:challengeId/`} render={(props) => (
                        <WoodysWordsChallengeDetails app={app} />
                    )} />
                    <Route exact path={`${match.url}/definitions/`} render={() => <Redirect to={`/${app.url_slug}/definitions/lang--1/100-1`} />} />
                    <Route exact path={`${match.url}/challenges/`} render={() => <Redirect to={`/${app.url_slug}/challenges/lang-1`} />} />
                    <Route path={`${match.path}/:tab`}>
                        <WoodysWordsTabs app={app}/>
                    </Route>
                    <Route render={() => <Redirect to={`/${app.url_slug}/${WoodysWordsTabs.defaultTab}/`}/>} />
                </Switch>


            </div>
        );
    }
}

class WoodysWordsTabs extends React.Component {

    static defaultTab = "levels";

    constructor(props) {
        super(props);
        this.state = {
            currentTab: WoodysWordsTabs.getCurrentTab(props),
        };
    }

    static getCurrentTab(props) {
        const tabName = props.match.params.tab;
        return tabName === undefined ? WoodysWordsTabs.defaultTab : tabName;
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            currentTab: WoodysWordsTabs.getCurrentTab(props),
        };
    }

    onCurrentTabChange = (e, value) => {
        this.props.history.push('/'+this.props.app.url_slug + '/' + value);
    };

    render() {
        const { app } = this.props;
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.currentTab}
                    onChange={this.onCurrentTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        margin: '-20px -22px 22px -22px',
                        borderBottom: '1px #ddd solid',
                        background: 'white',
                    }}
                    scrollable="true"
                    scrollButtons="auto"
                >
                    <Tab
                        value="levels"
                        label="Niveaux"
                    />
                    <Tab
                        value="groups"
                        label="Groupes"
                    />
                    <Tab
                        value="definitions"
                        label="Définitions"
                    />
                    <Tab
                        value="challenges"
                        label="Défis du jour"
                    />
                    <Tab
                        value="publish"
                        label="Publication"
                    />
                    <Tab
                        value="coupon-codes"
                        label="Codes promo"
                    />

                </Tabs>

                <Switch>
                    <Route path={`/${app.url_slug}/definitions/lang-:languageId/:pageSize-:page`} render={(props) => (
                        <WoodysWordsDefinitions app={app}/>
                    )} />
                    <Route path={`/${app.url_slug}/challenges/lang-:languageId`} render={(props) => (
                        <WoodysWordsChallenges app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/levels/`} render={(props) => (
                        <WoodysWordsLevels app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/groups/`} render={(props) => (
                        <WoodysWordsLevelGroups app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/languages/`} render={(props) => (
                        <AppLanguages app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/tournaments/`} render={(props) => (
                        <WoodysWordsTournaments app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/publish/`} render={(props) => (
                        <WoodysWordsAppDataVersions app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/coupon-codes/`} render={(props) => (
                        <CouponCodes app={app}/>
                    )} />
                    <Route/>
                </Switch>
            </React.Fragment>
        );
    }
}

WoodysWordsTabs = withRouter(WoodysWordsTabs);

export default withRouter(WoodysWords);
