import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {
    Button, Checkbox, FormControlLabel,
    IconButton, ListItemText,
    MenuItem, OutlinedInput,
    Paper, Select,
    Snackbar, Switch,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import KVC from "../../services/KVC";

class CrackListPartyPenaltyDetails extends React.Component {

    constructor(props) {
        super(props);
        let state = {
            languages: [],
            categories: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
        };
        if (props.match.url.includes("/create-penalty")) {
            state.penalty = {
                name: '',
                language_id: props.match.params.languageId,
                category_id: null,
                discovery: 0
            }
            state.$penalty = Object.assign({}, state.penalty);
        }
        this.state = state;
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let requests = [
            api.getAppLanguages(10),
        ];
        const penaltyId = this.getPenaltyId();
        if (!!penaltyId) {
            requests.push(api.getCrackListPartyPenalty(penaltyId));
        }
        Promise.all(requests).then(results => {
            const languages = results[0];
            let state = {
                languages: languages,
                penalty: this.state.penalty,
            };
            if (results.length > 1) {
                const penalty = results[1];
                state = {
                    ...state,
                    penalty: penalty,
                    $penalty: Object.assign({}, penalty),
                };
            }

            api.getCrackListPartyPenaltyCategories(state.penalty.language_id).then(res => {
                state.categories = res;
                this.setState(state);
            });
        });
    }

    getPenaltyId() {
        return parseInt(this.props.match.params.penaltyId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/penalties/`);
            return;
        }
        this.props.history.goBack();
    };

    penaltyConfigIsPristine = () => {
        return JSON.stringify(this.state.penalty) === JSON.stringify(this.state.$penalty);
    };

    handleConfigValueChange = (propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleConfigChange(propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleConfigChange(propNamePath, e.target.value);
        }
    };

    handleConfigSwitchChange = (propNamePath) => (e, checked) => {
        this.handleConfigChange(propNamePath, checked ? 1 : 0);
    };

    handleConfigChange = (propNamePath, value) => {
        let penalty = Object.assign({}, this.state.penalty);
        KVC(penalty).setValueForKeyPath(propNamePath, value);
        this.setState({
            penalty: penalty,
        });
    };

    handleCategoryChange = (e) => {
        this.handleConfigChange('category_id', e.target.value);
    }

    cancelEditingPenalty = () => {
        this.setState({
            penalty: Object.assign(this.state.$penalty),
        });
    };

    commitEditingPenalty = () => {
        const penaltyId = this.getPenaltyId();
        if (!!penaltyId) {
            api.patchCrackListPartyPenalty(penaltyId, this.state.penalty).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement du gage.");
                    return;
                }
                this.displaySnackbar("Gage sauvegardé.");
                this.updateData();
            });
        } else {
            api.postCrackListPartyPenalty(this.state.penalty).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement du gage.");
                    return;
                }
                this.setState({
                    penalty: res,
                    $penalty: JSON.parse(JSON.stringify(res))
                });
                this.props.history.replace(`/${this.props.app.url_slug}/penalty/${res.penalty_id}`);
            });
        }
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (this.state.penalty === undefined) {
            return <div/>;
        }

        const { languages, categories, penalty } = this.state;

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">{!this.getPenaltyId() ? "Nouveau gage" : `Détails du gage (ID : ${this.getPenaltyId()})`}</Typography>
                        </div>
                        {
                            this.penaltyConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingPenalty}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingPenalty}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    penalty && languages && categories ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={penalty.language_id || -1}
                                                onChange={this.handleConfigValueChange('language_id')}
                                                label="Langue"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !penalty.language_id ? <MenuItem value={-1} disabled>Sélectionner une langue</MenuItem> : null
                                                }
                                                {
                                                    languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                                }
                                            </TextField>

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={penalty.category_id || -1}
                                                onChange={this.handleCategoryChange}
                                                label="Catégorie"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !penalty.category_id ? <MenuItem value={-1} disabled>Sélectionner une catégorie</MenuItem> : null
                                                }
                                                {
                                                    categories.map((c, i) => <MenuItem key={i} value={c.category_id}>{c.name} ({c.penalty_count})</MenuItem>)
                                                }
                                            </TextField>
                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={penalty.name}
                                                onChange={this.handleConfigValueChange('name')}
                                                label="Intitulé"
                                            />

                                        </div>


                                        <div style={{marginBottom: 24}}>

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={penalty.discovery === 1}
                                                        onChange={this.handleConfigSwitchChange('discovery')}
                                                        value="1"
                                                    />}
                                                label="Gage Découverte"
                                            />

                                        </div>


                                    </div>
                                </div>

                            </Paper>

                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

CrackListPartyPenaltyDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListPartyPenaltyDetails.defaultProps = {

};

export default withRouter(CrackListPartyPenaltyDetails);