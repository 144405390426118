import React from 'react';
import PropTypes from 'prop-types';
import api from "../../services/api";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField
} from '@material-ui/core';
import {DataGrid} from "@material-ui/data-grid";
import Slider from "@material-ui/core/Slider";

class CrackListListPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            lists: [],
            filteredLists: [],
            nameFilter: '',
            tileRange: [0, 30],
            answerRange: [0, 15],
        };

        this.columns = [
            {
                field: 'min_tile_count',
                headerName: 'Tuiles',
                renderCell: (params) => {
                    if (params.data.min_fixed_tile_count === 0 && params.data.max_fixed_tile_count === 0) {
                        return <span style={{color: '#007AFF', fontWeight: 'bold'}}>{params.data.fixed_tile_count}</span>
                    } else {
                        return <span style={{color: this.listHasEnoughTiles(params.data) ? "#0C0" : "#C00"}}>{params.data.min_tile_count}-{params.data.max_tile_count}</span>
                    }
                },
                sortComparator: (v1, v2, params1, params2) => {
                    let tileCount1 = v1;
                    let secondaryValue1 = params1.data.max_tile_count;
                    if (params1.data.min_fixed_tile_count === 0 && params1.data.max_fixed_tile_count === 0) {
                        tileCount1 = params1.data.fixed_tile_count;
                        secondaryValue1 = params1.data.fixed_tile_count;
                    }
                    let tileCount2 = v2;
                    let secondaryValue2 = params2.data.max_tile_count;
                    if (params2.data.min_fixed_tile_count === 0 && params2.data.max_fixed_tile_count === 0) {
                        tileCount2 = params2.data.fixed_tile_count;
                        secondaryValue2 = params2.data.fixed_tile_count;
                    }
                    if (tileCount1 < tileCount2) {
                        return -1;
                    } else if (tileCount2 < tileCount1) {
                        return 1;
                    } else {
                        return secondaryValue1 - secondaryValue2;
                    }
                },
                width: 70,
            },
            {
                field: 'name',
                headerName: 'Intitulé',
                width: 400,
            },
            {
                field: 'items',
                headerName: 'Réponses',
                valueFormatter: (params) => {
                    return `${params.data.items.length} - ${params.data.items.map(i => i.name).join(" - ")}`;
                },
                sortComparator: (v1, v2, params1, params2) => {
                    return params1.data.items.length - params2.data.items.length;
                },
                width: 400
            },
            {
                field: 'target_time',
                headerName: 'Temps',
                type: 'number',
                width: 75,
                renderCell: (params) => {
                    if (params.data.auto_target_time) {
                        return <span style={{fontStyle: 'italic', color: '#999'}}>{params.data.auto_target_time}</span>
                    } else {
                        return <span>{params.value}</span>
                    }
                },
            },
            {
                field: 'list_id',
                headerName: ' ',
                renderCell: (params) => {
                    return <Button color="primary"
                                   onClick={this.pickList(params.data)}>Choisir</Button>
                },
                sortable: false
            }
        ]

    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            open: props.open,
        };
    }

    listHasEnoughTiles(list) {
        if (!this.props.level) {
            return true;
        }
        return this.props.level.tile_count >= list.min_tile_count && this.props.level.tile_count <= list.max_tile_count
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.open || prevState.open === this.state.open) {
            return;
        }
        this.updateLists();
    }

    updateLists() {
        Promise.all([api.getCrackListLists(this.props.languageId)]).then(res => {
            let lists = res[0].map(l => {
                return {
                    ...l,
                    id: l.list_id,
                }
            });

            if (!!this.props.level) {
                lists = lists.filter(l => !l.level_id);
            }

            const maxTileCount = lists.map(l => l.max_tile_count).reduce((val, cur) => Math.max(val, cur), 0);
            const minTileCount = lists.map(l => l.min_tile_count).reduce((val, cur) => Math.min(val, cur), 1000);
            const maxTileRange = Math.ceil(maxTileCount / 5) * 5;
            const minTileRange = minTileCount - (minTileCount % 5);
            const minAnswerCount = lists.map(l => l.items.length).reduce((val, cur) => Math.min(val, cur), 1000);
            const maxAnswerCount = Math.max(minAnswerCount, lists.map(l => l.items.length).reduce((val, cur) => Math.max(val, cur), 0));

            this.setState({
                lists: lists,
                filteredLists: Array.from(lists),
                nameFilter: '',
                tileRange: [minTileRange, maxTileRange],
                minTileRange: minTileRange,
                maxTileRange: maxTileRange,
                answerRange: [minAnswerCount, maxAnswerCount],
                minAnswerRange: minAnswerCount,
                maxAnswerRange: maxAnswerCount,
            });
        });
    }

    pickList = (list) => (e) => {
        if (!!this.props.level && !this.listHasEnoughTiles(list) && !window.confirm(`Le nombre de tuiles de cette liste (${list.min_tile_count}-${list.max_tile_count}) n'est pas compatible avec le nombre de tuiles du niveau (${this.props.level.tile_count}). Continuer ?`)) {
            return;
        }
        this.props.onPick(list);
    };

    cancelEditing = () => {
        this.props.onClose();
    };

    handleNameFilterChange = (e) => {
        this.setState({
            nameFilter: e.target.value,
            filteredLists: this.state.lists.filter(l => this.listMatchesFilters(l, e.target.value, this.state.tileRange, this.state.answerRange)),
        })
    }

    handleTileRangeFilterChange = (e, range) => {
        this.setState({
            tileRange: range,
            filteredLists: this.state.lists.filter(l => this.listMatchesFilters(l, this.state.nameFilter, range, this.state.answerRange)),
        });
    }

    handleAnswerRangeFilterChange = (e, range) => {
        this.setState({
            answerRange: range,
            filteredLists: this.state.lists.filter(l => this.listMatchesFilters(l, this.state.nameFilter, this.state.tileRange, range)),
        });
    }

    listMatchesFilters(list, nameFilter, tileRange, answerRange) {
        return (!nameFilter || list.name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1)
            && (tileRange[0] <= list.max_tile_count && tileRange[1] >= list.min_tile_count)
            && (answerRange[0] <= list.items.length && answerRange[1] >= list.items.length);
    }

    render() {

        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="form-dialog-title"
                style={{minWidth: 800}}
                maxWidth="xl"
                onClose={ (e, reason) => this.cancelEditing() }
            >
                {
                    !this.state.lists ? null :

                        <form noValidate autoComplete="off" onSubmit={this.commitEditing}>
                            <DialogTitle id="form-dialog-title">Choisir une liste</DialogTitle>
                            <DialogContent>
                                <br/>

                                {this.state.lists.length === 0 ? null :


                                    <div style={{marginBottom: 24, marginLeft: 10}}>

                                        <FormControlLabel
                                            control={
                                                <Slider
                                                    style={{width: 200}}
                                                    value={this.state.tileRange}
                                                    onChange={this.handleTileRangeFilterChange}
                                                    valueLabelDisplay="auto"
                                                    marks={this.state.maxTileRange >= this.state.minTileRange + 5}
                                                    step={5}
                                                    max={this.state.maxTileRange}
                                                    min={this.state.minTileRange}
                                                />
                                            }
                                            label={<div
                                                style={{marginLeft: 24}}>{this.state.tileRange[0]}-{this.state.tileRange[1]} tuiles</div>}
                                            style={{
                                                background: '#f8f8f8',
                                                padding: 12,
                                                borderRadius: 8,
                                            }}
                                        />

                                        {
                                            this.state.maxAnswerRange <= this.state.minAnswerRange ? null :
                                                <FormControlLabel
                                                    control={
                                                        <Slider
                                                            style={{width: 100}}
                                                            value={this.state.answerRange}
                                                            onChange={this.handleAnswerRangeFilterChange}
                                                            valueLabelDisplay="auto"
                                                            marks={this.state.maxAnswerRange > this.state.minAnswerRange}
                                                            step={1}
                                                            min={this.state.minAnswerRange}
                                                            max={this.state.maxAnswerRange}
                                                        />
                                                    }
                                                    label={<div
                                                        style={{marginLeft: 24}}>{this.state.answerRange[0]}-{this.state.answerRange[1]} réponses</div>}
                                                    style={{
                                                        background: '#f8f8f8',
                                                        padding: 12,
                                                        borderRadius: 8,
                                                    }}
                                                />
                                        }

                                        <TextField
                                            style={{marginRight: 24, width: 300}}
                                            value={this.state.nameFilter}
                                            label="Intitulé"
                                            placeholder="Filtrer sur l'intitulé"
                                            onChange={this.handleNameFilterChange}
                                        />

                                    </div>
                                }
                                <div style={{ display: 'flex', width: 1250, height: 800}}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGrid
                                            rowHeight={40}
                                            hideFooterSelectedRowCount={false}
                                            columns={this.columns}
                                            rows={this.state.filteredLists}
                                            pageSize={this.state.pageSize}
                                            rowsPerPageOptions={[25, 50]}
                                            autoHeight={true}
                                        />
                                    </div>
                                </div>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.cancelEditing} color="default">
                                    Annuler
                                </Button>
                            </DialogActions>
                        </form>
                }
            </Dialog>
        )
    }
}

CrackListListPicker.propTypes = {
    languageId: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onPick: PropTypes.func.isRequired,
    level: PropTypes.object,
};

export default CrackListListPicker;