import React from 'react';
import DefaultApp from "./DefaultApp";
import {Tab, Tabs} from '@material-ui/core';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import AppLanguages from "./AppLanguages";
import CrackListPartyAppDataVersions from "./cracklist-party/CrackListPartyAppDataVersions";
import CrackListPartyPacks from "./cracklist-party/CrackListPartyPacks";
import CrackListPartyPackDetails from "./cracklist-party/CrackListPartyPackDetails";
import CrackListPartyLists from "./cracklist-party/CrackListPartyLists";
import CrackListPartyListDetails from "./cracklist-party/CrackListPartyListDetails";
import CrackListPartyPenalties from "./cracklist-party/CrackListPartyPenalties";
import CrackListPartyPenaltyDetails from "./cracklist-party/CrackListPartyPenaltyDetails";
import CrackListPartyDiscovery from "./cracklist-party/CrackListPartyDiscovery";

class CrackListParty extends DefaultApp {

    render () {
        const { app, match } = this.props;
        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/create-list/:languageId/`} render={(props) => (
                        <CrackListPartyListDetails app={app} />
                    )} />
                    <Route path={`${match.url}/list/:listId/`} render={(props) => (
                        <CrackListPartyListDetails app={app} />
                    )} />
                    <Route path={`${match.url}/create-penalty/:languageId/`} render={(props) => (
                        <CrackListPartyPenaltyDetails app={app} />
                    )} />
                    <Route path={`${match.url}/penalty/:penaltyId/`} render={(props) => (
                        <CrackListPartyPenaltyDetails app={app} />
                    )} />
                    <Route path={`${match.url}/pack/:packId/`} render={(props) => (
                        <CrackListPartyPackDetails app={app} />
                    )} />
                    <Route path={`${match.path}/:tab`}>
                        <CrackListPartyTabs app={app}/>
                    </Route>
                    <Route render={() => <Redirect to={`/${app.url_slug}/${CrackListPartyTabs.defaultTab}/`}/>} />
                </Switch>


            </div>
        );
    }
}

class CrackListPartyTabs extends React.Component {

    static defaultTab = "lists";

    constructor(props) {
        super(props);
        this.state = {
            currentTab: CrackListPartyTabs.getCurrentTab(props),
        };
    }

    static getCurrentTab(props) {
        const tabName = props.match.params.tab;
        return tabName === undefined ? CrackListPartyTabs.defaultTab : tabName;
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            currentTab: CrackListPartyTabs.getCurrentTab(props),
        };
    }

    onCurrentTabChange = (e, value) => {
        this.props.history.push('/'+this.props.app.url_slug + '/' + value);
    };

    render() {
        const { app } = this.props;
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.currentTab}
                    onChange={this.onCurrentTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        margin: '-20px -22px 22px -22px',
                        borderBottom: '1px #ddd solid',
                        background: 'white',
                    }}
                    scrollable="true"
                    scrollButtons="auto"
                >
                    <Tab
                        value="packs"
                        label="Packs"
                    />
                    <Tab
                        value="lists"
                        label="Listes"
                    />
                    <Tab
                        value="penalties"
                        label="Gages"
                    />
                    <Tab
                        value="discovery"
                        label="Découverte"
                    />
                    <Tab
                        value="publish"
                        label="Publication"
                    />
                    <Tab
                        value="languages"
                        label="Langues"
                    />
                </Tabs>

                <Switch>
                    <Route path={`/${app.url_slug}/lists/`} render={(props) => (
                        <CrackListPartyLists app={app}/>
                    )} />
                    <Route path={`/${app.url_slug}/penalties/`} render={(props) => (
                        <CrackListPartyPenalties app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/packs/`} render={(props) => (
                        <CrackListPartyPacks app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/discovery/`} render={(props) => (
                        <CrackListPartyDiscovery app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/languages/`} render={(props) => (
                        <AppLanguages app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/publish/`} render={(props) => (
                        <CrackListPartyAppDataVersions app={app}/>
                    )} />
                    <Route/>
                </Switch>
            </React.Fragment>
        );
    }
}

CrackListPartyTabs = withRouter(CrackListPartyTabs);

export default withRouter(CrackListParty);
