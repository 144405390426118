import React from 'react';
import './App.css';
import {BrowserRouter, NavLink, Redirect, Route} from 'react-router-dom'
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    ListItem,
    ListItemText,
    Toolbar,
    Typography
} from '@material-ui/core';
import classNames from 'classnames'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Gallery from "./gallery/Gallery";
import WordsSearch from "./apps/WordsSearch";
import WordsApart from "./apps/WordsApart";
import CrossWords from "./apps/CrossWords";
import PicsAndWords from "./apps/PicsAndWords";
import CodeWords from "./apps/CodeWords";
import WordsOut from "./apps/WordsOut";
import CrackList from "./apps/CrackList";
import CrackListParty from "./apps/CrackListParty";
import WoodysWords from "./apps/WoodysWords";
import CrackNumber from "./apps/CrackNumber";
import DefaultApp from "./apps/DefaultApp";
import HTML5Backend from 'react-dnd-html5-backend';
import {DragDropContext} from 'react-dnd';
import api from './services/api'


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false,
            apps:[],
            appBarTitle: "Y A QU A",
            appBarTabs: [],
            appBarRightItems: [],
            currentTab: 0,
        };

    }

    componentDidMount() {
        this.updateApps();
    }

    updateApps() {
        api.getApps().then(apps => {
            this.setState({
                apps: apps
            });
        }).catch((error) => {

        });
    }

    handleDrawerOpen = () => {
        this.setState({ drawerOpen: true });
    };

    handleDrawerClose = () => {
        setTimeout(() => {
            this.setState({ drawerOpen: false });
        }, 150);
    };

    onAppBarTitleChange = (title) => {
        this.setState({
            appBarTitle: title,
        });
    };

    onAppBarRightItemsChange = (items) => {
        this.setState({
            appBarRightItems: items
        });
    };

    onAppBarTabsChange = (tabs, defaultTab) => {
        this.setState({
            currentTab: defaultTab,
            appBarTabs: tabs,
        });
    };

    render() {

        const theme = createMuiTheme({
            typography: {
                fontFamily: [
                    'Roboto',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
            },
            palette: {
                primary: {
                    main: '#18B5FD',
                    contrastText: '#ffffff'
                },
                secondary: {
                    main: '#FF6633',
                    contrastText: '#ffffff'
                }
            },
            status: {
                danger: 'orange',
            },
        });

        const { classes } = this.props;
        const menuItemClasses = classNames({
            [classes.drawerItem]: true,
            menuItem: true,
        });

        const appComponents = {
            WordsSearch: WordsSearch,
            WordsApart: WordsApart,
            CrossWords: CrossWords,
            DefaultApp: DefaultApp,
            PicsAndWords: PicsAndWords,
            CodeWords: CodeWords,
            WordsOut: WordsOut,
            CrackList: CrackList,
            CrackListParty: CrackListParty,
            WoodysWords: WoodysWords,
            CrackNumber: CrackNumber
        };

        return (
            <BrowserRouter>
                <MuiThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            position="absolute"
                            className={classNames(classes.appBar, this.state.drawerOpen && classes.appBarShift)}
                        >
                            <Toolbar disableGutters={!this.state.drawerOpen}>
                                <IconButton
                                    color="inherit"
                                    onClick={this.handleDrawerOpen}
                                    className={classNames(classes.menuButton, this.state.drawerOpen && classes.hide)}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Typography variant="h6" color="inherit" noWrap style={{flex: 1}}>
                                    {this.state.appBarTitle}
                                </Typography>

                                {
                                    this.state.appBarRightItems.length === 0 ? null : (
                                        <div style={{marginRight: '24px'}}>
                                            {this.state.appBarRightItems}
                                        </div>
                                    )
                                }

                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            classes={{
                                docked: classNames(classes.drawerPaper, !this.state.drawerOpen && classes.drawerPaperClose),
                                paper: classNames(classes.drawerPaper, !this.state.drawerOpen && classes.drawerPaperClose),
                            }}
                            open={this.state.drawerOpen}
                        >
                            <div className={classes.toolbar}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon style={{color: 'white'}} />
                                </IconButton>
                            </div>
                            <Divider className={classes.divider} />
                            <div>
                                <br/>
                                <NavLink to="/gallery">
                                    <ListItem
                                        button
                                        onClick={this.handleDrawerClose}
                                        className={menuItemClasses}
                                    >
                                            <PhotoLibraryIcon style={{width: 48, height: 48}}/>
                                        <ListItemText primary="Images" disableTypography className={classes.drawerItemText} />
                                    </ListItem>
                                </NavLink>
                                <Divider className={classes.divider}/>
                                {
                                    this.state.apps.map((app, i) => {
                                        return (
                                            <NavLink
                                                key={i}
                                                to={"/" + app.url_slug}>
                                                <ListItem
                                                    button
                                                    onClick={this.handleDrawerClose}
                                                    className={menuItemClasses}
                                                >
                                                    <div style={{backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: 'url(/'+ app.icon + '.png)', flex: '0 0 48px', height: '48px'}}>&nbsp;</div>
                                                    <ListItemText primary={app.name} disableTypography className={classes.drawerItemText} />
                                                </ListItem>
                                            </NavLink>
                                        )
                                    })
                                }
                            </div>
                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.toolbar}/>

                            <Route exact strict path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`}/>} />
                            <Route path="/gallery"  render={(props) => {
                                return <Gallery
                                    onAppBarTitleChange={this.onAppBarTitleChange}
                                    onAppBarRightItemsChange={this.onAppBarRightItemsChange}
                                    {...props} />
                            }} />
                            {
                                this.state.apps.map((app, i) => {
                                    return (
                                        <Route key={i} path={'/' + app.url_slug} render={(props) => {
                                            const AppComponent = appComponents[app.react_component];
                                            return (
                                                <AppComponent
                                                    onAppBarTitleChange={this.onAppBarTitleChange}
                                                    onAppBarRightItemsChange={this.onAppBarRightItemsChange}
                                                    onAppBarTabsChange={this.onAppBarTabsChange}
                                                    app={app}
                                                    {...props}
                                                />
                                            )
                                        }}
                                        />
                                    )
                                })

                            }
                        </main>
                    </div>
                </MuiThemeProvider>
            </BrowserRouter>
        );
    }
}

const drawerWidth = 240;

const styles = theme => ({
    root: {
        zIndex: 1,
        overflow: 'hidden',
        display: 'flex',
    },
    appBar: {
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        flexGrow: 0,
        width: drawerWidth + 'px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: '#202A35',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: '72px',
    },
    drawerItem: {
        padding: '12px',
        whiteSpace: 'nowrap',
        color: '#ffffff !important',
        fontWeight: '600',
        active: {
            backgroundColor: 'red'
        }
    },
    drawerItemText: {
        padding: '0 12px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3)
    },
    divider: {
        backgroundColor: 'rgba(200, 200, 255, 0.12)'
    }
});


export default DragDropContext(HTML5Backend)(withStyles(styles, { withTheme: true })(App));
