import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab, FormControlLabel, MenuItem,
    Paper,
    Snackbar,
    TextField,
    Toolbar,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputLabel,
    Select,
    Slider
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy"
import withRouter from "react-router-dom/withRouter";
import {DataGrid} from "@material-ui/data-grid";

const sortModel = [
    {
        field: 'name',
        sort: 'asc',
    },
];

class WoodysWordsDefinitions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            definitions: [],
            pageSize: parseInt(props.match.params.pageSize),
            page: parseInt(props.match.params.page),
            nameFilter: '',
            filteredDefinitions: [],
        };

        this.columns = [
            {
                field: 'name',
                headerName: 'Intitulé',
                width: 320,
            },
            {
                field: 'item_count',
                headerName: 'Mots',
                width: 550
            },
            {
                field: 'definition_id',
                headerName: 'ID / Éditer',
                renderCell: (params) => {
                    return <span>{params.value}
                        <Button color="primary" onClick={this.editDefinition(params.data)}><EditIcon/></Button>
                    </span>
                },
                sortable: false
            },
        ]
    }

    getRouteLanguageId() {
        return parseInt(this.props.match.params.languageId, 10);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.languageId === this.props.match.params.languageId) {
            if (prevProps.match.params.page !== this.props.match.params.page) {
                this.setState({
                    page: parseInt(this.props.match.params.page),
                    pageSize: parseInt(this.props.match.params.pageSize)
                })
            }
            return;
        }
        this.updateData();
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let requests = [
            api.getAppLanguages(this.props.app.app_id),
        ];
        if (this.hasDefinedLanguage()) {
            requests.push(api.getWoodysWordsDefinitions(this.getRouteLanguageId()))
        }

        Promise.all(requests).then(res => {
            const languages = res[0];
            const definitions = (res.length > 1 ? res[1] : []).map(d => {
                return {
                    ...d,
                    id: d.definition_id
                }
            })

            this.setState({
                languages: languages,
                definitions: definitions,
                filteredDefinitions: Array.from(definitions),
                nameFilter: '',
            });
        });
    }

    hasDefinedLanguage() {
        return this.getRouteLanguageId() !== -1
    }

    editDefinition = (definition) => (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/definition/${definition.definition_id}`);
    };

    createDefinition = (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/create-definition/${this.getRouteLanguageId()}`);
    };

    deleteDefinition = (definition) => (e) => {
        if (!window.confirm('Supprimer la définition ?')) {
            return;
        }
        api.deleteWoodysWordsDefinition(definition.definition_id).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la suppression de la définition");
                return;
            }
            this.updateData();
        });
    }

    handleLanguageChange = (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/definitions/lang-${e.target.value}/${this.state.pageSize}-1`);
    };

    handleNameFilterChange = (e) => {
        this.setState({
            nameFilter: e.target.value,
            filteredDefinitions: this.state.definitions.filter(l => this.definitionMatchesFilters(l, e.target.value)),
        })
    }

    definitionMatchesFilters(definition, nameFilter) {
        return (!nameFilter || (!!definition.name && definition.name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1))
    }

    onPageChange = (p) => {
        if (p.page === this.state.page) {
            return;
        }
        this.props.history.push(`/${this.props.app.url_slug}/definitions/lang-${this.getRouteLanguageId()}/${p.pageSize}-${p.page}`, {page: p.page});
    }

    onPageSizeChange = (p) => {
        if (p.pageSize === this.state.pageSize) {
            return;
        }
        this.props.history.push(`/${this.props.app.url_slug}/definitions/lang-${this.getRouteLanguageId()}/${p.pageSize}-1`, {pageSize: p.pageSize, page: 1});
    }

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (!this.state.languages) {
            return null;
        }

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Définitions</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.getRouteLanguageId()}
                                onChange={this.handleLanguageChange}
                                label="Langue"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                { !this.hasDefinedLanguage() ?
                                    <MenuItem disabled value={-1}>Sélectionner une langue</MenuItem> : null }
                                {
                                    this.state.languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                }
                            </TextField>

                        </div>

                        <div style={{marginBottom: 24, marginLeft: 10, display: this.hasDefinedLanguage() ? 'block' : 'none'}}>

                            { this.state.definitions.length === 0 ? null :
                                <React.Fragment>
                                    <TextField
                                        style={{marginRight: 24, width: 300}}
                                        value={this.state.nameFilter}
                                        label="Intitulé"
                                        placeholder="Filtrer sur l'intitulé"
                                        onChange={this.handleNameFilterChange}
                                        disabled={!this.hasDefinedLanguage()}
                                    />
                                </React.Fragment>
                            }

                        </div>

                        <div style={{ display: 'flex', height: 5000 }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGrid
                                    rowHeight={44}
                                    autoHeight
                                    sortingOrder={['asc', 'desc']}
                                    sortModel={sortModel}
                                    hideFooterSelectedRowCount={false}
                                    columns={this.columns}
                                    rows={this.state.filteredDefinitions}
                                    pageSize={this.state.pageSize}
                                    page={this.state.page}
                                    onPageChange={this.onPageChange}
                                    onPageSizeChange={this.onPageChange}
                                    rowsPerPageOptions={[50, 100]}
                                />
                            </div>
                        </div>

                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.createDefinition}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

WoodysWordsDefinitions.propTypes = {
    app: PropTypes.object.isRequired,
};

WoodysWordsDefinitions.defaultProps = {};

export default withRouter(WoodysWordsDefinitions);