import React from 'react';
import PropTypes from 'prop-types';
import api from "../services/api";
import PackCard from "./PackCard";
import {
    Button,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Snackbar,
    Switch,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withRouter from "react-router-dom/withRouter";
import './PackDetails.css'
import LanguagePicker from "./LanguagePicker";
import ImagePicker from "./ImagePicker";
import DeleteIcon from '@material-ui/icons/Delete';
import ReorderIcon from '@material-ui/icons/Reorder';
import EditIcon from '@material-ui/icons/Edit';
import LabelEditor from "./LabelEditor";
import KVC from '../services/KVC'
import AppleInAppPurchaseSelect from "../tools/AppleInAppPurchaseSelect";
import DataErrorLink from "../tools/DataErrorLink";

class PackDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            $imageSpecificsConfig: undefined,
            languages: [],
            imagePickerExpanded: false,
            imageViewerExpanded: true,
            languagePickerExpanded: false,
            snackbarDisplayed: false,
            packImages: [],
            orderedPackImages: [],
            unusedImages: [],
            showLabelEditor: false,
            reordering: false,
            isDragging: false,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData(callback) {
        const appId = this.props.app.app_id;
        const packId = this.getPackId();
        Promise.all([
            api.getAppLanguages(appId),
            api.getPack(packId),
            api.getPackImages(packId),
            api.getPackUnusedImages(packId),
        ]).then(results => {
            const languages = results[0];
            const pack = results[1];
            const packImages = results[2];
            const unusedImages = results[3];
            this.setState({
                languages: languages,
                pack: pack,
                $pack: Object.assign({}, pack),
                packImages: packImages,
                orderedPackImages: Array.from(packImages),
                unusedImages: unusedImages,
            });
            if (callback !== undefined) {
                callback(packImages, unusedImages);
            }
        });
    }

    getPackId() {
        return parseInt(this.props.match.params.packId, 10);
    }

    goBack = () => {
        this.props.history.goBack();
    };

    apiPack(pack) {
        let apiPack = Object.assign({}, pack);
        apiPack.label_id = apiPack.label.label_id;
        delete apiPack.languages;
        delete apiPack.images;
        delete apiPack.label;
        return apiPack;
    }

    packConfigIsPristine() {
        let initialPack = this.apiPack(this.state.$pack);
        let currentPack = this.apiPack(this.state.pack);
        return JSON.stringify(currentPack) === JSON.stringify(initialPack);
    }

    packIsFull(pack) {
        return pack.max_image_count > 0 && pack.images.length >= pack.max_image_count;
    }

    cancelEditingPack = () => {
        this.setState({
            pack: Object.assign(this.state.$pack),
        });
    };

    commitEditingPack = () => {
        api.patchPack(this.getPackId(), this.apiPack(this.state.pack)).then(pack => {
            if (pack === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement des propriétés du pack.");
                return;
            }
            this.setState({
                $pack: Object.assign({}, this.state.pack),
            });
            this.displaySnackbar("Propriétés du pack enregistrées.");
        });
    };

    onLabelEditingFinished = (label) => {
        let pack = Object.assign({}, this.state.pack);
        pack.label = label;
        let $pack = Object.assign({}, this.state.$pack);
        $pack.label = label;
        this.setState({
            showLabelEditor: false,
            pack: pack,
            $pack: $pack,
        });
    };

    handleConfigSwitchChange = (keyPath) => (e, checked) => {
        this.handleConfigChange(keyPath, checked);
    };

    handleConfigValueChange = (keyPath) => (e) => {
        this.handleConfigChange(keyPath, e.target.value);
    };

    handleConfigChange = (keyPath, value) => {
        let pack = Object.assign({}, this.state.pack);
        KVC(pack).setValueForKeyPath(keyPath, value);
        this.setState({
            pack: pack,
        });
    };

    handleIosSkuChange = (inAppProduct) => (e) => {
        if (inAppProduct.consumable) {
            window.alert("Attention ce produit est \"consommable\" et n'est donc pas éligible à la restauration des achats.")
        }
        this.handleConfigValueChange('sku_ios')(e);
    };

    onImagePickerToggle = () => {
        this.setState({
            imagePickerExpanded: !this.state.imagePickerExpanded,
        });
    };

    onImagePickerCancel = () => {
        this.onImagePickerToggle();
    };

    onImagePickerSave = (pickedImages, callback) => {
        const packId = this.getPackId();
        api.patchPackImages(packId, pickedImages.map((image, i) => {
            return {
                pack_id: packId,
                image_id: image.image_id,
            };
        })).then(() => {
            this.updateData((appImages, unusedImages) => {
                callback(unusedImages);
                this.openImageViewer();
            });
        });
    };

    openImageViewer() {
        this.setState({
            imagePickerExpanded: false,
            imageViewerExpanded: true,
        });
    }

    onImageViewerToggle = () => {
        if (this.state.reordering) {
            this.cancelReorderingImages();
        }
        this.setState({
            imageViewerExpanded: !this.state.imageViewerExpanded,
        });
    };

    onImageMove = (sourceIndex, targetIndex) => {
        let images = Array.from(this.state.orderedPackImages);
        let image = images[sourceIndex];
        images.splice(sourceIndex, 1);
        images.splice(targetIndex, 0, image);
        this.setState({
            orderedPackImages: images
        });
    };

    onImageDragBegin = () => {
        this.setState({
            isDragging: true,
        });
    };

    onImageDragEnd = () => {
        this.setState({
            isDragging: false,
        });
    };

    isImageOrderPristine() {
        return this.state.packImages.every((image, idx) => image.image_id === this.state.orderedPackImages[idx].image_id);
    }

    startReorderingImages = (e) => {
        e.stopPropagation();
        this.setState({
            reordering: true,
        });
    };

    cancelReorderingImages = (e) => {
        e.stopPropagation();
        this.setState({
            reordering: false,
            orderedPackImages: Array.from(this.state.packImages),
        });
    };

    commitReorderingImages = (e) => {
        e.stopPropagation();
        const packId = this.getPackId();
        api.postPackImages(packId, this.state.orderedPackImages.map((image, idx) => ({
            pack_id: packId,
            image_id: image.image_id,
            index: idx,
        }))).then(images => {
            if (images === undefined) {
                this.displaySnackbar('Erreur lors de l\'enregistrement de l\'ordre des images du pack.');
                return;
            }
            this.displaySnackbar('Ordre des images du pack enregistré avec succès.');
            this.setState({
                packImages: Array.from(this.state.orderedPackImages),
                orderedPackImages: Array.from(this.state.orderedPackImages),
                reordering: false,
            });
        });
    };

    onImageEdited = (image) => () => {
        this.props.history.push(`/${this.props.app.url_slug}/image/${image.image_id}`);
    };

    showImageDeleteDialog = (image) => () => {
        this.setState({
            showDeleteConfirmDialog: true,
            deleteConfirmImage: image,
        });
    };

    onImageDeleteCancel = (e) => {
        this.setState({
            showDeleteConfirmDialog: false,
        });
    };

    onImageDeleteAccept = (e) => {
        const packId = this.getPackId();
        api.deletePackImages(packId, [{
            pack_id: packId,
            image_id: this.state.deleteConfirmImage.image_id
        }]).then(() => {
            this.setState({
                showDeleteConfirmDialog: false,
                deleteConfirmImage: undefined
            });
            this.updateData();
        });
    };

    onLanguagePickerToggle = () => {
        this.setState({
            languagePickerExpanded: !this.state.languagePickerExpanded,
        });
    };

    savePackLanguages = (languages) => {

        const packId = this.getPackId();
        api.postPackLanguages(packId, languages.map(l => {
            return {
                pack_id: packId,
                language_id: l.language_id,
            };
        })).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde des langues du pack.");
                return;
            }
            this.updateData();
            this.displaySnackbar("Langues du pack sauvegardées !");
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {
        if (this.state.pack === undefined) {
            return <div/>;
        }
        const { pack } = this.state;

        const packTypes = [{
            name: 'Standard',
            value: 'standard',
        }, {
            name: 'Image du jour',
            value: 'daily'
        }, {
            name: 'Événementiel',
            value: 'event'
        }];

        const packIsFull = this.packIsFull(this.state.$pack);

        return (
            <div className="packDetails">
                <Paper>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails du pack</Typography>
                            <Typography variant="subtitle1" color="primary">{PackCard.getPackLabel(pack)} - {pack.uuid}</Typography>
                        </div>


                        {
                            this.packConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingPack}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingPack}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    this.state.pack && this.state.languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}>

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={pack.published}
                                                    onChange={this.handleConfigSwitchChange('published')}
                                                    value="1"
                                                />}
                                            label="Publié (peut apparaître dans l'app)"
                                            style={{margin: '0 0 32px -12px'}}
                                        />

                                        <br/>

                                        <TextField
                                            style={{marginBottom: 24, marginRight: 24, width: 200}}
                                            select
                                            value={pack.type}
                                            onChange={this.handleConfigValueChange('type')}
                                            label="Type de pack"
                                        >
                                            {
                                                packTypes.map(t => <MenuItem key={t.value} value={t.value}>{t.name}</MenuItem>)
                                            }
                                        </TextField>

                                        <TextField
                                            style={{marginBottom: 24, width: 180}}
                                            value={pack.max_image_count}
                                            onChange={this.handleConfigValueChange('max_image_count')}
                                            label="Nombre max. d'images"
                                            type="number"
                                            helperText="0 = pas de limite"
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                            }}
                                        />

                                        <Typography variant="button">
                                            Nom visible dans l'app
                                        </Typography>
                                        <Typography variant="body1">
                                            <ButtonBase
                                                focusRipple
                                                onClick={(e) => this.setState({showLabelEditor: true})}
                                                style={{fontSize:'1rem', padding: '4px 4px 4px 0', color: '#2196f3', marginTop: 12}}
                                            >
                                                {PackCard.getPackLabel(pack)}<EditIcon style={{marginLeft: 4}}/>
                                            </ButtonBase>
                                        </Typography>
                                        <LabelEditor
                                            labelId={pack.label.label_id}
                                            label={pack.label}
                                            open={this.state.showLabelEditor}
                                            languages={pack.languages}
                                            onClose={this.onLabelEditingFinished}
                                            title="Nom du pack d'images"
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <Typography variant="button">
                                            Monétisation
                                        </Typography>

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={pack.free}
                                                    onChange={this.handleConfigSwitchChange('free')}
                                                    value="1"
                                                />}
                                            label="Gratuit"
                                            style={{marginBottom: 12}}
                                        />

                                        <TextField
                                            style={{marginLeft: 50, marginBottom: 24, width: 200}}
                                            value={pack.coin_cost}
                                            onChange={this.handleConfigValueChange('coin_cost')}
                                            label="Coût en pièces"
                                            type="number"
                                            disabled={pack.free}
                                            error={!pack.free && pack.coin_cost === 0}
                                            inputProps={{
                                                min: 0,
                                            }}
                                        />

                                        <AppleInAppPurchaseSelect
                                            appId={this.props.app.app_id}
                                            value={pack.sku_ios}
                                            onChange={this.handleIosSkuChange}
                                            disabled={pack.free}
                                            error={!pack.free && !pack.sku_ios}
                                        />

                                        <TextField
                                            style={{marginTop: 12}}
                                            fullWidth
                                            value={!!pack.sku_android ? pack.sku_android : ''}
                                            onChange={this.handleConfigValueChange('sku_android')}
                                            label="Identifiant du produit in-app Android"
                                            disabled={pack.free}
                                            error={!pack.free && !pack.sku_android}
                                        />
                                    </div>
                                </div>
                            </Paper>

                            {
                                !pack.errors || pack.errors.length === 0 ? null : (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="h6">
                                                Erreurs ({pack.errors.length})
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <ul>
                                            {
                                                pack.errors.map((e, i) => <li key={i}><DataErrorLink app={this.props.app} error={e}/></li>)
                                            }
                                            </ul>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )

                            }

                            <ExpansionPanel
                                expanded={this.state.imagePickerExpanded}
                                onChange={this.onImagePickerToggle}
                                disabled={packIsFull || this.state.unusedImages.length === 0}
                            >
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">
                                        Images inutilisées ({this.state.unusedImages.length}) {packIsFull ? '— Nombre max. d\'images atteint.' : ''}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {
                                        this.state.imagePickerExpanded ? (
                                            <ImagePicker
                                                selectable
                                                images={this.state.unusedImages}
                                                onCancel={this.onImagePickerCancel}
                                                onSave={this.onImagePickerSave}
                                                saveButtonText="Ajouter au pack"
                                            />
                                        ) : <div/>
                                    }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel
                                expanded={this.state.imageViewerExpanded}
                                onChange={this.onImageViewerToggle}
                            >
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                        <Typography style={{flex: 1}} variant="h6">
                                            Images du pack ({pack.images.length})
                                        </Typography>
                                        {
                                            this.state.reordering ? (
                                                <React.Fragment>
                                                    <Button color="secondary" onClick={this.cancelReorderingImages}>
                                                        Annuler
                                                    </Button>
                                                    <Button
                                                        disabled={this.isImageOrderPristine()}
                                                        color="primary"
                                                        onClick={this.commitReorderingImages}>
                                                        Enregistrer
                                                    </Button>
                                                </React.Fragment>
                                            ) : (this.state.imageViewerExpanded ? (
                                                <Button color="primary" onClick={this.startReorderingImages}>
                                                    <ReorderIcon style={{marginRight: 8}}/> Modifier l'ordre
                                                </Button>
                                            ) : null)
                                        }
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <ImagePicker
                                        images={this.state.orderedPackImages}
                                        selectable={false}
                                        deletable
                                        onDelete={this.showImageDeleteDialog}
                                        onEdit={this.onImageEdited}
                                        editable
                                        searchable={false}
                                        pagination={false}
                                        draggable={this.state.reordering}
                                        onDragBegin={this.onImageDragBegin}
                                        onDragEnd={this.onImageDragEnd}
                                        onMove={this.onImageMove}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={this.state.languagePickerExpanded} onChange={this.onLanguagePickerToggle}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Disponibilité selon la langue</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display: 'block', padding: 0}}>
                                    <LanguagePicker
                                        languages={this.state.languages}
                                        initialLanguages={pack.languages}
                                        onSave={this.savePackLanguages}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

                {
                    this.state.deleteConfirmImage ? (
                        <Dialog
                            open={this.state.showDeleteConfirmDialog}
                            disableBackdropClick
                            disableEscapeKeyDown
                            maxWidth="sm"
                            aria-labelledby="confirmation-dialog-title"
                        >
                            <DialogTitle id="confirmation-dialog-title">
                                Suppression d'image
                            </DialogTitle>
                            <DialogContent>
                                <Typography>
                                    Voulez-vous supprimer cette image du pack ?<br/>
                                    Elle restera accessible dans la liste des images du jeu.
                                </Typography>
                                <img
                                    src={this.state.deleteConfirmImage.thumbnail_url}
                                    alt=""
                                    width={250}
                                    style={{
                                        display: 'block',
                                        margin: '24px auto 0 auto'
                                    }}
                                    align="center"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.onImageDeleteCancel} color="default">
                                    Annuler
                                </Button>
                                <Button onClick={this.onImageDeleteAccept} color="secondary">
                                    <DeleteIcon/>Supprimer
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null
                }
            </div>
        )
    }
}

PackDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

PackDetails.defaultProps = {};

export default withRouter(PackDetails);