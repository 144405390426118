import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import KVC from "../../services/KVC";
import withRouter from "react-router-dom/withRouter";
import {Link} from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class CrackListLevels extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            levels: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getCrackListLevels(),
        ]).then(res => {
            const levels = res[0];
            this.setState({
                levels: levels,
            });
        });
    }

    addNewLevel = () => {
        api.postCrackListLevel().then(level => {
            this.updateData();
        })
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    startEditingLevel = (i) => () => {
        let levelBackup = Object.assign({}, this.state.levels[i]);
        let level = Object.assign({}, this.state.levels[i]);

        let levels = Array.from(this.state.levels);
        levels[i] = level;

        level.editing = true;
        level.$level = levelBackup
        this.setState({
            levels: levels
        });
    };

    cancelEditingLevel = (i) => () => {
        let levels = Array.from(this.state.levels);
        let level = this.state.levels[i];
        level.editing = false;
        levels[i] = level.$level;
        delete level.$level;

        this.setState({
            levels: levels,
        });
    };

    commitEditingLevel = (i) => (e) => {
        let level = Object.assign({}, this.state.levels[i]);

        api.patchCrackListLevel(level.level_id, level).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde du niveau.");
                return;
            }

            delete level.$level;
            level.editing = false;

            let levels = Array.from(this.state.levels);
            levels[i] = level;

            this.setState({
                levels: levels,
            });
            this.displaySnackbar("Niveau sauvegardé !");
        });

        e.preventDefault();
    };


    handleLevelValueChange = (i, propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleLevelChange(i, propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleLevelChange(i, propNamePath, e.target.value);
        }
    };

    handleLevelChange = (i, propNamePath, value) => {
        let level = Object.assign({}, this.state.levels[i]);
        KVC(level).setValueForKeyPath(propNamePath, value);

        let levels = Array.from(this.state.levels);
        levels[i] = level;

        this.setState({
            levels: levels,
        });
    };

    openLevelDetails = (i) => (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/level/${this.state.levels[i].level_id}`);
    }

    render() {

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Niveaux</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>
                        <Table size={"medium"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>N°</TableCell>
                                    <TableCell>Leurres</TableCell>
                                    <TableCell>Vies</TableCell>
                                    <TableCell>Listes choisies</TableCell>
                                    <TableCell/>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.levels.map((level, i) => (
                                    <TableRow key={level.level_id}>
                                        { !level.editing ?
                                            <React.Fragment>
                                                <TableCell style={{width: "100px"}}>{level.level_index + 1}</TableCell>
                                                <TableCell style={{width: 150}}>{level.fake_tile_count}<span style={{color: "#aaa", fontSize: '10px'}}> tuiles</span></TableCell>
                                                <TableCell style={{width: 100}}>{level.live_count}<span style={{color: "#aaa", fontSize: '10px'}}> vies</span></TableCell>
                                                <TableCell style={{width: 650}}>
                                                    {level.specifics.map(sp =>
                                                        <div>
                                                            <code>{sp.language_code}</code> -&nbsp;<strong>{sp.list.items.length} {sp.list.items.length > 1 ? 'réponses' : 'réponse'}</strong> -&nbsp;
                                                            <Link style={{color: '#000'}} to={`/${this.props.app.url_slug}/list/${sp.list.list_id}`} key={sp.list.list_id}>{sp.list.name}</Link> -
                                                            <span style={{color: '#666'}}>&nbsp;({sp.list.tile_count_description} tuiles</span>)
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell style={{width: 100}}>
                                                    <IconButton color="primary" onClick={this.startEditingLevel(i)}><EditIcon/></IconButton>
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <TableCell style={{width: "100px"}}>{level.level_index + 1}</TableCell>
                                            <TableCell style={{width: 150}}>
                                                <TextField
                                                    onChange={this.handleLevelValueChange(i, 'fake_tile_count', 'number')}
                                                    value={level.fake_tile_count}
                                                    color="primary"
                                                    label="tuiles"
                                                    type="number"
                                                    inputProps={{
                                                        form: `level-${level.level_id}`,
                                                        style: {width: "100px"}
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={{width: 150}}>
                                                <TextField
                                                    onChange={this.handleLevelValueChange(i, 'live_count', 'number')}
                                                    value={level.live_count}
                                                    color="primary"
                                                    label="vies"
                                                    type="number"
                                                    inputProps={{
                                                        form: `level-${level.level_id}`,
                                                        style: {width: "100px"}
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={{width: 650}}>
                                                {level.specifics.map(sp =>
                                                    <div>
                                                        <code>{sp.language_code}</code> -&nbsp;<strong>{sp.list.items.length} {sp.list.items.length > 1 ? 'réponses' : 'réponse'}</strong> -&nbsp;
                                                        <Link style={{color: '#000'}} to={`/${this.props.app.url_slug}/list/${sp.list.list_id}`} key={sp.list.list_id}>{sp.list.name}</Link> -
                                                        <span style={{color: '#666'}}>&nbsp;({sp.list.tile_count_description} tuiles</span>)
                                                    </div>
                                                )}
                                            </TableCell>

                                            <TableCell style={{width: 200}}>
                                                <form onSubmit={this.commitEditingLevel(i)} id={`level-${level.level_id}`}>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={this.cancelEditingLevel(i)}
                                                    >
                                                        <CancelIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        <CheckCircleIcon/>
                                                    </IconButton>
                                                </form>
                                            </TableCell>
                                            <TableCell/>
                                        </React.Fragment>
                                }

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.addNewLevel}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

CrackListLevels.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListLevels.defaultProps = {};

export default withRouter(CrackListLevels);