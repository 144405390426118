import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {
    Button, FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Snackbar, Switch, Table, TableCell, TableRow,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import DeleteIcon from '@material-ui/icons/Delete'
import './CrackList.css'
import KVC from "../../services/KVC";
import Icon from "@material-ui/core/Icon";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

class CrackListListDetails extends React.Component {

    constructor(props) {
        super(props);
        let state = {
            languages: [],
            levels: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
        };
        if (props.match.url.includes("/create-list")) {
            state.list = {
                name: '',
                language_id: props.match.params.languageId,
            }
            state.$list = Object.assign({}, state.list);
            state.items = [];
            state.$items = [];
        }
        this.state = state;
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let requests = [
            api.getAppLanguages(9),
            api.getCrackListLevels(),
        ];
        const listId = this.getListId();
        if (!!listId) {
            requests.push(api.getCrackListList(this.getListId()));
        }
        Promise.all(requests).then(results => {
            const languages = results[0];
            const levels = results[1];
            let state = {
                languages: languages,
                newAnswer: ''
            };
            if (results.length > 2) {
                const list = results[2];
                if (!list.level_id) {
                    list.level_id = -1;
                }
                state = {
                    ...state,
                    list: list,
                    items: Array.from(list.items),
                    $items: JSON.parse(JSON.stringify(list.items)),
                    $list: Object.assign({}, list),
                    levels: levels,
                };
            }
            this.setState(state);
        });
    }

    getListId() {
        return parseInt(this.props.match.params.listId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/lists/lang-${this.state.list.language_id}/`);
            return;
        }
        this.props.history.goBack();
    };

    listConfigIsPristine = () => {
        return JSON.stringify(this.state.list) === JSON.stringify(this.state.$list);
    };

    itemsArePristine = () => {
        return JSON.stringify(this.state.items) === JSON.stringify(this.state.$items);
    };

    handleLevelAssociationChange = (e) => {
        let list = Object.assign({}, this.state.list);
        if (e.target.value === -1) {
            list.level_id = -1;
        } else {
            list.level_id = parseInt(e.target.value, 10);
        }
        this.setState({list: list})
    };

    handleConfigValueChange = (propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleConfigChange(propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleConfigChange(propNamePath, e.target.value);
        }
    };

    handleConfigSwitchChange = (propNamePath) => (e, checked) => {
        this.handleConfigChange(propNamePath, checked);
    };

    handleConfigChange = (propNamePath, value) => {
        let list = Object.assign({}, this.state.list);
        KVC(list).setValueForKeyPath(propNamePath, value);
        this.setState({
            list: list,
        });
    };

    cancelEditingList = () => {
        this.setState({
            list: Object.assign(this.state.$list),
        });
    };

    commitEditingList = () => {
        const listId = this.getListId();
        if (!!listId) {
            this.saveLevelAssociation(() => {
                api.patchCrackListList(listId, this.state.list).then(res => {
                    if (res === undefined) {
                        this.displaySnackbar("Erreur lors de l'enregistrement de la liste.");
                        return;
                    }
                    if (!res.level_id) {
                        res.level_id = -1;
                    }
                    this.displaySnackbar("Liste sauvegardée.");
                    this.setState({
                        list: res,
                        $list: JSON.parse(JSON.stringify(res))
                    });
                });
            });
        } else {
            api.postCrackListList(this.state.list).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement de la liste.");
                    return;
                }
                if (!res.level_id) {
                    res.level_id = -1;
                }
                this.setState({
                    list: res,
                    $list: JSON.parse(JSON.stringify(res))
                });
                this.props.history.replace(`/${this.props.app.url_slug}/list/${res.list_id}`);
            });
        }
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    saveLevelAssociation(callback) {
        if (this.state.list.level_id === this.state.$list.level_id) {
            callback();
            return;
        }
        const specifics = {
            'list_id': this.state.list.list_id,
            'language_id': this.state.list.language_id,
            'level_id': this.state.list.level_id,
        };
        if (this.state.list.level_id === -1) {
            api.deleteCrackListLevelSpecifics(specifics).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de la suppresssion de l'association au niveau.");
                    return;
                }
                callback();
            });
            return;
        }
        api.patchCrackListLevelSpecifics(specifics).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'association au niveau.");
                return;
            }
            callback();
        });
    }

    static getPossibleCuts(word) {
        function _getPossibleCuts(word) {
            if (word.length <= 3) {
                return [[word]];
            }
            if (word.length === 4) {
                return [[word], [word.substr(0, 2), word.substr(2)]];
            }
            let cuts = [];
            if (word.length >= 4) {
                const prefix = word.substr(0, 2);
                const nextWord = word.substr(2);
                let nextCuts = _getPossibleCuts(nextWord);
                cuts = cuts.concat(nextCuts.map(c => [prefix].concat(c)));
            }
            if (word.length >= 5) {
                const prefix = word.substr(0, 3);
                const nextWord = word.substr(3);
                let nextCuts = _getPossibleCuts(nextWord);
                cuts = cuts.concat(nextCuts.map(c => [prefix].concat(c)));
            }
            if (word.length >= 6) {
                const prefix = word.substr(0, 4);
                const nextWord = word.substr(4);
                let nextCuts = _getPossibleCuts(nextWord);
                cuts = cuts.concat(nextCuts.map(c => [prefix].concat(c)));
            }
            return cuts;
        }
        const cleanedWord = (word || "")
            .trim()
            .replaceAll(' ', '-')
            .replaceAll(',', '');
        if (cleanedWord.length > 32) {
            return [];
        }
        const subWords = cleanedWord.split("-");
        let cuts = [];
        subWords.forEach((subWord, i) => {
            if (cuts.length === 0) {
                cuts = _getPossibleCuts(subWord);
            } else {
                let nextCuts = [];
                cuts.forEach(cut => {
                    nextCuts = nextCuts.concat(_getPossibleCuts(subWord).map(c => cut.concat(c)));
                });
                cuts = nextCuts;
            }
            if (i < subWords.length - 1) {
                cuts.forEach(c => c[c.length - 1] = c[c.length - 1] + " ");
            }
        });
        if (cuts.length > 32) {
            return cuts.slice(0, 32);
        }
        return cuts;
    }

    saveItems(items) {
        const listId = this.getListId();
        api.patchCrackListListItems(listId, items).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement des réponses.");
                return;
            }
            this.displaySnackbar("Réponses sauvegardées.");
            this.setState({
                $items: JSON.parse(JSON.stringify(items)),
            });
        });
    }

    commitEditingItems = (e) => {
        e.preventDefault();
        this.saveItems(this.state.items);
    }

    cancelEditingItems = (e) => {
        this.setState({
            items: JSON.parse(JSON.stringify(this.state.$items))
        });
    }

    handleItemChange = (item) => (e) => {
        let items = Array.from(this.state.items);
        items[item.item_index].name = e.target.value.toLocaleUpperCase();
        this.setState({items: items});
    }

    handleNewAnswerChange = (e) => {
        this.setState({
            newAnswer: e.target.value.toLocaleUpperCase()
        });
    }

    deleteAnswer = (index) => (e) => {
        if (!window.confirm("Supprimer cette réponse ?")) {
            return;
        }
        let items = Array.from(this.state.items);
        items.splice(index, 1);
        const listId = this.getListId();
        api.patchCrackListListItems(listId, items).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la suppression de la réponse.");
                return;
            }
            this.displaySnackbar("Réponse supprimée.");
            this.setState({
                items: res,
                $items: JSON.parse(JSON.stringify(res)),
            });
        });
    }

    removeCustomCut = (e) => {
        if (!window.confirm("Supprimer tout le découpage ?")) {
            return;
        }
        let items = Array.from(this.state.items);
        items.forEach(it => it.name = it.name.replaceAll(',', ''));
        this.setState({items: items})
        this.saveItems(items);
    };

    applyCut = (cut, itemIndex) => (e) => {
        let items = Array.from(this.state.items);
        items[itemIndex].name = cut.join(',') + ',';
        this.setState({items: items});
    };

    addAnswer = (e) => {
        e.preventDefault();
        const listId = this.getListId();
        const newAnswer = this.state.newAnswer.trim();
        if (newAnswer.length === 0) {
            return;
        }
        api.postCrackListListItem({
            list_id: listId,
            name: this.state.newAnswer
        }).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'ajout de la réponse.");
                return;
            }
            this.displaySnackbar("Réponse ajoutée.");
            this.setState({
                items: res,
                $items: JSON.parse(JSON.stringify(res)),
                newAnswer: ''
            });
        });
    }

    render() {

        if (this.state.list === undefined) {
            return <div/>;
        }

        const { languages, list, items } = this.state;
        const cuts = items
            .map(it => CrackListListDetails.getPossibleCuts(it.name).filter(c => c.length > 0).sort((cut1, cut2) => cut1.length - cut2.length));

        const maxCut = cuts.filter(c => c.length > 0).map(c => c[c.length - 1].length).reduce((c, val) => c + val, 0);
        const minCut = cuts.filter(c => c.length > 0).map(c => c[0].length).reduce((c, val) => c + val, 0);

        const fixedCuts = items
            .filter(it => it.name.indexOf(',') !== -1)
            .reduce((c, val) => val.name.split(',').filter(p => p.trim().length !== 0).length + c, 0);
        const notFixedCuts = items
            .filter(it => it.name.indexOf(',') === -1)
            .map(it => CrackListListDetails.getPossibleCuts(it.name).filter(c => c.length > 0).sort((cut1, cut2) => cut1.length - cut2.length));

        const maxNotFixedCut = notFixedCuts.filter(c => c.length > 0).map(c => c[c.length - 1].length).reduce((c, val) => c + val, 0);
        const minNotFixedCut = notFixedCuts.filter(c => c.length > 0).map(c => c[0].length).reduce((c, val) => c + val, 0);

        let tilesDescription = '';
        if (notFixedCuts.length === 0) {
            tilesDescription += `${fixedCuts}`;
        } else if (fixedCuts === 0) {
            tilesDescription += `${minCut}-${maxCut}`;
        } else {
            tilesDescription += `${fixedCuts + minNotFixedCut}-${fixedCuts + maxNotFixedCut}`;
        }
        tilesDescription += ' tuiles';
        if (fixedCuts > 0) {
            tilesDescription += ` (sans découpe : ${minCut}-${maxCut})`;
        }
        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">{!this.getListId() ? "Nouvelle liste" : `Détails de la liste (ID : ${this.getListId()})`}</Typography>
                        </div>
                        {
                            this.listConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingList}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingList}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    list && languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={list.language_id || -1}
                                                onChange={this.handleConfigValueChange('language_id')}
                                                label="Langue"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !list.language_id ? <MenuItem value={-1} disabled>Sélectionner une langue</MenuItem> : null
                                                }
                                                {
                                                    languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                                }
                                            </TextField>

                                            <TextField
                                                onChange={this.handleConfigValueChange('target_time', 'number')}
                                                value={list.target_time}
                                                color="primary"
                                                label="Temps (0 = automatique)"
                                                type="number"
                                                inputProps={{
                                                    style: {width: "200px"},
                                                    min: 0,
                                                    max: 500,
                                                    step: 5,
                                                }}
                                                style={{marginRight: 24}}
                                            />

                                            {
                                                !this.getListId() ? null :

                                                    <TextField
                                                        style={{marginRight: 12, width: 250, marginBottom: 24}}
                                                        select
                                                        value={list.level_id}
                                                        onChange={this.handleLevelAssociationChange}
                                                        label="Niveau"
                                                        SelectProps={{
                                                            displayEmpty: true
                                                        }}
                                                    >
                                                        <MenuItem value={-1}>Aucun</MenuItem>
                                                        {
                                                            this.state.levels.map((lvl, i) => <MenuItem
                                                                key={lvl.level_id}
                                                                value={lvl.level_id}>Niveau {lvl.level_index + 1}</MenuItem>)
                                                        }
                                                    </TextField>
                                            }

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={list.name}
                                                onChange={this.handleConfigValueChange('name')}
                                                label="Intitulé"
                                            />

                                        </div>
                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={list.name_short}
                                                onChange={this.handleConfigValueChange('name_short')}
                                                label="Intitulé court"
                                            />

                                        </div>
                                    </div>
                                </div>

                            </Paper>

                            {
                                !this.getListId() ? null :
                                    <React.Fragment>
                                        <Paper style={{marginBottom: 24, marginTop: 24}}>
                                            <Toolbar>
                                                <Typography style={{flex: 1}} variant="h6">Réponses - {tilesDescription}</Typography>
                                                <form onSubmit={this.commitEditingItems} name={"items"} id={"items"}>
                                                    {
                                                        fixedCuts === 0 ? null : (
                                                            <Button color="secondary" onClick={this.removeCustomCut}>Effacer le découpage</Button>
                                                        )
                                                    }
                                                    {

                                                        this.itemsArePristine() ? null : (
                                                            <React.Fragment>
                                                                <Button color="secondary" onClick={this.cancelEditingItems}>
                                                                    Annuler
                                                                </Button>
                                                                <Button type={"submit"} color="primary" onClick={this.commitEditingItems}>
                                                                    Enregistrer
                                                                </Button>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </form>
                                            </Toolbar>
                                        </Paper>
                                        <Paper>
                                            <Table size={"medium"}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell colSpan={4}>{ items.length === 0 ? null : "Cliquer sur un découpage pour l'appliquer au mot"}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        items.map((it, i) =>
                                                            <TableRow key={it.list_item_id}>
                                                                <TableCell style={{width: 50}}>{i + 1}.</TableCell>
                                                                <TableCell style={{width: 400}}>
                                                                    <TextField value={it.name}
                                                                               onChange={this.handleItemChange(it)}
                                                                               inputProps={{
                                                                                   form: `items`
                                                                               }}
                                                                               style={{width: 350}}
                                                                    /></TableCell>
                                                                <TableCell>
                                                                    <ul>
                                                                        {
                                                                            cuts[i].map((cut, cutIndex) =>
                                                                                <li className={"cl-cut"} onClick={this.applyCut(cut, i)} key={cutIndex} style={{listStyle: 'none', cursor: 'pointer'}}>
                                                                                    <strong style={{color: '#00A', marginRight: 20}}>{cut.length}</strong>
                                                                                    {cut.map((c, j) => <code key={j} style={{margin: '0 2px', padding: '2px 4px', borderRadius: 3, background: '#eee'}}>{c}</code>)}
                                                                                    <span>&nbsp;- appliquer</span>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </TableCell>
                                                                <TableCell style={{width: 30}}>
                                                                    <IconButton tabIndex={-1} onClick={this.deleteAnswer(i)}>
                                                                        <DeleteIcon style={{color: '#D00'}}/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    <TableRow>
                                                        <TableCell style={{width: 50}}>{items.length + 1}.</TableCell>
                                                        <TableCell style={{width: 250}}>
                                                            <form id={"addAnswer"} onSubmit={this.addAnswer}>
                                                                <TextField value={this.state.newAnswer} onChange={this.handleNewAnswerChange}/>
                                                            </form>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button key="add" color="primary" onClick={this.addAnswer}>
                                                                <Icon>add</Icon>
                                                                Ajouter la réponse
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell/>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>

                                        </Paper>
                                    </React.Fragment>
                            }

                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

CrackListListDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListListDetails.defaultProps = {

};

export default withRouter(CrackListListDetails);