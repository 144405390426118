import React from 'react';
import PropTypes from 'prop-types'
import {
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

class LanguagePicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            languages: this.props.languages,
            initialLanguages: this.props.initialLanguages,
            currentLanguages: LanguagePicker.getLanguagesData(props.languages, props.initialLanguages),
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.languages === props.languages) {
            return null;
        }
        return {
            ...state,
            languages: props.languages,
            initialLanguages: props.initialLanguages,
            currentLanguages: LanguagePicker.getLanguagesData(props.languages, props.initialLanguages)
        };
    }

    static getLanguagesData(languages, initialLanguages) {
        return languages.map(l => {
            return {
                data: l,
                checked: initialLanguages.findIndex(ll => ll === l.language_id) !== -1,
                pristine: true
            };
        });
    }

    onLanguageStateChange = (l) => (e, checked) => {
        let currentLanguages = Array.from(this.state.currentLanguages);
        l.checked = checked;
        l.pristine = !l.pristine;
        this.setState({
            currentLanguages: currentLanguages
        });
    };

    onSaveClick = (e) => {
        let currentLanguages = this.state.currentLanguages.filter(l => l.checked).map(l => l.data);
        this.props.onSave(currentLanguages);
    };

    onCancelClick = (e) => {
        this.setState({
            currentLanguages: LanguagePicker.getLanguagesData(this.state.languages, this.state.initialLanguages)
        });
        this.props.onCancel();
    };

    isPristine = () => {
        return this.state.currentLanguages.every(l => l.pristine);
    };

    render () {
        return (
            <div>
                {
                    this.props.title && this.props.title.length !== 0 ? (
                        <Toolbar>
                            <div style={{flex: 1}}>
                                <Typography variant="h6">{this.props.title}</Typography>
                            </div>

                        </Toolbar>
                    ) : null
                }

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: 250}}>Langue</TableCell>
                            <TableCell style={{width: 200}}>
                                {
                                    this.isPristine() ? null : (
                                        <React.Fragment>

                                            <IconButton color="secondary" onClick={this.onCancelClick}>
                                                <CancelIcon/>
                                            </IconButton>
                                            <IconButton color="primary" onClick={this.onSaveClick}>
                                                <CheckCircleIcon/>
                                            </IconButton>
                                        </React.Fragment>
                                    )
                                }
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.currentLanguages.map((language, i) => {
                            return (
                                <TableRow key={language.data.language_id}>
                                    <TableCell>
                                        <Typography>
                                            {language.data.name}
                                        </Typography>
                                        {language.data.localized_name === language.data.name ? null : (
                                            <Typography style={{color: '#aaa'}}>
                                                {language.data.localized_name}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell style={{textAlign: 'center'}}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={language.checked === true}
                                                    onChange={this.onLanguageStateChange(language)}
                                                    value={`checked_${language.data.language_id}`}
                                                />
                                            }
                                        />
                                    </TableCell>
                                    <TableCell/>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        );
    }
}


LanguagePicker.propTypes = {
    languages: PropTypes.array.isRequired,
    initialLanguages: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    title: PropTypes.string,
};

LanguagePicker.defaultProps = {
    onCancel: () => {},
};

export default LanguagePicker;
