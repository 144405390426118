import React from 'react';
import PropTypes from 'prop-types';
import api from "../services/api";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core';
import SaveIcon from "@material-ui/icons/Save"

class LabelEditor extends React.Component {

    static getLabelValue(label, languageId, defaultValue) {
        let labelValue = undefined;
        if (languageId !== undefined) {
            labelValue = label.languages.find(l => l.language_id === languageId);
        }
        if (!labelValue || !labelValue.value) {
            labelValue = label.languages.find(l => !!l.value && l.value.length !== 0);
        }
        if (!!labelValue) {
            return labelValue.value;
        }
        if (!!defaultValue) {
            return `<${defaultValue}>`;
        }
        return undefined;
    }

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            allLanguages: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            //languages: state.allLanguages.filter(l => props.languages.indexOf(l.language_id) !== -1),
            open: props.open,
        };
    }

    componentDidMount() {
        let requests = [
            api.getLanguages(),
        ];
        if (this.props.label === undefined) {
            requests.push(api.getLabel(this.props.labelId));
        }
        Promise.all(requests).then(res => {
            const languages = res[0];
            const label = requests.length === 2 ? res[1] : this.props.label;
            this.setState({
                allLanguages: languages,
                languages: languages.filter(l => this.props.languages.indexOf(l.language_id) !== -1),
                label: label,
            });
        });
    }

    getLabelValue(languageId) {
        const labelLanguage = this.state.label.languages.find(l => l.language_id === languageId);
        return !!labelLanguage ? labelLanguage.value : '';
    }

    onLabelValueChange = (languageId, i) => (e) => {
        let label = Object.assign({}, this.state.label);
        let labelLanguage = label.languages.find(l => l.language_id === languageId);
        if (labelLanguage === undefined) {
            labelLanguage = {
                language_id: languageId,
            };
            label.languages[i] = labelLanguage;
        }
        labelLanguage.value = e.target.value;
        this.setState({
            label: label,
        });
    };

    cancelEditing = () => {
        this.props.onClose(this.state.label);
    };

    commitEditing = (e) => {
        e.preventDefault();
        api.postLabel(this.props.labelId, this.state.label.languages.map(l => ({
            label_id: this.props.labelId,
            language_id: l.language_id,
            label: l.value,
        }))).then((label) => {
            this.props.onClose(label);
        });
    };

    render() {

        if (this.state.label === undefined) {
            return null;
        }

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <form noValidate autoComplete="off" onSubmit={this.commitEditing}>
                    <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                        </DialogContentText>
                        {
                            this.state.languages.map((language, i) => (
                                <TextField
                                    fullWidth
                                    key={language.language_id}
                                    label={language.name}
                                    value={this.getLabelValue(language.language_id) || ""}
                                    style={{margin: 4}}
                                    onChange={this.onLabelValueChange(language.language_id, i)}
                                />
                            ))
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelEditing} color="default">
                            Annuler
                        </Button>
                        <Button color="primary" type="submit">
                            <SaveIcon/>Enregistrer
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

LabelEditor.propTypes = {
    labelId: PropTypes.number.isRequired,
    label: PropTypes.object,
    open: PropTypes.bool.isRequired,
    languages: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default LabelEditor;