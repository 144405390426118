import React from 'react';
import PropTypes from 'prop-types';
import api from '../../services/api';
import {
    Button,
    ButtonBase,
    IconButton,
    MenuItem,
    Paper,
    Snackbar,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import KVC from "../../services/KVC";
import EditIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {withRouter} from "react-router-dom";
import CrackListListPicker from "../crack-list/CrackListListPicker";
import WoodysWordsDefinitionPicker from "./WoodysWordsDefinitionPicker";
import DeleteIcon from "@material-ui/icons/Delete";

class WoodysWordsLevelDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
            definitionPickerConfig: null,
            definitionPickerConfigKey: null,
            definitionPickerLanguageId: -1
        };
    }

    componentDidMount() {
        this.updateData();
    }

    getDefaultCustomConfig() {
        return {
            def_1: '',
            def_2: '',
            def_3: '',
            def_4: '',
            def_5: '',
            word_1: '',
            word_2: '',
            word_3: '',
            word_4: '',
            word_5: '',
            w1_def_id: null,
            w2_def_id: null,
            w3_def_id: null,
            w4_def_id: null,
            w5_def_id: null,
        }
    }

    updateData() {
        Promise.all([
            api.getAppLanguages(11),
            api.getWoodysWordsLevel(this.getLevelId())
        ]).then(results => {
            const languages = results[0];
            const level = results[1];

            let otherConfig = []
            let config = level.config || []
            languages.forEach(l => {
                if (!config.find(c => c.language_id === l.language_id)) {
                    let c = this.getDefaultCustomConfig()
                    c.language_id = l.language_id
                    c.level_id = level.level_id
                    otherConfig.push(c)
                }
            });
            config = config.concat(otherConfig)

            level.config = config

            this.setState({
                languages: languages,
                level: level,
                $level: JSON.parse(JSON.stringify(level)),
            });
        });
    }

    getLevelId() {
        return parseInt(this.props.match.params.levelId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/levels/`);
            return;
        }
        this.props.history.goBack();
    };

    levelConfigIsPristine = () => {
        return JSON.stringify(this.state.level) === JSON.stringify(this.state.$level);
    };

    handleConfigValueChange = (propNamePath) => (e) => {
        this.handleConfigChange(propNamePath, e.target.value);
    };

    handleCustomConfigValueChange = (languageId, propNamePath) => (e) => {
        this.handleCustomConfigChange(languageId, propNamePath, e.target.value);
    };

    handleConfigChange = (propNamePath, value) => {
        let level = Object.assign({}, this.state.level);
        KVC(level).setValueForKeyPath(propNamePath, value);
        this.setState({
            level: level,
        });
    };

    handleCustomConfigChange = (languageId, propNamePath, value) => {
        let level = JSON.parse(JSON.stringify(this.state.level));
        level.config.forEach(c => {
            if (c.language_id !== languageId) {
                return;
            }
            KVC(c).setValueForKeyPath(propNamePath, value);
        })
        this.setState({
            level: level,
            definitionPickerConfig: null,
            definitionPickerConfigKey: null,
            definitionPickerLanguageId: -1
        });
    };

    cancelEditingLevel = () => {
        this.setState({
            level: JSON.parse(JSON.stringify(this.state.$level)),
        });
    };

    commitEditingLevel = () => {
        const levelId = this.getLevelId();
        api.patchWoodysWordsLevel(levelId, this.state.level).then(res => {
            api.patchWoodysWordsLevelConfig(this.state.level.config).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement du niveau.");
                    return;
                }
                this.displaySnackbar("Niveau sauvegardé.");
            });
        });
    };

    onDefinitionPickerStart = (config, key) => (e) => {
        this.setState({
            definitionPickerConfig: config,
            definitionPickerConfigKey: key,
            definitionPickerLanguageId: config.language_id,
        })
    };

    onDefinitionPickerClose = () => (e) => {
        this.setState({
            definitionPickerConfig: null,
            definitionPickerConfigKey: null,
            definitionPickerLanguageId: -1,
        })
    };

    deleteDefinitionAssociation = (languageId, key) => (e) => {
        let level = JSON.parse(JSON.stringify(this.state.level));
        level.config.forEach(c => {
            if (c.language_id !== languageId) {
                return;
            }
            c[key] = null
        })
        this.setState({
            level: level,
        });
    }

    onDefinitionPicked = () => (definition) => {
        let level = JSON.parse(JSON.stringify(this.state.level));
        level.config.forEach(c => {
            if (c.language_id !== this.state.definitionPickerLanguageId) {
                return;
            }
            c[this.state.definitionPickerConfigKey] = definition.definition_id
            switch (this.state.definitionPickerConfigKey) {
                case 'w1_def_id':
                    c.def1_name = definition.name
                    break
                case 'w2_def_id':
                    c.def2_name = definition.name
                    break
                case 'w3_def_id':
                    c.def3_name = definition.name
                    break
                case 'w4_def_id':
                    c.def4_name = definition.name
                    break
                case 'w5_def_id':
                    c.def5_name = definition.name
                    break
            }
        })

        this.setState({
            level: level,
            definitionPickerConfig: null,
            definitionPickerConfigKey: null,
            definitionPickerLanguageId: -1
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (this.state.level === undefined) {
            return <div/>;
        }

        const { level, languages } = this.state;

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails du niveau {level.level_index + 1}</Typography>
                        </div>
                        {
                            this.levelConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingLevel}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingLevel}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>



                {
                    level && languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>

                                <div style={{marginBottom: 50}}>
                                    <div style={{marginBottom: 24, color: 'gray'}}>
                                        Vies : -1 = Illimitées / Jokers : -1 = Désactivé
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('life_count', 'number')}
                                            label={"Vies"}
                                            value={level.life_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('abc_joker_count')}
                                            label={"Jokers ABC"}
                                            value={level.abc_joker_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('later_joker_count')}
                                            label={"Jokers Plus tard"}
                                            value={level.later_joker_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('red_letter_count')}
                                            label={"Lettres magiques"}
                                            value={level.red_letter_count}
                                            color="primary"
                                            type="number"
                                        />
                                    </div>

                                </div>

                                <div style={{marginBottom: 24, display: 'flex'}}>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w1_count')}
                                            value={level.w1_count}
                                            color="primary"
                                            label="Longueur mot 1"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,
                                            }}
                                        />
                                    </div>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w2_count')}
                                            value={level.w2_count}
                                            color="primary"
                                            label="Longueur mot 2"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,
                                            }}
                                        />
                                    </div>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w3_count')}
                                            value={level.w3_count}
                                            color="primary"
                                            label="Longueur mot 3"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,

                                            }}
                                        />
                                    </div>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w4_count')}
                                            value={level.w4_count}
                                            color="primary"
                                            label="Longueur mot 4"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,

                                            }}
                                        />
                                    </div>
                                </div>

                                <div style={{marginBottom: 24, display: 'flex'}}>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w1_initial_count')}
                                            value={level.w1_initial_count}
                                            color="primary"
                                            label="Départ mot 1"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,
                                            }}
                                        />
                                    </div>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w2_initial_count')}
                                            value={level.w2_initial_count}
                                            color="primary"
                                            label="Départ mot 2"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,
                                            }}
                                        />
                                    </div>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w3_initial_count')}
                                            value={level.w3_initial_count}
                                            color="primary"
                                            label="Départ mot 3"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,

                                            }}
                                        />
                                    </div>
                                    <div style={{marginBottom: 24, marginRight: 24}}>
                                        <TextField
                                            onChange={this.handleConfigValueChange('w4_initial_count')}
                                            value={level.w4_initial_count}
                                            color="primary"
                                            label="Départ mot 4"
                                            type="number"
                                            inputProps={{
                                                form: `level-${level.level_id}`,

                                            }}
                                        />
                                    </div>
                                </div>

                                <div style={{marginBottom: 24}}>
                                    <TextField
                                        style={{marginRight: 12, width: 350}}
                                        select
                                        value={level.config_type}
                                        onChange={this.handleConfigValueChange("config_type")}
                                        label="Configuration"
                                        SelectProps={{
                                            displayEmpty: true
                                        }}
                                    >
                                        <MenuItem value="default">Par défaut (nombre de lettres)</MenuItem>
                                        <MenuItem value="definitions">Choisir les définitions</MenuItem>
                                        <MenuItem value="custom">Personnalisé (choisir définitions et mots)</MenuItem>
                                    </TextField>
                                </div>

                                {
                                    level.config_type === "definitions" ?

                                            <React.Fragment>
                                                <WoodysWordsDefinitionPicker
                                                    open={!!this.state.definitionPickerConfig}
                                                    languageId={this.state.definitionPickerLanguageId}
                                                    onClose={this.onDefinitionPickerClose()}
                                                    onPick={this.onDefinitionPicked()}
                                                />

                                                {
                                                    languages.map(lang => {
                                                        let config = level.config.find(c => c.language_id === lang.language_id)
                                                        return (
                                                            <div key={lang.language_id} style={{borderRadius: 16, backgroundColor: '#f8f8f8', padding: '12px 24px 12px 24px', marginBottom: 24}}>
                                                                <h3>{lang.name}</h3>

                                                                <div style={{marginBottom: 4}}>
                                                                    Mot 1 - { !config.w1_def_id ? null : <strong>{config.def1_name}</strong> }
                                                                    <Button color={"primary"} onClick={this.onDefinitionPickerStart(config, 'w1_def_id')}>Choisir une definition</Button>
                                                                    { !config.w1_def_id ? null : <IconButton tabIndex={-1} onClick={this.deleteDefinitionAssociation(lang.language_id, 'w1_def_id')}>
                                                                        <DeleteIcon style={{color: '#D00'}}/>
                                                                    </IconButton> }
                                                                </div>

                                                                <div style={{marginBottom: 4}} hidden={!config.w1_def_id}>
                                                                    Mot 2 - { !config.w2_def_id ? null : <strong>{config.def2_name}</strong> }
                                                                    <Button color={"primary"} onClick={this.onDefinitionPickerStart(config, 'w2_def_id')}>Choisir une definition</Button>
                                                                    { !config.w2_def_id ? null : <IconButton tabIndex={-1} onClick={this.deleteDefinitionAssociation(lang.language_id, 'w2_def_id')}>
                                                                        <DeleteIcon style={{color: '#D00'}}/>
                                                                    </IconButton> }
                                                                </div>

                                                                <div style={{marginBottom: 4}} hidden={!config.w2_def_id || !config.w1_def_id }>
                                                                    Mot 3 - { !config.w3_def_id ? null : <strong>{config.def3_name}</strong> }
                                                                    <Button color={"primary"} onClick={this.onDefinitionPickerStart(config, 'w3_def_id')}>Choisir une definition</Button>
                                                                    { !config.w3_def_id ? null : <IconButton tabIndex={-1} onClick={this.deleteDefinitionAssociation(lang.language_id, 'w3_def_id')}>
                                                                        <DeleteIcon style={{color: '#D00'}}/>
                                                                    </IconButton> }
                                                                </div>

                                                                <div style={{marginBottom: 4}} hidden={!config.w3_def_id || !config.w2_def_id || !config.w1_def_id }>
                                                                    Mot 4 - { !config.w4_def_id ? null : <strong>{config.def4_name}</strong> }
                                                                    <Button color={"primary"} onClick={this.onDefinitionPickerStart(config, 'w4_def_id')}>Choisir une definition</Button>
                                                                    { !config.w4_def_id ? null : <IconButton tabIndex={-1} onClick={this.deleteDefinitionAssociation(lang.language_id, 'w4_def_id')}>
                                                                        <DeleteIcon style={{color: '#D00'}}/>
                                                                    </IconButton> }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>

                                        :
                                        (
                                            level.config_type === "custom" ?
                                                (
                                                    languages.map(lang => {
                                                        let config = level.config.find(c => c.language_id === lang.language_id)
                                                        return (
                                                            <div key={lang.language_id} style={{borderRadius: 16, backgroundColor: '#f8f8f8', padding: '12px 24px 12px 24px', marginBottom: 24}}>
                                                                <h3>{lang.name}</h3>
                                                                <div style={{marginBottom: 24}}>
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_1')}
                                                                        value={config.def_1}
                                                                        color="primary"
                                                                        label="Définition 1"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                        style={{
                                                                            marginRight: 24,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_1')}
                                                                        value={config.word_1}
                                                                        color="primary"
                                                                        label="Mot 1"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{marginBottom: 24}} hidden={!config.def_1 || config.def_1.length === 0}>
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_2')}
                                                                        value={config.def_2}
                                                                        color="primary"
                                                                        label="Définition 2"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                        style={{
                                                                            marginRight: 24,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_2')}
                                                                        value={config.word_2}
                                                                        color="primary"
                                                                        label="Mot 2"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{marginBottom: 24}} hidden={!config.def_2 || config.def_2.length === 0}>
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_3')}
                                                                        value={config.def_3}
                                                                        color="primary"
                                                                        label="Définition 3"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                        style={{
                                                                            marginRight: 24,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_3')}
                                                                        value={config.word_3}
                                                                        color="primary"
                                                                        label="Mot 3"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{marginBottom: 24}} hidden={!config.def_3 || config.def_3.length === 0}>
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'def_4')}
                                                                        value={config.def_4}
                                                                        color="primary"
                                                                        label="Définition 4"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                        style={{
                                                                            marginRight: 24,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        onChange={this.handleCustomConfigValueChange(lang.language_id, 'word_4')}
                                                                        value={config.word_4}
                                                                        color="primary"
                                                                        label="Mot 4"
                                                                        inputProps={{
                                                                            form: `level-custom-${lang.language_id}`,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                )
                                                :
                                                null
                                        )
                                }

                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                    </div>
                                </div>

                            </Paper>

                        </React.Fragment>

                    ) : null
                }

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

WoodysWordsLevelDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

WoodysWordsLevelDetails.defaultProps = {

};

export default withRouter(WoodysWordsLevelDetails);