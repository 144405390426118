import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, ButtonBase, MenuItem,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import LabelEditor from "../LabelEditor";
import {withRouter} from "react-router-dom";
import CrackListPartyOrderedPack from "./CrackListPartyOrderedPack";
import CrackListPartyOrderedList from "./CrackListPartyOrderedList";
import CrackListPartyOrderedPenalty from "./CrackListOrderedPenalty";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PackCard from "../PackCard";

class CrackListPartyDiscovery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            boxLists: [],
            languages: [],
            penalties: [],
            boxPenalties: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
            orderedLists: [],
            orderedPenalties: [],
            orderedBoxLists: [],
            orderedBoxPenalties: [],
            isDraggingList: false,
            isDraggingPenalty: false,
            isDraggingBoxList: false,
            isDraggingBoxPenalty: false,
            languageId: 1,
            showLabelEditor: false,
            label: null,
        };
    }

    componentDidMount() {
        this.updateData(this.state.languageId);
    }

    updateData(languageId) {
        Promise.all([
            api.getAppLanguages(this.props.app.app_id),
            api.getCrackListPartyDiscoveryLists(languageId),
            api.getCrackListPartyDiscoveryBoxLists(languageId),
            api.getCrackListPartyDiscoveryPenalties(languageId),
            api.getCrackListPartyDiscoveryBoxPenalties(languageId),
            api.getLabel(4847)
        ]).then(res => {
            const languages = res[0];
            const lists = res[1];
            const boxLists = res[2];
            const penalties = res[3];
            const boxPenalties = res[4];
            const label = res[5]
            this.setState({
                languages: languages,
                label: label,
                lists: lists,
                boxLists: boxLists,
                penalties: penalties,
                boxPenalties: boxPenalties,
                orderedLists: lists.slice().sort((l1, l2) => { return (l1.discovery_index > l2.discovery_index) ? 1 : -1 }),
                orderedBoxLists: boxLists.slice().sort((l1, l2) => { return (l1.discovery_box_index > l2.discovery_box_index) ? 1 : -1 }),
                orderedPenalties: penalties.slice().sort((p1, p2) => { return (p1.discovery_index > p2.discovery_index) ? 1 : -1 }),
                orderedBoxPenalties: boxPenalties.slice().sort((p1, p2) => { return (p1.discovery_box_index > p2.discovery_box_index) ? 1 : -1 }),
            });
        });
    }

    handleLanguageChange = (e) => {
        this.setState({languageId: e.target.value});
        this.updateData(e.target.value);
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    isListsOrderPristine() {
        return this.state.orderedLists.every((l, i) => l.discovery_index === i);
    }

    isBoxListsOrderPristine() {
        return this.state.orderedBoxLists.every((l, i) => l.discovery_box_index === i);
    }

    isPenaltiesOrderPristine() {
        return this.state.orderedPenalties.every((p, i) => p.discovery_index === i);
    }

    isBoxPenaltiesOrderPristine() {
        return this.state.orderedBoxPenalties.every((p, i) => p.discovery_box_index === i);
    }

    onListMove = (sourceIndex, targetIndex) => {
        let lists = Array.from(this.state.orderedLists);
        let list = lists[sourceIndex];
        lists.splice(sourceIndex, 1);
        lists.splice(targetIndex, 0, list);
        this.setState({
            orderedLists: lists
        });
    };

    onBoxListMove = (sourceIndex, targetIndex) => {
        let boxLists = Array.from(this.state.orderedBoxLists);
        let list = boxLists[sourceIndex];
        boxLists.splice(sourceIndex, 1);
        boxLists.splice(targetIndex, 0, list);
        this.setState({
            orderedBoxLists: boxLists
        });
    };

    onPenaltyMove = (sourceIndex, targetIndex) => {
        let penalties = Array.from(this.state.orderedPenalties);
        let penalty = penalties[sourceIndex];
        penalties.splice(sourceIndex, 1);
        penalties.splice(targetIndex, 0, penalty);
        this.setState({
            orderedPenalties: penalties
        });
    };

    onBoxPenaltyMove = (sourceIndex, targetIndex) => {
        let boxPenalties = Array.from(this.state.orderedBoxPenalties);
        let penalty = boxPenalties[sourceIndex];
        boxPenalties.splice(sourceIndex, 1);
        boxPenalties.splice(targetIndex, 0, penalty);
        this.setState({
            orderedBoxPenalties: boxPenalties
        });
    };

    onListDragBegin = () => {
        this.setState({
            isDraggingList: true,
        });
    };

    onBoxListDragBegin = () => {
        this.setState({
            isDraggingBoxList: true,
        });
    };

    onPenaltyDragBegin = () => {
        this.setState({
            isDraggingPenalty: true,
        });
    };

    onBoxPenaltyDragBegin = () => {
        this.setState({
            isDraggingBoxPenalty: true,
        });
    };

    onListDragEnd = () => {
        this.setState({
            isDraggingList: false,
        });
    };

    onBoxListDragEnd = () => {
        this.setState({
            isDraggingBoxList: false,
        });
    };

    onPenaltyDragEnd = () => {
        this.setState({
            isDraggingPenalty: false,
        });
    };

    onBoxPenaltyDragEnd = () => {
        this.setState({
            isDraggingBoxPenalty: false,
        });
    };

    cancelListOrder = () => {
        this.setState({
            orderedLists: this.state.lists.slice().sort((l1, l2) => { return (l1.discovery_index > l2.discovery_index) ? 1 : -1 }),
        });
    };

    cancelBoxListOrder = () => {
        this.setState({
            orderedBoxLists: this.state.boxLists.slice().sort((l1, l2) => { return (l1.discovery_box_index > l2.discovery_box_index) ? 1 : -1 }),
        });
    };

    cancelPenaltyOrder = () => {
        this.setState({
            orderedPenalties: this.state.penalties.slice().sort((p1, p2) => { return (p1.discovery_index > p2.discovery_index) ? 1 : -1 }),
        });
    };

    cancelBoxPenaltyOrder = () => {
        this.setState({
            orderedBoxPenalties: this.state.boxPenalties.slice().sort((p1, p2) => { return (p1.discovery_box_index > p2.discovery_box_index) ? 1 : -1 }),
        });
    };

    _commitListOrder(lists, indexKey) {
        const finalLists = lists.map((list, i) => ({
            ...list,
            [indexKey]: i,
        }));
        console.log(finalLists)
        api.patchCrackListPartyLists(finalLists).then(lists => {
            if (lists === undefined) {
                this.displaySnackbar('Erreur lors de l\'enregistrement de l\'ordre des listes "Découverte".');
                return;
            }
            this.displaySnackbar('Ordre des listes "Découverte" enregistré avec succès.');
            this.updateData(this.state.languageId);
        });
    };

    commitListOrder = () => {
        this._commitListOrder(this.state.orderedLists, 'discovery_index');
    };

    commitBoxListOrder = () => {
        this._commitListOrder(this.state.orderedBoxLists, 'discovery_box_index');
    };

    _commitPenaltyOrder(penalties, indexKey) {
        api.patchCrackListPartyPenalties(penalties.map((penalty, i) => ({
            ...penalty,
            [indexKey]: i,
        }))).then(packs => {
            if (packs === undefined) {
                this.displaySnackbar('Erreur lors de l\'enregistrement de l\'ordre des gages "Découverte".');
                return;
            }
            this.displaySnackbar('Ordre des gages "Découverte" enregistré avec succès.');
            this.updateData(this.state.languageId);
        });
    };

    commitPenaltyOrder = () => {
        this._commitPenaltyOrder(this.state.orderedPenalties, 'discovery_index');
    };

    commitBoxPenaltyOrder = () => {
        this._commitPenaltyOrder(this.state.orderedBoxPenalties, 'discovery_box_index');
    };

    onLabelEditingFinished = (label) => {
        this.setState({
            showLabelEditor: false,
        });
    };

    render() {

        const state = this.state

        return (
            <React.Fragment>

                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Pack Découverte</Typography>
                        </div>
                    </Toolbar>

                    { !!this.state.label ?
                        <div style={{padding: 24}}>
                            Modifier le nom<br/>
                            <ButtonBase
                                focusRipple
                                onClick={(e) => this.setState({showLabelEditor: true})}
                                style={{fontSize:'1rem', padding: '4px 4px 4px 0', color: '#2196f3', marginTop: 12}}
                            >
                                {LabelEditor.getLabelValue(this.state.label, 1)}<EditIcon style={{marginLeft: 4}}/>
                            </ButtonBase>
                            {state.label.json}
                            <LabelEditor
                                label={this.state.label}
                                labelId={this.state.label.label_id}
                                open={this.state.showLabelEditor}
                                languages={this.state.languages.map(l => l.language_id)}
                                onClose={this.onLabelEditingFinished}
                                title="Nom du pack découverte"
                            />
                        </div> : null
                    }

                    <div style={{padding: 24}}>

                        <div style={{marginBottom: 24}}>

                            <TextField
                                style={{marginRight: 12, width: 250}}
                                select
                                value={this.state.languageId}
                                onChange={this.handleLanguageChange}
                                label="Langue"
                                SelectProps={{
                                    displayEmpty: true
                                }}
                            >
                                {
                                    this.state.languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                }
                            </TextField>

                        </div>

                    </div>
                </Paper>

                <div style={{display: 'flex', gap: 24, marginTop: 24}}>

                    <Paper>
                        <Toolbar>
                            <div>
                                <div style={{flex: 1}}>
                                    <Typography variant="h6">Listes Découverte</Typography>
                                    Glisser les listes dans l'ordre désiré
                                </div>
                            </div>

                        </Toolbar>

                        <div style={{padding: '0 24px'}}>
                            {this.state.orderedLists.map((list, i) => (
                                <CrackListPartyOrderedList
                                    key={list.list_id}
                                    list={list}
                                    index={i}
                                    draggable={true}
                                    onMove={this.onListMove}
                                    onDragBegin={this.onListDragBegin}
                                    onDragEnd={this.onListDragEnd}
                                    discoveryIndex={list.discovery_index}
                                />
                            ))}
                        </div>

                        <div style={{padding: '24px'}}>
                            {
                                (this.state.isDraggingList || this.isListsOrderPristine()) ? null : (
                                    <React.Fragment>
                                        <Button color="secondary" onClick={this.cancelListOrder}>
                                            Annuler
                                        </Button>
                                        <Button color="primary" onClick={this.commitListOrder}>
                                            Enregistrer
                                        </Button>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </Paper>

                    <Paper>
                        <Toolbar>
                            <div>
                                <div style={{flex: 1}}>
                                    <Typography variant="h6">Gages Découverte</Typography>
                                    Glisser les gages dans l'ordre désiré
                                </div>
                            </div>

                        </Toolbar>

                        <div style={{padding: '0 24px'}}>
                            {this.state.orderedPenalties.map((penalty, i) => (
                                <CrackListPartyOrderedPenalty
                                    key={penalty.penalty_id}
                                    penalty={penalty}
                                    index={i}
                                    draggable={true}
                                    onMove={this.onPenaltyMove}
                                    onDragBegin={this.onPenaltyDragBegin}
                                    onDragEnd={this.onPenaltyDragEnd}
                                    discoveryIndex={penalty.discovery_index}
                                />
                            ))}
                        </div>

                        <div style={{padding: '24px'}}>
                            {
                                (this.state.isDraggingPenalty || this.isPenaltiesOrderPristine()) ? null : (
                                    <React.Fragment>
                                        <Button color="secondary" onClick={this.cancelPenaltyOrder}>
                                            Annuler
                                        </Button>
                                        <Button color="primary" onClick={this.commitPenaltyOrder}>
                                            Enregistrer
                                        </Button>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </Paper>

                </div>


                <div style={{display: 'flex', gap: 24, marginTop: 24}}>

                    <Paper>
                        <Toolbar>
                            <div>
                                <div style={{flex: 1}}>
                                    <Typography variant="h6">Listes Découverte Boîte de jeu</Typography>
                                    Glisser les listes dans l'ordre désiré
                                </div>
                            </div>

                        </Toolbar>

                        <div style={{padding: '0 24px'}}>
                            {this.state.orderedBoxLists.map((list, i) => (
                                <CrackListPartyOrderedList
                                    key={list.list_id}
                                    list={list}
                                    index={i}
                                    draggable={true}
                                    onMove={this.onBoxListMove}
                                    onDragBegin={this.onBoxListDragBegin}
                                    onDragEnd={this.onBoxListDragEnd}
                                    discoveryIndex={list.discovery_box_index}
                                />
                            ))}
                        </div>

                        <div style={{padding: '24px'}}>
                            {
                                (this.state.isDraggingBoxList || this.isBoxListsOrderPristine()) ? null : (
                                    <React.Fragment>
                                        <Button color="secondary" onClick={this.cancelBoxListOrder}>
                                            Annuler
                                        </Button>
                                        <Button color="primary" onClick={this.commitBoxListOrder}>
                                            Enregistrer
                                        </Button>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </Paper>


                </div>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

CrackListPartyDiscovery.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListPartyDiscovery.defaultProps = {};

export default withRouter(CrackListPartyDiscovery);

/*
                    <Paper>
                        <Toolbar>
                            <div>
                                <div style={{flex: 1}}>
                                    <Typography variant="h6">Gages Découverte Boîte de jeu</Typography>
                                    Glisser les gages dans l'ordre désiré
                                </div>
                            </div>

                        </Toolbar>

                        <div style={{padding: '0 24px'}}>
                            {this.state.orderedBoxPenalties.map((penalty, i) => (
                                <CrackListPartyOrderedPenalty
                                    penalty={penalty}
                                    index={i}
                                    draggable={true}
                                    onMove={this.onBoxPenaltyMove}
                                    onDragBegin={this.onBoxPenaltyDragBegin}
                                    onDragEnd={this.onBoxPenaltyDragEnd}
                                    discoveryIndex={penalty.discovery_box_index}
                                />
                            ))}
                        </div>

                        <div style={{padding: '24px'}}>
                            {
                                (this.state.isDraggingBoxPenalty || this.isBoxPenaltiesOrderPristine()) ? null : (
                                    <React.Fragment>
                                        <Button color="secondary" onClick={this.cancelBoxPenaltyOrder}>
                                            Annuler
                                        </Button>
                                        <Button color="primary" onClick={this.commitBoxPenaltyOrder}>
                                            Enregistrer
                                        </Button>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </Paper>

 */