import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import api from '../services/api'

class EditImageDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            image: props.image
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            open: props.open
        };
    }

    resetImage() {
        this.setState({
            image: this.props.image
        });
    }

    onImageEditAccept = (e) => {
        e.preventDefault();
        api.postImage(this.state.image.image_id, this.state.image).then(image => {
            this.props.onImageEdited(image);
        });
    };

    onImageEditClose = (e) => {
        this.resetImage();
        this.props.onImageEdited(this.state.image);
    };

    onImageNameChange = (e) => {
        let image = {...this.state.image};
        image.name = e.target.value;
        this.setState({
            image: image
        });
    };

    onImageSentByChange = (e) => {
        let image = {...this.state.image};
        image.sent_by = e.target.value;
        this.setState({
            image: image
        });
    };

    onImagePromotionalChange = (e, checked) => {
        let image = {...this.state.image};
        image.promotional = checked ? 1 : 0;
        this.setState({
            image: image
        });
    };

    render () {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.onImageEditClose}
                aria-labelledby="confirmation-dialog-title"
            >
                <form noValidate autoComplete="off" onSubmit={this.onImageEditAccept}>
                    <DialogTitle id="confirmation-dialog-title">Modifier l'image</DialogTitle>
                    <DialogContent>
                        <img src={this.props.image.thumbnail_url} alt="" width="100%" style={{maxHeight: '250px', objectFit: 'contain'}} />
                        <FormGroup>
                            <TextField
                                autoFocus
                                margin="normal"
                                id="name"
                                label="Description"
                                type="text"
                                fullWidth
                                value={this.state.image.name}
                                onChange={this.onImageNameChange}
                            />
                        </FormGroup>
                        <br/>
                        <FormGroup>
                            <TextField
                                margin="normal"
                                id="sent_by"
                                label="Envoyée par…"
                                type="text"
                                fullWidth
                                value={this.state.image.sent_by}
                                onChange={this.onImageSentByChange}
                            />
                        </FormGroup>
                        <br/>
                        <FormGroup>
                            <DialogContentText>
                                Marquer l'image comme promotionnelle l'empêchera d'apparaître dans les builds à destination des joueurs.
                            </DialogContentText>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.image.promotional === 1}
                                        onChange={this.onImagePromotionalChange}
                                        value="1"
                                        color="primary"
                                    />
                                }
                                label="Image promotionnelle"
                            />
                        </FormGroup>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onImageEditClose} color="default">
                            Annuler
                        </Button>
                        <Button color="primary" type="submit">
                            <SaveIcon/>Enregistrer
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

export default EditImageDialog;