import React from "react";
import Link from "@material-ui/core/Link";
import {Button} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

class API {

    constructor(base, options) {
        this.base = base;
        this.options = options;
    }

    getJSON(path) {
        return fetch(this.base + path, {
            headers: {
                'Bearer': this.options.authToken,
            },
        }).then(response => response.json()).catch(error => console.error(error));
    }

    postJSON(path, body) {
        return this.sendJSON('POST', path, body);
    }

    patchJSON(path, body) {
        return this.sendJSON('PATCH', path, body);
    }

    deleteJSON(path, body) {
        return this.sendJSON('DELETE', path, body);
    }

    putJSON(path, body) {
        return this.sendJSON('PUT', path, body);
    }

    sendJSON(method, path, body) {
        return fetch(this.base + path, {
            method: method,
            headers: {
                'Content-type': 'application/json',
                'Bearer': this.options.authToken,
            },
            body: JSON.stringify(body)
        }).then(response => response.json()).catch(error => console.log(error));
    }

    routes = {
        apps: '/apps',
        languages: '/languages',
        images: '/images',
        imageUpload: '/image/upload',
        appLanguages: (appId) => `/app/${appId}/languages`,
        appImages: (appId) => `/app/${appId}/images`,
        image: (imageId) => `/image/${imageId}`,
        imagesUnusedInApp: (appId) => `/images/app/${appId}/unused`,
        appImage: (appId, imageId) => `/app/${appId}/image/${imageId}`,
        appImageLanguages: (appId, imageId) => `/app/${appId}/image/${imageId}/languages`,
        appPacks: (appId) => `/app/${appId}/packs`,
        appPack: (appId) => `/app/${appId}/pack`,
        pack: (packId) => `/pack/${packId}`,
        packImages: (packId) => `/pack/${packId}/images`,
        packUnusedImages: (packId) => `/pack/${packId}/images/unused`,
        packLanguages: (packId) => `/pack/${packId}/languages`,
        label: (labelId) => `/label/${labelId}`,
        inAppPurchase: (appId, platform) => `/app/${appId}/iap/${platform}`,
        appDataVersions: (appId) => `/app/${appId}/data_versions`,
        appDataPreview: (appId) => `/app/${appId}/data_preview`,
        appDataVersionPublish: (appId, version) => `/app/${appId}/data_versions/${version}/publish`,
        appCouponCodes: (appId) => `/app/${appId}/coupon_codes`,
        couponCodes: '/coupon_codes',
        appUnlockCodes: (appId) => `/app/${appId}/unlock_codes`,
        unlockCodes: '/unlock_codes',
        appStoreProducts: (appId) => `/app/${appId}/store-products`,
        appStoreProduct: (appId) => `/app/${appId}/store-product`,
        storeProduct: (productId) => `/store-product/${productId}`,
        generateCrosswordsGrid: '/crosswords-grid/generate',

        wordsOutLevels: '/words-out/levels',
        createWordsOutLevel: "/words-out/level",
        wordsOutLevel: (levelId) => `/words-out/level/${levelId}`,
        wordsOutLevelGroups: '/words-out/groups',
        createWordsOutLevelGroup: "/words-out/group",
        wordsOutLevelGroup: (groupId) => `/words-out/group/${groupId}`,
        wordsOutImages: '/words-out/images',
        wordsOutNextChallenges: '/words-out/next-challenges',
        wordsOutChallenge: (challengeId) => `/words-out/challenge/${challengeId}`,
        wordsOutGenMonthChallenges: (uuid) => `/words-out/month/${uuid}/gen-challenges`,
        wordsOutMonthChallenges: (uuid) => `/words-out/month/${uuid}`,
        wordsOutTournament: (tournamentId) => `/words-out/tournament/${tournamentId}`,
        wordsOutTournaments: '/words-out/tournaments',
        createWordsOutTournament: '/words-out/tournament',

        crackListLists: (languageId) => `/crack-list/language/${languageId}/lists`,
        crackListList: (listId) => `/crack-list/list/${listId}`,
        createCrackListList: '/crack-list/list',
        crackListListItems: (listId) => `/crack-list/list/${listId}/items`,
        crackListListItem: '/crack-list/list-item',
        crackListLevels: '/crack-list/levels',
        createCrackListLevel: "/crack-list/level",
        crackListLevel: (levelId) => `/crack-list/level/${levelId}`,
        crackListLevelSpecifics: '/crack-list/level-specifics',
        crackListLevelGroups: '/crack-list/groups',
        createCrackListLevelGroup: "/crack-list/group",
        crackListLevelGroup: (groupId) => `/crack-list/group/${groupId}`,
        crackListDuplicateList: (listId, languageId) => `/crack-list/duplicate-list/${listId}/lang/${languageId}`,
        crackListTournament: (tournamentId) => `/crack-list/tournament/${tournamentId}`,
        crackListTournaments: '/crack-list/tournaments',
        createCrackListTournament: '/crack-list/tournament',

        crackListPartyCategories: (languageId) => `/crack-list/party/categories/${languageId}`,
        crackListPartyPacks: '/crack-list/party/packs',
        crackListPartyPack: (packId) => `/crack-list/party/pack/${packId}`,
        crackListPartyLists: (languageId) => `/crack-list/party/lists/${languageId}`,
        crackListPartyList: (listId) => `/crack-list/party/list/${listId}`,
        createCrackListPartyList: '/crack-list/party/list',
        createCrackListPartyPack: '/crack-list/party/pack',
        crackListPartyPackMix : '/crack-list/party/pack-mix',
        crackListPartyPenaltyCategories: (languageId) => `/crack-list/party/penalty-categories/${languageId}`,
        crackListPartyPenalties: (languageId) => `/crack-list/party/penalties/${languageId}`,
        crackListPartyPenalty: (penaltyId) => `/crack-list/party/penalty/${penaltyId}`,
        createCrackListPartyPenalty: '/crack-list/party/penalty',
        crackListPartyPackPenaltyMix : '/crack-list/party/pack-penalty-mix',
        crackListPartyDiscoveryLists: (languageId) => `/crack-list/party/discovery/lists/${languageId}`,
        crackListPartyDiscoveryBoxLists: (languageId) => `/crack-list/party/discovery/box-lists/${languageId}`,
        crackListPartyDiscoveryPenalties: (languageId) => `/crack-list/party/discovery/penalties/${languageId}`,
        crackListPartyDiscoveryBoxPenalties: (languageId) => `/crack-list/party/discovery/box-penalties/${languageId}`,
        updateCrackListPartyLists: '/crack-list/party/lists',
        updateCrackListPartyPenalties: '/crack-list/party/penalties',

        woodysWordsLevels: '/woodys-words/levels',
        createWoodysWordsLevel: "/woodys-words/level",
        woodysWordsLevel: (levelId) => `/woodys-words/level/${levelId}`,
        woodysWordsLevelConfig: `/woodys-words/level-config`,
        woodysWordsLevelGroups: '/woodys-words/groups',
        createWoodysWordsLevelGroup: "/woodys-words/group",
        woodysWordsLevelGroup: (groupId) => `/woodys-words/group/${groupId}`,
        woodysWordsDefinitionsByLanguage: (languageId) => `/woodys-words/language/${languageId}/definitions`,
        createWoodysWordsDefinition: `/woodys-words/definition`,
        woodysWordsDefinition: (definitionId) => `/woodys-words/definition/${definitionId}`,
        woodysWordsDefinitionItems: (definitionId) => `/woodys-words/definition/${definitionId}/items`,
        woodysWordsDefinitionItem: '/woodys-words/definition-item',
        woodysWordsImages: '/woodys-words/images',
        woodysWordsTournament: (tournamentId) => `/woodys-words/tournament/${tournamentId}`,
        woodysWordsTournaments: '/woodys-words/tournaments',
        createWoodysWordsTournament: '/woodys-words/tournament',
        woodysWordsNextChallenges: (languageId) => `/woodys-words/next-challenges/${languageId}`,
        woodysWordsChallenge: (challengeId) => `/woodys-words/challenge/${challengeId}`,
        woodysWordsChallengeConfig: `/woodys-words/challenge-config`,
        woodysWordsGenMonthChallenges: (uuid) => `/woodys-words/month/${uuid}/gen-challenges`,
        woodysWordsMonthChallenges: (uuid) => `/woodys-words/month/${uuid}`,

        crackNumberNumbers: (languageId) => `/crack-number/numbers/${languageId}`,
        crackNumberNumber: (numberId) => `/crack-number/number/${numberId}`,
        updateCrackNumberNumbers: '/crack-number/numbers',
        createCrackNumberNumber: '/crack-number/number',

        crackNumberCategories: '/crack-number/categories',
        crackNumberCategoriesByLanguage: (languageId) => `/crack-number/categories/${languageId}`,
        crackNumberCategory: (categoryId) => `/crack-number/category/${categoryId}`,
        createCrackNumberCategory: '/crack-number/category',
    };

    getApps() {
        return this.getJSON(this.routes.apps);
    }

    getLanguages() {
        return this.getJSON(this.routes.languages);
    }

    getImages() {
        return this.getJSON(this.routes.images);
    }

    uploadImages(images) {
        return fetch(this.base + this.routes.imageUpload, {
            method: 'POST',
            headers: {
                'Bearer': this.options.authToken,
            },
            body: images,
        }).then(response => response.json()).catch(error => console.error(error));
    }

    postImage(imageId, image) {
        return this.postJSON(this.routes.image(imageId), image);
    }

    deleteImage(imageId) {
        return this.deleteJSON(this.routes.image(imageId));
    }

    getAppLanguages(appId) {
        return this.getJSON(this.routes.appLanguages(appId));
    }

    postAppLanguages(appId, languages) {
        return this.postJSON(this.routes.appLanguages(appId), languages);
    }

    getAppImages(appId) {
        return this.getJSON(this.routes.appImages(appId));
    }

    postAppImages(appId, images) {
        return this.postJSON(this.routes.appImages(appId), images);
    }

    patchAppImages(appId, images) {
        return this.patchJSON(this.routes.appImages(appId), images);
    }

    deleteAppImages(appId, images) {
        return this.deleteJSON(this.routes.appImages(appId), images);
    }

    getUnusedImagesForApp(appId) {
        return this.getJSON(this.routes.imagesUnusedInApp(appId));
    }

    getAppImage(appId, imageId) {
        return this.getJSON(this.routes.appImage(appId, imageId));
    }

    patchAppImage(appId, imageId, appImage) {
        return this.patchJSON(this.routes.appImage(appId, imageId), appImage);
    }

    postAppImageLanguages(appId, imageId, appImageLanguages) {
        return this.postJSON(this.routes.appImageLanguages(appId, imageId), appImageLanguages);
    }

    patchAppImageLanguages(appId, imageId, appImageLanguages) {
        return this.patchJSON(this.routes.appImageLanguages(appId, imageId), appImageLanguages);
    }

    getAppPacks(appId) {
        return this.getJSON(this.routes.appPacks(appId));
    }

    postAppPacks(appId, packs) {
        return this.postJSON(this.routes.appPacks(appId), packs);
    }

    getPack(packId) {
        return this.getJSON(this.routes.pack(packId));
    }

    patchPack(packId, pack) {
        return this.patchJSON(this.routes.pack(packId), pack);
    }

    getPackImages(packId) {
        return this.getJSON(this.routes.packImages(packId));
    }

    getPackUnusedImages(packId) {
        return this.getJSON(this.routes.packUnusedImages(packId));
    }

    postPackLanguages(packId, languages) {
        return this.postJSON(this.routes.packLanguages(packId), languages);
    }

    deletePackImages(packId, packImages) {
        return this.deleteJSON(this.routes.packImages(packId), packImages);
    }

    patchPackImages(packId, packImages) {
        return this.patchJSON(this.routes.packImages(packId), packImages);
    }

    getLabel(labelId) {
        return this.getJSON(this.routes.label(labelId));
    }

    postLabel(labelId, label) {
        return this.postJSON(this.routes.label(labelId), label);
    }

    postPackImages(packId, packImages) {
        return this.postJSON(this.routes.packImages(packId), packImages);
    }

    postAppPack(appId) {
        return this.postJSON(this.routes.appPack(appId));
    }

    getInAppPurchaseList(appId, platform) {
        return this.getJSON(this.routes.inAppPurchase(appId, platform));
    }

    postInAppPurchaseList(appId, platform) {
        return this.postJSON(this.routes.inAppPurchase(appId, platform));
    }

    getAppDataVersions(appId) {
        return this.getJSON(this.routes.appDataVersions(appId));
    }

    postAppDataVersion(appId, version) {
        return this.postJSON(this.routes.appDataVersions(appId), version);
    }

    getAppDataPreview(appId) {
        return this.getJSON(this.routes.appDataPreview(appId))
    }

    postAppDataVersionPublish(appId, version) {
        return fetch(this.base + this.routes.appDataVersionPublish(appId, version), {
            method: 'POST',
            headers: {
                'Bearer': this.options.authToken,
            },
        }).then(response => response.json()).catch(error => console.error(error));
    }

    getAppCouponCodes(appId) {
        return this.getJSON(this.routes.appCouponCodes(appId));
    }

    postAppCouponCodes(appId, codes) {
        return this.postJSON(this.routes.appCouponCodes(appId), codes);
    }

    patchCouponCodes(couponCodes) {
        return this.patchJSON(this.routes.couponCodes, couponCodes);
    }

    getAppUnlockCodes(appId) {
        return this.getJSON(this.routes.appUnlockCodes(appId));
    }

    postAppUnlockCodes(appId, codes) {
        return this.postJSON(this.routes.appUnlockCodes(appId), codes);
    }

    patchUnlockCodes(unlockCodes) {
        return this.patchJSON(this.routes.unlockCodes, unlockCodes);
    }

    getAppStoreProducts(appId) {
        return this.getJSON(this.routes.appStoreProducts(appId));
    }

    postAppStoreProducts(appId, products) {
        return this.postJSON(this.routes.appStoreProducts(appId), products);
    }

    postAppStoreProduct(appId) {
        return this.postJSON(this.routes.appStoreProduct(appId));
    }

    patchStoreProduct(productId, product) {
        return this.patchJSON(this.routes.storeProduct(productId), product)
    }

    getStoreProduct(productId) {
        return this.getJSON(this.routes.storeProduct(productId));
    }

    postGeneratedCrosswordsGrid(words) {
        return this.postJSON(this.routes.generateCrosswordsGrid, words);
    }

    getWordsOutLevels() {
        return this.getJSON(this.routes.wordsOutLevels)
    }

    postWordsOutLevels(levels) {
        return this.postJSON(this.routes.wordsOutLevels, levels)
    }

    postWordsOutLevel() {
        return this.postJSON(this.routes.createWordsOutLevel)
    }

    patchWordsOutLevel(levelId, level) {
        return this.patchJSON(this.routes.wordsOutLevel(levelId), level)
    }

    getWordsOutLevelGroups() {
        return this.getJSON(this.routes.wordsOutLevelGroups)
    }

    getWordsOutLevelGroup(groupId) {
        return this.getJSON(this.routes.wordsOutLevelGroup(groupId))
    }

    postWordsOutLevelGroup() {
        return this.postJSON(this.routes.createWordsOutLevelGroup)
    }

    patchWordsOutLevelGroup(groupId, group) {
        return this.patchJSON(this.routes.wordsOutLevelGroup(groupId), group)
    }

    getWordsOutImages() {
        return this.getJSON(this.routes.wordsOutImages)
    }

    getWordsOutNextChallenges() {
        return this.getJSON(this.routes.wordsOutNextChallenges)
    }

    getWordsOutChallenge(challengeId) {
        return this.getJSON(this.routes.wordsOutChallenge(challengeId))
    }

    patchWordsOutChallenge(challengeId, challenge) {
        return this.patchJSON(this.routes.wordsOutChallenge(challengeId), challenge)
    }

    postWordsOutGenMonthChallenges(monthUuid) {
        return this.postJSON(this.routes.wordsOutGenMonthChallenges(monthUuid))
    }

    patchWordsOutChallengeMonth(monthUuid, month) {
        return this.patchJSON(this.routes.wordsOutMonthChallenges(monthUuid), month)
    }

    getCrackListLists(languageId) {
        return this.getJSON(this.routes.crackListLists(languageId))
    }

    getCrackListList(listId) {
        return this.getJSON(this.routes.crackListList(listId))
    }

    postCrackListList(list) {
        return this.postJSON(this.routes.createCrackListList, list);
    }

    patchCrackListList(listId, list) {
        return this.patchJSON(this.routes.crackListList(listId), list);
    }

    duplicateCrackListList(listId, languageId) {
        return this.getJSON(this.routes.crackListDuplicateList(listId, languageId));
    }

    deleteCrackListList(listId) {
        return this.deleteJSON(this.routes.crackListList(listId));
    }

    patchCrackListListItems(listId, items) {
        return this.patchJSON(this.routes.crackListListItems(listId), items);
    }

    postCrackListListItem(item) {
        return this.postJSON(this.routes.crackListListItem, item);
    }

    getCrackListLevels() {
        return this.getJSON(this.routes.crackListLevels);
    }

    postCrackListLevels(levels) {
        return this.postJSON(this.routes.crackListLevels, levels);
    }

    postCrackListLevel() {
        return this.postJSON(this.routes.createCrackListLevel);
    }

    patchCrackListLevel(levelId, level) {
        return this.patchJSON(this.routes.crackListLevel(levelId), level);
    }

    getCrackListLevel(levelId) {
        return this.getJSON(this.routes.crackListLevel(levelId));
    }

    patchCrackListLevelSpecifics(specifics) {
        return this.patchJSON(this.routes.crackListLevelSpecifics, specifics);
    }

    deleteCrackListLevelSpecifics(specifics) {
        return this.deleteJSON(this.routes.crackListLevelSpecifics, specifics);
    }

    getCrackListLevelGroups() {
        return this.getJSON(this.routes.crackListLevelGroups);
    }

    getCrackListLevelGroup(groupId) {
        return this.getJSON(this.routes.crackListLevelGroup(groupId));
    }

    postCrackListLevelGroup() {
        return this.postJSON(this.routes.createCrackListLevelGroup);
    }

    patchCrackListLevelGroup(groupId, group) {
        return this.patchJSON(this.routes.crackListLevelGroup(groupId), group);
    }

    getWordsOutTournaments() {
        return this.getJSON(this.routes.wordsOutTournaments)
    }

    getWordsOutTournament(tournamentId) {
        return this.getJSON(this.routes.wordsOutTournament(tournamentId))
    }

    patchWordsOutTournament(tournamentId, tournament) {
        return this.patchJSON(this.routes.wordsOutTournament(tournamentId), tournament)
    }

    postWordsOutTournament(tournament) {
        return this.postJSON(this.routes.createWordsOutTournament, tournament)
    }

    getCrackListTournaments() {
        return this.getJSON(this.routes.crackListTournaments)
    }

    getCrackListTournament(tournamentId) {
        return this.getJSON(this.routes.crackListTournament(tournamentId))
    }

    patchCrackListTournament(tournamentId, tournament) {
        return this.patchJSON(this.routes.crackListTournament(tournamentId), tournament)
    }

    postCrackListTournament(tournament) {
        return this.postJSON(this.routes.createCrackListTournament, tournament)
    }

    getCrackListPartyCategories(languageId) {
        return this.getJSON(this.routes.crackListPartyCategories(languageId))
    }

    getCrackListPartyPacks() {
        return this.getJSON(this.routes.crackListPartyPacks)
    }

    getCrackListPartyPack(packId) {
        return this.getJSON(this.routes.crackListPartyPack(packId))
    }

    patchCrackListPartyPack(packId, pack) {
        return this.patchJSON(this.routes.crackListPartyPack(packId), pack)
    }

    patchCrackListPartyPackMix(mix) {
        return this.patchJSON(this.routes.crackListPartyPackMix, mix)
    }

    patchCrackListPartyPacks(packs) {
        return this.patchJSON(this.routes.crackListPartyPacks, packs)
    }

    getCrackListPartyLists(languageId) {
        return this.getJSON(this.routes.crackListPartyLists(languageId))
    }

    getCrackListPartyList(listId) {
        return this.getJSON(this.routes.crackListPartyList(listId))
    }

    postCrackListPartyList(list) {
        return this.postJSON(this.routes.createCrackListPartyList, list);
    }

    postCrackListPartyPack() {
        return this.postJSON(this.routes.createCrackListPartyPack, {});
    }

    deleteCrackListPartyPack(packId) {
        return this.deleteJSON(this.routes.crackListPartyPack(packId));
    }

    patchCrackListPartyList(listId, list) {
        return this.patchJSON(this.routes.crackListPartyList(listId), list);
    }

    deleteCrackListPartyList(listId) {
        return this.deleteJSON(this.routes.crackListPartyList(listId));
    }

    patchCrackListPartyLists(lists) {
        return this.patchJSON(this.routes.updateCrackListPartyLists, lists)
    }

    getCrackListPartyPenaltyCategories(languageId) {
        return this.getJSON(this.routes.crackListPartyPenaltyCategories(languageId))
    }

    getCrackListPartyPenalties(languageId) {
        return this.getJSON(this.routes.crackListPartyPenalties(languageId))
    }

    getCrackListPartyPenalty(penaltyId) {
        return this.getJSON(this.routes.crackListPartyPenalty(penaltyId))
    }

    postCrackListPartyPenalty(penalty) {
        return this.postJSON(this.routes.createCrackListPartyPenalty, penalty);
    }

    patchCrackListPartyPenalty(penaltyId, penalty) {
        return this.patchJSON(this.routes.crackListPartyPenalty(penaltyId), penalty);
    }

    deleteCrackListPartyPenalty(penaltyId) {
        return this.deleteJSON(this.routes.crackListPartyPenalty(penaltyId));
    }

    patchCrackListPartyPenalties(penalties) {
        return this.patchJSON(this.routes.updateCrackListPartyPenalties, penalties);
    }

    getCrackListPartyDiscoveryLists(languageId) {
        return this.getJSON(this.routes.crackListPartyDiscoveryLists(languageId));
    }

    getCrackListPartyDiscoveryBoxLists(languageId) {
        return this.getJSON(this.routes.crackListPartyDiscoveryBoxLists(languageId));
    }

    getCrackListPartyDiscoveryPenalties(languageId) {
        return this.getJSON(this.routes.crackListPartyDiscoveryPenalties(languageId));
    }

    getCrackListPartyDiscoveryBoxPenalties(languageId) {
        return this.getJSON(this.routes.crackListPartyDiscoveryBoxPenalties(languageId));
    }

    patchCrackListPartyPackPenaltyMix(mix) {
        return this.patchJSON(this.routes.crackListPartyPackPenaltyMix, mix)
    }

    /////////////// WOODYS WORDS - CRACK QUIZ PUZZLE //////////////////

    getWoodysWordsLevels() {
        return this.getJSON(this.routes.woodysWordsLevels)
    }

    getWoodysWordsLevel(levelId) {
        return this.getJSON(this.routes.woodysWordsLevel(levelId))
    }

    postWoodysWordsLevels(levels) {
        return this.postJSON(this.routes.woodysWordsLevels, levels)
    }

    postWoodysWordsLevel() {
        return this.postJSON(this.routes.createWoodysWordsLevel)
    }

    patchWoodysWordsLevel(levelId, level) {
        return this.patchJSON(this.routes.woodysWordsLevel(levelId), level)
    }

    patchWoodysWordsLevelConfig(levelConfig) {
        return this.patchJSON(this.routes.woodysWordsLevelConfig, levelConfig)
    }

    getWoodysWordsLevelGroups() {
        return this.getJSON(this.routes.woodysWordsLevelGroups)
    }

    getWoodysWordsLevelGroup(groupId) {
        return this.getJSON(this.routes.woodysWordsLevelGroup(groupId))
    }

    postWoodysWordsLevelGroup() {
        return this.postJSON(this.routes.createWoodysWordsLevelGroup)
    }

    patchWoodysWordsLevelGroup(groupId, group) {
        return this.patchJSON(this.routes.woodysWordsLevelGroup(groupId), group)
    }

    getWoodysWordsDefinitions(languageId) {
        return this.getJSON(this.routes.woodysWordsDefinitionsByLanguage(languageId))
    }

    getWoodysWordsDefinition(definitionId) {
        return this.getJSON(this.routes.woodysWordsDefinition(definitionId))
    }

    postWoodysWordsDefinition(definition) {
        return this.postJSON(this.routes.createWoodysWordsDefinition, definition);
    }

    patchWoodysWordsDefinition(definitionId, definition) {
        return this.patchJSON(this.routes.woodysWordsDefinition(definitionId), definition);
    }

    patchWoodysWordsDefinitionItems(definitionId, items) {
        return this.patchJSON(this.routes.woodysWordsDefinitionItems(definitionId), items);
    }

    postWoodysWordsDefinitionItem(item) {
        return this.postJSON(this.routes.woodysWordsDefinitionItem, item);
    }

    getWoodysWordsImages() {
        return this.getJSON(this.routes.woodysWordsImages)
    }

    getWoodysWordsTournaments() {
        return this.getJSON(this.routes.woodysWordsTournaments)
    }

    getWoodysWordsTournament(tournamentId) {
        return this.getJSON(this.routes.woodysWordsTournament(tournamentId))
    }

    patchWoodysWordsTournament(tournamentId, tournament) {
        return this.patchJSON(this.routes.woodysWordsTournament(tournamentId), tournament)
    }

    postWoodysWordsTournament(tournament) {
        return this.postJSON(this.routes.createWoodysWordsTournament, tournament)
    }

    getWoodysWordsNextChallenges(languageId) {
        return this.getJSON(this.routes.woodysWordsNextChallenges(languageId))
    }

    getWoodysWordsChallenge(challengeId) {
        return this.getJSON(this.routes.woodysWordsChallenge(challengeId))
    }

    patchWoodysWordsChallenge(challengeId, challenge) {
        return this.patchJSON(this.routes.woodysWordsChallenge(challengeId), challenge)
    }

    patchWoodysWordsChallengeConfig(challengeConfig) {
        return this.patchJSON(this.routes.woodysWordsChallengeConfig, challengeConfig)
    }

    postWoodysWordsGenMonthChallenges(monthUuid) {
        return this.postJSON(this.routes.woodysWordsGenMonthChallenges(monthUuid))
    }

    patchWoodysWordsChallengeMonth(monthUuid, month) {
        return this.patchJSON(this.routes.woodysWordsMonthChallenges(monthUuid), month)
    }

    ////////// CRACK NUMBER ////////////

    getCrackNumberCategories() {
        return this.getJSON(this.routes.crackNumberCategories)
    }

    getCrackNumberCategoriesByLanguage(languageId) {
        return this.getJSON(this.routes.crackNumberCategoriesByLanguage(languageId))
    }

    getCrackNumberNumbers(languageId) {
        return this.getJSON(this.routes.crackNumberNumbers(languageId))
    }

    getCrackNumberNumber(numberId) {
        return this.getJSON(this.routes.crackNumberNumber(numberId))
    }

    postCrackNumberNumber(nb) {
        return this.postJSON(this.routes.createCrackNumberNumber, nb);
    }

    patchCrackNumberNumber(numberId, nb) {
        return this.patchJSON(this.routes.crackNumberNumber(numberId), nb);
    }

    deleteCrackNumberNumber(numberId) {
        return this.deleteJSON(this.routes.crackNumberNumber(numberId));
    }

    patchCrackNumberNumbers(numbers) {
        return this.patchJSON(this.routes.updateCrackNumberNumbers, numbers)
    }

    getDateFromTimestamp(unixTimestamp) {
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        };
        const date = new Date(unixTimestamp * 1000);
        return new Intl.DateTimeFormat('fr-FR', options).format(date)
    }
}

export default (new API('/api', {
    authToken: '76e886f3e9275e7b2b378b6ad98443bc6551d5d09626010435cce2fc1eea6e44'
}));