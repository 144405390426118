import React from 'react';
import PropTypes from 'prop-types';
import ImageIcon from "@material-ui/icons/Image"
import DragHandleIcon from '@material-ui/icons/DragHandle'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import {withStyles} from '@material-ui/core/styles';
import {DragSource, DropTarget} from 'react-dnd';
import ErrorIcon from "@material-ui/icons/Error"
import classNames from "classnames"
import {Avatar, Card, CardContent, CardHeader, Chip, IconButton, Typography} from '@material-ui/core';


class PackCard extends React.Component {


    static getPackLabel(pack, languageId) {
        if (!pack.label || !pack.label.languages || pack.label.languages.length === 0) {
            return "(Pack sans titre)";
        }
        let labelValue = undefined;
        if (languageId !== undefined) {
            labelValue = pack.label.languages.find(l => l.language_id === languageId);
        }
        if (!labelValue || !labelValue.value) {
            labelValue = pack.label.languages.find(l => !!l.value && l.value.length !== 0);
        }
        if (!!labelValue) {
            return labelValue.value;
        }
        return "(Pack sans titre)";
    }

    render() {

        const {
            pack,
            isDragging,
            connectDragSource,
            connectDropTarget,
            classes,
        } = this.props;

        return connectDragSource(connectDropTarget(
            <div>
                <Card
                    style={{
                        maxWidth: 320,
                        flex: 1,
                        margin: 12,
                        opacity: isDragging ? 0.3 : 1.,
                    }}
                >
                    <CardHeader
                        action={
                            this.props.draggable ? (
                                <IconButton>
                                    <DragHandleIcon/>
                                </IconButton>
                            ) : null
                        }
                        title={PackCard.getPackLabel(pack)}
                        subheader={`${pack.images.length} image${pack.images.length < 2 ? '' : 's'}`}
                    />
                    <div className={`previewImages previewImages-${pack.max_image_count}`} style={{margin: 'auto'}}>
                        {
                            [...Array(Math.min(4, pack.max_image_count === 0 ? 4 : pack.max_image_count)).keys()].map(i => {
                                if (i < pack.preview_images.length) {
                                    return <img key={i} src={pack.preview_images[i].thumbnail_url} alt="" className="previewImage" />
                                } else {
                                    return (
                                        <div key={i} className="previewImage empty">
                                            <ImageIcon className="previewImagePlaceholder" />
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                    <CardContent>
                        <div className={classes.chipContainer}>
                            <Chip
                                className={classes.chip}
                                label={pack.free ? 'Gratuit' : 'Payant'}
                                avatar={<Avatar className={classes.chipAvatar}>{pack.free ? <MoneyOffIcon className={classes.chipAvatarIcon}/> : <AttachMoneyIcon className={classes.chipAvatarIcon}/>}</Avatar>}
                            />

                            {
                                pack.languages.length === this.props.app.languages.length ? (
                                    <Chip
                                        className={classes.chip}
                                        label="Toutes les langues"
                                    />
                                ) : (
                                    <Chip
                                        className={classes.chip}
                                        label={`${pack.languages.length} langues`}
                                    />
                                )
                            }
                            {
                                pack.errors.length === 0 ? null : (
                                    <Chip
                                        className={classNames(classes.chip, classes.chipError)}
                                        label={`${pack.errors.length} erreur${pack.errors.length > 1 ? 's' : ''}`}
                                        avatar={<Avatar className={classNames(classes.chipAvatar, classes.chipErrorAvatar)}><ErrorIcon className={classNames(classes.chipAvatarIcon, classes.chipErrorIcon)}/></Avatar>}
                                    />
                                )
                            }
                        </div>
                        <Typography component="p">
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        ));
    }
}

function requireIfDraggable(error) {
    return (props, propName, componentName) => {
        if ((!!props.draggable && (props[propName] === undefined || typeof(props[propName]) !== 'function'))) {
            return new Error(error);
        }
    };
}

PackCard.propTypes = {
    app: PropTypes.object.isRequired,
    pack: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    draggable: PropTypes.bool,
    onMove: requireIfDraggable('Please provide a onMove(sourceIndex, targetIndex) function as prop.'),
    onDragBegin: requireIfDraggable('Please provide a onDragBegin(index) function as prop.'),
    onDragEnd: requireIfDraggable('Please provide a onDragEnd() function as prop.'),
};

PackCard.defaultProps = {
    draggable: false,
};

const AppPackDragItemType = 'AppPack';

const cardTarget = {

    canDrop(props, monitor) {
        return true;
    },

    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        if (dragIndex === hoverIndex) {
            return;
        }

        props.onMove(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};

const cardSource = {

    canDrag(props) {
        return props.draggable;
    },

    beginDrag(props) {
        props.onDragBegin(props.index);
        return {
            id: props.pack.pack_id,
            index: props.index,
        };
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }
        props.onDragEnd();
    }
};

const styles = theme => ({
    chipContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    chipAvatar: {
        width: 32,
        height: 32,
        color: 'currentColor !important',
        fontSize: 'inherit !important',
    },
    chipAvatarIcon: {
        width: 19,
        height: 19,
    },
    chipError: {
        background: "#f4d6d6",
        color: "#904c4d"
    },
    chipErrorIcon: {
        color: '#fff'
    },
    chipErrorAvatar: {
        background: "#d26f71"
    }
});

export default DropTarget(AppPackDragItemType, cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
}))(DragSource(AppPackDragItemType, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))(withStyles(styles)(PackCard)));