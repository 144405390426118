import React from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar} from '@material-ui/core';
import api from '../../services/api';


class CrossWordsGrid extends React.Component {

    constructor(props) {
        super(props);

        const specifics = this.props.image.specifics.languages.find(sp => sp.language_id === this.props.language.language_id);
        const grid = !!specifics.grid ? specifics.grid : null;

        this.state = {
            savingGrid: false,
            snackbarDisplayed: false,
            snackbarMessage: "",
            open: false,
            grid: grid,
            currentGrid: !!grid ? {
                grid: grid,
                display: this.getGridDisplay(grid),
                previous: null,
            } : null,
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            open: props.open
        };
    }

    onGridDialogClose = (e) => {
        this.props.onClose();
    };

    onGridDialogCancel = (e) => {
        this.props.onClose();
    };

    onGridDialogAccept = (e) => {

        let image = Object.assign({}, this.props.image);
        let languageSpecifics = image.specifics.languages.find(sp => sp.language_id === this.props.language.language_id);
        delete languageSpecifics.$words;
        languageSpecifics.editing = false;

        let specifics = Object.assign({}, languageSpecifics);
        specifics.grid = this.state.currentGrid.grid;

        delete specifics.language_id;
        delete specifics.editing;
        const appId = this.props.app.app_id;
        const imageId = this.props.image.image_id;
        api.patchAppImageLanguages(appId, imageId, [{
            app_id: appId,
            image_id: imageId,
            language_id: this.props.language.language_id,
            specifics: specifics,
        }]).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la grille.");
                return;
            }
            this.setState({
                currentGrid: {
                    grid: this.state.currentGrid.grid,
                    display: this.state.currentGrid.display,
                    previous: null,
                }
            });
            this.displaySnackbar("Grille sauvegardée !");
            this.props.onSave(this.state.currentGrid.grid);
        });


    };

    getGridDisplay(gridInfo) {
        if (!gridInfo) {
            return [];
        }
        let gridDisplay = [];
        for (let i = 0; i < gridInfo.height; i++) {
            let row = [];
            for (let j = 0; j < gridInfo.width; j++) {
                row[row.length] = "";
            }
            gridDisplay[gridDisplay.length] = row;
        }

        gridInfo.items.forEach(function(it) {
            if (it.direction === 0) {
                for (let c = 0; c < it.word.length; c++) {
                    gridDisplay[it.row][it.column + c] = it.word[c];
                }
            } else {
                for (let cc = 0; cc < it.word.length; cc++) {
                    gridDisplay[it.row + cc][it.column] = it.word[cc];
                }
            }
        });
        return gridDisplay;
    }

    generateGrid = (e) => {

        const specifics = this.props.image.specifics.languages.find(sp => sp.language_id === this.props.language.language_id);

        api.postGeneratedCrosswordsGrid({'words': specifics.words}).then(gridInfo => {
            if (gridInfo.error) {
                this.displaySnackbar(gridInfo.error);
                return;
            }
            this.setState({
                currentGrid: {
                    grid: gridInfo,
                    display: this.getGridDisplay(gridInfo),
                    previous: this.state.currentGrid
                },
            });
        })
    };

    previousGrid = (e) => {
        this.setState({
            currentGrid: this.state.currentGrid.previous
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        let dialogBody = this.state.savingGrid ? (
            <div className="uploadProgress">
                <CircularProgress size={50}/>
            </div>
        ) : (

            <React.Fragment>

                    {
                        !!this.state.currentGrid ? (
                            <React.Fragment>
                                <table style={{
                                    borderCollapse: 'collapse',
                                    margin: 'auto',
                                    marginTop: 20,
                                    fontWeight: 'bold',
                                    fontSize: '1.2em',
                                    color: '#333',

                                }}>
                                    <tbody>
                                    {
                                        this.state.currentGrid.display.map((row, rowIndex) => (
                                            <tr key={rowIndex} style={{padding: 0, margin: 0}}>
                                                {
                                                    row.map((column, columnIndex) => (
                                                        <td
                                                            key={columnIndex}
                                                            style={{
                                                                width: 40,
                                                                height: 40,
                                                                textAlign: 'center',
                                                                lineHeight: '40px',
                                                                border: '1px solid #333',
                                                                backgroundColor: column.length === 0 ? "#333" : "#fff",
                                                            }}>{column}</td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                                <div style={{float: 'right', margin: '12px 0', fontWeight: 'bold'}}>
                                    L&times;H = {this.state.currentGrid.grid.width}&times;{this.state.currentGrid.grid.height}
                                </div>
                                <div style={{clear: 'both'}}/>
                            </React.Fragment>
                        ) : null
                    }
            </React.Fragment>
        );

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.onGridDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Grille - {this.props.language.name}</DialogTitle>
                    <DialogContent>
                        {dialogBody}
                    </DialogContent>
                    <DialogActions>
                        {
                            !!this.state.currentGrid && !!this.state.currentGrid.previous ? (
                                <Button color="secondary" onClick={this.previousGrid}
                                        disabled={this.state.savingGrid}>
                                    Précédente
                                </Button>
                            ) : null
                        }
                        <Button color="secondary" onClick={this.generateGrid}
                                disabled={this.state.savingGrid}>
                            Générer
                        </Button>
                    </DialogActions>
                    <DialogActions>
                        <Button onClick={this.onGridDialogCancel} color="default">
                            Annuler
                        </Button>
                        <Button color="primary" onClick={this.onGridDialogAccept}
                                disabled={this.state.savingGrid || !this.state.currentGrid}>
                            Enregistrer
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small"
                                onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>
        )

    }
}

CrossWordsGrid.propTypes = {
    app: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
};

CrossWordsGrid.defaultProps = {};

export default CrossWordsGrid;