import React from 'react';
import api from '../services/api';
import LanguagePicker from "./LanguagePicker";
import {Paper} from '@material-ui/core';

class AppLanguages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            languages: [],
            appLanguages: [],
        };
    }

    componentDidMount() {
        Promise.all([
            api.getLanguages(),
            api.getAppLanguages(this.props.app.app_id),
        ]).then(results => {
            this.setState({
                languages: results[0],
                appLanguages: results[1],
            });
        });
    }

    saveAppLanguages = (languages, callback) => {
        let remoteAppLanguages = languages.map(l => {
            return {
                app_id: this.props.app.app_id,
                language_id: l.language_id,
            };
        });

        api.postAppLanguages(this.props.app.app_id, remoteAppLanguages).then(() => {
            this.setState({
                appLanguages: languages
            });
        });
    };

    render () {
        return (
            <Paper>
                <LanguagePicker
                    title="Langues du jeu"
                    languages={this.state.languages}
                    initialLanguages={this.state.appLanguages.map(l => l.language_id)}
                    onSave={this.saveAppLanguages}
                />
            </Paper>
        );
    }
}


export default AppLanguages;
