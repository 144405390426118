import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
import UploadDialog from './UploadDialog'
import './Gallery.css'
import ImageCard from "./ImageCard";
import SearchBox from "../tools/SearchBox";
import Pagination from "../tools/Pagination";
import {withStyles} from "@material-ui/core";
import api from "../services/api"

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadImagesDialogOpen: false,
            images: [],
            filteredImages: [],
            filterText: '',
            imagePerPage: 25,
            currentPage: 0,
        };
    }

    componentDidMount() {
        this.updateImages();
        document.title = "8 Mots - Collection d'images";
        this.props.onAppBarTitleChange("Collection d'images");
        this.props.onAppBarRightItemsChange([
            <Button key="add" color="primary" style={styles.addButton} onClick={evt => this.setState({uploadImagesDialogOpen: true})}>
                <Icon>add</Icon>
                Ajouter des images
            </Button>
        ]);
    }

    updateImages() {
        api.getImages().then(images => {
            this.setState({
                images: images
            });
            this.filterImages(images, this.state.filterText);
        }).catch((error) => {
            console.error(error);
        });
    }

    onUploadFinished = (newImages, error) => {
        let images = newImages.concat(this.state.images);
        this.setState({
            uploadImagesDialogOpen: false,
            images: images
        });
        this.filterImages(images, this.state.filterText);
    };

    onImageDeleted = (image) => {
        const i = this.state.images.findIndex(img => img.image_id === image.image_id);
        let images = this.state.images;
        images.splice(i, 1);
        this.setState({
            images: images
        });
        this.filterImages(images, this.state.filterText);
    };

    onImageEdited = (image) => {
        const i = this.state.images.findIndex(img => img.image_id === image.image_id);
        let images = this.state.images;
        images[i] = image;
        this.setState({
            images: images
        });
        this.filterImages(images, this.state.filterText);
    };

    onFilterChange = (filter) => {
        this.setState({
            filterText: filter
        });
        this.filterImages(this.state.images, filter);
    };

    filterImages(images, filter) {
        let filterText = filter.trim().toLowerCase();
        if (filterText.length < 3) {
            this.setState({
                filteredImages: images
            });
            return;
        }
        const filteredImages = images.filter((image) => {
            return image.name.toLowerCase().includes(filterText);
        });
        this.setState({
            filteredImages: filteredImages,
            currentPage: Math.max(Math.min(this.state.currentPage, this.getNumberOfPages(filteredImages) - 1), 0)
        });
    }

    currentPageImages() {
        const i = this.getCurrentMinimumImageIndex();
        return this.state.filteredImages.slice(i, Math.min(this.state.filteredImages.length, i + this.state.imagePerPage));
    }

    getNumberOfPages(images) {
        if (images.length === 0) {
            return 0;
        }
        return Math.ceil((images.length - 1) / this.state.imagePerPage);
    }

    getCurrentMinimumImageIndex() {
        return this.state.currentPage * this.state.imagePerPage;
    }

    getCurrentMaximumImageIndex() {
        return Math.min(this.state.filteredImages.length - 1, this.getCurrentMinimumImageIndex() + this.state.imagePerPage - 1);
    }

    getFilterHelperText() {
        if (this.state.filteredImages.length === 0) {
            return 'Aucune image ne correspond à cette recherche.';
        }
        return "Affichage des images " + (this.getCurrentMinimumImageIndex() + 1) + " à " + (this.getCurrentMaximumImageIndex() + 1) + " sur " + this.state.filteredImages.length + " correspondant à cette recherche";
    }

    render () {

        const numberOfPages = this.getNumberOfPages(this.state.filteredImages);

        let pagination = (
            <Pagination
                numberOfPages={numberOfPages}
                currentPage={this.state.currentPage}
                onPageChange={(i) => this.setState({currentPage: i})}
            />
        );


        return (
            <React.Fragment>

                <UploadDialog open={this.state.uploadImagesDialogOpen}
                              onUploadFinished={this.onUploadFinished}/>

                <div>

                    <SearchBox
                        placeholder="Filtrer la liste des images"
                        onFilterChange={this.onFilterChange}
                        helperText={this.getFilterHelperText()}
                    />

                    {pagination}

                    {
                        this.currentPageImages().map((image, i) => {
                            return (
                                <ImageCard
                                    image={image}
                                    key={image.image_id}
                                    onImageDeleted={this.onImageDeleted}
                                    onImageEdited={this.onImageEdited}
                                />
                            )
                        })
                    }

                    {pagination}

                </div>

            </React.Fragment>
        )

    }
}


const styles = {

    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    addButton: {
        backgroundColor: 'white'
    }
};

export default withStyles(styles)(Gallery);