import React from 'react';
import PropTypes from 'prop-types';
import api from "../services/api";
import {
    Button,
    ButtonBase,
    FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Snackbar,
    Switch,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import EditIcon from '@material-ui/icons/Edit';
import LabelEditor from "./LabelEditor";
import KVC from '../services/KVC'
import AppleInAppPurchaseSelect from "../tools/AppleInAppPurchaseSelect";
import InAppStoreProducts from "./InAppStoreProducts";
import {withRouter} from "react-router-dom";

class StoreProduct extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            snackbarDisplayed: false,
            showTitleLabelEditor: false,
            showDetailsLabelEditor: false,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        const productId = this.getProductId();
        Promise.all([
            api.getAppLanguages(this.props.app.app_id),
            api.getStoreProduct(productId),
        ]).then(results => {
            const languages = results[0];
            const product = results[1];
            this.setState({
                languages: languages,
                product: product,
                $product: Object.assign({}, product),
            });
        });
    }

    getProductId() {
        return parseInt(this.props.match.params.productId, 10);
    }

    goBack = () => {
        this.props.history.goBack();
    };

    productIsPristine = () => {
        let initialProduct = this.apiStoreProduct(this.state.$product);
        let currentProduct = this.apiStoreProduct(this.state.product);
        return JSON.stringify(currentProduct) === JSON.stringify(initialProduct);
    };

    apiStoreProduct(product) {
        let apiStoreProduct = Object.assign({}, product);
        apiStoreProduct.title_label_id = apiStoreProduct.title_label.label_id;
        apiStoreProduct.details_label_id = apiStoreProduct.details_label.label_id;
        delete apiStoreProduct.title_label;
        delete apiStoreProduct.details_label;
        return apiStoreProduct;
    }

    handleSwitchChange = (keyPath) => (e, checked) => {
        this.handleChange(keyPath, checked);
    };

    handleValueChange = (keyPath) => (e) => {
        this.handleChange(keyPath, e.target.value);
    };

    handleChange = (keyPath, value) => {
        let product = Object.assign({}, this.state.product);
        KVC(product).setValueForKeyPath(keyPath, value);
        this.setState({
            product: product,
        });
    };

    handleIosSkuChange = (inAppProduct) => (e) => {
        let product = Object.assign({}, this.state.product);
        product.consumable = !!inAppProduct.consumable;
        product.sku_ios = inAppProduct.product_id;
        this.setState({
            product: product,
        })
    };

    cancelEditingProduct = () => {
        this.setState({
            product: Object.assign(this.state.$product),
        });
    };

    commitEditingProduct = () => {
        api.patchStoreProduct(this.getProductId(), this.apiStoreProduct(this.state.product)).then(product => {
            if (product === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement des propriétés du produit in-app.");
                return;
            }
            this.setState({
                $product: Object.assign({}, this.state.product),
            });
            this.displaySnackbar("Propriétés du produit in-app enregistrées.");
        });
    };

    onTitleLabelEditingFinished = (label) => {
        this.onLabelEditingFinished(label, 'title_label');
        this.setState({
            showTitleLabelEditor: false,
        });
    };

    onDetailsLabelEditingFinished = (label) => {
        this.onLabelEditingFinished(label, 'details_label');
        this.setState({
            showDetailsLabelEditor: false,
        });
    };

    onLabelEditingFinished = (label, labelKey) => {
        let product = Object.assign({}, this.state.product);
        product[labelKey] = label;
        let $product = Object.assign({}, this.state.$product);
        $product[labelKey] = label;
        this.setState({
            product: product,
            $product: $product,
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    getProductTitleLabel() {
        return InAppStoreProducts.getProductLabel(this.state.product, 'title_label', '(Sans titre)');
    }

    getProductDetailsLabel() {
        return InAppStoreProducts.getProductLabel(this.state.product, 'details_label', '(Sans détails)');
    }

    render() {

        const { product } = this.state;

        return !product ? <div/> : (
            <div className="productDetails">
                <Paper>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails du produit</Typography>
                            <Typography variant="subtitle1" color="primary">{this.getProductTitleLabel()}</Typography>
                        </div>
                        {
                            this.productIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingProduct}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingProduct}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                <Paper style={{padding: 24}}>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={product.active}
                                        onChange={this.handleSwitchChange('active')}
                                        value="1"
                                    />}
                                label="Actif (peut apparaître dans l'app)"
                                style={{margin: '0 0 4px -12px'}}
                            />

                            <br/>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={product.highlighted}
                                        onChange={this.handleSwitchChange('highlighted')}
                                        value="1"
                                    />}
                                label="Mis en avant"
                                style={{margin: '0 0 4px -12px'}}
                            />

                            <br/>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={product.remove_ads}
                                        onChange={this.handleSwitchChange('remove_ads')}
                                        value="1"
                                    />}
                                label={<span>Enlève les pubs<span style={{fontSize:'0.8em', color:'#999'}}><br/>Attention, le produit in-app iOS correspondant doit alors être non consommable.</span></span>}
                                style={{margin: '0 0 32px -12px'}}
                            />

                            <br/>

                            <Typography variant="button">
                                Nom du produit (visible dans la boutique)
                            </Typography>
                            <Typography variant="body1">
                                <ButtonBase
                                    focusRipple
                                    onClick={(e) => this.setState({showTitleLabelEditor: true})}
                                    style={{fontSize:'1rem', padding: '4px 4px 4px 0', color: '#2196f3', marginTop: 12, marginBottom: 24}}
                                >
                                    {this.getProductTitleLabel()}<EditIcon style={{marginLeft: 4}}/>
                                </ButtonBase>
                            </Typography>
                            <LabelEditor
                                labelId={product.title_label.label_id}
                                label={product.title_label}
                                open={this.state.showTitleLabelEditor}
                                languages={this.state.languages.map(l => l.language_id)}
                                onClose={this.onTitleLabelEditingFinished}
                                title="Nom du produit in-app"
                            />

                            <Typography variant="button">
                                Détails du produit (visible dans la boutique)
                            </Typography>
                            <Typography variant="body1">
                                <ButtonBase
                                    focusRipple
                                    onClick={(e) => this.setState({showDetailsLabelEditor: true})}
                                    style={{fontSize:'1rem', padding: '4px 4px 4px 0', color: '#2196f3', marginTop: 12, marginBottom: 24}}
                                >
                                    {this.getProductDetailsLabel()}<EditIcon style={{marginLeft: 4}}/>
                                </ButtonBase>
                            </Typography>
                            <LabelEditor
                                labelId={product.details_label.label_id}
                                label={product.details_label}
                                open={this.state.showDetailsLabelEditor}
                                languages={this.state.languages.map(l => l.language_id)}
                                onClose={this.onDetailsLabelEditingFinished}
                                title="Détails du produit in-app"
                            />

                            <TextField
                                select
                                value={product.image_name}
                                onChange={this.handleValueChange('image_name')}
                                label="Image affichée"
                                style={{marginTop: 12, width: 150}}
                            >
                                {
                                    ["Product-CoinPack", "Product-PackPremium", "Product-MaxiCoinPack", "Product-NoAds", "Product-WO-CoinPack", "Product-WO-BigCoinPack", "Product-WO-MaxiCoinPack"]
                                        .map((val, i) => <MenuItem key={i} value={val}><img src={`/${val}.png`} alt="" width="48" height="48"/></MenuItem>)
                                }
                            </TextField>


                        </div>
                        <div style={{flex: 1}}>

                            <TextField
                                style={{marginBottom: 24, width: 180}}
                                value={product.coin_count}
                                onChange={this.handleValueChange('coin_count')}
                                label="Nombre de pièces"
                                type="number"
                                helperText="Attention à bien refléter le changement dans le nom de produit si nécessaire"
                            />

                            <AppleInAppPurchaseSelect
                                appId={this.props.app.app_id}
                                value={product.sku_ios}
                                onChange={this.handleIosSkuChange}
                                error={!product.sku_ios}
                            />

                            <TextField
                                style={{marginTop: 12}}
                                fullWidth
                                value={!!product.sku_android ? product.sku_android : ''}
                                onChange={this.handleValueChange('sku_android')}
                                label="Identifiant du produit in-app Android"
                                error={!product.sku_android}
                            />
                        </div>
                    </div>
                </Paper>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />
            </div>
        );
    }
}

StoreProduct.propTypes = {
    app: PropTypes.object.isRequired,
};
StoreProduct.defaultProps = {};

export default withRouter(StoreProduct);