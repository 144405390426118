import React from 'react';
import DefaultApp from "./DefaultApp";
import {Tab, Tabs} from '@material-ui/core';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import AppLanguages from "./AppLanguages";
import CrackNumberAppDataVersions from "./crack-number/CrackNumberAppDataVersions";
import CrackNumberNumbers from "./crack-number/CrackNumberNumbers";
import CrackNumberNumberDetails from "./crack-number/CrackNumberNumberDetails";
import CrackListPartyListDetails from "./cracklist-party/CrackListPartyListDetails";
import CrackNumberCategories from "./crack-number/CrackNumberCategories";

class CrackNumber extends DefaultApp {

    render () {
        const { app, match } = this.props;
        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/create-number/:languageId/`} render={(props) => (
                        <CrackNumberNumberDetails app={app} />
                    )} />
                    <Route path={`${match.url}/number/:numberId/`} render={(props) => (
                        <CrackNumberNumberDetails app={app} />
                    )} />
                    <Route path={`${match.path}/:tab`}>
                        <CrackNumberTabs app={app}/>
                    </Route>
                    <Route render={() => <Redirect to={`/${app.url_slug}/${CrackNumberTabs.defaultTab}/`}/>} />
                </Switch>


            </div>
        );
    }
}

class CrackNumberTabs extends React.Component {

    static defaultTab = "numbers";

    constructor(props) {
        super(props);
        this.state = {
            currentTab: CrackNumberTabs.getCurrentTab(props),
        };
    }

    static getCurrentTab(props) {
        const tabName = props.match.params.tab;
        return tabName === undefined ? CrackNumberTabs.defaultTab : tabName;
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            currentTab: CrackNumberTabs.getCurrentTab(props),
        };
    }

    onCurrentTabChange = (e, value) => {
        this.props.history.push('/'+this.props.app.url_slug + '/' + value);
    };

    render() {
        const { app } = this.props;
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.currentTab}
                    onChange={this.onCurrentTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        margin: '-20px -22px 22px -22px',
                        borderBottom: '1px #ddd solid',
                        background: 'white',
                    }}
                    scrollable="true"
                    scrollButtons="auto"
                >
                    <Tab
                        value="numbers"
                        label="Numbers"
                    />
                    <Tab
                        value="categories"
                        label="Catégories"
                    />
                    <Tab
                        value="publish"
                        label="Publication"
                    />
                    <Tab
                        value="languages"
                        label="Langues"
                    />
                </Tabs>

                <Switch>
                    <Route path={`/${app.url_slug}/numbers/`} render={(props) => (
                        <CrackNumberNumbers app={app}/>
                    )} />
                    <Route path={`/${app.url_slug}/categories/`} render={(props) => (
                        <CrackNumberCategories app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/languages/`} render={(props) => (
                        <AppLanguages app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/publish/`} render={(props) => (
                        <CrackNumberAppDataVersions app={app}/>
                    )} />
                    <Route/>
                </Switch>
            </React.Fragment>
        );
    }
}

CrackNumberTabs = withRouter(CrackNumberTabs);

export default withRouter(CrackNumber);
