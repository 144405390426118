import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import LabelEditor from "../LabelEditor";
import {withRouter} from "react-router-dom";

class CrackListLevelGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getCrackListLevelGroups(),
        ]).then(res => {
            const groups = res[0];
            this.setState({
                groups: groups,
            });
        });
    }

    addNewGroup = () => {
        api.postCrackListLevelGroup().then(group => {
            this.updateData();
        })
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    goToGroup = (group) => () => {
        this.props.history.push(`/${this.props.app.url_slug}/group/${group.group_id}`);
    };

    render() {

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Groupes de niveaux</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: 150}}></TableCell>
                                    <TableCell style={{width: 200}}>Nom</TableCell>
                                    <TableCell style={{width: 200}}>Pièces offertes</TableCell>
                                    <TableCell style={{width: 200}}>Couleur</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.groups.map((group, i) => (
                                    <TableRow style={{cursor: 'pointer'}} key={group.group_id} onClick={this.goToGroup(group)}>
                                        <TableCell style={{width: 150}}><strong>Niveau {group.start_at + 1}+</strong></TableCell>
                                        <TableCell style={{width: 200}}>{LabelEditor.getLabelValue(group.label)}</TableCell>
                                        <TableCell style={{width: 200}}>{group.free_coin}</TableCell>
                                        <TableCell style={{width: 200, height: 40}}>
                                            <div style={{padding: 0, margin: -8, backgroundColor: group.background_color, width: '100%', position: 'relative', height: 32, borderRadius: 4}}></div>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.addNewGroup}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

CrackListLevelGroups.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListLevelGroups.defaultProps = {};

export default withRouter(CrackListLevelGroups);