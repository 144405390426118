import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import api from '../services/api'
import FormatteDateTime from "../tools/FormatteDateTime";
import CopyToClipboardLink from "../tools/CopyToClipboardLink";

class CouponCodes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            couponCodes: [],
            newCodesCount: 1,
            newCodesCoinCount: 500,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        api.getAppCouponCodes(this.props.app.app_id).then(codes => {
            this.setState({
                couponCodes: codes,
            });
        });
    }

    handleCoinCountChange = (e) => {
        this.setState({
            newCodesCoinCount: e.target.value,
        });
    };

    handleCodeCountChange = (e) => {
        this.setState({
            newCodesCount: parseInt(e.target.value, 10),
        });
    };

    newCodesIsValid() {
        return this.state.newCodesCount > 0 && this.state.newCodesCoinCount > 0;
    }

    createCodes = (e) => {
        e.preventDefault();
        if (!this.newCodesIsValid()) {
            return;
        }
        if (this.state.newCodesCount > 10) {
            if (!window.confirm(`Cela va créer ${this.state.newCodesCount} codes promos de ${this.state.newCodesCoinCount} pièces. Continuer ?`)) {
                return;
            }
        }
        const appId = this.props.app.app_id;
        api.postAppCouponCodes(appId, [...Array(this.state.newCodesCount).keys()].map(i => ({
            app_id: appId,
            coin_count: this.state.newCodesCoinCount,
        }))).then(res => {
            this.setState({
                newCodesCoinCount: 500,
                newCodesCount: 1
            });
            this.updateData();
        });
    };

    sendCode = (code, i) => (e) => {
        code.sent = 1;
        api.patchCouponCodes([code]).then(res => {
            let codes = Array.from(this.state.couponCodes);
            codes[i] = code;
            this.setState({
                couponCodes: codes,
            });
        });
    };

    canBeSent(code) {
        return !code.sent && !code.redeemed;
    }

    render () {
        return (
            <Paper>
                <Toolbar>
                    <div style={{flex: 1, alignItems: 'center'}}>
                        <Typography variant="h6">
                            Codes promotionnels
                        </Typography>
                    </div>
                    <div>
                        <form onSubmit={this.createCodes}>
                            <TextField
                                label="Nombre de pièces"
                                onChange={this.handleCoinCountChange}
                                value={this.state.newCodesCoinCount}
                                color="primary"
                                type="number"
                                style={{marginRight: 12}}
                            />
                            <TextField
                                label="Nombre de codes"
                                onChange={this.handleCodeCountChange}
                                value={this.state.newCodesCount}
                                color="primary"
                                type="number"
                            />
                            <Button
                                color="primary"
                                type="submit"
                                disabled={!this.newCodesIsValid()}
                            >Créer</Button>
                        </form>
                    </div>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: 150}}>Code</TableCell>
                            <TableCell style={{width: 50}}>Pièces</TableCell>
                            <TableCell>Date de création</TableCell>
                            <TableCell>Date d'utilisation</TableCell>
                            <TableCell>Lien à transmettre</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.couponCodes.map((code, i) => {
                            return (
                                <TableRow key={code.code}>
                                    <TableCell>
                                        <pre style={{
                                            fontSize:'1.3rem',
                                        }}>{code.code}</pre>
                                    </TableCell>
                                    <TableCell numeric>{code.coin_count}</TableCell>
                                    <TableCell>
                                        <Typography variant="body1"><FormatteDateTime timestamp={code.created_at}/></Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1"><FormatteDateTime timestamp={code.redeemed_at} emptyDescription="Jamais"/></Typography>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            !this.canBeSent(code) ? (
                                                <span>Code déjà envoyé</span>
                                            ) : (
                                                <div>
                                                    <CopyToClipboardLink text={`iOS — ${code.ios_url}`} value={code.ios_url}/>
                                                    <CopyToClipboardLink text={`Android — ${code.public_url}`} value={code.public_url}/>
                                                </div>
                                            )
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            !this.canBeSent(code) ? null : (
                                                <Button color="primary" onClick={this.sendCode(code, i)}>Envoyé</Button>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

CouponCodes.propTypes = {
    app: PropTypes.object.isRequired,
};

export default CouponCodes;