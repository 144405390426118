import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {
    Button, Checkbox, FormControlLabel,
    IconButton, ListItemText,
    MenuItem, OutlinedInput,
    Paper, Select,
    Snackbar, Switch,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import KVC from "../../services/KVC";
import LabelEditor from "../LabelEditor";

class CrackNumberNumberDetails extends React.Component {

    constructor(props) {
        super(props);
        let state = {
            languages: [],
            categories: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
        };
        if (props.match.url.includes("/create-number")) {
            state.number = {
                question: '',
                answer: '',
                answer_unit: null,
                answer_source: null,
                language_id: props.match.params.languageId,
                category_id: null,
                onboarding_index: -1,
                priority: 3,
            }
            state.$number = Object.assign({}, state.number);
        }
        this.state = state;
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let requests = [
            api.getAppLanguages(10),
        ];
        const numberId = this.getNumberId();
        if (!!numberId) {
            requests.push(api.getCrackNumberNumber(numberId));
        }
        Promise.all(requests).then(results => {
            const languages = results[0];
            let state = {
                languages: languages,
                number: this.state.number,
            };
            if (results.length > 1) {
                const number = results[1];
                state = {
                    ...state,
                    number: number,
                    $number: Object.assign({}, number),
                };
            }

            api.getCrackNumberCategoriesByLanguage(state.number.language_id).then(res => {
                state.categories = res;
                this.setState(state);
            });
        });
    }

    getNumberId() {
        return parseInt(this.props.match.params.numberId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/numbers/`);
            return;
        }
        this.props.history.goBack();
    };

    numberConfigIsPristine = () => {
        return JSON.stringify(this.state.number) === JSON.stringify(this.state.$number);
    };

    handleConfigValueChange = (propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleConfigChange(propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleConfigChange(propNamePath, e.target.value);
        }
    };

    handleConfigSwitchChange = (propNamePath) => (e, checked) => {
        this.handleConfigChange(propNamePath, checked ? 1 : 0);
    };

    handleConfigChange = (propNamePath, value) => {
        let number = Object.assign({}, this.state.number);
        KVC(number).setValueForKeyPath(propNamePath, value);
        this.setState({
            number: number,
        });
    };

    handleCategoryChange = (e) => {
        this.handleConfigChange('category_id', e.target.value);
    }

    cancelEditingNumber = () => {
        this.setState({
            number: Object.assign(this.state.$number),
        });
    };

    commitEditingNumber = () => {
        const numberId = this.getNumberId();
        if (!!numberId) {
            api.patchCrackNumberNumber(numberId, this.state.number).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement du number.");
                    return;
                }
                this.displaySnackbar("Number sauvegardé.");
                this.updateData();
            });
        } else {
            api.postCrackNumberNumber(this.state.number).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement du number.");
                    return;
                }
                this.setState({
                    number: res,
                    $number: JSON.parse(JSON.stringify(res))
                });
                this.props.history.replace(`/${this.props.app.url_slug}/number/${res.number_id}`);
            });
        }
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (this.state.number === undefined) {
            return <div/>;
        }

        const { languages, categories, number } = this.state;

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">{!this.getNumberId() ? "Nouveau number" : `Détails du Number (ID : ${this.getNumberId()})`}</Typography>
                        </div>
                        {
                            this.numberConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingNumber}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingNumber}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    number && languages && categories ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={number.language_id || -1}
                                                onChange={this.handleConfigValueChange('language_id')}
                                                label="Langue"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !number.language_id ? <MenuItem value={-1} disabled>Sélectionner une langue</MenuItem> : null
                                                }
                                                {
                                                    languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                                }
                                            </TextField>

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={number.category_id || -1}
                                                onChange={this.handleCategoryChange}
                                                label="Catégorie"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !number.category_id ? <MenuItem value={-1} disabled>Sélectionner une catégorie</MenuItem> : null
                                                }
                                                {
                                                    categories.map((c, i) => <MenuItem key={i} value={c.category_id}>{LabelEditor.getLabelValue(c.label)} ({c.number_count})</MenuItem>)
                                                }
                                            </TextField>
                                        </div>

                                        <div style={{marginBottom: 32}}>

                                            <div style={{marginBottom: 24}}>
                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={number.question}
                                                onChange={this.handleConfigValueChange('question')}
                                                label="Intitulé"
                                            />
                                            </div>

                                            <div style={{marginBottom: 24}}>
                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={number.answer}
                                                onChange={this.handleConfigValueChange('answer')}
                                                label="Réponse"
                                            />
                                            </div>

                                            <div style={{marginBottom: 24}}>
                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={number.answer_unit}
                                                onChange={this.handleConfigValueChange('answer_unit')}
                                                label="Unité / suffixe (optionnel)"
                                            />
                                            </div>

                                            <div style={{marginBottom: 24}}>
                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={number.answer_source}
                                                onChange={this.handleConfigValueChange('answer_source')}
                                                label="Source (optionnel)"
                                            />
                                            </div>

                                        </div>


                                        <div style={{marginBottom: 12}}>

                                            <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                                <TextField
                                                    onChange={this.handleConfigValueChange('onboarding_index', 'number')}
                                                    label={"Index découverte"}
                                                    value={number.onboarding_index}
                                                    color="primary"
                                                    type="number"
                                                />
                                            </div>

                                            <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                                <TextField
                                                    onChange={this.handleConfigValueChange('priority', 'number')}
                                                    label={"Priorité"}
                                                    value={number.priority}
                                                    color="primary"
                                                    type="number"
                                                />
                                            </div>


                                        </div>

                                    </div>
                                </div>

                            </Paper>

                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

CrackNumberNumberDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackNumberNumberDetails.defaultProps = {

};

export default withRouter(CrackNumberNumberDetails);