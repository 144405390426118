import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {Button, ButtonBase, IconButton, Paper, Snackbar, TextField, Toolbar, Typography} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import KVC from "../../services/KVC";
import ColorPicker from 'material-ui-color-picker'
import EditIcon from "@material-ui/core/SvgIcon/SvgIcon";
import LabelEditor from "../LabelEditor";
import classNames from "classnames";
import ListIcon from "@material-ui/icons/List";
import WordsOutImagePicker from "./WordsOutImagePicker";
import '../GroupImage.css'

class WordsOutGroupLevelDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            images: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
            selectImageOpen: false
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getAppLanguages(8),
            api.getWordsOutLevelGroup(this.getGroupId()),
            api.getWordsOutImages(),
        ]).then(results => {
            const languages = results[0];
            const group = results[1];
            const images = results[2];
            this.setState({
                languages: languages,
                group: group,
                images: images,
                $group: Object.assign({}, group),
            });
        });
    }

    getGroupId() {
        return parseInt(this.props.match.params.groupId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/groups/`);
            return;
        }
        this.props.history.goBack();
    };

    groupConfigIsPristine = () => {
        return JSON.stringify(this.state.group) === JSON.stringify(this.state.$group);
    };

    handleConfigValueChange = (propNamePath) => (e) => {
        this.handleConfigChange(propNamePath, e.target.value);
    };

    handleConfigChange = (propNamePath, value) => {
        let group = Object.assign({}, this.state.group);
        KVC(group).setValueForKeyPath(propNamePath, value);
        this.setState({
            group: group,
        });
    };

    handleStartAtValueChange = (e) => {
        let group = Object.assign({}, this.state.group);
        group.start_at = e.target.value - 1;
        this.setState({
            group: group,
        });
    };

    cancelEditingGroup = () => {
        this.setState({
            group: Object.assign(this.state.$group),
        });
    };

    commitEditingGroup = () => {
        const groupId = this.getGroupId();
        api.patchWordsOutLevelGroup(groupId, this.state.group).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement du groupe de niveaux.");
                return;
            }
            this.displaySnackbar("Groupe de niveaux sauvegardé.");
        });
    };

    onLabelEditingFinished = (label) => {
        let group = Object.assign({}, this.state.group);
        group.label = label;
        let $group = Object.assign({}, this.state.$group);
        $group.label = label;
        this.setState({
            showLabelEditor: false,
            group: group,
            $group: $group,
        });
    };

    pickImage = (e) => {
        this.setState({
            selectImageOpen: true
        })
    };

    didSelectImage = (image) => {
        let group = Object.assign({}, this.state.group);
        group.image_id = image.image_id;
        group.image_name = image.name;
        this.setState({
            selectImageOpen: false,
            group: group
        })
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    render() {

        if (this.state.group === undefined) {
            return <div/>;
        }

        const { group, languages } = this.state;

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Détails du groupe de niveaux</Typography>
                        </div>
                        {
                            this.groupConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingGroup}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingGroup}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    group && languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>


                                        <div style={{marginBottom: 24}}>
                                            <Typography variant="button">
                                                Nom du groupe
                                            </Typography>
                                            <Typography variant="body1">
                                                <ButtonBase
                                                    focusRipple
                                                    onClick={(e) => this.setState({showLabelEditor: true})}
                                                    style={{fontSize:'1rem', padding: '4px 4px 4px 0', color: '#2196f3', marginTop: 12}}
                                                >
                                                    {LabelEditor.getLabelValue(group.label)} <EditIcon style={{marginLeft: 4}}/>
                                                </ButtonBase>
                                            </Typography>
                                            <LabelEditor
                                                labelId={group.label.label_id}
                                                label={group.label}
                                                open={this.state.showLabelEditor}
                                                languages={this.state.languages.map(l => l.language_id)}
                                                onClose={this.onLabelEditingFinished}
                                                title="Nom du groupe"
                                            />
                                        </div>

                                        <div style={{marginBottom: 24}}>
                                            <TextField
                                                onChange={this.handleStartAtValueChange}
                                                label={"Démarre au niveau n°"}
                                                value={group.start_at + 1}
                                                color="primary"
                                                type="number"
                                                inputProps={{
                                                    min: "1"
                                                }}
                                            />
                                        </div>

                                        <div style={{marginBottom: 24}}>
                                            <TextField
                                                onChange={this.handleConfigValueChange('free_coin', 'number')}
                                                label={"Pièces offertes"}
                                                value={group.free_coin}
                                                color="primary"
                                                type="number"
                                            />
                                        </div>

                                        <div style={{marginBottom: 24, display: 'flex', flexWrap: 'wrap'}}>

                                            <div onClick={this.pickImage} className={classNames({
                                                'group-image': true,
                                                'group-image-selected': true
                                            })}>
                                                <img height={200} src={`/words-out/v3/thumb_${group.image_name}`}/>
                                                <div className={"group-image-overlay"}></div>
                                            </div>
                                            <Button color="primary" onClick={this.pickImage}><ListIcon/>&nbsp;Choisir une image</Button>
                                            <WordsOutImagePicker
                                                open={this.state.selectImageOpen}
                                                onClose={() => this.setState({selectImageOpen: false})}
                                                onPick={this.didSelectImage}
                                                selectedImageId={group.image_id}
                                            />

                                        </div>

                                        <div style={{marginBottom: 24}}>
                                            <ColorPicker
                                                label="Couleur de jeu"
                                                name='color'
                                                defaultValue='#000000'
                                                value={group.game_color}
                                                onChange={color => this.handleConfigChange('game_color', color)}
                                            />
                                        </div>


                                        <div style={{marginBottom: 24}}>
                                                <div style={{textAlign: 'center', fontSize: '80px', lineHeight: '140px', borderRadius: 12, border: '4px solid', backgroundColor: group.game_color, borderColor: group.text_color, width: 100, height: 150, boxShadow: '0 0 10px #000'}}>
                                                    &nbsp;
                                                </div>

                                        </div>
                                    </div>
                                </div>

                            </Paper>

                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

WordsOutGroupLevelDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

WordsOutGroupLevelDetails.defaultProps = {

};

export default withRouter(WordsOutGroupLevelDetails);