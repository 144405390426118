import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab, FormControlLabel,
    IconButton, MenuItem,
    Paper,
    Snackbar, Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import {DateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import KVC from "../../services/KVC";
import ColorPicker from "material-ui-color-picker";
import CrackListListPicker from "./CrackListListPicker";
import ListIcon from "@material-ui/icons/List";

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, "d MMM yyyy", { locale: this.locale });
    }
}

class CrackListTournaments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            tournaments: [],
            filteredTournaments: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
            createTournamentModal: false,
            createTournament: this.getCreateTournament(),
            showCreateTournamentListPicker: false,
            editedTournamentIndex: null,
            filter: 1,
            languageFilter: -1,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    getCreateTournament() {
        return {
            start_date: (+ new Date()) / 1000,
            end_date: (+ new Date() + 7 * 86400000) / 1000,
            answer_count: 12,
            language_id: undefined,
            list: null,
        };
    }

    updateData() {
        Promise.all([
            api.getAppLanguages(9),
            api.getCrackListTournaments(),
        ]).then(res => {
            const languages = res[0];
            const tournaments = res[1];
            this.setState({
                languages: languages,
                tournaments: tournaments,
                filteredTournaments: this.filterTournaments(this.state.filter, this.state.languageFilter, tournaments),
            });
        });
    }

    addNewTournament = () => {
        this.setState({
            createTournamentModal: true
        });
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    startEditingTournament = (i) => () => {
        let tournamentBackup = Object.assign({}, this.state.filteredTournaments[i]);
        let tournament = Object.assign({}, this.state.filteredTournaments[i]);
        tournament.editing = true;
        tournament.$tournament = tournamentBackup
        this.updateStateForTournament(tournament);
    };

    cancelEditingTournament = (i) => () => {
        let tournament = this.state.filteredTournaments[i];
        let backupTournament = tournament.$tournament;
        delete tournament.$tournament;
        this.updateStateForTournament(backupTournament);
    };

    commitEditingTournament = (i) => (e) => {
        let tournament = Object.assign({}, this.state.filteredTournaments[i]);

        api.patchCrackListTournament(tournament.tournament_id, tournament).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde du tournoi.");
                return;
            }

            delete tournament.$tournament;
            tournament.editing = false;
            this.updateStateForTournament(tournament);
            this.displaySnackbar("Tournoi sauvegardé !");
        });

        e.preventDefault();
    };

    handleTournamentSwitchChange = (i, propNamePath) => (e, checked) => {
        this.handleTournamentChange(i, propNamePath, checked ? 1 : 0);
    };

    handleTournamentValueChange = (i, propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleTournamentChange(i, propNamePath, parseInt(e.target.value, 10));
        } else if (type === 'date') {
            this.handleTournamentChange(i, propNamePath, Math.round(e/1000));
        } else {
            this.handleTournamentChange(i, propNamePath, e.target.value);
        }
    };

    handleTournamentChange = (i, propNamePath, value) => {
        let tournament = Object.assign({}, this.state.filteredTournaments[i]);
        KVC(tournament).setValueForKeyPath(propNamePath, value);
        this.updateStateForTournament(tournament);
    };

    onTournamentListPickerStart = (tournament) => (e) => {
        tournament.$showListPicker = true;
        this.updateStateForTournament(tournament);
    };

    onTournamentListPickerClose = (tournament) => (e) => {
        tournament.$showListPicker = false;
        this.updateStateForTournament(tournament);
    };

    onTournamentListPicked = (tournament) => (list) => {
        tournament.list = list;
        tournament.list_id = list.list_id;
        tournament.$showListPicker = false;
        this.updateStateForTournament(tournament);
    };

    updateStateForTournament(tournament) {
        let tournaments = Array.from(this.state.tournaments);
        let index = this.state.tournaments.findIndex(t => t.tournament_id === tournament.tournament_id);
        tournaments[index] = tournament;

        this.setState({
            tournaments: tournaments,
            filteredTournaments: this.filterTournaments(this.state.filter, this.state.languageFilter, tournaments),
        });
    }

    onCancelCreateTournament = () => {
        this.setState({
            createTournamentModal: false,
            createTournament: this.getCreateTournament(),
        });
    }

    onCommitCreateTournament = () => {
        api.postCrackListTournament(this.state.createTournament).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la création du tournoi.");
                return;
            }
            this.displaySnackbar('Tournoi créé');
            this.updateData();
            this.setState({
                createTournament: this.getCreateTournament(),
                createTournamentModal: false,
            });
        })
    }

    onCreateTournamentClose = () => {

    }

    onCreateTournamentLanguageChange = (e) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.language_id = e.target.value;
        this.setState({createTournament: tournament});
    }

    onCreateTournamentStartDateChange = (d) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.start_date = d/1000;
        this.setState({createTournament: tournament});
    }

    onCreateTournamentEndDateChange = (d) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.end_date = d/1000;
        this.setState({createTournament: tournament});
    }

    onCreateTournamentListPicked = (l) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.list_id = l.list_id;
        tournament.list = l;
        this.setState({
            createTournament: tournament,
            showCreateTournamentListPicker: false
        });
    }

    onCreateTournamentAnswerCountChange = (e) => {
        let tournament = Object.assign({}, this.state.createTournament);
        tournament.answer_count = parseInt(e.target.value, 10)
        this.setState({createTournament: tournament});
    }

    handleFilterChange = (e) => {
        this.setState({
            filter: e.target.value,
            filteredTournaments: this.filterTournaments(e.target.value, this.state.languageFilter, this.state.tournaments),
        });
    };

    handleLanguageFilterChange = (e) => {
        this.setState({
            languageFilter: e.target.value,
            filteredTournaments: this.filterTournaments(this.state.filter, e.target.value, this.state.tournaments),
        });
    }

    filterTournaments(filter, languageFilter, tournaments) {
        const now = Math.floor((new Date()).getTime() / 1000);
        let filteredTournaments = tournaments;
        if (languageFilter !== -1) {
            filteredTournaments = filteredTournaments.filter(t => t.language_id === languageFilter);
        }
        switch (filter) {
            case 0: return filteredTournaments;
            case 1:
                return filteredTournaments.filter(t => t.start_date <= now && (t.closed !== 1 || t.end_date + 2* 86400 >= now));
            case 2:
                return filteredTournaments.filter(t => t.start_date <= now && t.end_date >= now);
            case 3:
                return filteredTournaments.filter(t => t.start_date > now);

        }
    }

    render() {

        return (
            <React.Fragment>

                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
                    <Paper style={{paddingBottom: 190}}>
                        <Toolbar>
                            <div style={{flex: 1}}>
                                <Typography variant="h6">Niveaux</Typography>
                            </div>
                        </Toolbar>

                        <div style={{padding: '24px'}}>

                            <div style={{marginBottom: 24}}>

                                <TextField
                                    style={{marginRight: 12, width: 250}}
                                    select
                                    value={this.state.languageFilter || -1}
                                    onChange={this.handleLanguageFilterChange}
                                    label="Langue"
                                    SelectProps={{
                                        displayEmpty: true
                                    }}
                                >
                                    <MenuItem value={-1}>Toutes les langues</MenuItem>
                                    {
                                        this.state.languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                    }
                                </TextField>

                                <TextField
                                    style={{marginRight: 12, width: 250}}
                                    select
                                    onChange={this.handleFilterChange}
                                    value={this.state.filter}
                                    label="Afficher"
                                    SelectProps={{
                                        displayEmpty: true
                                    }}
                                >
                                    <MenuItem value={0}>Tous</MenuItem>
                                    <MenuItem value={1}>Visibles dans le jeu</MenuItem>
                                    <MenuItem value={2}>En cours</MenuItem>
                                    <MenuItem value={3}>À venir</MenuItem>
                                </TextField>

                            </div>
                        </div>
                        <div style={{padding: '24px 0'}}>
                            <Table size={"medium"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{padding: 5}}>Langue</TableCell>
                                        <TableCell style={{padding: 5}}>Début</TableCell>
                                        <TableCell style={{padding: 5}}>Fin</TableCell>
                                        <TableCell style={{padding: 5}}>Réponses</TableCell>
                                        <TableCell style={{padding: 5}}>Liste</TableCell>
                                        <TableCell style={{padding: 5}}>Mise</TableCell>
                                        <TableCell style={{padding: 5}}>Gains</TableCell>
                                        <TableCell style={{padding: 5}}>Free</TableCell>
                                        <TableCell style={{padding: 5}}>Vies</TableCell>
                                        <TableCell style={{padding: 5}}>Fake</TableCell>
                                        <TableCell style={{padding: 5}}>Jok.Bonus</TableCell>
                                        <TableCell style={{padding: 5}}>Jok.Poub</TableCell>
                                        <TableCell style={{padding: 5}}>Couleur</TableCell>
                                        <TableCell style={{padding: 5}}/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {this.state.filteredTournaments.map((tournament, i) => {
                                        const languageName = this.state.languages.find(l => tournament.language_id === l.language_id).name;
                                        return <TableRow key={tournament.tournament_id}>
                                            {!tournament.editing ?
                                                <React.Fragment>
                                                    <TableCell style={{
                                                        width: "110px",
                                                        padding: 5
                                                    }}>{languageName}</TableCell>
                                                    <TableCell style={{
                                                        width: "140px",
                                                        padding: 5
                                                    }}>{api.getDateFromTimestamp(tournament.start_date)}</TableCell>
                                                    <TableCell style={{
                                                        width: "140px",
                                                        padding: 5
                                                    }}>{api.getDateFromTimestamp(tournament.end_date)}</TableCell>
                                                    <TableCell style={{
                                                        width: "50px",
                                                        padding: 5
                                                    }}>{tournament.answer_count}</TableCell>
                                                    <TableCell style={{width: 250, padding: 5}}>
                                                        {!!tournament.list ? `${tournament.list.name_short} / ${tournament.list.name}` : null}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{width: "50px", padding: 5}}>{tournament.coin_cost}<span
                                                        style={{color: "#aaa", fontSize: '10px'}}> pcs</span></TableCell>
                                                    <TableCell
                                                        style={{width: "70px", padding: 5}}>{!!tournament.reward_coin_count ? `${tournament.reward_coin_count}` : ''}<span
                                                        style={{color: "#aaa", fontSize: '10px'}}> {!!tournament.reward_coin_count ? ` pcs` : 'auto'}</span></TableCell>
                                                    <TableCell style={{
                                                        width: "50px",
                                                        padding: 5
                                                    }}>{tournament.free_try_count}</TableCell>
                                                    <TableCell style={{
                                                        width: "50px",
                                                        padding: 5
                                                    }}>{tournament.lives_count}</TableCell>
                                                    <TableCell style={{
                                                        width: "50px",
                                                        padding: 5
                                                    }}>{tournament.fake_tile_count}</TableCell>
                                                    <TableCell style={{
                                                        width: "50px",
                                                        padding: 5
                                                    }}>{tournament.bonus_tile_count}</TableCell>
                                                    <TableCell style={{
                                                        width: "50px",
                                                        padding: 5
                                                    }}>{tournament.trash_count}</TableCell>

                                                    <TableCell style={{width: "60px", padding: 5}}><span style={{
                                                        display: 'inline-block',
                                                        width: 30,
                                                        height: 40,
                                                        backgroundColor: tournament.background_color
                                                    }}>&nbsp;</span></TableCell>
                                                    <TableCell style={{padding: 0}}>
                                                        <Button color="primary"
                                                                onClick={this.startEditingTournament(i)}><EditIcon/></Button> (ID {tournament.tournament_id})
                                                    </TableCell>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <TableCell style={{width: 110}}>{languageName}</TableCell>
                                                    <TableCell style={{width: "200px"}} colSpan={2}>
                                                        <DateTimePicker
                                                            value={new Date(tournament.start_date * 1000)}
                                                            format="d/MM/yyyy à H:mm"
                                                            disablePast={true}
                                                            ampm={false}
                                                            minutesStep={10}
                                                            helperText={"Date de début"}
                                                            onChange={this.handleTournamentValueChange(i, 'start_date', 'date')}/>
                                                        <DateTimePicker
                                                            value={new Date(tournament.end_date * 1000)}
                                                            format="d/MM/yyyy à H:mm"
                                                            disablePast={true}
                                                            ampm={false}
                                                            minutesStep={10}
                                                            helperText={"Date de fin"}
                                                            onChange={this.handleTournamentValueChange(i, 'end_date', 'date')}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'answer_count', 'number')}
                                                            value={tournament.answer_count}
                                                            color="primary"
                                                            label="Réponses"
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "50px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button color="primary" onClick={this.onTournamentListPickerStart(tournament)}><ListIcon/>&nbsp;Choisir une liste</Button>
                                                        <CrackListListPicker
                                                            languageId={tournament.language_id}
                                                            open={!!tournament.$showListPicker}
                                                            onClose={this.onTournamentListPickerClose(tournament)}
                                                            onPick={this.onTournamentListPicked(tournament)}
                                                        />
                                                        <div>
                                                        {!tournament.list ? 'Pas de liste choisie' :
                                                            <React.Fragment>
                                                                {tournament.list.name}<br/>{tournament.list.items.length} réponses
                                                                max
                                                            </React.Fragment>
                                                        }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell colSpan={7}>
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'coin_cost', 'number')}
                                                            value={tournament.coin_cost}
                                                            color="primary"
                                                            label="Mise"
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "50px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'reward_coin_count', 'number')}
                                                            value={tournament.reward_coin_count}
                                                            color="primary"
                                                            label="Gains"
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "70px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'free_try_count', 'number')}
                                                            value={tournament.free_try_count}
                                                            color="primary"
                                                            label="Nb."
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "50px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'lives_count', 'number')}
                                                            value={tournament.lives_count}
                                                            color="primary"
                                                            label="Vies"
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "50px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'fake_tile_count', 'number')}
                                                            value={tournament.fake_tile_count}
                                                            color="primary"
                                                            label="Fake"
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "50px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'bonus_tile_count', 'number')}
                                                            value={tournament.bonus_tile_count}
                                                            color="primary"
                                                            label="Bonus"
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "50px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                        <TextField
                                                            onChange={this.handleTournamentValueChange(i, 'trash_count', 'number')}
                                                            value={tournament.trash_count}
                                                            color="primary"
                                                            label="Poub."
                                                            type="number"
                                                            inputProps={{
                                                                form: `tournament-${tournament.tournament_id}`,
                                                                style: {width: "50px"}
                                                            }}
                                                            style={{margin: 5}}
                                                        />
                                                    </TableCell>
                                                    <TableCell colSpan={2} style={{padding: 5}}>
                                                        <ColorPicker
                                                            label="Fond"
                                                            name='background_color'
                                                            defaultValue={tournament.background_color}
                                                            value={tournament.background_color}
                                                            onChange={color => this.handleTournamentChange(i, 'background_color', color)}
                                                            style={{margin: 5}}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <form onSubmit={this.commitEditingTournament(i)}
                                                              id={`tournament-${tournament.tournament_id}`}>
                                                            <IconButton
                                                                color="secondary"
                                                                onClick={this.cancelEditingTournament(i)}
                                                            >
                                                                <CancelIcon/>
                                                            </IconButton>
                                                            <IconButton
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                <CheckCircleIcon/>
                                                            </IconButton>
                                                        </form>
                                                    </TableCell>
                                                </React.Fragment>
                                            }
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>

                    <Fab
                        color="primary"
                        style={{
                            position: 'fixed',
                            bottom: 24,
                            right: 24,
                        }}
                        onClick={this.addNewTournament}
                    >
                        <AddIcon />
                    </Fab>

                    {!this.state.createTournamentModal ? null :
                        <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.createTournamentModal}
                                onClose={this.onCreateTournamentClose}>
                            <DialogTitle>Créer un tournoi</DialogTitle>
                            <DialogContent>
                                <form>
                                    <div style={{marginBottom: 24}}>
                                    <TextField
                                        style={{marginRight: 12, width: 250}}
                                        select
                                        value={this.state.createTournament.language_id || -1}
                                        onChange={this.onCreateTournamentLanguageChange}
                                        label="Langue"
                                        SelectProps={{
                                            displayEmpty: true
                                        }}
                                    >
                                        {
                                            !this.state.createTournament.language_id ? <MenuItem value={-1} disabled>Sélectionner une langue</MenuItem> : null
                                        }
                                        {
                                            this.state.languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                        }
                                    </TextField>
                                    </div>

                                    <div style={{marginBottom: 24}}>
                                        <DateTimePicker
                                            value={new Date(this.state.createTournament.start_date * 1000)}
                                            format="d/MM/yyyy à H:mm"
                                            disablePast={true}
                                            ampm={false}
                                            minutesStep={10}
                                            helperText={"Date de début"}
                                            onChange={this.onCreateTournamentStartDateChange} />
                                    </div>
                                    <div style={{marginBottom: 24}}>
                                        <DateTimePicker
                                            value={new Date(this.state.createTournament.end_date * 1000)}
                                            format="d/MM/yyyy à H:mm"
                                            disablePast={true}
                                            ampm={false}
                                            minutesStep={10}
                                            helperText={"Date de fin"}
                                            onChange={this.onCreateTournamentEndDateChange} />
                                    </div>

                                    {
                                        !this.state.createTournament.language_id ? null :
                                            <div style={{marginBottom: 24}}>
                                                <Button color="primary" onClick={() => { this.setState({showCreateTournamentListPicker: true}) }}><ListIcon/>&nbsp;Choisir une liste</Button>
                                                <CrackListListPicker
                                                    languageId={this.state.createTournament.language_id}
                                                    open={this.state.showCreateTournamentListPicker}
                                                    onClose={() => { this.setState({showCreateTournamentListPicker: false})}}
                                                    onPick={this.onCreateTournamentListPicked}
                                                />
                                                <div>
                                                    {!this.state.createTournament.list ? 'Pas de liste choisie' :
                                                        <React.Fragment>
                                                            {this.state.createTournament.list.name}<br/>{this.state.createTournament.list.items.length} réponses
                                                            max
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                    }

                                    <div style={{marginBottom: 24}}>
                                        <TextField
                                            value={this.state.createTournament.answer_count}
                                            onChange={this.onCreateTournamentAnswerCountChange}
                                            color="primary"
                                            label="Nombre de réponses"
                                            type="number"
                                        />
                                    </div>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.onCancelCreateTournament} color="secondary">
                                    Annuler
                                </Button>
                                <Button onClick={this.onCommitCreateTournament}
                                        color="primary"
                                        disabled={!this.state.createTournament.language_id}>
                                    Créer
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }

                    <Snackbar
                        open={this.state.snackbarDisplayed}
                        message={this.state.snackbarMessage}
                        action={
                            <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                                OK
                            </Button>
                        }
                        onClose={() => this.setState({snackbarDisplayed: false})}
                        autoHideDuration={3000}
                    />
                </MuiPickersUtilsProvider>
            </React.Fragment>
        );
    }
}

CrackListTournaments.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackListTournaments.defaultProps = {};

export default CrackListTournaments;