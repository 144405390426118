import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    Typography
} from '@material-ui/core';
import EditImageDialog from "./EditImageDialog";
import {withTheme} from "@material-ui/core/styles";
import api from '../services/api'

class ImageCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDeleteConfirmDialog: false,
            showEditImageDialog: false,
        };
        this.onImageDeleteClick = this.onImageDeleteClick.bind(this);
        this.editImage = this.editImage.bind(this);
        this.onImageDeleteCancel = this.onImageDeleteCancel.bind(this);
        this.onImageDeleteAccept = this.onImageDeleteAccept.bind(this);
        this.onImageEdited = this.onImageEdited.bind(this);

    }

    onImageDeleteClick(e) {
        e.stopPropagation();
        this.setState({
            showDeleteConfirmDialog: true
        });
    }

    editImage() {
        this.setState({
            showEditImageDialog: true,
        });
    }

    onImageDeleteCancel(e) {
        this.setState({
            showDeleteConfirmDialog: false,
        });
    }

    onImageDeleteAccept(e) {
        api.deleteImage(this.props.image.image_id).then(() => {
            this.setState({
                showDeleteConfirmDialog: false
            });
            this.props.onImageDeleted(this.props.image);
        });
    }

    onImageEdited(image) {
        this.props.onImageEdited(image);
        this.setState({
            showEditImageDialog: false
        });
    }

    render () {

        const styles = {
            button: {
                float: 'right',
                marginRight: '8px',
                marginTop: '8px'
            },
            input: {
                display: 'none',
            },
            card: {
                maxWidth: 345,
            },
            media: {
                height: 200,
                position: 'relative',
                color: 'white !important'
            },
        };

        return (
            <React.Fragment>
                <Card
                    className="imageCard"

                    onClick={this.editImage}
                    style={{cursor: 'pointer'}}
                >
                    <CardMedia
                        style={styles.media}
                        image={this.props.image.thumbnail_url}
                        title={this.props.image.name}>

                        <Fab
                            size="small"
                            color="secondary"
                            style={styles.button}
                            aria-label="Supprimer"
                            onClick={this.onImageDeleteClick}>
                            <DeleteIcon />
                        </Fab>
                        <CardContent>
                            <Typography component="p" style={{
                                padding: '0.5rem 1rem',
                                backgroundImage: 'linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%)',
                                bottom: '0',
                                left: '0',
                                right: '0',
                                position: 'absolute',
                                color: 'white'
                            }}>
                                {this.props.image.name}
                            </Typography>

                        </CardContent>
                    </CardMedia>
                    <CardActions>
                        <Button size="small" color="primary" onClick={this.editImage}>
                            Modifier
                        </Button>
                    </CardActions>
                </Card>

                <Dialog
                    open={this.state.showDeleteConfirmDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="sm"
                    aria-labelledby="confirmation-dialog-title"
                >
                    <DialogTitle id="confirmation-dialog-title">
                        Suppression d'image
                    </DialogTitle>
                    <DialogContent>
                        Voulez-vous supprimer définitivement cette image ?<br/>
                        <img src={this.props.image['thumbnail_url']} alt=""
                             width={150}
                             style={{
                                 display: 'block',
                                 margin: '12px auto 0 auto'
                             }}
                             align="center"/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onImageDeleteCancel} color="default">
                            Annuler
                        </Button>
                        <Button onClick={this.onImageDeleteAccept} color="secondary">
                            <DeleteIcon/>Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>

                <EditImageDialog
                    image={this.props.image}
                    open={this.state.showEditImageDialog}
                    onImageEdited={this.onImageEdited}
                />

            </React.Fragment>
        )
    }
}

export default withTheme(ImageCard);