import React from 'react';
import Typography from "@material-ui/core/Typography";
import PermMediaIcon from '@material-ui/icons/PermMedia'
import Button from "@material-ui/core/Button";
import ImagePicker from "./ImagePicker";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import api from '../services/api'
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary
} from '@material-ui/core';
import withRouter from "react-router-dom/withRouter";
import WarningIcon from "@material-ui/icons/Warning"
import FormControlLabel from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import './PreviewImages.css'

class AppImages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appImages: [],
            filteredAppImages: [],
            unusedImages: [],
            imagePickerExpanded: false,
            appImagesExpanded: true,
            showDeleteConfirmDialog: false,
            onlyIncompleteAppImages: false,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData(callback) {
        Promise.all([
            api.getAppImages(this.props.app.app_id),
            api.getUnusedImagesForApp(this.props.app.app_id),
        ]).then(values => {
            const appImages = values[0];
            const unusedImages = values[1];
            this.setState({
                appImages: appImages,
                filteredAppImages: this.filteredAppImages(appImages, this.state.onlyIncompleteAppImages),
                unusedImages: unusedImages,
            });
            if (callback !== undefined) {
                callback(appImages, unusedImages);
            }
        });
    }

    onImageEdited = (image) => () => {
        this.props.history.push('/' + this.props.app.url_slug + '/image/' + image.image_id);
    };

    showImageDeleteDialog = (image) => () => {
        this.setState({
            showDeleteConfirmDialog: true,
            deleteConfirmImage: image,
        });
    };

    onImageDeleteCancel = (e) => {
        this.setState({
            showDeleteConfirmDialog: false,
        });
    };

    onImageDeleteAccept = (e) => {
        api.deleteAppImages(this.props.app.app_id, [{
            app_id: this.props.app.app_id,
            image_id: this.state.deleteConfirmImage.image_id
        }]).then(() => {
            this.setState({
                showDeleteConfirmDialog: false,
                deleteConfirmImage: undefined
            });
            this.updateData();

        });
    };

    onAppImagesExpand = () => {
        this.setState({
            appImagesExpanded: !this.state.appImagesExpanded,
            imagePickerExpanded: false,
        });
    };

    onImagePickerExpand = () => {
        this.setState({
            imagePickerExpanded: !this.state.imagePickerExpanded,
            appImagesExpanded: false,
        });
    };

    openImagePicker = (e) => {
        e.stopPropagation();
        this.setState({
            imagePickerExpanded: true,
            appImagesExpanded: false,
        });
    };

    openAppImages() {
        this.setState({
            imagePickerExpanded: false,
            appImagesExpanded: true,
        });
    }

    onImagePickerCancel = () => {
        this.onImagePickerExpand();
    };

    onImagePickerSave = (pickedImages, callback) => {
        api.patchAppImages(this.props.app.app_id, pickedImages.map(i => {
            return {
                'app_id': this.props.app.app_id,
                'image_id': i.image_id,
            };
        })).then(() => {
            this.updateData((appImages, unusedImages) => {
                callback(unusedImages);
                this.openAppImages();
            });
        });
    };

    filteredAppImages(appImages, onlyIncomplete) {
        if (!onlyIncomplete) {
            return Array.from(appImages)
        }
        return appImages.filter((image) => image.errors.length !== 0);
    }

    toggleIncompleteAppImagesFilter = () => {
        let newValue = !this.state.onlyIncompleteAppImages;
        this.setState({
            onlyIncompleteAppImages: newValue,
            filteredAppImages: this.filteredAppImages(this.state.appImages, newValue),
        });
    };

    render() {
        return (
            <div>
                <ExpansionPanel expanded={this.state.imagePickerExpanded} onChange={this.onImagePickerExpand}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Images inutilisées ({this.state.unusedImages.length})</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ImagePicker
                            selectable
                            images={this.state.unusedImages}
                            onCancel={this.onImagePickerCancel}
                            onSave={this.onImagePickerSave}
                            saveButtonText="Ajouter au jeu"
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.appImagesExpanded} onChange={this.onAppImagesExpand}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Images disponibles dans le jeu ({this.state.appImages.length})</Typography>
                        {
                            this.state.appImagesExpanded ? (
                                <Button
                                    color="primary"
                                    onClick={this.openImagePicker}
                                    style={{
                                        position: 'absolute',
                                        right: 48,
                                        marginTop: -8,
                                    }}>
                                    <PermMediaIcon/>&nbsp;
                                    Sélectionner des images
                                </Button>
                            ) : null
                        }
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display: 'block'}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.onlyIncompleteAppImages}
                                    onChange={this.toggleIncompleteAppImagesFilter}
                                    value="1"
                                />}
                            label="Afficher seulement les images incomplètes (mots manquants…)"
                        />
                        <ImagePicker
                            selectable={false}
                            images={this.state.filteredAppImages}
                            deletable
                            onDelete={this.showImageDeleteDialog}
                            onEdit={this.onImageEdited}
                            editable
                            showActions
                            style={{width: '100%'}}
                        />

                    </ExpansionPanelDetails>
                </ExpansionPanel>


                {
                    this.state.deleteConfirmImage ? (
                        <Dialog
                            open={this.state.showDeleteConfirmDialog}
                            disableBackdropClick
                            disableEscapeKeyDown
                            maxWidth="sm"
                            aria-labelledby="confirmation-dialog-title"
                        >
                            <DialogTitle id="confirmation-dialog-title">
                                Suppression d'image
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1" component="p">
                                    Voulez-vous supprimer cette image du jeu ?
                                </Typography>
                                <div style={{textAlign: 'center', marginTop: 12}}><WarningIcon color="secondary"/></div>
                                <Typography variant="body2" component="p">
                                    Cela supprimera l'image du pack auqel elle est associée, ainsi que toutes les données associées (mots, configuration).
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Elle restera accessible dans la collection d'images.
                                </Typography>
                                <img
                                    src={this.state.deleteConfirmImage.thumbnail_url}
                                    alt=""
                                    width={250}
                                    style={{
                                        display: 'block',
                                        margin: '24px auto 0 auto'
                                    }}
                                    align="center"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.onImageDeleteCancel} color="default">
                                    Annuler
                                </Button>
                                <Button onClick={this.onImageDeleteAccept} color="secondary">
                                    <DeleteIcon/>Supprimer
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null
                }
            </div>
        );
    }
}

export default withRouter(AppImages);