import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab, MenuItem,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import {withRouter} from "react-router-dom";
import LabelEditor from "../LabelEditor";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class CrackNumberCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            snackbarDisplayed: false,
            snackbarMessage: '',
            categories: [],
            editingCategory: null
        };
    }

    componentDidMount() {
        this.updateData(this.state.languageId);
    }

    updateData(languageId) {
        Promise.all([
            api.getAppLanguages(this.props.app.app_id),
            api.getCrackNumberCategories()
        ]).then(res => {
            const languages = res[0];
            const categories = res[1];
            this.setState({
                languages: languages,
                categories: categories,
            });
        });
    }

    editCategory = (category) => (e) => {
        this.setState({
            editingCategory: category
        })
    };

    createCategory = (e) => {
        this.props.history.push(`/${this.props.app.url_slug}/create-category/${this.state.languageId}`);
    };

    categoryName(categoryId) {
        return LabelEditor.getLabelValue(this.state.categories.find(c => c.category_id === categoryId).label)
    }

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    onCategoryEditingFinished = () => {
        this.setState({
            editingCategory: null
        })
    };

    render() {

        if (!this.state.languages) {
            return null;
        }

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Catégories</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: 100}}>ID</TableCell>
                                    <TableCell style={{width: 100}}>Stats</TableCell>
                                    <TableCell style={{width: 240}}>Nom</TableCell>
                                    <TableCell style={{width: 160}}/>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.categories.map((category, i) => (
                                    <TableRow key={category.category_id}>
                                        <TableCell>{category.category_id}</TableCell>
                                        <TableCell>🕹 {category.play_count}</TableCell>
                                        <TableCell>{this.categoryName(category.category_id)}<br/><span style={{color: '#909090', fontSize: '80%'}}>{LabelEditor.getLabelValue(category.label, 2)}</span></TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={this.editCategory(category)}><EditIcon/></Button>
                                        </TableCell>
                                        <TableCell/>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.createCategory}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />
                {
                    !!this.state.editingCategory ?
                        <LabelEditor
                            labelId={this.state.editingCategory.label_id}
                            label={this.state.editingCategory.label}
                            open={!!this.state.editingCategory}
                            languages={this.state.languages.map(l => l.language_id)}
                            onClose={this.onCategoryEditingFinished}
                            title="Nom de la catégorie"
                        /> : null
                }

            </React.Fragment>
        );
    }
}

CrackNumberCategories.propTypes = {
    app: PropTypes.object.isRequired,
};

CrackNumberCategories.defaultProps = {};

export default withRouter(CrackNumberCategories);