import React from 'react'
import {FormGroup, Icon, IconButton, TextField} from "@material-ui/core";

class SearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.onFilterTextChange = this.onFilterTextChange.bind(this);
        this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
        this.onFilterKeyPress = this.onFilterKeyPress.bind(this);
        this.onFilterKeyUp = this.onFilterKeyUp.bind(this);
        this.state = {
            filterText: ""
        };
    }

    onFilterTextChange(e) {
        this.updateFilterText(e.target.value);
    }

    onCloseButtonClick(e) {
        this.resetFilterText();
    }

    onFilterKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur()
        }
    }

    onFilterKeyUp(e) {
        if (e.key === 'Escape') {
            this.resetFilterText();
            e.target.blur();
        }
    }

    updateFilterText(filter) {
        this.setState({
            filterText: filter
        });
        this.props.onFilterChange(filter);
    }

    resetFilterText() {
        this.updateFilterText('');
    }

    render() {
        return (
            <FormGroup style={{marginBottom: '24px'}}>
                <TextField
                    autoComplete="off"
                    placeholder={this.props.placeholder}
                    fullWidth
                    margin="normal"
                    value={this.state.filterText}
                    onChange={this.onFilterTextChange}
                    InputProps={{
                        startAdornment: <IconButton><Icon>search</Icon></IconButton>,
                        endAdornment: <IconButton onClick={this.onCloseButtonClick}>{this.state.filterText.trim().length !== 0 ? <Icon>close</Icon> : null }</IconButton>
                    }}
                    onKeyPress={this.onFilterKeyPress}
                    onKeyUp={this.onFilterKeyUp}
                    helperText={this.props.helperText}
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
            </FormGroup>
        )
    }
}

export default SearchBox;