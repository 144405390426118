import React from 'react';
import PropTypes from 'prop-types';
import withRouter from "react-router-dom/withRouter";
import api from '../../services/api';
import {
    Button, FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    Snackbar, Switch, Table, TableCell, TableRow,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import DeleteIcon from '@material-ui/icons/Delete'
import KVC from "../../services/KVC";
import Icon from "@material-ui/core/Icon";
import TableBody from "@material-ui/core/TableBody";

class WoodysWordsDefinitionDetails extends React.Component {

    constructor(props) {
        super(props);
        let state = {
            languages: [],
            snackbarDisplayed: false,
            showLabelEditor: false,
        };
        if (props.match.url.includes("/create-definition")) {
            state.definition = {
                name: '',
                language_id: props.match.params.languageId,
            }
            state.$definition = Object.assign({}, state.definition);
            state.items = [];
            state.$items = [];
        }
        this.state = state;
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let requests = [
            api.getAppLanguages(11),
        ];
        const definitionId = this.getDefinitionId();
        if (!!definitionId) {
            requests.push(api.getWoodysWordsDefinition(definitionId));
        }
        Promise.all(requests).then(results => {
            const languages = results[0];
            let state = {
                languages: languages,
                newItem: ''
            };
            if (results.length > 1) {
                const definition = results[1];
                state = {
                    ...state,
                    definition: definition,
                    items: Array.from(definition.items),
                    $items: JSON.parse(JSON.stringify(definition.items)),
                    $definition: Object.assign({}, definition),
                };
            }
            this.setState(state);
        });
    }

    getDefinitionId() {
        return parseInt(this.props.match.params.definitionId, 10);
    }

    goBack = () => {
        if (this.props.history.length === 1) {
            this.props.history.push(`/${this.props.app.url_slug}/definitions/lang-${this.state.definition.language_id}/`);
            return;
        }
        this.props.history.goBack();
    };

    definitionConfigIsPristine = () => {
        return JSON.stringify(this.state.definition) === JSON.stringify(this.state.$definition);
    };

    itemsArePristine = () => {
        return JSON.stringify(this.state.items) === JSON.stringify(this.state.$items);
    };

    handleConfigValueChange = (propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleConfigChange(propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleConfigChange(propNamePath, e.target.value);
        }
    };

    handleConfigSwitchChange = (propNamePath) => (e, checked) => {
        this.handleConfigChange(propNamePath, checked);
    };

    handleConfigChange = (propNamePath, value) => {
        let definition = Object.assign({}, this.state.definition);
        KVC(definition).setValueForKeyPath(propNamePath, value);
        this.setState({
            definition: definition,
        });
    };

    cancelEditingDefinition = () => {
        this.setState({
            definition: Object.assign(this.state.$definition),
        });
    };

    commitEditingDefinition = () => {
        const definitionId = this.getDefinitionId();
        if (!!definitionId) {
            api.patchWoodysWordsDefinition(definitionId, this.state.definition).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement de la définition.");
                    return;
                }
                this.displaySnackbar("Définition sauvegardée.");
                this.setState({
                    definition: res,
                    $definition: JSON.parse(JSON.stringify(res))
                });
            });
        } else {
            api.postWoodysWordsDefinition(this.state.definition).then(res => {
                if (res === undefined) {
                    this.displaySnackbar("Erreur lors de l'enregistrement de la définition.");
                    return;
                }
                this.setState({
                    definition: res,
                    $definition: JSON.parse(JSON.stringify(res))
                });
                this.props.history.replace(`/${this.props.app.url_slug}/definition/${res.definition_id}`);
            });
        }
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    saveItems(items) {
        const definitionId = this.getDefinitionId()
        api.patchWoodysWordsDefinitionItems(definitionId, items).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'enregistrement des réponses.");
                return;
            }
            this.displaySnackbar("Réponses sauvegardées.");
            this.setState({
                $items: JSON.parse(JSON.stringify(items)),
            });
        });
    }

    commitEditingItems = (e) => {
        e.preventDefault();
        this.saveItems(this.state.items);
    }

    cancelEditingItems = (e) => {
        this.setState({
            items: JSON.parse(JSON.stringify(this.state.$items))
        });
    }

    handleItemChange = (item) => (e) => {
        let items = Array.from(this.state.items);
        items[item.item_index].name = e.target.value.toLocaleUpperCase();
        this.setState({items: items});
    }

    handleNewItemChange = (e) => {
        this.setState({
            newItem: e.target.value.toLocaleUpperCase()
        });
    }

    deleteAnswer = (index) => (e) => {
        if (!window.confirm("Supprimer cette réponse ?")) {
            return;
        }
        let items = Array.from(this.state.items);
        items.splice(index, 1);
        const definitionId = this.getDefinitionId();
        api.patchWoodysWordsDefinitionItems(definitionId, items).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la suppression de la réponse.");
                return;
            }
            this.displaySnackbar("Réponse supprimée.");
            this.setState({
                items: res,
                $items: JSON.parse(JSON.stringify(res)),
            });
        });
    }

    addItem = (e) => {
        e.preventDefault();
        const definitionId = this.getDefinitionId();
        const newItem = this.state.newItem.trim();
        if (newItem.length === 0) {
            return;
        }
        api.postWoodysWordsDefinitionItem({
            definition_id: definitionId,
            name: this.state.newItem
        }).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de l'ajout de la réponse.");
                return;
            }
            this.displaySnackbar("Réponse ajoutée.");
            this.setState({
                items: res,
                $items: JSON.parse(JSON.stringify(res)),
                newItem: ''
            });
        });
    }

    render() {

        if (this.state.definition === undefined) {
            return <div/>;
        }

        const { languages, definition, items } = this.state;

        return (
            <div className="wordsSearch">
                <Paper style={{marginBottom: 24}}>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">{!this.getDefinitionId() ? "Nouvelle définition" : `Détails de la définition (ID : ${this.getDefinitionId()})`}</Typography>
                        </div>
                        {
                            this.definitionConfigIsPristine() ? null : (
                                <React.Fragment>
                                    <Button color="secondary" onClick={this.cancelEditingDefinition}>
                                        Annuler
                                    </Button>
                                    <Button color="primary" onClick={this.commitEditingDefinition}>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Toolbar>
                </Paper>

                {
                    definition && languages ? (

                        <React.Fragment>
                            <Paper style={{padding: 24}}>
                                <div style={{display: 'flex'}}>

                                    <div style={{flex: 1}}>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: 250}}
                                                select
                                                value={definition.language_id || -1}
                                                onChange={this.handleConfigValueChange('language_id')}
                                                label="Langue"
                                                SelectProps={{
                                                    displayEmpty: true
                                                }}
                                            >
                                                {
                                                    !definition.language_id ? <MenuItem value={-1} disabled>Sélectionner une langue</MenuItem> : null
                                                }
                                                {
                                                    languages.map((l, i) => <MenuItem key={i} value={l.language_id}>{l.name}</MenuItem>)
                                                }
                                            </TextField>

                                        </div>

                                        <div style={{marginBottom: 24}}>

                                            <TextField
                                                style={{marginRight: 12, width: '100%'}}
                                                value={definition.name}
                                                onChange={this.handleConfigValueChange('name')}
                                                label="Intitulé"
                                            />

                                        </div>
                                    </div>
                                </div>

                            </Paper>

                            {
                                !this.getDefinitionId() ? null :
                                    <React.Fragment>
                                        <Paper style={{marginBottom: 24, marginTop: 24}}>
                                            <Toolbar>
                                                <Typography style={{flex: 1}} variant="h6">Réponses</Typography>
                                                <form onSubmit={this.commitEditingItems} name={"items"} id={"items"}>
                                                    {

                                                        this.itemsArePristine() ? null : (
                                                            <React.Fragment>
                                                                <Button color="secondary" onClick={this.cancelEditingItems}>
                                                                    Annuler
                                                                </Button>
                                                                <Button type={"submit"} color="primary" onClick={this.commitEditingItems}>
                                                                    Enregistrer
                                                                </Button>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </form>
                                            </Toolbar>
                                        </Paper>
                                        <Paper>
                                            <Table size={"medium"}>
                                                <TableBody>
                                                    {
                                                        items.map((it, i) =>
                                                            <TableRow key={it.item_id}>
                                                                <TableCell style={{width: 50}}>{i + 1}.</TableCell>
                                                                <TableCell style={{width: 400}}>
                                                                    <TextField value={it.name}
                                                                               onChange={this.handleItemChange(it)}
                                                                               inputProps={{
                                                                                   form: `items`
                                                                               }}
                                                                               style={{width: 350}}
                                                                    /></TableCell>
                                                                <TableCell style={{width: 30}}>
                                                                    <IconButton tabIndex={-1} onClick={this.deleteAnswer(i)}>
                                                                        <DeleteIcon style={{color: '#D00'}}/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    <TableRow>
                                                        <TableCell style={{width: 50}}>{items.length + 1}.</TableCell>
                                                        <TableCell style={{width: 250}}>
                                                            <form id={"addItem"} onSubmit={this.addItem}>
                                                                <TextField value={this.state.newItem} onChange={this.handleNewItemChange}/>
                                                            </form>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button key="add" color="primary" onClick={this.addItem}>
                                                                <Icon>add</Icon>
                                                                Ajouter
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell/>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>

                                        </Paper>
                                    </React.Fragment>
                            }

                        </React.Fragment>

                    ) : null
                }


                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </div>

        )
    }
}

WoodysWordsDefinitionDetails.propTypes = {
    app: PropTypes.object.isRequired,
};

WoodysWordsDefinitionDetails.defaultProps = {

};

export default withRouter(WoodysWordsDefinitionDetails);