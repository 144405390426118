import React from 'react';
import PropTypes from 'prop-types';
import api from "../services/api";
import PackCard from "./PackCard";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withRouter from "react-router-dom/withRouter";
import AppImageCard from "./AppImageCard";
import ErrorIcon from "@material-ui/icons/Error"
import DataErrorLink from "../tools/DataErrorLink";

class AppPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            languageId: 1,
            languages: [],
            packs: [],
            filteredPacks: [],
        };
    }

    componentDidMount() {
        const appId = this.props.app.app_id;
        Promise.all([
            api.getAppLanguages(appId),
            api.getAppDataPreview(appId),
        ]).then(res => {
            this.setState({
                languages: res[0],
                packs: res[1],
                filteredPacks: this.filterPackForLanguage(this.state.languageId, res[1]),
            });
        });
    }

    onLanguageChange = (e) => {
        const languageId = e.target.value;
        this.setState({
            languageId: languageId,
            filteredPacks: this.filterPackForLanguage(languageId, this.state.packs),
        })
    };

    filterPackForLanguage(languageId, packs) {
        let filteredPacks = packs.filter(pack => pack.languages.indexOf(languageId) !== -1);
        filteredPacks.forEach(pack => {
            let filteredImages = pack.images.filter(image => {
                if (image.languages.indexOf(languageId) === -1) {
                    return false;
                }
                let specifics = image.specifics.languages.find(sp => sp.language_id === languageId);
                if (pack.app_id === 4) {
                    return !!specifics && specifics.words.length === 8 && specifics.words.every(parts => (!parts.every(p => p.trim().length === 0)));
                }
                return !!specifics && specifics.words.length === 8 && specifics.words.every(w => w.trim().length > 0);
            });
            pack.filteredImages = filteredImages;
        });
        return filteredPacks.filter(pack => pack.images.count !== 0);
    }

    goBack = (e) => {
        this.props.history.goBack();
    };

    render() {
        return (
            <div>
                <Paper>
                    <Toolbar>
                        <IconButton onClick={this.goBack} style={{marginLeft:-16}}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Aperçu des données du jeu</Typography>
                        </div>
                        <div>
                            <TextField
                                select
                                value={this.state.languageId}
                                label="Langue"
                                color="primary"
                                onChange={this.onLanguageChange}
                                style={{width: 250}}
                            >
                                {
                                    this.state.languages.map(language => (
                                        <MenuItem key={language.language_id} value={language.language_id}>{language.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </div>
                    </Toolbar>
                </Paper>

                <div style={{height:32}}/>

                {
                    this.state.filteredPacks.map(pack => (
                        <ExpansionPanel key={pack.pack_id}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{display: 'flex'}}>
                                <Typography variant="h6" style={{flex: 1}}>
                                    {pack.index + 1} — {PackCard.getPackLabel(pack, this.state.languageId)} ({pack.filteredImages.length} images)
                                </Typography>
                                <span style={{marginRight:32, width: 50, textAlign: 'right'}}>
                                        {
                                            pack.errors.length === 0 ? null : (
                                                <Button color="secondary" >
                                                    {pack.errors.length}&nbsp;<ErrorIcon/>
                                                </Button>
                                            )
                                        }
                                    </span>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{display: 'block'}}>
                                {
                                    pack.errors.length === 0 ? null : (
                                        <Paper style={{padding: 12, marginBottom: 24, background:"#FFD9CA"}}>
                                            <ul>
                                                {
                                                    pack.errors.map((e, i) => (
                                                        <li key={i}><DataErrorLink target="_blank" app={this.props.app} error={e}/></li>
                                                    ))
                                                }
                                            </ul>
                                        </Paper>
                                    )
                                }
                                {
                                    pack.filteredImages.map(image => (
                                        <AppImageCard
                                            deletable={false}
                                            editable={false}
                                            draggable={false}
                                            image={image}
                                            index={image.index}
                                            key={image.image_id}
                                        />
                                    ))
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))
                }

            </div>
        )
    }
}


AppPreview.propTypes = {
    app: PropTypes.object.isRequired,
};

AppPreview.defaultProps = {};

export default withRouter(AppPreview);