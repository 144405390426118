import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DefaultApp extends Component {

    componentDidMount() {
        this.props.onAppBarTitleChange(this.props.app.name);
        this.props.onAppBarRightItemsChange([]);
        document.title = this.props.app.name;
    }

    render () {
        return (
            <div/>
        );
    }
}

DefaultApp.propTypes = {
    app: PropTypes.object.isRequired
};


export default DefaultApp;
