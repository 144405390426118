import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import SettingsIcon from '@material-ui/icons/Settings'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import KVC from "../../services/KVC";
import {withRouter} from "react-router-dom";

class WoodysWordsLevels extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            levels: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getWoodysWordsLevels(),
        ]).then(res => {
            const levels = res[0];
            this.setState({
                levels: levels,
            });
        });
    }

    addNewLevel = () => {
        api.postWoodysWordsLevel().then(level => {
            this.updateData();
        })
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    goToLevel = (level) => () => {
        this.props.history.push(`/${this.props.app.url_slug}/level/${level.level_id}`);
    };

    startEditingLevel = (i) => () => {
        let levelBackup = Object.assign({}, this.state.levels[i]);
        let level = Object.assign({}, this.state.levels[i]);

        let levels = Array.from(this.state.levels);
        levels[i] = level;

        level.editing = true;
        level.$level = levelBackup
        this.setState({
            levels: levels
        });
    };

    cancelEditingLevel = (i) => () => {
        let levels = Array.from(this.state.levels);
        let level = this.state.levels[i];
        level.editing = false;
        levels[i] = level.$level;
        delete level.$level;

        this.setState({
            levels: levels,
        });
    };

    commitEditingLevel = (i) => (e) => {
        let level = Object.assign({}, this.state.levels[i]);

        api.patchWoodysWordsLevel(level.level_id, level).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde du niveau.");
                return;
            }

            delete level.$level;
            level.editing = false;

            let levels = Array.from(this.state.levels);
            levels[i] = level;

            this.setState({
                levels: levels,
            });
            this.displaySnackbar("Niveau sauvegardé !");
        });

        e.preventDefault();
    };

    handleLevelValueChange = (i, propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleLevelChange(i, propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleLevelChange(i, propNamePath, e.target.value);
        }
    };

    handleLevelChange = (i, propNamePath, value) => {
        let level = Object.assign({}, this.state.levels[i]);
        KVC(level).setValueForKeyPath(propNamePath, value);

        let levels = Array.from(this.state.levels);
        levels[i] = level;

        this.setState({
            levels: levels,
        });
    };


    render() {

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Niveaux</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: 100}}>N°</TableCell>
                                    <TableCell style={{width: 100}}>Vies</TableCell>
                                    <TableCell style={{width: 100}}>Joker ABC</TableCell>
                                    <TableCell style={{width: 140}}>Joker Plus tard</TableCell>
                                    <TableCell style={{width: 140}}>Lettres magiques</TableCell>
                                    <TableCell style={{width: 150}}>Mot 1</TableCell>
                                    <TableCell style={{width: 150}}>Mot 2</TableCell>
                                    <TableCell style={{width: 150}}>Mot 3</TableCell>
                                    <TableCell style={{width: 150}}>Mot 4</TableCell>
                                    <TableCell style={{width: 200}}></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.levels.map((level, i) => (
                                    <TableRow key={level.level_id}>
                                        { !level.editing ?
                                            <React.Fragment>
                                                <TableCell>{level.level_index + 1}</TableCell>
                                                <TableCell>
                                                    {level.life_count === -1 ? 'illimité' : <span>{level.life_count}<span style={{color: "#aaa", fontSize: '10px'}}> vies</span></span>}
                                                </TableCell>
                                                <TableCell>
                                                    {level.abc_joker_count === -1 ? 'désactivé' : <span>{level.abc_joker_count}<span style={{color: "#aaa", fontSize: '10px'}}> jokers</span></span>}
                                                </TableCell>
                                                <TableCell>
                                                    {level.later_joker_count === -1 ? 'désactivé' : <span>{level.later_joker_count}<span style={{color: "#aaa", fontSize: '10px'}}> jokers</span></span>}
                                                </TableCell>
                                                <TableCell>{level.red_letter_count}</TableCell>
                                                <TableCell>{level.w1_count}<span
                                                    style={{
                                                        color: "#aaa",
                                                        fontSize: '10px'
                                                    }}> lettres</span></TableCell>
                                                <TableCell>{level.w2_count}<span
                                                    style={{
                                                        color: "#aaa",
                                                        fontSize: '10px'
                                                    }}> lettres</span></TableCell>
                                                <TableCell>{level.w3_count}<span
                                                    style={{
                                                        color: "#aaa",
                                                        fontSize: '10px'
                                                    }}> lettres</span></TableCell>
                                                <TableCell>{level.w4_count}<span
                                                    style={{
                                                        color: "#aaa",
                                                        fontSize: '10px'
                                                    }}> lettres</span>
                                                </TableCell>
                                                <TableCell>
                                                    <Button color="primary" onClick={this.startEditingLevel(i)}><EditIcon/></Button>
                                                    <Button color="primary" onClick={this.goToLevel(level)}><SettingsIcon/></Button>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        level.config_type === 'definitions' ?
                                                            <React.Fragment>Personnalisé : Définitions choisies</React.Fragment>
                                                        : (
                                                        level.config_type === 'custom' ?
                                                            <React.Fragment>Personnalisé : Définitions &amp; Mots choisis</React.Fragment>
                                                        : null )
                                                    }
                                                </TableCell>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <TableCell>{level.level_index + 1}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'life_count', 'number')}
                                                        value={level.life_count}
                                                        color="primary"
                                                        label="vies"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'abc_joker_count', 'number')}
                                                        value={level.abc_joker_count}
                                                        color="primary"
                                                        label="jokers"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'later_joker_count', 'number')}
                                                        value={level.later_joker_count}
                                                        color="primary"
                                                        label="jokers"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'red_letter_count', 'number')}
                                                        value={level.red_letter_count}
                                                        color="primary"
                                                        label="ltr. magiques"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'w1_count', 'number')}
                                                        value={level.w1_count}
                                                        color="primary"
                                                        label="lettres"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'w2_count', 'number')}
                                                        value={level.w2_count}
                                                        color="primary"
                                                        label="lettres"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'w3_count', 'number')}
                                                        value={level.w3_count}
                                                        color="primary"
                                                        label="lettres"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'w4_count', 'number')}
                                                        value={level.w4_count}
                                                        color="primary"
                                                        label="lettres"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell colSpan={2}>
                                                    <form onSubmit={this.commitEditingLevel(i)} id={`level-${level.level_id}`}>
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={this.cancelEditingLevel(i)}
                                                        >
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <IconButton
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            <CheckCircleIcon/>
                                                        </IconButton>
                                                    </form>
                                                </TableCell>

                                            </React.Fragment>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.addNewLevel}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

WoodysWordsLevels.propTypes = {
    app: PropTypes.object.isRequired,
};

WoodysWordsLevels.defaultProps = {};

export default withRouter(WoodysWordsLevels);