import React from 'react';
import DefaultApp from "./DefaultApp";
import {Tab, Tabs} from '@material-ui/core';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import AppLanguages from "./AppLanguages";
import AppConfig from "./AppConfig";
import WordsOutAppDataVersions from "./words-out/WordsOutAppDataVersions";
import CouponCodes from "./CouponCodes";
import InAppStoreProducts from "./InAppStoreProducts";
import StoreProduct from "./StoreProduct";
import WordsOutLevels from "./words-out/WordsOutLevels";
import WordsOutLevelGroups from "./words-out/WordsOutLevelGroups";
import WordsOutGroupLevelDetails from "./words-out/WordsOutGroupLevelDetails";
import WordsOutTournaments from "./words-out/WordsOutTournaments";
import WordsOutChallenges from "./words-out/WordsOutChallenges";

class WordsOut extends DefaultApp {

    render () {
        const { app, match } = this.props;
        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/product/:productId/`} render={(props) => (
                        <StoreProduct app={app}/>
                    )} />
                    <Route path={`${match.url}/group/:groupId/`} render={(props) => (
                        <WordsOutGroupLevelDetails app={app} />
                    )} />
                    <Route path={`${match.path}/:tab`}>
                        <WordsOutTabs app={app}/>
                    </Route>
                    <Route render={() => <Redirect to={`/${app.url_slug}/${WordsOutTabs.defaultTab}/`}/>} />
                </Switch>


            </div>
        );
    }
}

class WordsOutTabs extends React.Component {

    static defaultTab = "levels";

    constructor(props) {
        super(props);
        this.state = {
            currentTab: WordsOutTabs.getCurrentTab(props),
        };
    }

    static getCurrentTab(props) {
        const tabName = props.match.params.tab;
        return tabName === undefined ? WordsOutTabs.defaultTab : tabName;
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            currentTab: WordsOutTabs.getCurrentTab(props),
        };
    }

    onCurrentTabChange = (e, value) => {
        this.props.history.push('/'+this.props.app.url_slug + '/' + value);
    };

    render() {
        const { app } = this.props;
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.currentTab}
                    onChange={this.onCurrentTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        margin: '-20px -22px 22px -22px',
                        borderBottom: '1px #ddd solid',
                        background: 'white',
                    }}
                    scrollable="true"
                    scrollButtons="auto"
                >
                    <Tab
                        value="levels"
                        label="Niveaux"
                    />
                    <Tab
                        value="groups"
                        label="Groupes"
                    />
                    <Tab
                        value="challenges"
                        label="Défis du jour"
                    />
                    <Tab
                        value="store"
                        label="Boutique"
                    />
                    <Tab
                        value="publish"
                        label="Publication"
                    />
                    <Tab
                        value="coupon-codes"
                        label="Codes promo"
                    />
                    <Tab
                        value="languages"
                        label="Langues"
                    />
                    <Tab
                        value="tournaments"
                        label="Tournois"
                    />
                </Tabs>

                <Switch>
                    <Route exact path={`/${app.url_slug}/levels/`} render={(props) => (
                        <WordsOutLevels app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/groups/`} render={(props) => (
                        <WordsOutLevelGroups app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/challenges/`} render={(props) => (
                        <WordsOutChallenges app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/languages/`} render={(props) => (
                        <AppLanguages app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/tournaments/`} render={(props) => (
                        <WordsOutTournaments app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/config/`} render={(props) => (
                        <AppConfig app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/store/`} render={(props) => (
                        <InAppStoreProducts app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/publish/`} render={(props) => (
                        <WordsOutAppDataVersions app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/coupon-codes/`} render={(props) => (
                        <CouponCodes app={app}/>
                    )} />
                    <Route/>
                </Switch>
            </React.Fragment>
        );
    }
}

WordsOutTabs = withRouter(WordsOutTabs);

export default withRouter(WordsOut);
