import React from 'react';
import PropTypes from 'prop-types';
import {DragSource, DropTarget} from 'react-dnd';

class CrackListPartyOrderedPenalty extends React.Component {

    render() {

        const {
            penalty,
            connectDragSource,
            connectDropTarget,
        } = this.props;

        return connectDragSource(connectDropTarget(
            <div>
                <div style={{
                    flex: 1,
                    margin: 4,
                }}>
                    <div
                        style={{
                            border: '1px #eee solid',
                            padding: 4,
                            width: 450,
                            cursor: 'pointer',
                            backgroundColor: '#eee',
                            borderRadius: 4,
                            fontWeight: 700,
                            color: '#000'
                        }}
                    >
                        {this.props.discoveryIndex + 1} - {penalty.name}
                    </div>
                </div>

            </div>
        ));
    }
}

CrackListPartyOrderedPenalty.propTypes = {
    penalty: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    draggable: PropTypes.bool,
    onMove: PropTypes.func.isRequired,
    onDragBegin: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    discoveryIndex: PropTypes.number.isRequired,
};

CrackListPartyOrderedPenalty.defaultProps = {
    draggable: false,
};

const CrackListPartyPenaltyDragItemType = 'Penalty';

const cardTarget = {

    canDrop(props, monitor) {
        return true;
    },

    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        if (dragIndex === hoverIndex) {
            return;
        }

        props.onMove(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};

const cardSource = {

    canDrag(props) {
        return props.draggable;
    },

    beginDrag(props) {
        props.onDragBegin(props.index);
        return {
            id: props.penalty.penalty_id,
            index: props.index,
        };
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }
        props.onDragEnd();
    }
};

export default DropTarget(CrackListPartyPenaltyDragItemType, cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
}))(DragSource(CrackListPartyPenaltyDragItemType, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))(CrackListPartyOrderedPenalty));