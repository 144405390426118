import React from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography} from '@material-ui/core';
import {Link} from "react-router-dom";

class AppConfig extends React.Component {
    render() {
        return (
            <Paper>
                <Toolbar>
                    <div style={{flex: 1}}>
                        <Typography variant="h6">Configuration de l'app</Typography>
                    </div>
                    <Link target="_blank" to={`https://console.firebase.google.com/u/1/project/${this.props.app.firebase_id}/config`}>
                        <Button color="primary">Accéder à Firebase Remote Config</Button>
                    </Link>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Clé Firebase</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.app.remote_config.map((config) => {
                            return (
                                <TableRow key={config.config_id}>
                                    <TableCell>
                                        <pre style={{fontSize:'1.1rem'}}>{config.field}</pre>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{config.name}</Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

AppConfig.propTypes = {
    app: PropTypes.object.isRequired,
};

AppConfig.defaultProps = {};

export default AppConfig;