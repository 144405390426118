import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import LabelEditor from "../LabelEditor";
import {withRouter} from "react-router-dom";

class WordsOutLevelGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getWordsOutLevelGroups(),
        ]).then(res => {
            const groups = res[0];
            this.setState({
                groups: groups,
            });
        });
    }

    addNewGroup = () => {
        api.postWordsOutLevelGroup().then(group => {
            this.updateData();
        })
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    goToGroup = (group) => () => {
        this.props.history.push(`/${this.props.app.url_slug}/group/${group.group_id}`);
    };

    render() {

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Groupes de niveaux</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>
                        <Table size={"medium"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: "100px"}}></TableCell>
                                    <TableCell style={{width: "250px"}}>Nom</TableCell>
                                    <TableCell style={{width: "150px"}}>Pièces offertes</TableCell>
                                    <TableCell style={{width: "100px"}}>Image</TableCell>
                                    <TableCell>Couleurs</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.groups.map((group, i) => (
                                    <TableRow key={group.group_id} onClick={this.goToGroup(group)}>
                                            <TableCell>Niveau {group.start_at + 1}+</TableCell>
                                            <TableCell>{LabelEditor.getLabelValue(group.label)}</TableCell>
                                            <TableCell>{group.free_coin}</TableCell>
                                            <TableCell><img src={`/words-out/v3/thumb_${group.image_name}`} height={80}/></TableCell>
                                            <TableCell>
                                                <div style={{
                                                    textAlign: 'left',
                                                    fontSize: '35px',
                                                    lineHeight: '57px',
                                                    borderRadius: 8,
                                                    border: '2px solid',
                                                    backgroundColor: group.game_color,
                                                    borderColor: '#fff',
                                                    width: 40,
                                                    height: 60,
                                                    boxShadow: '0 0 4px #000'
                                                }}>
                                                    &nbsp;
                                                </div>
                                            </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.addNewGroup}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

WordsOutLevelGroups.propTypes = {
    app: PropTypes.object.isRequired,
};

WordsOutLevelGroups.defaultProps = {};

export default withRouter(WordsOutLevelGroups);