import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    MenuItem,
    Paper,
    Snackbar,
    TextField,
    Toolbar,
    Typography,
    Table, TableHead, TableBody, TableRow, TableCell, IconButton
} from "@material-ui/core";
import api from '../../services/api'
import EditIcon from '@material-ui/icons/Edit'
import withRouter from "react-router-dom/withRouter";
import SettingsIcon from "@material-ui/icons/Settings";
import KVC from "../../services/KVC";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ListIcon from "@material-ui/icons/List";
import classNames from "classnames";
import WordsOutImagePicker from "./WordsOutImagePicker";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

class WordsOutChallenges extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            months: [],
            pickImageMonth: null,
            pickImageKey: null
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([api.getWordsOutNextChallenges()]).then(res => {
            const months = res[0];
            this.setState({
                months: months,
            });
        });
    }

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    startEditingChallenge = (i, j) => () => {
        let challenge = Object.assign({}, this.state.months[i].challenges[j]);
        let challengeBackup = Object.assign({}, challenge);

        let months = Array.from(this.state.months);
        let challenges = Array.from(months[i].challenges);
        challenges[j] = challenge;

        challenge.editing = true;
        challenge.$challenge = challengeBackup
        months[i].challenges = challenges

        this.setState({
            months: months
        });
    };

    cancelEditingChallenge = (i, j) => () => {
        let months = Array.from(this.state.months);
        let challenge = months[i].challenges[j];
        challenge.editing = false;
        months[i].challenges[j] = challenge.$challenge;
        delete challenge.$challenge;

        this.setState({
            months: months,
        });
    };

    commitEditingChallenge = (i, j) => (e) => {
        let months = Array.from(this.state.months);
        let challenge = Object.assign({}, months[i].challenges[j]);

        api.patchWordsOutChallenge(challenge.challenge_id, challenge).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde du défi.");
                return;
            }

            delete challenge.$challenge;
            challenge.editing = false;
            months[i].challenges[j] = challenge

            this.setState({
                months: months,
            });
            this.displaySnackbar("Défi du jour sauvegardé !");
        });

        e.preventDefault();
    };

    handleChallengeValueChange = (i, j, propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleChallengeChange(i, j, propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleChallengeChange(i, propNamePath, e.target.value);
        }
    };

    handleChallengeChange = (i, j, propNamePath, value) => {
        let challenge = Object.assign({}, this.state.months[i].challenges[j]);
        KVC(challenge).setValueForKeyPath(propNamePath, value);

        let months = Array.from(this.state.months);
        let challenges = Array.from(months[i].challenges);
        challenges[j] = challenge;
        months[i].challenges = challenges;

        this.setState({
            months: months,
        });
    };

    pickImage = (month, key) => e => {
        this.setState({
            pickImageMonth: month,
            pickImageKey: key
        })
    };

    didSelectImage = (image) => {
        let monthIndex = this.state.months.findIndex(m => m.month.uuid === this.state.pickImageMonth.uuid)
        let monthGroup = Object.assign({}, this.state.months[monthIndex]);
        monthGroup.month[this.state.pickImageKey] = image.image_id;
        monthGroup.month[`${this.state.pickImageKey}_name`] = image.name;
        let months = Array.from(this.state.months);
        months[monthIndex] = monthGroup;
        this.setState({
            months: months,
            pickImageMonth: null,
            pickImageKey: null,
        });
    }

    monthConfigIsPristine = (i) => {
        return JSON.stringify(this.state.months[i].month) === JSON.stringify(this.state.months[i].$month);
    };

    handleConfigValueChange = (i, propNamePath) => (e) => {
        this.handleConfigChange(i, propNamePath, parseInt(e.target.value, 10));
    };

    handleConfigChange = (i, propNamePath, value) => {
        let monthGroup = Object.assign({}, this.state.months[i]);
        monthGroup.month[propNamePath] = value;
        let months = Array.from(this.state.months);
        months[i] = monthGroup;
        this.setState({
            months: months
        });
    };

    startEditingMonth = (i) => e => {
        let monthGroup = Object.assign({}, this.state.months[i]);
        monthGroup.editing = true;
        monthGroup.$month = Object.assign({}, monthGroup.month);
        let months = Array.from(this.state.months);
        months[i] = monthGroup;
        this.setState({
            months: months
        });
    };

    cancelEditingMonth = (i, finishEditing) => e => {
        let monthGroup = Object.assign({}, this.state.months[i]);
        monthGroup.editing = !finishEditing;
        monthGroup.month = monthGroup.$month;
        let months = Array.from(this.state.months);
        months[i] = monthGroup;
        this.setState({
            months: months
        });
    }

    commitEditingMonth = (i) => e => {
        let monthGroup = Object.assign({}, this.state.months[i]);

        api.patchWordsOutChallengeMonth(monthGroup.month.uuid, monthGroup.month).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde de la configuration du mois.");
                return;
            }

            delete monthGroup.$month;
            monthGroup.editing = false;

            let months = Array.from(this.state.months);
            months[i] = monthGroup;

            this.setState({
                months: months,
            });
            this.displaySnackbar("Configuration du mois enregistrée !");
        });

        e.preventDefault();
    };

    render() {

        const todayDate = new Date();

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Défis du jour</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>

                        <Table style={{display: 'none'}} size={"small"}>
                            <TableHead></TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        {
                            this.state.months.map((monthGroup, i) => {
                                const yearIndex = parseInt(monthGroup.month.uuid.substring(0, 4), 10);
                                const monthIndex = parseInt(monthGroup.month.uuid.substring(4), 10) - 1;
                                const monthDate = new Date(yearIndex, monthIndex)
                                const monthDisplayName = monthDate.toLocaleDateString("fr-FR", {month: 'long', year: 'numeric'});
                                return <div key={monthGroup.month.group_id} style={{paddingTop: 40}}>
                                    <h1 style={{display: "table-cell", verticalAlign: "middle"}}>{monthDisplayName}
                                    {
                                        !monthGroup.editing ? (
                                            <React.Fragment>
                                                <Button color="primary" onClick={this.startEditingMonth(i)}><EditIcon/></Button>
                                            </React.Fragment>
                                        ) : null
                                    }
                                    </h1>


                                    {
                                        !monthGroup.editing ? (
                                            <div style={{borderRadius: 12, backgroundColor: '#f8f8f8', padding: 20, marginTop: 12, marginBottom: 12}}>
                                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div className={classNames({
                                                            'group-image': true,
                                                        })} style={{marginBottom: -6}}>
                                                            <img height={200} src={`/words-out/v3/thumb_${monthGroup.month.image_1_name}`}/>
                                                        </div>
                                                        0 à {monthGroup.month.reward_1_count - 1} victoires
                                                    </div>
                                                    <div style={{textAlign: 'center', marginRight: 12}}>
                                                        RÉCOMPENSE 1<br/>
                                                        <img src="/ww-coin.png" width="40" height="42"/><br/>
                                                        {monthGroup.month.reward_1 <= 0 ? "(Auto)" : `${monthGroup.month.reward_1} pièces`}
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div className={classNames({
                                                            'group-image': true,
                                                        })} style={{marginBottom: -6}}>
                                                            <img height={200} src={`/words-out/v3/thumb_${monthGroup.month.image_2_name}`}/>
                                                        </div>
                                                        {monthGroup.month.reward_1_count} à {monthGroup.month.reward_2_count - 1} victoires
                                                    </div>
                                                    <div style={{textAlign: 'center', marginRight: 12}}>
                                                        RÉCOMPENSE 2<br/>
                                                        <img src="/ww-coin.png" width="40" height="42"/><br/>
                                                        {monthGroup.month.reward_2 <= 0 ? "(Auto)" : `${monthGroup.month.reward_2} pièces`}
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div className={classNames({
                                                            'group-image': true,
                                                        })} style={{marginBottom: -6}}>
                                                            <img height={200} src={`/words-out/v3/thumb_${monthGroup.month.image_3_name}`}/>
                                                        </div>
                                                        {monthGroup.month.reward_2_count} victoires ou plus
                                                    </div>
                                                    <div style={{textAlign: 'center', marginRight: 12}}>
                                                        RÉCOMPENSE 3<br/>
                                                        <img src="/ww-coin.png" width="40" height="42"/><br/>
                                                        {monthGroup.month.reward_2 <= 0 ? "(Auto)" : `${monthGroup.month.reward_2} pièces`}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{borderRadius: 12, backgroundColor: '#f8f8f8', padding: 20, marginTop: 12, marginBottom: 12}}>
                                                <div style={{marginBottom: 20, paddingBottom: 8, borderBottom: '1px #ccc solid'}}>
                                                <Button color="primary" onClick={this.cancelEditingMonth(i, true)}>
                                                    Fermer
                                                </Button>
                                                {
                                                    this.monthConfigIsPristine(i) ? null : (
                                                        <React.Fragment>
                                                            &nbsp; - &nbsp;
                                                            <Button color="secondary" onClick={this.cancelEditingMonth(i, false)}>
                                                                Reset
                                                            </Button>
                                                            <Button color="primary" onClick={this.commitEditingMonth(i)}>
                                                                Enregistrer
                                                            </Button>
                                                        </React.Fragment>
                                                    )
                                                }
                                                </div>
                                                <div>
                                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                                        <TextField
                                                            onChange={this.handleConfigValueChange(i, 'reward_1', 'number')}
                                                            label={"Récompense n°1"}
                                                            value={monthGroup.month.reward_1 || 0}
                                                            color="primary"
                                                            type="number"
                                                            style={{width: 130}}
                                                        />
                                                    </div>

                                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                                        <TextField
                                                            onChange={this.handleConfigValueChange(i, 'reward_2', 'number')}
                                                            label={"Récompense n°2"}
                                                            value={monthGroup.month.reward_2 || 0}
                                                            color="primary"
                                                            type="number"
                                                            style={{width: 130}}
                                                        />
                                                    </div>

                                                    <div style={{marginBottom: 24, marginRight: 48, display: 'inline-block'}}>
                                                        <TextField
                                                            onChange={this.handleConfigValueChange(i, 'reward_3', 'number')}
                                                            label={"Récompense n°3"}
                                                            value={monthGroup.month.reward_3 || 0}
                                                            color="primary"
                                                            type="number"
                                                            style={{width: 130}}
                                                        />
                                                    </div>

                                                    0 = automatique (cf. Firebase)
                                                </div>

                                                <div>
                                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                                        <TextField
                                                            onChange={this.handleConfigValueChange(i, 'reward_1_count', 'number')}
                                                            label={"Victoires pour récompense 1"}
                                                            value={monthGroup.month.reward_1_count}
                                                            color="primary"
                                                            type="number"
                                                            inputProps={{
                                                                min: 5,
                                                                max: monthGroup.month.reward_2_count - 1
                                                            }}
                                                            style={{width: 210}}
                                                        />
                                                    </div>

                                                    <div style={{marginBottom: 24, marginRight: 24, display: 'inline-block'}}>
                                                        <TextField
                                                            onChange={this.handleConfigValueChange(i, 'reward_2_count', 'number')}
                                                            label={"Victoires pour récompense 2"}
                                                            value={monthGroup.month.reward_2_count}
                                                            color="primary"
                                                            type="number"
                                                            inputProps={{
                                                                min: monthGroup.month.reward_1_count + 1,
                                                                max: 25
                                                            }}
                                                            style={{width: 210}}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div onClick={this.pickImage(monthGroup.month, 'image_1')} className={classNames({
                                                            'group-image': true,
                                                        })} style={{marginBottom: -6}}>
                                                            <img height={200} src={`/words-out/v3/thumb_${monthGroup.month.image_1_name}`}/>
                                                            <div className={"group-image-overlay"}/>
                                                        </div>
                                                        <Button style={{marginLeft: -6}} color="primary" onClick={this.pickImage(monthGroup.month, 'image_1')}>Image n°1</Button>
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div onClick={this.pickImage(monthGroup.month, 'image_2')} className={classNames({
                                                            'group-image': true,
                                                        })} style={{marginBottom: -6}}>
                                                            <img height={200} src={`/words-out/v3/thumb_${monthGroup.month.image_2_name}`}/>
                                                            <div className={"group-image-overlay"}/>
                                                        </div>
                                                        <Button style={{marginLeft: -6}} color="primary" onClick={this.pickImage(monthGroup.month, 'image_2')}>Image n°2</Button>
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div onClick={this.pickImage(monthGroup.month, 'image_3')} className={classNames({
                                                            'group-image': true,
                                                        })} style={{marginBottom: -6}}>
                                                            <img height={200} src={`/words-out/v3/thumb_${monthGroup.month.image_3_name}`}/>
                                                            <div className={"group-image-overlay"}/>
                                                        </div>
                                                        <Button style={{marginLeft: -6}} color="primary" onClick={this.pickImage(monthGroup.month, 'image_3')}>Image n°3</Button>
                                                    </div>
                                                </div>
                                                <WordsOutImagePicker
                                                    open={this.state.pickImageMonth && this.state.pickImageMonth.uuid === monthGroup.month.uuid}
                                                    onClose={() => { this.setState({pickImageMonth: null, pickImageKey: null})}}
                                                    onPick={this.didSelectImage}
                                                    selectedImageId={!!this.state.pickImageMonth ? this.state.pickImageMonth[this.state.pickImageKey] : -1}
                                                />
                                            </div>
                                        )

                                    }


                                    <table className={"MuiTable-root"}>
                                        <thead className={"MuiTableHead-root"}>
                                            <tr className={"MuiTableRow-root MuiTableRow-head"}>
                                                <th className={"MuiTableCell-root MuiTableCell-head"} scope="col" style={{width: 200}}>Jour</th>
                                                <th className={"MuiTableCell-root MuiTableCell-head"} scope="col" style={{width: 110}}>Cartes</th>
                                                <th className={"MuiTableCell-root MuiTableCell-head"} scope="col" style={{width: 110}}>Score</th>
                                                <th className={"MuiTableCell-root MuiTableCell-head"} scope="col" style={{width: 110}}>Lettres</th>
                                                <th className={"MuiTableCell-root MuiTableCell-head"} scope="col" style={{width: 110}}>Lignes</th>
                                                <th className={"MuiTableCell-root MuiTableCell-head"} scope="col"/>
                                            </tr>
                                        </thead>
                                        <tbody className={"MuiTableBody-root"}>
                                    {
                                        monthGroup.challenges.map((challenge, j) => {
                                            const dayIndex = parseInt(challenge.uuid.substring(6));
                                            const challengeDate = new Date(yearIndex, monthIndex, dayIndex);

                                            if (todayDate.getFullYear() === yearIndex && todayDate.getMonth() === monthIndex && dayIndex < todayDate.getDate()) {
                                                return null
                                            }
                                            if (!challenge.editing) {
                                                return <tr className={"MuiTableRow-root"} key={challenge.challenge_id}>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        {challengeDate.toLocaleDateString("fr-FR")}
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <span>{challenge.card_count}<span style={{
                                                            color: "#aaa",
                                                            fontSize: '10px'
                                                        }}> cartes</span></span>
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <span>{challenge.target_score}<span
                                                            style={{color: "#aaa", fontSize: '10px'}}> pts</span></span>
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <span>{challenge.letter_count}</span>
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <span>{challenge.line_count}</span>
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <Button color="primary" onClick={this.startEditingChallenge(i, j)}><EditIcon/></Button>
                                                    </td>
                                                </tr>
                                            } else {
                                                return <tr className={"MuiTableRow-root"} key={challenge.challenge_id}>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        {challengeDate.toLocaleDateString("fr-FR")}
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <TextField
                                                            onChange={this.handleChallengeValueChange(i, j, 'card_count', 'number')}
                                                            value={challenge.card_count}
                                                            color="primary"
                                                            label="cartes"
                                                            type="number"
                                                            inputProps={{
                                                                form: `challenge-${challenge.challenge_id}`,
                                                                style: {width: "100px"}
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <TextField
                                                            onChange={this.handleChallengeValueChange(i, j, 'target_score', 'number')}
                                                            value={challenge.target_score}
                                                            color="primary"
                                                            label="pts"
                                                            type="number"
                                                            inputProps={{
                                                                form: `challenge-${challenge.challenge_id}`,
                                                                style: {width: "100px"}
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <TextField
                                                            onChange={this.handleChallengeValueChange(i, j, 'letter_count', 'number')}
                                                            value={challenge.letter_count}
                                                            color="primary"
                                                            label="lettres"
                                                            type="number"
                                                            inputProps={{
                                                                form: `challenge-${challenge.challenge_id}`,
                                                                style: {width: "100px"}
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <TextField
                                                            onChange={this.handleChallengeValueChange(i, j, 'line_count', 'number')}
                                                            value={challenge.line_count}
                                                            color="primary"
                                                            label="lignes"
                                                            type="number"
                                                            inputProps={{
                                                                form: `challenge-${challenge.challenge_id}`,
                                                                style: {width: "100px"}
                                                            }}
                                                        />
                                                    </td>
                                                    <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-sizeSmall"}>
                                                        <form onSubmit={this.commitEditingChallenge(i, j)} id={`challenge-${challenge.challenge_id}`}>
                                                            <IconButton
                                                                color="secondary"
                                                                onClick={this.cancelEditingChallenge(i, j)}
                                                            >
                                                                <CancelIcon/>
                                                            </IconButton>
                                                            <IconButton
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                <CheckCircleIcon/>
                                                            </IconButton>
                                                        </form>
                                                    </td>
                                                </tr>
                                            }
                                        })
                                    }
                                        </tbody>
                                    </table>
                                </div>
                            })
                        }


                    </div>
                </Paper>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

WordsOutChallenges.propTypes = {
    app: PropTypes.object.isRequired,
};

WordsOutChallenges.defaultProps = {};

export default withRouter(WordsOutChallenges);