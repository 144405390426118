import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Fab,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import api from '../../services/api'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import KVC from "../../services/KVC";

class WordsOutLevels extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            levels: [],
            snackbarDisplayed: false,
            snackbarMessage: '',
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        Promise.all([
            api.getWordsOutLevels(),
        ]).then(res => {
            const levels = res[0];
            this.setState({
                levels: levels,
            });
        });
    }

    addNewLevel = () => {
        api.postWordsOutLevel().then(level => {
            this.updateData();
        })
    };

    displaySnackbar(message) {
        this.setState({
            snackbarDisplayed: true,
            snackbarMessage: message,
        });
    }

    startEditingLevel = (i) => () => {
        let levelBackup = Object.assign({}, this.state.levels[i]);
        let level = Object.assign({}, this.state.levels[i]);

        let levels = Array.from(this.state.levels);
        levels[i] = level;

        level.editing = true;
        level.$level = levelBackup
        this.setState({
            levels: levels
        });
    };

    cancelEditingLevel = (i) => () => {
        let levels = Array.from(this.state.levels);
        let level = this.state.levels[i];
        level.editing = false;
        levels[i] = level.$level;
        delete level.$level;

        this.setState({
            levels: levels,
        });
    };

    commitEditingLevel = (i) => (e) => {
        let level = Object.assign({}, this.state.levels[i]);

        api.patchWordsOutLevel(level.level_id, level).then(res => {
            if (res === undefined) {
                this.displaySnackbar("Erreur lors de la sauvegarde du niveau.");
                return;
            }

            delete level.$level;
            level.editing = false;

            let levels = Array.from(this.state.levels);
            levels[i] = level;

            this.setState({
                levels: levels,
            });
            this.displaySnackbar("Niveau sauvegardé !");
        });

        e.preventDefault();
    };


    handleLevelValueChange = (i, propNamePath, type) => (e) => {
        if (type === 'number') {
            this.handleLevelChange(i, propNamePath, parseInt(e.target.value, 10));
        } else {
            this.handleLevelChange(i, propNamePath, e.target.value);
        }
    };

    handleLevelChange = (i, propNamePath, value) => {
        let level = Object.assign({}, this.state.levels[i]);
        KVC(level).setValueForKeyPath(propNamePath, value);

        let levels = Array.from(this.state.levels);
        levels[i] = level;

        this.setState({
            levels: levels,
        });
    };


    render() {

        return (
            <React.Fragment>
                <Paper>
                    <Toolbar>
                        <div style={{flex: 1}}>
                            <Typography variant="h6">Niveaux</Typography>
                        </div>
                    </Toolbar>

                    <div style={{padding: '24px'}}>
                        <Table size={"medium"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>N°</TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>Lettres/ligne</TableCell>
                                    <TableCell>Lignes</TableCell>
                                    <TableCell>Lettres totales</TableCell>
                                    <TableCell>Lettres ?</TableCell>
                                    <TableCell>Lettres &times;2</TableCell>
                                    <TableCell>Lettres &times;3</TableCell>
                                    <TableCell>Lettres bombes</TableCell>
                                    <TableCell>Lettres +</TableCell>
                                    <TableCell>Lettres ++</TableCell>
                                    <TableCell>Lettres <DeleteIcon/></TableCell>
                                    <TableCell>Gains pièces</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.levels.map((level, i) => (
                                    <TableRow key={level.level_id}>
                                        { !level.editing ?
                                            <React.Fragment>
                                                <TableCell style={{width: "100px"}}>{level.level_index + 1}</TableCell>
                                                <TableCell style={{width: "200px"}}>{level.target_score}<span style={{color: "#aaa", fontSize: '10px'}}> points</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.letter_count}<span style={{color: "#aaa", fontSize: '10px'}}> lettres</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.line_count}<span style={{color: "#aaa", fontSize: '10px'}}> lignes</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.card_count}<span style={{color: "#aaa", fontSize: '10px'}}> cartes</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.special_mystery_count}<span style={{color: "#aaa", fontSize: '10px'}}> ?</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.special_double_count}<span style={{color: "#aaa", fontSize: '10px'}}> &times;2</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.special_triple_count}<span style={{color: "#aaa", fontSize: '10px'}}> &times;3</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.special_bomb_count}<span style={{color: "#aaa", fontSize: '10px'}}> bombes</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.special_more_card_count}<span style={{color: "#aaa", fontSize: '10px'}}> +</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.special_even_more_card_count}<span style={{color: "#aaa", fontSize: '10px'}}> ++</span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.special_trash_count}<span style={{color: "#aaa", fontSize: '10px'}}> <DeleteIcon/></span></TableCell>
                                                <TableCell style={{width: "200px"}}>{level.coin_reward}<span style={{color: "#aaa", fontSize: '10px'}}> pièces</span></TableCell>
                                                <TableCell>
                                                    <Button color="primary" onClick={this.startEditingLevel(i)}><EditIcon/></Button>
                                                </TableCell>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <TableCell>{level.level_index + 1}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'target_score', 'number')}
                                                        value={level.target_score}
                                                        color="primary"
                                                        label="Score"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'letter_count', 'number')}
                                                        value={level.letter_count}
                                                        color="primary"
                                                        label="lettres"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'line_count', 'number')}
                                                        value={level.line_count}
                                                        color="primary"
                                                        label="lignes"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'card_count', 'number')}
                                                        value={level.card_count}
                                                        color="primary"
                                                        label="Cartes"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'special_mystery_count', 'number')}
                                                        value={level.special_mystery_count}
                                                        color="primary"
                                                        label="?"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'special_double_count', 'number')}
                                                        value={level.special_double_count}
                                                        color="primary"
                                                        label="x2"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'special_triple_count', 'number')}
                                                        value={level.special_triple_count}
                                                        color="primary"
                                                        label="x3"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'special_bomb_count', 'number')}
                                                        value={level.special_bomb_count}
                                                        color="primary"
                                                        label="bombes"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'special_more_card_count', 'number')}
                                                        value={level.special_more_card_count}
                                                        color="primary"
                                                        label="+"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'special_even_more_card_count', 'number')}
                                                        value={level.special_even_more_card_count}
                                                        color="primary"
                                                        label="++"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'special_trash_count', 'number')}
                                                        value={level.special_trash_count}
                                                        color="primary"
                                                        label="Poub."
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        onChange={this.handleLevelValueChange(i, 'coin_reward', 'number')}
                                                        value={level.coin_reward}
                                                        color="primary"
                                                        label="Gains"
                                                        type="number"
                                                        inputProps={{
                                                            form: `level-${level.level_id}`,
                                                            step: 3,
                                                            style: {width: "100px"}
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <form onSubmit={this.commitEditingLevel(i)} id={`level-${level.level_id}`}>
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={this.cancelEditingLevel(i)}
                                                        >
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <IconButton
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            <CheckCircleIcon/>
                                                        </IconButton>
                                                    </form>
                                                </TableCell>
                                            </React.Fragment>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>

                <Fab
                    color="primary"
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                    }}
                    onClick={this.addNewLevel}
                >
                    <AddIcon />
                </Fab>

                <Snackbar
                    open={this.state.snackbarDisplayed}
                    message={this.state.snackbarMessage}
                    action={
                        <Button color="secondary" size="small" onClick={() => this.setState({snackbarDisplayed: false})}>
                            OK
                        </Button>
                    }
                    onClose={() => this.setState({snackbarDisplayed: false})}
                    autoHideDuration={3000}
                />

            </React.Fragment>
        );
    }
}

WordsOutLevels.propTypes = {
    app: PropTypes.object.isRequired,
};

WordsOutLevels.defaultProps = {};

export default WordsOutLevels;