import React from 'react';
import PropTypes from 'prop-types';
import {DragSource, DropTarget} from 'react-dnd';
import LabelEditor from "../LabelEditor";

class CrackListPartyOrderedPack extends React.Component {

    render() {

        const {
            pack,
            isDragging,
            connectDragSource,
            connectDropTarget,
        } = this.props;

        return connectDragSource(connectDropTarget(
            <div>
                <div style={{
                    flex: 1,
                    margin: 12,
                }}>
                    <div
                        style={{
                            border: '1px #eee solid',
                            padding: 8,
                            width: 250,
                            cursor: 'pointer',
                            backgroundColor: pack.color,
                            borderRadius: 4,
                            fontWeight: 700,
                            color: '#fff'
                        }}
                    >
                        {LabelEditor.getLabelValue(pack.name, 1, 'Pack sans nom')}
                    </div>
                </div>

            </div>
        ));
    }
}

CrackListPartyOrderedPack.propTypes = {
    pack: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    draggable: PropTypes.bool,
    onMove: PropTypes.func.isRequired,
    onDragBegin: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
};

CrackListPartyOrderedPack.defaultProps = {
    draggable: false,
};

const CrackListPartyPackDragItemType = 'Pack';

const cardTarget = {

    canDrop(props, monitor) {
        return true;
    },

    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        if (dragIndex === hoverIndex) {
            return;
        }

        props.onMove(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};

const cardSource = {

    canDrag(props) {
        return props.draggable;
    },

    beginDrag(props) {
        props.onDragBegin(props.index);
        return {
            id: props.pack.pack_id,
            index: props.index,
        };
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }
        props.onDragEnd();
    }
};

export default DropTarget(CrackListPartyPackDragItemType, cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
}))(DragSource(CrackListPartyPackDragItemType, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))(CrackListPartyOrderedPack));