import React from "react"
import PropTypes from 'prop-types'
import {CircularProgress, IconButton, MenuItem, TextField} from "@material-ui/core";
import api from '../services/api'
import RefreshIcon from "@material-ui/icons/Refresh"

class AppleInAppPurchaseSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            updating: true,
        }
    }

    componentDidMount() {
        api.getInAppPurchaseList(this.props.appId, 'ios').then(products => {
            this.productsReceived(products);
        });
    }

    updateProducts = () => {
        this.setState({
            updating: true,
        });
        api.postInAppPurchaseList(this.props.appId, 'ios').then(products => {
            this.productsReceived(products);
        });
    };

    productsReceived(products) {
        this.setState({
            products: [{
                name: '(aucun)',
                product_id: null,
            }].concat(products.sort((a, b) => a.pricing.tier > b.pricing.tier)),
            updating: false,
        });
    }

    onChange = (e) => {
        let product = this.state.products.find(p => p.product_id === e.target.value);
        this.props.onChange(product)(e);
    };

    render() {

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                    style={{flex: 1}}
                    fullWidth
                    value={!!this.props.value ? this.props.value : ''}
                    onChange={this.onChange}
                    label="Produit in-app iOS"
                    disabled={this.props.disabled}
                    select
                    SelectProps={{
                        displayEmpty: true,
                    }}
                    error={this.props.error}
                    helperText={this.state.updating ? 'L\'actualisation peut prendre un peu de temps…' : ''}
                >
                    {
                        this.state.products.map(iap => (
                            <MenuItem key={iap.product_id} value={iap.product_id}>
                                {
                                    iap.product_id === null ? "(aucun)" : (
                                        <React.Fragment>
                                            {iap.name}
                                            <span style={{color: '#aaa'}}>
                                            &nbsp;— {`${iap.pricing.retail_price_label} (tier ${iap.pricing.tier})`}
                                        </span>
                                            &nbsp;{(iap.consumable ? '' : '> Non-consommable')}
                                        </React.Fragment>
                                    )
                                }
                            </MenuItem>
                        ))
                    }
                </TextField>
                <div style={{marginTop:16}}>
                    {
                        this.state.updating ? (
                            <CircularProgress size={25} thickness={6} style={{marginLeft: 12}} />
                        ) : (
                            <IconButton
                                color="primary"
                                onClick={this.updateProducts}
                                disabled={this.props.disabled}
                            >
                                <RefreshIcon/>
                            </IconButton>
                        )
                    }
                </div>
            </div>
        );
    }
}

AppleInAppPurchaseSelect.propTypes = {
    appId: PropTypes.number.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

AppleInAppPurchaseSelect.defaultProps = {
    disabled: false,
    error: false,
};

export default AppleInAppPurchaseSelect;