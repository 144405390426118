import React from 'react';
import PropTypes from 'prop-types';
import api from "../../services/api";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@material-ui/core';
import {DataGrid} from "@material-ui/data-grid";

class WoodysWordsDefinitionPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            definitions: [],
            filteredDefinitions: [],
            nameFilter: '',
        };

        this.columns = [
            {
                field: 'name',
                headerName: 'Intitulé',
                width: 400,
            },
            {
                field: 'item_count',
                headerName: 'Réponses',
            },
            {
                field: 'definition_id',
                headerName: ' ',
                renderCell: (params) => {
                    return <Button color="primary"
                                   onClick={this.pickDefinition(params.data)}>Choisir</Button>
                },
                sortable: false
            }
        ]

    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            open: props.open,
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.open || prevState.open === this.state.open) {
            return;
        }
        this.updateDefinitions();
    }

    updateDefinitions() {
        Promise.all([api.getWoodysWordsDefinitions(this.props.languageId)]).then(res => {
            let definitions = res[0].map(d => {
                return {
                    ...d,
                    id: d.definition_id,
                }
            });

            this.setState({
                definitions: definitions,
                filteredDefinitions: Array.from(definitions),
                nameFilter: '',
            });

        });
    }

    pickDefinition = (definition) => (e) => {
        this.props.onPick(definition);
    };

    cancelEditing = () => {
        this.props.onClose();
    };

    handleNameFilterChange = (e) => {
        this.setState({
            nameFilter: e.target.value,
            filteredDefinitions: this.state.definitions.filter(d => this.definitionMatchesFilters(d, e.target.value)),
        })
    }

    definitionMatchesFilters(definition, nameFilter) {
        return (!nameFilter || definition.name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1)
    }

    render() {

        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="form-dialog-title"
                style={{minWidth: 800}}
                maxWidth="xl"
                onClose={ (e, reason) => this.cancelEditing() }
            >
                {
                    !this.state.definitions ? null :

                        <form noValidate autoComplete="off" onSubmit={this.commitEditing}>
                            <DialogTitle id="form-dialog-title">Choisir une définition</DialogTitle>
                            <DialogContent>
                                <br/>

                                {this.state.definitions.length === 0 ? null :


                                    <div style={{marginBottom: 24, marginLeft: 10}}>

                                        <TextField
                                            style={{marginRight: 24, width: 300}}
                                            value={this.state.nameFilter}
                                            label="Intitulé"
                                            placeholder="Filtrer sur l'intitulé"
                                            onChange={this.handleNameFilterChange}
                                        />

                                    </div>
                                }
                                <div style={{ display: 'flex', width: 1250, height: 800}}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGrid
                                            rowHeight={40}
                                            hideFooterSelectedRowCount={false}
                                            columns={this.columns}
                                            rows={this.state.filteredDefinitions}
                                            pageSize={this.state.pageSize}
                                            rowsPerPageOptions={[25, 50]}
                                            autoHeight={true}
                                        />
                                    </div>
                                </div>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.cancelEditing} color="default">
                                    Annuler
                                </Button>
                            </DialogActions>
                        </form>
                }
            </Dialog>
        )
    }
}

WoodysWordsDefinitionPicker.propTypes = {
    languageId: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onPick: PropTypes.func.isRequired,
    level: PropTypes.object,
};

export default WoodysWordsDefinitionPicker;