import React from 'react';
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete';
import {Avatar, Button, Card, CardActions, CardContent, CardMedia, Chip, Fab, Tooltip, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {DragSource, DropTarget} from 'react-dnd';
import {withStyles} from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error"
import classNames from "classnames"


class ImageCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            selected: props.selected
        };
    }

    onCardClick = (e) => {
        if (this.props.selectable) {
            this.props.onSelect();
        } else if (this.props.editable) {
            this.props.onEdit();
        }
    };

    onImageDeleteClick = (e) => {
        e.stopPropagation();
        this.props.onDelete();
    };

    render() {

        const styles = {
            button: {
                float: 'right',
                marginRight: '8px',
                marginTop: '8px'
            },
            input: {
                display: 'none',
            },
            card: {
                maxWidth: 345,
            },
            media: {
                height: 200,
                position: 'relative',
                color: 'white !important'
            },
        };


        const {
            image,
            isDragging,
            connectDragSource,
            connectDropTarget,
            classes,
        } = this.props;

        return connectDragSource(connectDropTarget(
            <div>
                <Card
                    className="imageCard"
                    onClick={this.onCardClick}
                    style={{cursor: 'pointer', opacity: isDragging ? 0.3 : 1.}}
                >
                    <CardMedia
                        style={styles.media}
                        image={image.thumbnail_url}
                        title={image.name}>

                        {
                            this.props.deletable ? (
                                <Fab
                                    size="small"
                                    color="secondary"
                                    style={styles.button}
                                    aria-label="Supprimer"
                                    onClick={this.onImageDeleteClick}>
                                    <DeleteIcon/>
                                </Fab>
                            ) : null
                        }

                        <CardContent>

                            {
                                this.state.selected ?
                                    <div style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                                        position: 'absolute',
                                        right: 0,
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        textAlign: 'center',
                                    }}>
                                        <div style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 48,
                                            height: 48,
                                            borderRadius: 24,
                                            backgroundColor: 'white',
                                            fontSize: '3em',

                                        }}>
                                            <CheckCircleIcon
                                                color="primary"
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '48px',
                                                    height: '48px'
                                                }}
                                            />
                                        </div>
                                    </div> : null
                            }

                            <Typography component="p" style={{
                                padding: '0.5rem 1rem',
                                backgroundImage: 'linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%)',
                                bottom: '0',
                                left: '0',
                                right: '0',
                                position: 'absolute',
                                color: 'white'
                            }}>
                                {this.props.image.name}
                            </Typography>

                        </CardContent>
                    </CardMedia>

                    {
                        this.props.editable && this.props.showActions
                            ? (
                                <CardActions>
                                    <Button size="small" color="primary" onClick={this.props.onEdit}>
                                        Modifier
                                    </Button>
                                    {
                                        !image.errors || image.errors.length === 0 ? null : (
                                            <Tooltip
                                                title={image.errors.map(e => e.message).join("\n")}
                                                placement="bottom"
                                                classes={{
                                                    popper: classNames(classes.errorTooltip)
                                                }}
                                            >
                                                <Chip
                                                    className={classNames(classes.chip, classes.chipError)}
                                                    label={`${image.errors.length}`}
                                                    avatar={<Avatar
                                                        className={classNames(classes.chipAvatar, classes.chipErrorAvatar)}><ErrorIcon
                                                        className={classNames(classes.chipAvatarIcon, classes.chipErrorIcon)}/></Avatar>}
                                                />
                                            </Tooltip>
                                        )
                                    }
                                </CardActions>
                            ) : null
                    }
                </Card>
            </div>
        ))
    }
}

function requireIfDraggable(error) {
    return (props, propName, componentName) => {
        if ((!!props.draggable && (props[propName] === undefined || typeof(props[propName]) !== 'function'))) {
            return new Error(error);
        }
    };
}

ImageCard.propTypes = {
    deletable: PropTypes.bool,
    editable: PropTypes.bool,
    image: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    onSelect: PropTypes.func,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    showActions: PropTypes.bool,
    index: PropTypes.number.isRequired,
    draggable: PropTypes.bool,
    onMove: requireIfDraggable('Please provide a onMove(sourceIndex, targetIndex) function as prop.'),
    onDragBegin: requireIfDraggable('Please provide a onDragBegin(index) function as prop.'),
    onDragEnd: requireIfDraggable('Please provide a onDragEnd() function as prop.'),
};

ImageCard.defaultProps = {
    selectable: false,
    editable: false,
    deletable: false,
    showActions: false,
    onEdit: () => {
    },
    onSelect: () => {
    },
    draggable: false,
};

const AppImageDragItemType = 'AppImageCard';


const styles = theme => ({
    chipContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chip: {
        margin: theme.spacing(0.5),
        height: 24
    },
    chipAvatar: {
        width: 22,
        height: 22,
        color: 'currentColor !important',
        fontSize: 'inherit !important',
    },
    chipAvatarIcon: {
        width: 16,
        height: 16,
    },
    chipError: {
        background: "#f4d6d6",
        color: "#904c4d"
    },
    chipErrorIcon: {
        color: '#fff'
    },
    chipErrorAvatar: {
        background: "#d26f71"
    },
    errorTooltip: {
        whiteSpace: 'pre-line',
    }
});

const cardTarget = {

    canDrop(props, monitor) {
        return true;
    },

    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        if (dragIndex === hoverIndex) {
            return;
        }

        props.onMove(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};

const cardSource = {

    canDrag(props) {
        return props.draggable;
    },

    beginDrag(props) {
        props.onDragBegin(props.index);
        return {
            id: props.image.image_id,
            index: props.index,
        };
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }
        props.onDragEnd();
    }
};

export default DropTarget(AppImageDragItemType, cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
}))(DragSource(AppImageDragItemType, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))(withStyles(styles)(ImageCard)));