import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom"

class DataErrorLink extends React.Component {

    getNavLinkTarget() {
        switch (this.props.error.type) {
            case 'image':
                return `/${this.props.app.url_slug}/image/${this.props.error.image_id}`;
            case 'pack':
                return `/${this.props.app.url_slug}/pack/${this.props.error.pack_id}`;
            default:
                return "";
        }
    }

    render() {
        return (
            <NavLink to={this.getNavLinkTarget()} target={this.props.target}>{this.props.error.message}</NavLink>
        );
    }
}

DataErrorLink.propTypes = {
    app: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    target: PropTypes.string,
};
DataErrorLink.defaultProps = {
    target: '_self'
};

export default DataErrorLink;