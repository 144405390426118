import React from 'react';
import DefaultApp from "./DefaultApp";
import {Tab, Tabs} from '@material-ui/core';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import AppLanguages from "./AppLanguages";
import AppConfig from "./AppConfig";
import CrackListAppDataVersions from "./crack-list/CrackListAppDataVersions";
import CouponCodes from "./CouponCodes";
import InAppStoreProducts from "./InAppStoreProducts";
import StoreProduct from "./StoreProduct";
import CrackListLists from "./crack-list/CrackListLists";
import CrackListListDetails from "./crack-list/CrackListListDetails";
import CrackListLevels from "./crack-list/CrackListLevels";
import CrackListLevelDetails from "./crack-list/CrackListLevelDetails";
import CrackListLevelGroupDetails from "./crack-list/CrackListLevelGroupDetails";
import CrackListLevelGroups from "./crack-list/CrackListLevelGroups";
import CrackListTournaments from "./crack-list/CrackListTournaments";

class CrackList extends DefaultApp {

    render () {
        const { app, match } = this.props;
        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/product/:productId/`} render={(props) => (
                        <StoreProduct app={app}/>
                    )} />
                    <Route path={`${match.url}/create-list/:languageId/`} render={(props) => (
                        <CrackListListDetails app={app} />
                    )} />
                    <Route path={`${match.url}/list/:listId/`} render={(props) => (
                        <CrackListListDetails app={app} />
                    )} />
                    <Route path={`${match.url}/level/:levelId/`} render={(props) => (
                        <CrackListLevelDetails app={app} />
                    )} />
                    <Route path={`${match.url}/group/:groupId/`} render={(props) => (
                        <CrackListLevelGroupDetails app={app} />
                    )} />
                    <Route exact path={`${match.url}/lists/`} render={() => <Redirect to={`/${app.url_slug}/lists/lang--1/100-1`} />} />
                    <Route path={`${match.path}/:tab`}>
                        <CrackListTabs app={app}/>
                    </Route>
                    <Route render={() => <Redirect to={`/${app.url_slug}/${CrackListTabs.defaultTab}/`}/>} />
                </Switch>


            </div>
        );
    }
}

class CrackListTabs extends React.Component {

    static defaultTab = "lists";

    constructor(props) {
        super(props);
        this.state = {
            currentTab: CrackListTabs.getCurrentTab(props),
        };
    }

    static getCurrentTab(props) {
        const tabName = props.match.params.tab;
        return tabName === undefined ? CrackListTabs.defaultTab : tabName;
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            currentTab: CrackListTabs.getCurrentTab(props),
        };
    }

    onCurrentTabChange = (e, value) => {
        this.props.history.push('/'+this.props.app.url_slug + '/' + value);
    };

    render() {
        const { app } = this.props;
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.currentTab}
                    onChange={this.onCurrentTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        margin: '-20px -22px 22px -22px',
                        borderBottom: '1px #ddd solid',
                        background: 'white',
                    }}
                    scrollable="true"
                    scrollButtons="auto"
                >
                    <Tab
                        value="levels"
                        label="Niveaux"
                    />
                    <Tab
                        value="groups"
                        label="Groupes"
                    />
                    <Tab
                        value="lists"
                        label="Listes"
                    />
                    <Tab
                        value="store"
                        label="Boutique"
                    />
                    <Tab
                        value="publish"
                        label="Publication"
                    />
                    <Tab
                        value="coupon-codes"
                        label="Codes promo"
                    />
                    <Tab
                        value="languages"
                        label="Langues"
                    />
                    <Tab
                        value="tournaments"
                        label="Tournois"
                    />
                </Tabs>

                <Switch>
                    <Route path={`/${app.url_slug}/lists/lang-:languageId/:pageSize-:page`} render={(props) => (
                        <CrackListLists app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/levels/`} render={(props) => (
                        <CrackListLevels app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/groups/`} render={(props) => (
                        <CrackListLevelGroups app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/languages/`} render={(props) => (
                        <AppLanguages app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/tournaments/`} render={(props) => (
                        <CrackListTournaments app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/config/`} render={(props) => (
                        <AppConfig app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/store/`} render={(props) => (
                        <InAppStoreProducts app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/publish/`} render={(props) => (
                        <CrackListAppDataVersions app={app}/>
                    )} />
                    <Route exact path={`/${app.url_slug}/coupon-codes/`} render={(props) => (
                        <CouponCodes app={app}/>
                    )} />
                    <Route/>
                </Switch>
            </React.Fragment>
        );
    }
}

CrackListTabs = withRouter(CrackListTabs);

export default withRouter(CrackList);
